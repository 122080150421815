import React, { useRef, useEffect, useState, useCallback } from 'react'
import FontAwesome from 'react-fontawesome'

import { useDetectOutsideClick } from '../../hooks'

import './style.css'

function Tabs({className = "", children, ...rest}){
  return(
    <div className={`tabs ${className}`} {...rest}>
      {children}
    </div>
  )
}

function Menu({className = "", children, ...rest}){
  const wrapper = useRef(null)
  const tabMenu = useRef(null)
  const [compact, toggleCompact] = useState(false)
  const [text, setText] = useState("Menu")
  const [isActive, setIsActive] = useDetectOutsideClick({wrapper, menu: tabMenu, initialState: false})

  const check = useCallback(() => {
    const widthsArray = Array.from(tabMenu.current.children).map(item => item.getBoundingClientRect().width)
    const itemsWidth = widthsArray.reduce((accumulator, curr) => accumulator + curr)
    const menuWidth = tabMenu.current?.getBoundingClientRect()?.width
    const _compact = itemsWidth >= menuWidth
    if (_compact){
      setText(Array.from(tabMenu.current.children).find(ch => ch.classList.contains("active")).children[0].innerText)
    }

    toggleCompact(_compact)
  }, [toggleCompact])

  useEffect(() => {
    window.addEventListener('resize', check)
    check()

    return () => {
      window.removeEventListener('resize', check)
    }
  }, [check])

  return(
    <div ref={wrapper} className={`tabs-menu ${className} ${compact ? "compact" : ""}`} {...rest}>
      {compact &&
        <div className="toggle" onClick={() => setIsActive(!isActive)}><FontAwesome name="bars" />{text}</div>
      }
      <ul ref={tabMenu} style={compact && isActive ? {height: tabMenu.current?.scrollHeight} : {}}>{children}</ul>
    </div>
  )
}

function Button({id, className = "", children, tabs = {}, ...rest}){

  const { selectedTab, changeTab } = tabs

  return(
    <li className={`${selectedTab === id ? "active" : ""}`}>
      <button id={`${id}-tab-button`} className={`tabs-button ${className}`} {...rest} onClick={() => changeTab(id)}>
        {children}
      </button>
    </li>
  )
}

function Content({className = "", children, ...rest}){
  return(
    <div className={`tabs-content ${className}`} {...rest}>
      {children}
    </div>
  )
}

function Tab({id, className = "", children, tabs = {}, ...rest}){

  const { selectedTab } = tabs

  if (selectedTab !== id) return null

  return(
    <div id={`${id}-tab`} className={`tab ${className}`} {...rest}>
      {children}
    </div>
  )
}

const tabs = { Tabs, Menu, Button, Content, Tab }

export default tabs
