import { useQuery } from '@apollo/client'

import { IS_LOGGED_IN, ME_QUERY } from '../graphql'

export function useAuth(){
    const isLoggedIn = useQuery(IS_LOGGED_IN)
    const {loading, error, data} = useQuery(ME_QUERY, {skip: !isLoggedIn?.data?.isLoggedIn, notifyOnNetworkStatusChange: true})

    function userCanDo(){
        return !!data?.me?.superAdmin
    }

    return {
        isLoggedIn: !!isLoggedIn?.data?.isLoggedIn, 
        loading, 
        error, 
        user: data?.me, 
        userCanDo
    }
}