import React, { useContext } from 'react'
import { useMutation } from '@apollo/client'
import FontAwesome from 'react-fontawesome'

import { IntlContext } from '../../../contexts'
import { Form, IntlText, EditBox, PhoneNumberEditBox } from '../../../components'
import { toast } from '../../../helpers'
import { EDIT_RIDER_MUTATION } from '../../../graphql'

export function RiderContactDetails({rider}){

  const { getTranslation } = useContext(IntlContext)
  const [editRider] = useMutation(EDIT_RIDER_MUTATION)

  async function save(_data){
    const alert = toast.loading()

    try {
      await editRider({variables: {id: rider.id, data: _data}})
      return alert.success(getTranslation({id: "edit-success"}))
    } catch (e) {
      alert.error(e?.message)
    }
  }
  
  return(
    <div id="rider-details">
      <Form.Form>
        <Form.Field>
          <PhoneNumberEditBox verificationStatus={rider.celVerificationStatus} placeholder={<IntlText group="rider-contact" id="phone" />} value={rider.cel} save={(value) => save({cel: value})} />
        </Form.Field>
        <Form.Field>
          <VerifiedEditBox verified={rider.emailVerified} placeholder={<IntlText group="rider-contact" id="email" />} save={(value) => save({email: value})}>{rider.email}</VerifiedEditBox>
        </Form.Field>   
        <Form.Legend><IntlText group="rider-contact" id="emergencyContact" /></Form.Legend>
        <Form.Field>
          <EditBox.Text placeholder={getTranslation({group: "rider-contact", id: "emergencyContactName"})} save={(value) => save({emergencyContactName: value})}>{rider.emergencyContactName}</EditBox.Text>
        </Form.Field>
        <Form.Field>
          <EditBox.Text placeholder={getTranslation({group: "rider-contact", id: "emergencyContactRelation"})} save={(value) => save({emergencyContactRelation: value})}>{rider.emergencyContactRelation}</EditBox.Text>
        </Form.Field>
        <Form.Field>
          <EditBox.PhoneNumber placeholder={<IntlText group="rider-contact" id="emergencyContactPhone" />} value={rider.emergencyContact} save={(value) => save({emergencyContact: value})} />
        </Form.Field>
      </Form.Form>
    </div>
  )

}

function VerifiedEditBox({verified, save, ...props}){

  function getIcon(){
    switch (verified) {
      case true:
        return <FontAwesome name="check" style={{color: 'var(--valid-color)'}}/>
      case false:
        return <FontAwesome name="times" style={{color: 'var(--invalid-color)'}}/>
      default:
        return null
    }
  }

  return <EditBox.Text icon={getIcon()} {...props} />
}
