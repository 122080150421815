import { gql } from '@apollo/client'

const GET_MERCADO_PAGO_PAYMENT = gql`
  query getMercadoPagoPayment($id: String!){
     getMercadoPagoPayment(id: $id){
       id
       status
       status_detail
       payer{
         first_name
         last_name
         email
       }
       date_created
       date_approved
       transaction_amount
       transaction_amount_refunded
       money_release_date
       fee_details{
         type
         amount
       }
       refunds{
         id
         payment_id
         date_created
       }
       transaction_details{
         net_received_amount
       }
     }
  }
`

export default GET_MERCADO_PAGO_PAYMENT
