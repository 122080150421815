import React, { useRef } from 'react'
import ReactDOM from 'react-dom'
import FontAwesome from 'react-fontawesome'

import { useDetectOutsideClick, useFitInView } from '../../hooks'
import { Button as UIButton, MediaQuery, IntlText, Title, Loader } from '../../components'

import './style.css'

function Bar({className = "", vertical, children, ...rest}){
  return(
    <div className={`filter-bar ${className} ${vertical ? "vertical" : ""}`} {...rest}>
      {children}
    </div>
  )
}

function Dropdown({value, placeholder, children, reset, right, disableInClickClose, run, inlineMenu, ...rest}){

  const wrapper = useRef(null)
  const menu = useRef(null)
  const [isActive, setIsActive] = useDetectOutsideClick({wrapper, menu, initialState: false, disableInClickClose, inlineMenu})

  useFitInView({element: menu, offset: 40})

  function _run(){
    setIsActive(false)
    run()
  }  

  return(
    <div ref={wrapper} className="filter-dropdown" {...rest}>
      <div className={`filter-dropdown-toggle ${!!value ? "selected" : ""}`} onClick={() => setIsActive(!isActive)}><span>{value || placeholder}</span>{!!value && isActive ? <FontAwesome name="trash" onClick={reset}/> : <FontAwesome name="chevron-down" />}</div>
      <MediaQuery max={900}>
        {isActive && ReactDOM.createPortal(
          <div className="portal-modal">
            <div className='modal'>
              <div className="inner">
                <div className="modal-header">
                  <Title tag="h3">{placeholder}</Title>
                </div>
                <div className="modal-content">
                  {children}
                </div>
                <div className="modal-footer">
                  <UIButton size="small" theme="alt" onClick={reset}><IntlText id="clear" /></UIButton>
                  {run &&
                    <UIButton theme="main" onClick={_run} ><IntlText id="go" /></UIButton>                
                  }
                </div>
              </div>
            </div>
          </div>
        , document.getElementById('modal-portal'))}
      </MediaQuery>
      <MediaQuery min={900}>
        <div className={`filter-dropdown-menu ${isActive ? "opened" : ""} ${right ? "right" : ""}`} ref={menu}>
          {children}
          <div className="actions">
            <UIButton size="small" theme="alt" onClick={reset}><IntlText id="clear" /></UIButton>
            {run &&
              <UIButton theme="main" onClick={_run} ><IntlText id="go" /></UIButton>                
            }            
          </div>
        </div>
      </MediaQuery>
    </div>
  )

}

function Select({children, ...rest}){
  return(
    <Dropdown {...rest}>
      <ul className="filter-select">
        {children}
      </ul>
    </Dropdown>
  )
}

function SelectItem({children, active, ...rest}){
  return(
    <li {...rest}>
      <div>
        {active ? <FontAwesome style={{color: "var(--main-color)"}} name="dot-circle" /> : <FontAwesome name="circle" /> }
        <span>{children}</span>
      </div>
    </li>
  )
}


function Button({active, children, reset, onClick, ...rest}){

  return(
    <div {...rest}>
      <button className={`filter-button ${!!active ? "selected" : ""}`} onClick={active ? reset : onClick}>
        {children}
        {!!active && <FontAwesome name="trash" />}
      </button>
    </div>
  )

}

function Submit({icon, children, onClick}){

  function showIcon(){
    switch (typeof icon) {
      case "string":
        return <div className="icon"><FontAwesome name={icon} /></div>
      default:
        return <div className="icon">{icon}</div>
    }
  }

  return(
    <button className="filter-submit" onClick={onClick}>
      {icon && showIcon()}
      {children}
    </button>
  )

}

function Input({value, icon, reset = () => console.log("Reset not defined"), loading, ...rest}){

  return(
    <div className={`filter-input ${!!value ? "selected" : ""}`}>
      <input autoComplete="off" value={value || ""} {...rest}/>
      {loading && <Loader loading={loading} />}
      {!!value && !loading && <FontAwesome onClick={reset} name="trash" />}
    </div>
  )

}

function ActiveParams({activeParams, removeParam, clearParams}){

  if (!(!!activeParams?.length)) return false

  return(
    <div className="filter-active-params">
      {activeParams?.length >= 2 &&
        <div key="clear" onClick={() => clearParams()}>
          <span>Clear all</span>
          <FontAwesome name="times-circle" />
        </div>
      }
      {activeParams.map((param, key) =>
        <div key={key} onClick={() => removeParam(param?.key)}>
          <span>{param?.label}</span>
          <FontAwesome name="trash" />
        </div>
      )}
    </div>
  )

}

const filters = { Bar, Select, SelectItem, Button, Dropdown, Input, ActiveParams, Submit }

export default filters
