import React from 'react'
import FontAwesome from 'react-fontawesome'
import Tippy from '@tippyjs/react'

import { formatPrice } from '../../../helpers'
import { IntlText, IntlValue} from '../../../components'

import './style.css'

export function PurchaseProducts({vendor, children, products}) {
  return(
    <div className="purchase-products">
        {products.map((product, key) =>
            <div key={key} className="purchase-product">
                <div className="product-name-box">
                    <div className="product-name">
                        <span className="name">
                            <IntlValue>{product.product.name}</IntlValue>
                            {product.alternative &&
                            <div className="alternative"><Tippy content={<IntlText group="purchase-products" id={product.alternative} />}><div><FontAwesome name="question-circle" /></div></Tippy></div>
                            }
                        </span>
                        <span className="category"><Tippy content={<IntlText group="purchase-products" id="category-name" />}><div><FontAwesome name="list-alt" /><IntlValue>{product.category.name}</IntlValue></div></Tippy></span>
                    </div>
                    {product.addons && product.addons.length > 0 &&
                        <div className="addons">
                            {product.addons.map((addon, addonKey) =>
                                <div key={addonKey} className="addon">
                                    <div className="selected-options">
                                        {addon.selectedOptions.map((selectedOption, selectedOptionKey) =>
                                            <div key={selectedOptionKey} className="selected-option">
                                                <span className='quantity'>{selectedOption.quantity || 1} x </span>
                                                <span><IntlValue>{selectedOption.name}</IntlValue></span>
                                                {selectedOption.price > 0 &&
                                                    <span className="price"> - {formatPrice(selectedOption.price)}</span>
                                                }
                                            </div>
                                        )}
                                    </div>
                                </div>
                            )}
                        </div>
                    }
                    {product.instructions &&
                        <span className="instructions">{product.instructions}</span>
                    }
                </div>
                <div className="product-price-box">
                    <span className="qty">{product.quantity}</span>
                    x 
                    <span className="price">{formatPrice(product.totalPricePerProduct)}</span>
                    {vendor &&
                        <Tippy content={<IntlText group="purchase-products" id="vendor-price" />}>
                            <div style={{fontSize: "var(--font-xs)", color: "var(--ui-placeholder)", display: "flex", alignItems: "center", marginTop: 5}}>
                                <i className="fas fa-hat-chef" style={{fontSize: "var(--font-xxs)", color: "var(--ui-placeholder)"}} />
                                <span style={{paddingTop: 3, marginLeft: 5}}>{formatPrice(product.totalPricePerProduct * (1-vendor.serviceFee))}</span>
                            </div>
                        </Tippy>
                    }
                </div>
                <div className="product-actions">
                    {children && children({key, product})}
                </div>
            </div>
        )}
    </div> 
  )
}