import { gql } from '@apollo/client'

const INVOICE_FRAGMENT = gql`
  fragment INVOICE_FRAGMENT on InvoiceRequest {
    id
    downloadlink
    type
    subject
    purchase{
      originalId
      payment
      status
      paymentStatus
      voided
      createdAt
    }
    customer{
      originalId
      name
      email
      phone{
        countryCode
        phoneNumber
        phoneCode
      }
    }
    vendor{
      originalId
      name
    }
    status
    statusNote
    note
    amount
    vendorInvoicable
    rfc
    name
    address
    email
    updatedAt
    createdAt
  }
`

export default INVOICE_FRAGMENT
