import { gql } from '@apollo/client'

import { VEHICLE_FRAGMENT } from './'

export const VEHICLE = gql`
  query vehicle($slug: String, $id: ID){
    vehicle(slug: $slug, id: $id){
      ...VEHICLE_FRAGMENT
    }
  }
  ${VEHICLE_FRAGMENT}
`
