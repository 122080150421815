import React, { useState, useEffect, useRef, useContext } from 'react'
import FontAwesome from 'react-fontawesome'

import { IntlContext } from '../../contexts'

import './style.css'

export function SelectV2({selected, options, placeholder, value, defaultValue, onChange}) {

  const [opened, toggle] = useState(false)
  const element = useRef(null)
  const { getTranslation } = useContext(IntlContext)

  useEffect(() => {
    function handleClick(e){
      if (!element.current || element.current.contains(e.target)) return false
      toggle(false)
    }

    document.addEventListener("click", handleClick)
    document.addEventListener("touchend", handleClick)

    return () => {
      document.removeEventListener("click", handleClick)
      document.removeEventListener("touchend", handleClick)
    }
  }, [])

  function _onChange(option){
    onChange(option)
    toggle(false)
  }

  function showLabel(_option){
    if (value instanceof Function){
      return value(_option)
    }

    return _option
  }

  return(
    <div className={`select ${placeholder ? "placeholder" : ""}`} ref={element}>
      {placeholder && <span className="placeholder">{placeholder}</span>}
      <span className="selected" onClick={() => toggle(!opened)}>{selected ? showLabel(selected) : defaultValue || getTranslation({id: "please-select"})}</span>
      {opened &&
        <ul>
          {options?.map((option, key) =>
            <SelectItem key={key} onChange={_onChange} option={option} showLabel={showLabel} />
          )}
        </ul>
      }
      <FontAwesome name={opened ? "chevron-up" : "chevron-down"} />
    </div>
  )
}

function SelectItem({option, onChange, showLabel}){

  function _onChange(){
    onChange(option)
  }

  return(
    <li onClick={_onChange}>{showLabel(option)}</li>
  )
}