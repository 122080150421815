import React from 'react'

import { Table } from '../../components'
import { CookingTime } from '../../views'
import { formatMinutes } from '../../helpers'

export default function CookingTimeField({purchase, status}) {

    return(
      <Table.Td label="Cooking time" className="center cooking-time-field">
        {["waitingForVendor", "waitingForRider", "processed"].includes(status) && (purchase.status !== "ONTHEWAY" && purchase.status !== "ATLOCATION") ?
          <CookingTime purchase={purchase} disableCallback={status === "waitingForVendor" ? true : false}/>
        :
          formatMinutes(purchase.preparationTime) || "Not set"
        }
      </Table.Td>
    )

}
