import { gql } from '@apollo/client'

import { RIDER_BOOK_FRAGMENT } from './'

export const RIDER_BOOK = gql`
  query riderBook($rider: ID){
    riderBook(rider: $rider){
      ...RIDER_BOOK_FRAGMENT
    }
  }
  ${RIDER_BOOK_FRAGMENT}
`
