import { gql } from '@apollo/client'

import { RIDER_BOOK_ENTRY_FRAGMENT } from './'

export const CREATE_RIDER_BOOK_ENTRY = gql`
  mutation createRiderBookEntry($data: RiderBookEntryInput!){
    createRiderBookEntry(data: $data){
      ...RIDER_BOOK_ENTRY_FRAGMENT        
    }
  }
  ${RIDER_BOOK_ENTRY_FRAGMENT}
`
