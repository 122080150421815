import FontAwesome from 'react-fontawesome'
import moment from 'moment'
import Tippy from '@tippyjs/react'

import { formatPrice, getPurchaseWarnings, getPurchaseActionType } from '../../../helpers'
import { Checkbox, Table } from '../../../components'
import { CookingTimeField, OrderTagsField, DeliveryTimeField, RiderName } from '../../../views'
import { VendorField, CustomerField, ActionsField } from './fields'

export function OrdersListItem({id, purchase, visibleFields, dateFormat = 'DD/MM/YYYY HH:mm', selected, selectItem, unselectItem}) {

  let actionType = getPurchaseActionType(purchase)
  let warnings = getPurchaseWarnings(purchase, actionType)

  function getTimestamp(s){
    switch (s) {
      case "PROCESSED":
        return "processed"
      case "INPREPARATION":
      case "READYFORCOLLECTION":
        return "vendorConfirmed"
      case "ONTHEWAY":
        return "collected"
      case "ATLOCATION":
        return "arrivedAtClient"
      case "COMPLETED":
        return "completed"
      case "DELIVERYFAILED":
        return "deliveryFailed"
      case "PAYMENTFAILED":
        return "paymentFailed"
      case "CANCELLED":
        return "cancelled"
      case "REJECTED":
        return "rejected"
      case "UNPROCESSED":
        return "unprocessed"
      default:
        return null
    }

  }

  return(
    <Table.Tr className={`orders-list-item ${purchase.status.toLowerCase()} ${selected ? "selected" : ""} ${purchase.problem ? "problem" : ""}`} >
      <Table.Td label="#" className="center">
        <div className="order-id-field">
          {!!selectItem && !purchase.group && !purchase.rider &&
            <Checkbox id={purchase.id} checked={selected} onChange={!!selected ? () => unselectItem(purchase.id) : () => selectItem(purchase)}/>
          }
          {(warnings || purchase.problem )&& <FontAwesome name="exclamation-triangle" />}
          {++id}
        </div>
      </Table.Td>
      {visibleFields.vendor &&
        <Table.Td>
          <VendorField purchase={purchase} />
        </Table.Td>      
      }
      {visibleFields.customer &&
        <Table.Td>
          <CustomerField purchase={purchase} />
        </Table.Td>      
      }
      {visibleFields.zone &&
        <Table.Td>
          <div>
            {purchase.address?.zone}
          </div>
        </Table.Td>
      }
      {visibleFields.basketValue &&
        <Table.Td className="center">{formatPrice(purchase.price.subtotal)}</Table.Td>
      }
      {visibleFields.deliveryFee &&
        <Table.Td className="center">{formatPrice(purchase.price.deliveryFeeTotal)}</Table.Td>
      }
      {visibleFields.tax &&
        <Table.Td className="center">{formatPrice(purchase.price.tax)}</Table.Td>
      }
      {visibleFields.total &&
        <Table.Td className="center">{purchase.payment === "CARD_STRIPE" ? formatPrice(purchase.price["chargeOnCard"]) : formatPrice(purchase.price["chargeInCash"])}</Table.Td>
      }
      {visibleFields.createdAt &&
        <Table.Td  className="center">{moment(purchase.createdAt).format(dateFormat)}</Table.Td>
      }
      {visibleFields.cookingTime &&
        <CookingTimeField purchase={purchase} />
      }
      {visibleFields.deliveryTime &&
        <DeliveryTimeField purchase={purchase} />
      }
      {visibleFields.rider &&
        <Table.Td>
          {purchase.type === "ONDEMAND" ?
            <RiderName purchase={purchase} />
          :null}
        </Table.Td>
      }
      <Table.Td className="order-tags-field">
        <OrderTagsField purchase={purchase} />
      </Table.Td>
      <Table.Td>
        <ActionsField purchase={purchase} />
      </Table.Td>
      <Table.Td className={`purchase-status-field ${purchase.status.toLowerCase()}`}><Tippy content={`${purchase.status} - ${moment(purchase.timestamps[getTimestamp(purchase.status)]).format('HH:mm')}`}><div className="placeholder">&nbsp;</div></Tippy></Table.Td>
    </Table.Tr>
  )

}
