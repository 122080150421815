import React, { useState, useEffect, useRef } from 'react'
import FontAwesome from 'react-fontawesome'

import { Button } from '../'

import './style.css'

import _countries from './countries.json'

export function PhoneNumberEditBox({save, value, defaultCountry = "mx", featuredCountries = ["mx", 'us'], placeholder, verificationStatus, disabled, actions}) {

  const [edit, toggleEdit] = useState(false)
  const [phone, setPhone] = useState(value)
  const [showList, toggleList] = useState(false)
  const [search, setSearch] = useState("")
  const [countries, setCountries] = useState(_countries)
  const element = useRef(null)
  const phoneNumber = ((phone?.phoneCode + phone?.phoneNumber) || "").replace(/[\s+()]/g, "")

  useEffect(() => {
    const handleClick = (e) => {
      if (element.current && element.current.contains(e.target)) return false
      toggleList(false)
    }

    document.addEventListener("click", handleClick)

    return () => document.removeEventListener("click", handleClick)
  }, [])

  function getIcon(){
    switch (verificationStatus) {
      case "WHATSAPP_VERIFIED":
        return <i className="fab fa-whatsapp-square" style={{color: 'var(--valid-color)'}}/>
      case "SMS_VERIFIED":
        return <i className="fab fa-whatsapp-square" style={{color: 'var(--valid-color)'}}/>
      case "UNVERIFIED":
        default:
        return <FontAwesome name="times" style={{color: 'var(--invalid-color)'}}/>
    }
  }

  function _save(){
    toggleEdit(false)

    const _phone = {
      countryCode: phone?.countryCode,
      phoneCode: phone?.phoneCode,
      phoneNumber: phone?.phoneNumber
    }

    save(_phone)
  }

  function reset(){
    setPhone(value)
    toggleEdit(false)
  }  

  function onSearch(string){
    let filtered = _countries.filter((country) => country.name.toLowerCase().search(string.toLowerCase()) !== -1 || country.phoneCode.toLowerCase().search(string.toLowerCase()) !== -1)

    setSearch(string)
    setCountries(filtered)
  }

  function _onChange(data){
    setPhone(data)
    toggleList(false)
  }

  function changeCountry(data){
    const _phone = {
      countryCode: data.countryCode,
      phoneCode: data.phoneCode,
      phoneNumber: data.phoneNumber
    }

    _onChange(_phone)
    save(_phone)
  }

  let selectedCountry =  phone || _countries.filter((data) => data.countryCode.toLowerCase() === defaultCountry)[0] || countries[0]

  let countriesList = countries.reduce((current, next) => {
    let listItem =
      <li key={`${next.countryCode}`} onClick={() => changeCountry({...next, phoneNumber: phone?.phoneNumber || ""})}>
        <span className="list-flag"><span className={"flag-icon flag-icon-" + next.countryCode.toLowerCase()}></span></span>
        <span className="list-name">{next.name}</span>
        <span className="list-code">({next.phoneCode})</span>
      </li>

    if (featuredCountries && (featuredCountries.includes(next.countryCode.toLowerCase()) || featuredCountries.includes(next.countryCode))){
      current.featured.push(listItem)
    } else {
      current.normal.push(listItem)
    }

    return current

  }, {featured: [], normal: []})

  return(
    <div className={`edit-box phone-edit-box ${value ? "populated" : ""} ${edit ? "edit" : ""}`}>
      <div className="icon">{getIcon()}</div>      
      <div className="phone-picker" ref={element}>
        <div className="country" onClick={() => toggleList(!showList)}>
          <span className={"flag-icon flag-icon-" + selectedCountry.countryCode.toLowerCase()}></span>
          <span className="country-code">{selectedCountry.phoneCode}</span>
          <FontAwesome name="caret-down" />
        </div>
        <span className="value-text">
          <span className="label">{placeholder}</span>
          <input ref={element} className="value-input" value={phone?.phoneNumber || ""} onChange={edit ? (e) => _onChange({...selectedCountry, phoneNumber: e.target.value}) : null} disabled={!edit}/>
        </span>      
        <div className={`country-dropdown ${showList ? "opened" : ""}`}>
          <ul className="country-list">
            <input name="search" autoComplete="off" type="text" value={search} placeholder="Search" onChange={(e) => onSearch(e.target.value)}/>
            {countriesList.featured}
            {countriesList.featured.length ? <li key="divider" className="divider"></li> : ""}
            {countriesList.normal}
          </ul>
        </div>
      </div>
      {!disabled &&
        <div className="actions-wrapper">
            {edit ?
              <div className="actions">
                <Button icon="save" theme="main" onClick={_save}/>
                <Button icon="times" theme="alt" onClick={reset}/>
                {actions}
              </div>
            :
              <div className="actions">
                {value?.phoneCode && value?.phoneNumber &&
                  <a href={`https://wa.me/${phoneNumber}`} className="button" rel="noreferrer" style={{color: "var(--whatsapp)"}} target="_blank" ><i className="fab fa-whatsapp-square" /></a>
                }                
                <Button icon="edit" theme="alt" onClick={() => toggleEdit(true)}/>
                {actions}
              </div>
            }
        </div>
      }      
    </div>
  )
}
