import { gql } from '@apollo/client'

import { RIDER_BREAK_FRAGMENT } from './'

export const CHANGE_BREAK_STATUS = gql`
  mutation changeBreakStatus($id: ID!, $status: RIDERBREAK_STATUS_INPUT!, $allowedLength: Int){
    changeBreakStatus(id: $id, status: $status, allowedLength: $allowedLength){
      ...RIDER_BREAK_FRAGMENT
    }
  }
  ${RIDER_BREAK_FRAGMENT}
`
