import React, { useState } from 'react'
import { toast } from 'react-toastify'
import moment from 'moment'
import { useLocation } from 'react-router-dom'
import { useQuery, useMutation } from '@apollo/client'

import { useQueryBuilder } from '../../../hooks'
import { VENDOR_BOOK_ENTRIES, VOID_VENDOR_BOOK_ENTRY, VENDOR_BOOK } from '../../../graphql'
import { IntlValue, ContextMenu, StatusDot, Pagination, Loader, Filters, DatePicker, Table } from '../../../components'
import { formatPrice } from '../../../helpers'
import { tomato } from '../../../assets'

import './style.css'

export function VendorBookEntries({vendor, footer}) {

  const [filter, setFilter] = useState()
  const location = useLocation()
  const search = new URLSearchParams(location.search)

  const {params, removeParam, buildQuery, isQuery, setPage, page, processQuery, runQuery } = useQueryBuilder({
    data: {
      date: {key: "date", label: `${search.getAll("date")[0]} - ${search.getAll("date")[1]}`, value: search.getAll("date")?.length === 2 ? [search.getAll("date")[0], search.getAll("date")[1]] : undefined, skip: !search.get("date")},
      vendor: {key: "vendor", label: `${search.get("vendor")}`, value: vendor || search.get("vendor")},
    },
    page: search.get("page"),
  })

  const { data, loading, error, refetch } = useQuery(VENDOR_BOOK_ENTRIES, {variables: buildQuery(), skip: !isQuery(), onCompleted: (_data) => processQuery(_data?.vendorBookEntries?.pageInfo)})
  const [voidVendorBookEntry] = useMutation(VOID_VENDOR_BOOK_ENTRY, {refetchQueries: [{query: VENDOR_BOOK, variables: {vendor: vendor || search.get("vendor")}}]})

  if (error) return `Error! ${error.message}`

  async function _voidVendorBookEntry(id){
    try {
      await voidVendorBookEntry({variables: {id}})
      refetch()
      toast.success("Record voided successful")
    } catch (e) {
      console.log("Error: ", e.message)
    }
  }

  function setDate({selection: {startDate, endDate}}){
    const _date = [
      moment(startDate).format("YYYY-MM-DD"),
      moment(endDate).add(1, 'd').format("YYYY-MM-DD")
    ]
    runQuery({instantParams: {...params, date: {key: "date", label: `${_date[0]} - ${_date[1]}`, value: [_date[0], _date[1]], skip: !(_date[0] && _date[1])}}})
  }

  return(
    <div id="balance-history">
      <Filters.Bar>
        <Filters.Input id="query" name="query" value={filter} onChange={({target}) => setFilter(target.value)} reset={() => setFilter("")} placeholder="Type here for searching" />
        <Filters.Dropdown style={{marginLeft: "auto"}} placeholder="Date" value={params?.date?.label} disableInClickClose right reset={() => removeParam("date")}>
          <DatePicker.Range startDate={params?.date?.value[0] ? new Date(`${params.date.value[0]}T00:00`) : new Date()} endDate={params?.date?.value[1] ? new Date(`${params.date.value[1]}T00:00`) : new Date()} onChange={setDate} />
        </Filters.Dropdown>
      </Filters.Bar>
      {loading && <Loader theme="main" overlay/>}
      {footer}
      <Table.Table>
        <Table.Head>
          <Table.Tr>
            <Table.Th></Table.Th>
            <Table.Th className="text-left">Date</Table.Th>
            <Table.Th className="text-left">Type</Table.Th>
            <Table.Th className="text-left">Note</Table.Th>
            <Table.Th>Amount</Table.Th>
            <Table.Th></Table.Th>
          </Table.Tr>
        </Table.Head>
        <Table.Body>
          {data?.vendorBookEntries?.nodes?.map((historyItem, key) =>
            <Table.Tr className="item" key={key}>
              <Table.Td><StatusDot active={!historyItem.voided} /></Table.Td>
              <Table.Td className="date text-left">{moment(historyItem?.createdAt).format("YYYY MMMM DD. HH:mm:ss")}</Table.Td>
              <Table.Td className="description-field text-left">
                <div>
                  <span><IntlValue>{historyItem?.description}</IntlValue></span>
                  <span className="message">{historyItem.auditMessage}</span>
                </div>
              </Table.Td>
              <Table.Td className="note text-left">{historyItem?.note}</Table.Td>
              <Table.Td className={`price-field ${historyItem.value > 0 ? "plus" : "minus"}`}>
                {historyItem?.type === "TOMATO_POINTS" ?
                  <div className="tomato-point"><span>{historyItem?.value}</span><img alt="tomato points" src={tomato} className="icon" /></div>
                :
                  formatPrice(historyItem?.value)
                }
              </Table.Td>
              <Table.Td>
                <ContextMenu.Menu id={historyItem?.id}>
                  {historyItem.relatedPurchase &&
                    <ContextMenu.Link to={`/orders/${historyItem.relatedPurchase}`} icon="external-link-alt">View purchase</ContextMenu.Link>
                  }
                  <ContextMenu.Button className="invalid" onClick={() => _voidVendorBookEntry(historyItem?.id)} icon="times">Void</ContextMenu.Button>
                </ContextMenu.Menu>
              </Table.Td>
            </Table.Tr>
          )}
        </Table.Body>
      </Table.Table>
      <Pagination page={page} setPage={setPage} pageInfo={data?.vendorBookEntries?.pageInfo}/>
    </div>
  )
}
