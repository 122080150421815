import { gql } from '@apollo/client'

export const PURCHASE_PRICE_FRAGMENT = gql`
  fragment PURCHASE_PRICE_FRAGMENT on PurchasePrice {
    subtotal
    serviceFeeCustomer
    serviceFeeVendor
    totalRevenue
    vendorPrice
    discount
    deliveryFeeBase
    deliveryFeeDistance
    deliveryFeeTotal
    grossTip
    transactionFee
    tax
    total
    commissionFreeTotal
    currency
    applicableExchangeRate
    totalInCurrency
    customerCashBalanceAdjustment
    vendorCashBalanceAdjustment
    chargeInCash
    cashChargeInCurrency
    vendorCashPayment
    chargeOnCard
    riderCashCreditBalanceAdjustment
    vendorPriceAdjustmentByRiderCashCreditBalance
  }
`
