import { gql } from '@apollo/client'

import { RIDER_MODIFICATION_FRAGMENT } from './'

export const CREATE_RIDER_MODIFICATION_MUTATION = gql`
  mutation createRiderModification($data: RiderModificationInput!){
    createRiderModification(data: $data){
      ...RIDER_MODIFICATION_FRAGMENT
    }
  }
  ${RIDER_MODIFICATION_FRAGMENT}
`
