import React, { useContext } from 'react'
import { useMutation } from '@apollo/client'
import { useParams } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'

import { toast } from '../../helpers'
import { useValidatedForm } from '../../hooks'
import { CREATE_ANNOUNCEMENT_MUTATION } from '../../graphql'
import { IntlContext } from '../../contexts'
import { HtmlEditor, LabeledInput, Loader, Wrapper, Form, LabeledTextarea, IntlText } from '../../components'
import { PageHeader, AnnouncementOptions } from '../../views'

export function NewAnnouncement(){

  const { language } = useParams()
  const navigate = useNavigate()
  const { getTranslation } = useContext(IntlContext)
  const _language = language || "en"
  const { form, updateField, updateMultilangField, getEditedData } = useValidatedForm({})
  const [create, { loading }] = useMutation(CREATE_ANNOUNCEMENT_MUTATION)

  async function _create(){
    const alert = toast.loading()
    try {
      const { data: { createAnnouncement } } = await create({variables: {data: getEditedData()}})
      navigate(`/announcement/${createAnnouncement?.id}`)
      return alert.success(getTranslation({id: "create-success"}))
    } catch (e) {
      alert.apolloError(e)
    }
  }

  return(
    <Wrapper>
      <div id="new-announcement" className="view">
        <div className="row padding">
          {loading && <Loader theme="main" overlay />}
          <PageHeader slug={form.slug} title={form.title} titlePlaceholder={getTranslation({group: "announcement", id: "new"})} onChange={({target}) => updateField({key: target.name, value: target.value})} />
          <div className="grids">
            <div className="grid">
              <Form.Form>
                <Form.Field>
                  <LabeledInput name="title" placeholder="Title" value={form.title?.[_language]} onChange={({target}) => updateMultilangField({key: target.name, value: target.value, language: _language})} />
                </Form.Field>
                <Form.Field>
                  <LabeledTextarea id={`editor-${_language}`} name="description" placeholder="Description" value={form.description?.[_language]} onChange={({target}) => updateMultilangField({key: target.name, value: target.value, language: _language})}/>
                </Form.Field>
                <Form.Field>
                  <HtmlEditor id={`editor-${_language}`} name="content" placeholder="Content" value={form.content?.[_language]} onChange={({text, target}) => updateMultilangField({key: target.name, value: text, language: _language})}/>
                </Form.Field>
              </Form.Form>
            </div>
            <div className="grid sidebar">
              <AnnouncementOptions placeholder={<IntlText id="create" />} language={_language} save={_create} loading={loading} url={`/announcements/new`} />
            </div>
          </div>
        </div>       
      </div>
    </Wrapper>
  )
}
