import { useMutation, useQuery } from '@apollo/client'
import { toast } from 'react-toastify'

import { DELETE_ADDRESS_MUTATION, ADDRESSES_QUERY } from '../../../graphql'
import { useIntl, useModal } from '../../../hooks'
import { Loader, Button, Modalv3, IntlText } from '../../../components'
import { CustomerAddress } from '../../../views'

import './style.css'

export function CustomerAddresses({customer}) {

  const modal = useModal()
  const { getTranslation } = useIntl()
  const { loading, data, error } = useQuery(ADDRESSES_QUERY, {variables: {owner: customer.id}})
  const [deleteAddress] = useMutation(DELETE_ADDRESS_MUTATION, {refetchQueries: [{query: ADDRESSES_QUERY, variables: {owner: customer.id}}]})

  async function _deleteAddress(id){
    try {
      await deleteAddress({variables: {id}})
      toast.success(getTranslation({id: "delete-success"}))
    } catch (error) {
      if (error.graphQLErrors && error.graphQLErrors.length > 0) error.graphQLErrors.map(x => toast.error(x.message))
      console.log("Error: ", error)
    }
  }

  if (loading) return <Loader theme="main"/>
  if (error) return `Error! ${error.message}`

  const { addresses } = data

  return(
    <div id="addresses">
      {addresses.map((address, key) =>
        <div key={key} className="address">
          <div className="info">
            <span>{address.name}</span>
            <span className="building">{address.zone}</span>
          </div>
          <div className="actions">
            <Button icon="info" onClick={() => modal?.show({address})} />
            <Button theme="reject" onClick={() => _deleteAddress(address.id)} icon="trash" />
          </div>
        </div>
      )}
      <Modalv3 modal={modal} id="customer-address-modal" headerTitle={<IntlText group="customer-address-modal" id="title" />}>
        <CustomerAddress address={modal?.state?.address} />
      </Modalv3>        
    </div>
  )
}
