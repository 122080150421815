import { useContext } from 'react'

import { IntlContext } from '../../../contexts'
import { LabeledInput, Select, Validation, PhoneNumberInput, Form, SelectV2, EditBox, Button } from '../../../components'

export function NewVendorForm({form, errors, updateField, updateFields}){
  
  const { getTranslation } = useContext(IntlContext)
  const vendorTypes = [{id: "RESTAURANT", label: "Restaurant"}, {id: "SHOP", label: "Shop"}]
  const contractTypes = [{id: "FEATURED", label: "Featured"}, {id: "BASIC", label: "Basic"}]
  const serviceFeeChargeTypes = [{id: "ONPICKUP", label: "On-pickup"}, {id: "PERIODICAL", label: "Periodical"}]
  const serviceFeeChargePeriods = [{id: "WEEKLY", label: "Weekly"}, {id: "MONTHLY", label: "Monthly"}]
  const { name, username, email, phone, password, type, contract, serviceFee, serviceFeeChargeType, serviceFeeChargePeriod, contractExpiry, whatsappGroup } = form

  function changePhoneNumberField(data){
    let { countryCode, phoneCode, phoneNumber } = data

    let _phone = {
      countryCode: countryCode,
      phoneCode: phoneCode,
      phoneNumber: phoneNumber
    }

    updateField({key: "phone", value: _phone})
  }

  function randomPassword(){
    const charset = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    let password = '';
  
    for (let i = 0; i < 10; i++) {
      const randomIndex = Math.floor(Math.random() * charset.length);
      password += charset[randomIndex];
    }

    updateField({key: "password", value: password})
  }

  function generateSlug(text) {
    return text
      .toLowerCase()                           // Convert to lowercase
      .replace(/[^a-z0-9\s-]/g, '')            // Remove special characters
      .replace(/\s+/g, '-')                    // Replace spaces with hyphens
      .replace(/-+/g, '-')                     // Replace multiple hyphens with a single hyphen
      .replace(/^-|-$/g, '');                  // Trim leading and trailing hyphens
  }

  function updateName({target}){
    updateFields({fields: [{key: target.name, value: target.value}, {key: "username", value: generateSlug(target.value)}]})
  }

  return(
    <Form.Form>
      <Form.Field>
        <Validation errors={errors.name}>
          <LabeledInput name="name" placeholder="Name" value={name} onChange={updateName}/>
        </Validation>
      </Form.Field>
      <Form.Field>
        <Validation errors={errors.username}>
          <LabeledInput name="username" placeholder="Username" value={username} onChange={({target}) => updateField({key: target.name, value: target.value})}/>
        </Validation>
      </Form.Field>
      <Form.Field>
        <Validation errors={errors.password}>
          <LabeledInput name="password" placeholder="Password" value={password} onChange={({target}) => updateField({key: target.name, value: target.value})}/>
        </Validation>
        <div style={{position: "absolute", top: '50%', right: 10, transform: 'translate(0%, -50%)'}}>
          <Button icon="repeat" onClick={randomPassword} />
        </div>
      </Form.Field>
      <Form.Field>
        <Validation errors={errors.email}>
          <LabeledInput name="email" placeholder="Email" value={email} onChange={({target}) => updateField({key: target.name, value: target.value})}/>
        </Validation>
      </Form.Field>        
      <Form.Field>
        <Validation errors={errors.phone}>
          <PhoneNumberInput name="phone" placeholder="Phone" value={phone} defaultCountry="mx" featuredCountries={["us", "ca", "mx"]} onChange={changePhoneNumberField} />
        </Validation>
      </Form.Field>
      <Form.Field>
        <EditBox.Text placeholder="Whats App Group" save={(value) => updateField({key: "whatsappGroup", value})}>{whatsappGroup}</EditBox.Text>
      </Form.Field>          
      <Form.Field>
        <Validation errors={errors.type}>
          <Select placeholder="Restaurant type" selected={vendorTypes.find(t => t.id === type)} defaultValue="Please select" options={vendorTypes} onChange={(_type) => updateField({key: "type", value: _type.id})} />
        </Validation>
      </Form.Field>
      <Form.Field>
        <Validation errors={errors.contract}>
          <Select placeholder="Contract type" selected={contractTypes.find(t => t.id === contract)} defaultValue="Please select" options={contractTypes} onChange={(_contract) => updateField({key: "contract", value: _contract.id})} />
        </Validation>
      </Form.Field>
      <Form.Field>
        <EditBox.Date placeholder={getTranslation({group: "vendor-contract", id: "contractExpiry"})} save={(value) => updateField({key: "contractExpiry", value})}>{contractExpiry}</EditBox.Date>
      </Form.Field>
      <Form.Field>
        <Validation errors={errors.serviceFee}>
          <LabeledInput name="serviceFee" placeholder="Service fee coverage" value={serviceFee} onChange={({target}) => updateField({key: target.name, value: parseFloat(target.value)})}/>
        </Validation>
      </Form.Field>
      <Form.Field>
        <SelectV2 placeholder={getTranslation({group: "vendor-contract", id: "serviceFeeChargeType"})} value={(_value) => _value.label} selected={serviceFeeChargeTypes?.find(_t => _t.id === serviceFeeChargeType)} options={serviceFeeChargeTypes} onChange={(_type) => updateField({key: "serviceFeeChargeType", value: _type.id})} />
      </Form.Field>      
      <Form.Field>
        <SelectV2 placeholder={getTranslation({group: "vendor-contract", id: "serviceFeeChargePeriod"})} value={(_value) => _value.label} selected={serviceFeeChargePeriods?.find(_t => _t.id === serviceFeeChargePeriod)} options={serviceFeeChargePeriods} onChange={(_contract) => updateField({key: "serviceFeeChargePeriod", value: _contract.id})} />
      </Form.Field>
    </Form.Form>
  )
}
