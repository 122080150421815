import { gql } from '@apollo/client'

import { PAGE_FRAGMENT } from './'

export const EDIT_PAGE_MUTATION = gql`
  mutation editPage($id: ID!, $data:EditedPageInput!){
    editPage(id: $id, data:$data){
      ...PAGE_FRAGMENT
    }
  }
  ${PAGE_FRAGMENT}
`
