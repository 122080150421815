import { gql } from '@apollo/client'

import { SETTING_FRAGMENT } from './'

export const SETTING_UPDATE_SUBSCRIPTION = gql`
  subscription settingUpdated($settings: [String]!){
    settingUpdated(settings: $settings){
      ...SETTING_FRAGMENT
    }
  }
  ${SETTING_FRAGMENT}
`
