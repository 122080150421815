import React from 'react'
import { useLocation } from 'react-router-dom'

import { useQueryBuilder } from '../../hooks'
import { Wrapper, Title, Filters } from '../../components'
import { DispatcherTasks, DispatcherSearch, DispatcherBar } from '../../views'

import './style.css'

export function Dispatcher() {

  const location = useLocation()
  const search = new URLSearchParams(location.search)
  const { params, getParams, addParam, removeParam, isQuery, buildQuery, runQuery, clearParams } = useQueryBuilder({
    data: {
      query: {key: "query", label: `Search for ${search.get("query")}`, value: search.get("query")}
    },
  })
  
  return(
    <Wrapper>
      <div id="dispatcher" className="view">
        <div className="row padding">
          <DispatcherBar runQuery={runQuery} params={params} addParam={addParam} removeParam={removeParam} />
          <Filters.ActiveParams activeParams={getParams()} removeParam={removeParam} clearParams={clearParams} />
          {isQuery() &&
            <div className='block'>
              <div className='block-title'>
                <Title tag="h3">Search results</Title>
              </div>              
              <DispatcherSearch buildQuery={buildQuery} isQuery={isQuery} />
            </div>
          }
          <DispatcherTasks runQuery={runQuery}/>
        </div>
      </div>
    </Wrapper>
  )
}
