import React from 'react'
import { useNavigate } from 'react-router-dom'

import config from '../../config'
import { getAsset } from '../../helpers'

import './style.css'

export function Logo({className, small}) {

  const navigate = useNavigate()

  return(
    <div className={`logo ${className}`} onClick={() => navigate("/", {reset: true})}>
      <img src={small ? getAsset("logo-small.png") : getAsset("logo.png")} alt={config.siteName} />
    </div>
  )
}
