import React from 'react'
import moment from 'moment'
import { useApolloClient } from '@apollo/client'

import { PROMOTION, VENDOR_QUERY, PURCHASE_QUERY, CUSTOMER_QUERY, RIDER_QUERY, ADMIN_QUERY, PAGE_QUERY, FAQ, INVOICE_REQUEST_QUERY, GET_RIDER_WORKSHIFT, GET_DAY_CLOSING_SNAPSHOT, ANNOUNCEMENTS_QUERY } from '../../graphql'
import { Breadcrumbs as UIBreadcrumbs, IntlValue } from '../../components'

export function Breadcrumbs() {

  const client = useApolloClient()
  const breadcrumbs = {
    label: "Home",
    orders: {
      label: "Orders",
      fallback: {
        label: async ({p, link, _routes, key, setRoutes}) => {
          try {
            const {data:{ purchase }} = await client.query({
              query: PURCHASE_QUERY,
              variables: {
                id: p
              },
            })

            let label = `${purchase.vendor.name} - ${purchase.customer.name} (${moment(purchase.createdAt).format("YYYY-MM-DD")})`

            setRoutes(Object.assign({[p]: {url: link, value: label, rank: key}}, _routes))
          } catch (e) {
            console.log("errorrr", e);
          }
        }
      }
    },
    vendors: {
      label: "Vendors",
      new: {
        label: "New vendor",
      },
      invoices: {
        label: "Invoices"
      },
      "book-entries": {
        label: "Book entries"
      },
      fallback: {
        label: async ({p, link, _routes, key, setRoutes}) => {
          try {
            const {data:{ vendor }} = await client.query({
              query: VENDOR_QUERY,
              variables: {
                id: p
              },
            })

            setRoutes(Object.assign({[p]: {url: link, value: vendor.name, rank: key}}, _routes))
          } catch (e) {
            console.log("errorrr", e);
          }
        },
        balance: {
          label: "Balance"
        },
        "opening-hours": {
          label: "Opening Hours"
        },
        settings: {
          label: "Settings"
        },
        location: {
          label: "Location"
        },
        payment: {
          label: "Payment settings"
        },
        tags: {
          label: "Tags"
        },
        media: {
          label: "Media"
        },
        orders: {
          label: "Purchases"
        },
        categories: {
          label: "Categories"
        },
        products: {
          label: "Products"
        },
        addons: {
          label: "Addons"
        },
        "addon-options": {
          label: "Addon options"
        },
        promotions: {
          label: "Promotions",
          new: {
            label: "New"
          },
          fallback: {
            label: async ({p, link, _routes, key, setRoutes}) => {
              try {
                const {data:{ promotion }} = await client.query({
                  query: PROMOTION,
                  variables: {
                    id: p
                  },
                })
    
                setRoutes(Object.assign({[p]: {url: link, value: promotion.name, rank: key}}, _routes))
              } catch (e) {
                console.log("errorrr", e);
              }
            }
          } ,         
        },
        invoices: {
          label: "Invoices",
          new: {
            label: "New"
          },
          // fallback: {
          //   label: async ({p, link, _routes, key, setRoutes}) => {
          //     try {
          //       const {data:{ promotion }} = await client.query({
          //         query: PROMOTION,
          //         variables: {
          //           id: p
          //         },
          //       })
    
          //       setRoutes(Object.assign({[p]: {url: link, value: promotion.name, rank: key}}, _routes))
          //     } catch (e) {
          //       console.log("errorrr", e);
          //     }
          //   }
          // } ,         
        },
        reset: {
          label: "Reset"
        },
      }
    },
    customers: {
      label: "Customers",
      invoices: {
        label: "Invoices",
        fallback: {
          label: async ({p, link, _routes, key, setRoutes}) => {
            try {
              const {data:{ invoiceRequest }} = await client.query({
                query: INVOICE_REQUEST_QUERY,
                variables: {
                  id: p
                },
              })

              setRoutes(Object.assign({[p]: {url: link, value: `${invoiceRequest.customer.name} (${moment(invoiceRequest.createdAt).format("YYYY-MM-DD")})`, rank: key}}, _routes))
            } catch (e) {
              console.log("errorrr", e);
            }
          }
        }
      },  
      "book-entries":{
        label: "Customer book entries"
      },
      deleted:{
        label: "Deleted customers"
      },
      fallback: {
        label: async ({p, link, _routes, key, setRoutes}) => {
          try {
            const {data:{ customer }} = await client.query({
              query: CUSTOMER_QUERY,
              variables: {
                id: p
              },
            })

            setRoutes(Object.assign({[p]: {url: link, value: customer.name, rank: key}}, _routes))
          } catch (e) {
            console.log("errorrr", e);
          }
        },
        balance: {
          label: "Balance"
        },
        addresses: {
          label: "Addresses"
        },
        orders: {
          label: "Orders"
        },
        settings: {
          label: "Settings"
        },
        tags: {
          label: "Tags"
        },
        reset: {
          label: "Reset"
        },
      }
    },
    riders: {
      label: "Riders",
      new: {
        label: "New rider"
      },
      breaks: {
        label: "Breaks"
      },
      payouts: {
        label: "Payouts"
      },
      "day-closings": {
        label: "Day closing",
        fallback: {
          label: async ({p, link, _routes, key, setRoutes}) => {
            try {
              const {data:{ getDayClosingSnapshot }} = await client.query({
                query: GET_DAY_CLOSING_SNAPSHOT,
                variables: {
                  workShiftId: p
                },
              })
              
              let label = `${getDayClosingSnapshot.rider.name} (${moment(getDayClosingSnapshot.createdAt).format("YYYY-MM-DD")})`

              setRoutes(Object.assign({[p]: {url: link, value: label, rank: key}}, _routes))
            } catch (e) {
              console.log("errorrr", e);
            }
          },
          details: {
            label: "Details"
          },
          settings: {
            label: "Settings"
          }
        }             
      },      
      workshifts: {
        label: "Workshifts",
        fallback: {
          label: async ({p, link, _routes, key, setRoutes}) => {
            try {
              const {data:{ getRiderWorkShift }} = await client.query({
                query: GET_RIDER_WORKSHIFT,
                variables: {
                  id: p
                },
              })
              
              let label = `${getRiderWorkShift.rider.name} (${moment(getRiderWorkShift.createdAt).format("YYYY-MM-DD")})`

              setRoutes(Object.assign({[p]: {url: link, value: label, rank: key}}, _routes))
            } catch (e) {
              console.log("errorrr", e);
            }
          },
          active: {
            label: "Active"
          },
          details: {
            label: "Details"
          },
          settings: {
            label: "Settings"
          }
        }        
      },
      fallback: {
        label: async ({p, link, _routes, key, setRoutes}) => {
          try {
            const {data:{ rider }} = await client.query({
              query: RIDER_QUERY,
              variables: {
                id: p
              },
            })

            setRoutes(Object.assign({[p]: {url: link, value: rider.name, rank: key}}, _routes))
          } catch (e) {
            console.log("errorrr", e);
          }
        },
        details: {
          label: "Details"
        },
        settings: {
          label: "Settings"
        }
      }
    },
    admins: {
      label: "Admins",
      new: {
        label: "Add new admin"
      },
      fallback: {
        label: async ({p, link, _routes, key, setRoutes}) => {
          try {
            const {data:{ admin }} = await client.query({
              query: ADMIN_QUERY,
              variables: {
                id: p
              },
            })

            setRoutes(Object.assign({[p]: {url: link, value: admin.name, rank: key}}, _routes))
          } catch (e) {
            console.log("errorrr", e);
          }
        }
      }
    },
    announcements: {
      label: "Announcements",
      new: {
        label: "Create new announcement"
      },
      fallback: {
        label: async ({p, link, _routes, key, setRoutes}) => {
          try {
            const {data:{ page }} = await client.query({
              query: ANNOUNCEMENTS_QUERY,
              variables: {
                id: p
              },
            })

            setRoutes(Object.assign({[p]: {url: link, value: <IntlValue value={page.title} />, rank: key}}, _routes))
          } catch (e) {
            console.log("errorrr", e);
          }
        }
      }
    },
    pages: {
      label: "Pages",
      new: {
        label: "Create new page"
      },
      fallback: {
        label: async ({p, link, _routes, key, setRoutes}) => {
          try {
            const {data:{ page }} = await client.query({
              query: PAGE_QUERY,
              variables: {
                id: p
              },
            })

            setRoutes(Object.assign({[p]: {url: link, value: <IntlValue value={page.title} />, rank: key}}, _routes))
          } catch (e) {
            console.log("errorrr", e);
          }
        }
      }
    },
    accounting: {
      label: "Accounting",
      modifications: {
        label: "Modifications"
      }
    },
    faqs: {
      label: "FAQ",
      new: {
        label: "Create new FAQ"
      },
      fallback: {
        label: async ({p, link, _routes, key, setRoutes}) => {
          try {
            const {data:{ faqItem }} = await client.query({
              query: FAQ,
              variables: {
                id: p
              },
            })

            setRoutes(Object.assign({[p]: {url: link, value: <IntlValue value={faqItem.title} />, rank: key}}, _routes))
          } catch (e) {
            console.log("errorrr", e);
          }
        }
      }
    },
    configuration: {
      label: "Configuration",
      settings: {
        label: "Settings list"
      },
      delivery: {
        label: "Delivery zones"
      },
      payment: {
        label: "Payment settings"
      },
      "info-modal": {
        label: "Info modal"
      },
      "alert-bar": {
        label: "Alert bar"
      },
      import: {
        label: "Import",
      }
    },
    audit: {
      label: "Audit"
    }
  }

  return <UIBreadcrumbs breadcrumbs={breadcrumbs}/>
}
