import React from 'react'
import FontAwesome from 'react-fontawesome'

import './style.css';

export function QuantitySelector({qty, increase, decrease}) {

  return(
    <div className="quantity-selector">
      <span className="clip" onClick={decrease}><FontAwesome name="minus" /></span>
      <span className="qty">{qty}</span>
      <span className="clip" onClick={increase}><FontAwesome name="plus" /></span>
    </div>
  )
}
