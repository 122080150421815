import React, { useRef, useState } from 'react'
import { useMutation } from '@apollo/client'

import { useConfirm, useIntl } from '../../../hooks'
import { toast } from '../../../helpers'
import { EDIT_ADDON_MUTATION, DELETE_ADDON_MUTATION } from '../../../graphql'
import { Loader, Filter, IntlText, IntlValue, LabeledCheckbox, StatusDot, Table, ContextMenu, Modal, Title } from '../../../components'
import { Addon } from '../../../views'

export function AddonsList({data, refetch, filter}){

  const modal = useRef(null)
  const { getTranslatedValue, getTranslation } = useIntl()
  const { confirm } = useConfirm()
  const [selectedItem, selectItem] = useState(undefined)
  const [editAddon, { loading: editLoading }] = useMutation(EDIT_ADDON_MUTATION)
  const [deleteAddon] = useMutation(DELETE_ADDON_MUTATION)

  function _selectItem(_data){
    selectItem(_data)
    modal.current?.show()
  }

  async function _delete(id){
    const alert = toast.loading(getTranslation({group: "confirm", id: "confirm-to-continue"}))
    try {
      await confirm({title: getTranslation({group: "confirm", id: "delete"}), desc: getTranslation({group: "confirm", id: "delete-info"})})
      alert.update(getTranslation({id: "loading"}))
      await deleteAddon({variables: {id}})
      alert.success(getTranslation({id: "delete-success"}))
      refetch()
    } catch (error) {
      alert.apolloError(error)
    }
  }  

  async function onSuccess(){
    await refetch()
    modal.current?.hide()
  }

  return(
    <div id="addons-list" className="relative">
      {!!editLoading && <div className="full-size-loader"><Loader theme="main"/></div>}
      <Filter id="addons" data={data?.productAddons || []} search={[{key: "search", value: filter}]} filters={[{filter: (data) => data.id}, {filter: (data) => getTranslatedValue(data.name)}, {filter: (data) => data.disabled}]}>
        {(data) =>
          <Table.Table>
            <Table.Head>
              <Table.Tr>
                <Table.Th></Table.Th>
                <Table.Th className="text-left"><IntlText group="table-header" id="name" /></Table.Th>
                <Table.Th><IntlText group="table-header" id="instruction" /></Table.Th>
                <Table.Th className="center"><IntlText group="table-header" id="min" /></Table.Th>
                <Table.Th className="center"><IntlText group="table-header" id="max" /></Table.Th>
                <Table.Th><IntlText group="table-header" id="disabled" /></Table.Th>
                <Table.Th></Table.Th>
              </Table.Tr>
            </Table.Head>
            <Table.Body>
              {data.map((_addon) =>
                <Table.Tr key={_addon.id} className="addon">
                  <Table.Td><StatusDot active={!_addon?.disabled} /></Table.Td>
                  <Table.Td className="text-left"><IntlValue value={_addon?.name} /></Table.Td>
                  <Table.Td><IntlValue value={_addon?.instruction} /></Table.Td>
                  <Table.Td>{_addon?.minSelect}</Table.Td>
                  <Table.Td>{_addon?.maxSelect}</Table.Td>
                  <Table.Td centered><LabeledCheckbox id={`disabled-${_addon.id}`} checked={_addon?.disabled} onChange={(e) => editAddon({variables: {id: _addon.id, data: {disabled: e.target.checked}}})} rounded/></Table.Td>
                  <Table.Td>
                    <ContextMenu.Menu id={_addon.id} >
                      <ContextMenu.Button icon="edit" onClick={() => _selectItem(_addon)}><IntlText id="quick-edit" /></ContextMenu.Button>
                      <ContextMenu.Button icon="trash" theme="invalid" onClick={() => _delete(_addon.id)}><IntlText id="delete" /></ContextMenu.Button>
                    </ContextMenu.Menu>
                  </Table.Td>
                </Table.Tr>
              )}
            </Table.Body>
          </Table.Table>
        }
      </Filter>
      <Modal id="add-addon" ref={modal} header={<Title tag="h3"><IntlValue value={selectedItem?.name} /></Title>}>
        {() =>
          <Addon addon={selectedItem} success={onSuccess} cancel={() => modal.current?.hide()} />
        }
      </Modal>          
    </div>
  )
}
