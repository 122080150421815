import React, { useContext, useState } from 'react'
import { Mutation } from '@apollo/client/react/components'
import { toast } from 'react-toastify'

import { Button, LabeledInput, IntlText } from '../../components'
import { IntlContext } from '../../contexts'
import { AuthKit } from '../../helpers'
import { LOGIN_MUTATION, IS_LOGGED_IN } from '../../graphql'

import './LoginForm.css'

export default function LoginForm() {

  const { getTranslation } = useContext(IntlContext)
  const [loading, toggleLoading] = useState(false)
  const [form, setValues] = useState({uid: "", password: ""})
  const { uid, password } = form

  const submit = async (login) => {
    toggleLoading(true)
    try {
      await login({variables: form})
    } catch (e) {
      e.graphQLErrors.map(x => toast.error(x.message))
      toggleLoading(false)
    }
  }

  const updateField = (field) => {
    setValues({
      ...form,
      ...field
    })
  }

  const updateStore = (cache, { data: { login: { token, user } } }) => {
    cache.writeQuery({
      query: IS_LOGGED_IN,
      data: { isLoggedIn: !!user.id},
    })

    AuthKit.login(user, token)

  }

  return(
    <Mutation mutation={LOGIN_MUTATION} update={updateStore}>
      {(login, { data }) => (
        <div id="login-form">
          <div className="form">
            <LabeledInput name="uid" placeholder={getTranslation({group: "login-form", id: "input-username", defaultValue: "Username"})} value={uid} onChange={(e) => updateField({[e.target.name]: e.target.value})} />
            <LabeledInput name="password" placeholder={getTranslation({group: "login-form", id: "input-password", defaultValue: "Password"})} type="password" value={password} onChange={(e) => updateField({[e.target.name]: e.target.value})} />
            <div className="actions">
              <Button theme="main" className="login" loading={loading} onClick={() => submit(login)}><IntlText group="login-form" id="login">Login</IntlText></Button>
            </div>
          </div>
        </div>
      )}
    </Mutation>
  )
}
