import { gql } from '@apollo/client'

import { ANNOUNCEMENT_FRAGMENT } from './'

export const ANNOUNCEMENTS_QUERY = gql`
  query announcements{
    announcements{
      ...ANNOUNCEMENT_FRAGMENT
    }
  }
  ${ANNOUNCEMENT_FRAGMENT}
`
