import React from 'react'

import { IntlText } from '../'

import './style.css'

export function FileUploader({id, value, placeholder, dropText, onChange}){

  return(
    <div className="file-uploader">
      <label htmlFor={id} className="btn btn-primary"></label>
      <span className="drop-box" >
        {placeholder && <span className="placeholder">{placeholder}</span>}
        {value ?
          <div><span><IntlText group="file-uploader" id="selected-file">Selected file: </IntlText></span>{`${value.name} (${(value.size / 1000).toFixed(2)} kb)`}</div>
        :
        <span>{dropText || <IntlText group="file-uploader" id="drop-file" />}</span>
        }
        <input id={id} type="file" onChange={(e) => onChange(e)} />
      </span>
    </div>
  )
}
