import React from 'react'

import { IntlText, Grids } from '../../../components'

import moment from 'moment'

export function RiderInsights({rider}){

  const {
    assignedPurchases,
    acceptedPurchases,
    completedPurchases,
    rejectedPurchases,
    numOfFeedbacksPositive,
    numOfFeedbacksNeutral,
    numOfFeedbacksNegative,
    numOfFeedbacks,
    percentageOfPositive,
    lastPurchaseRejected,
  } = rider

  
  return(
    <div id="rider-details">
      <Grids.Grids>
        <Grids.Grid noPadding title={<IntlText group="rider-insights" id="summary" />}>
          <div className="chard-cards">
            <div className="card">
              <div className="label"><IntlText group="rider-insights" id="assigned-purchases" /></div>
                <div className="value">{assignedPurchases}</div>
            </div>            
            <div className="card">
              <div className="label"><IntlText group="rider-insights" id="accepted-purchases" /></div>
                <div className="value">{acceptedPurchases}</div>
            </div>            
            <div className="card">
              <div className="label"><IntlText group="rider-insights" id="completed-purchases" /></div>
                <div className="value">{completedPurchases}</div>
            </div>            
            <div className="card">
              <div className="label"><IntlText group="rider-insights" id="rejected-purchases" /></div>
                <div className="value">{rejectedPurchases}</div>
            </div>            
            <div className="card">
              <div className="label"><IntlText group="rider-insights" id="last-rejected-purchase" /></div>
                <div className="value"><IntlText group="rider-insights" id="ago" variables={{days: -moment(lastPurchaseRejected).diff(moment(), 'days', false)}} /></div>
            </div>            
          </div>                    
        </Grids.Grid>
        <Grids.Grid noPadding title={<IntlText group="rider-insights" id="feedbacks" />}>
          <div className="chard-cards">
            <Progress strokeWidth={8} percentage={Math.round(percentageOfPositive * 100)} />
            <div className="card">
              <div className="label"><IntlText group="rider-insights" id="total-feedback" /></div>
                <div className="value">{numOfFeedbacks || 0}</div>
            </div>            
            <div className="card">
              <div className="label"><IntlText group="rider-insights" id="positive-feedback" /></div>
                <div className="value">{numOfFeedbacksPositive || 0}</div>
            </div>            
            <div className="card">
              <div className="label"><IntlText group="rider-insights" id="neutral-feedback" /></div>
                <div className="value">{numOfFeedbacksNeutral || 0}</div>
            </div>            
            <div className="card">
              <div className="label"><IntlText group="rider-insights" id="negative-feedback" /></div>
                <div className="value">{numOfFeedbacksNegative || 0}</div>
            </div>                   
          </div>          
        </Grids.Grid>
      </Grids.Grids>
    </div>
  )

}

function Progress({ strokeWidth, percentage }){
	const radius = (50 - strokeWidth / 2)
  const pathDescription = `
    M 50,50 m 0,-${radius}
    a ${radius},${radius} 0 1 1 0,${2 * radius}
    a ${radius},${radius} 0 1 1 0,-${2 * radius}
  `
  const activeColor = percentage < 50 ? "var(--invalid-color)" : percentage < 80 ? "var(--warning-color)" : "var(--valid-color)"
  const diameter = Math.PI * 2 * radius;
  const progressStyle = {
    stroke: activeColor,
    strokeLinecap: 'round',
    strokeDasharray: `${diameter}px ${diameter}px`,
    strokeDashoffset: `${((100 - percentage) / 100 * diameter)}px`,
  }


  return (
    <svg
      className={'CircularProgressbar'}
      viewBox="0 0 100 100"
      width={100}
      height={100}
    >
      <path
        className="CircularProgressbar-trail"
        d={pathDescription}
        strokeWidth={strokeWidth}
        fillOpacity={1}
        style={{
          fill: 'var(--ui-element)',
          stroke: 'var(--ui-border)',
        }}
      />

      <path
        className="CircularProgressbar-path"
        d={pathDescription}
        strokeWidth={strokeWidth}
        fillOpacity={0}
        style={progressStyle}
      />

      <text
        className="CircularProgressbar-text"
        x={50}
        y={50}
        style={{
          fill: activeColor,
          fontSize: '24px',
          dominantBaseline: 'central',
          textAnchor: 'middle',
        }}
      >
        {`${percentage}%`}
      </text>
    </svg>
  )
}
