import React from 'react'

import { Accordion } from '../../../components'

import './style.css'

function List({id, title, children, opened}) {

  return(
    <Accordion style={{marginTop: 20}} id={id} title={title} initialState={opened}>     
        <div className='rider-workshift-list'>
            {children}
        </div>           
    </Accordion>
  )
}

function ListItem({children}) {

  return(
    <div className='list-item'>
        {children}
    </div>
  )
}

const RiderWorkshiftList = { List, ListItem }

export default RiderWorkshiftList