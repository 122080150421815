import { gql } from '@apollo/client'

import { FAQ_FRAGMENT } from './'

export const FAQ = gql`
  query faqItem($id: String, $slug: String){
     faqItem(id: $id, slug: $slug){
       ...FAQ_FRAGMENT
     }
  }
  ${FAQ_FRAGMENT}
`
