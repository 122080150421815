import React from 'react'
import { useQuery } from '@apollo/client'
import { useParams, Routes, Route } from 'react-router-dom'


import { RIDER_QUERY } from '../../graphql'
import { Loader, Wrapper, Sidebar, SidebarMenu, IntlText } from '../../components'
import { RiderFeedbacks, RiderWorkshifts, RiderContract, RiderInsights, CreateRiderPayout, RiderPayout, RiderPaymentSettings, RiderInvoicing, RiderContactDetails, RiderVehicle, RiderNewVehicle, RiderVehicles, RiderSettings, RiderDetails, RiderReset, RiderBalance, RiderBalanceHistory, RiderBalanceNewEntry, RiderPurchases } from '../../views'
import { RiderPayouts } from '../../containers'

export function Rider() {

  const { id } = useParams()
  const {loading, error, data} = useQuery(RIDER_QUERY, {variables: {id}})

  if (loading) return <Loader theme="main"/>
  if (error) return `Error! ${error.message}`

  const { rider } = data

  return(
    <Wrapper>
      <div id="rider" className="container">
        <div className="row padding">
          <Sidebar.View>
            <Sidebar.Menu style={{maxWidth: 280}} size={1}>
              <SidebarMenu.Menu bordered rounded>
                <SidebarMenu.Menu bordered>
                  <SidebarMenu.Link to=""><IntlText group="rider" id="tab-menu-details" /></SidebarMenu.Link>
                  <SidebarMenu.Link to="payment-settings"><IntlText group="rider" id="tab-menu-payment-settings" /></SidebarMenu.Link>
                  <SidebarMenu.Link to="contract"><IntlText group="rider" id="tab-menu-contract" /></SidebarMenu.Link>
                  <SidebarMenu.Link to="contact"><IntlText group="rider" id="tab-menu-contact" /></SidebarMenu.Link>
                  <SidebarMenu.Link to="invoicing"><IntlText group="rider" id="tab-menu-invoicing" /></SidebarMenu.Link>
                  <SidebarMenu.SubMenu label={<IntlText group="rider" id="tab-menu-vehicles" />} path="riders/:id/vehicles">
                    <SidebarMenu.Link to="vehicles"><IntlText group="rider" id="tab-menu-all-vehicles" /></SidebarMenu.Link>
                    <SidebarMenu.Link to="vehicles/new"><IntlText group="rider" id="tab-menu-new-vehicle" /></SidebarMenu.Link>
                  </SidebarMenu.SubMenu>                     
                  <SidebarMenu.SubMenu label={<IntlText group="rider" id="tab-menu-balance" />} path="riders/:id/balance">
                    <SidebarMenu.Link to="balance"><IntlText group="rider" id="tab-menu-balance-all" /></SidebarMenu.Link>
                    <SidebarMenu.Link to="balance/new"><IntlText group="rider" id="tab-menu-balance-new" /></SidebarMenu.Link>
                  </SidebarMenu.SubMenu>                     
                  <SidebarMenu.SubMenu label={<IntlText group="rider" id="tab-menu-payouts" />} path="riders/:id/payouts">
                    <SidebarMenu.Link to="payouts"><IntlText group="rider" id="tab-menu-all-payouts" /></SidebarMenu.Link>
                    <SidebarMenu.Link to="payouts/new"><IntlText group="rider" id="tab-menu-new-payout" /></SidebarMenu.Link>
                  </SidebarMenu.SubMenu>                     
                  <SidebarMenu.Link to="insights"><IntlText group="rider" id="tab-menu-insights" /></SidebarMenu.Link>
                  <SidebarMenu.Link to="feedbacks"><IntlText group="rider" id="tab-menu-feedbacks" /></SidebarMenu.Link>
                  <SidebarMenu.Link to="orders"><IntlText group="rider" id="tab-menu-orders" /></SidebarMenu.Link>                  
                  <SidebarMenu.Link to="workshifts"><IntlText group="rider" id="tab-menu-workshifts" /></SidebarMenu.Link>                  
                  <SidebarMenu.Link to="settings"><IntlText group="rider" id="tab-menu-settings" /></SidebarMenu.Link>
                  <SidebarMenu.Link to="reset"><IntlText group="rider" id="tab-menu-reset" /></SidebarMenu.Link>
                </SidebarMenu.Menu>
              </SidebarMenu.Menu>
            </Sidebar.Menu>
            <Sidebar.Content size={2}>                  
              <Routes>
                <Route path="" element={<RiderDetails rider={rider} />} />
                <Route path="contact" element={<RiderContactDetails rider={rider} />} />
                <Route path="insights" element={<RiderInsights rider={rider} />} />
                <Route path="feedbacks" element={<RiderFeedbacks rider={rider} />} />
                <Route path="orders" element={<RiderPurchases />} />                
                <Route path="workshifts" element={<RiderWorkshifts rider={rider} />} />                
                <Route path="contract" element={<RiderContract rider={rider} />} />
                <Route path="invoicing" element={<RiderInvoicing rider={rider} />} />
                <Route path="payouts" >
                  <Route path="" element={<RiderPayouts rider={rider} />} />
                  <Route path='new' element={<CreateRiderPayout rider={rider} />} />                      
                  <Route path=':payoutID' element={<RiderPayout rider={rider} />} />                      
                </Route>
                <Route path="balance">
                  <Route path="" element={                  
                    <>
                      <RiderBalance rider={rider} />
                      <RiderBalanceHistory rider={rider} />
                    </>} 
                  />
                  <Route path="new" element={<RiderBalanceNewEntry rider={id}/>} />
                </Route>
                <Route path="payment-settings" element={<RiderPaymentSettings rider={rider} />} />
                <Route path="vehicles">
                  <Route path="" element={<RiderVehicles rider={rider} />} />
                  <Route path="new" element={<RiderNewVehicle rider={rider} />} />
                  <Route path=":vehicleId" element={<RiderVehicle />} />
                </Route>
                <Route path="settings" element={<RiderSettings rider={rider} />} />
                <Route path="reset" element={<RiderReset rider={rider} />} />
              </Routes>
            </Sidebar.Content>
          </Sidebar.View>                  
        </div>
      </div>
    </Wrapper>
  )
}
