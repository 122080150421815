import React from 'react'
import { useMutation } from '@apollo/client'

import config from '../../../config'
import { EDIT_CUSTOMER_MUTATION } from '../../../graphql'
import { copyToClipboard } from '../../../helpers'
import { Title, IntlText, Button, SwitchBox, SelectV2 } from '../../../components'
import { ReferralUser } from '../../../views'

import './style.css'

export function ReferralCode({customer}) {

  const [editCustomer] = useMutation(EDIT_CUSTOMER_MUTATION)
  const options = ["ABUSIVE_BEHAVIOUR", "INVALID_CONTACTS"]

  async function _editCustomer(data){
    try {
      await editCustomer({variables: {id: customer?.id, data}})
    } catch (error) {
      console.log("error", error);
    }
  }

  function copyLink(){
    copyToClipboard(`${config.home}/delivery-service?referral=${customer?.referrerCode}`)
  }

  return(
    <div style={{display: "flex", gap: 40}}>
      {customer?.referralSource && 
        <div style={{display: "flex", flexDirection: "column", gap: 15}}>
          <Title tag="h3" styled><IntlText group="referred-customers" id="referral-source"/></Title>
          <ReferralUser id={customer?.referralSourceId} />
          <SwitchBox id="cashPaymentsDisabled" label={<IntlText group="referred-customers" id="valid-referral-desc" />} checked={customer.validReferral} onChange={(e) => _editCustomer({validReferral: !customer?.validReferral})}>
            <IntlText group='referred-customers' id='valid-referral' />
          </SwitchBox>        
          <SelectV2 placeholder={<IntlText group="referred-customers" id="referralProblem" />} value={(val) => <IntlText group="referred-customers" id={val} />} selected={options?.find(_t => _t === customer?.referralProblem)} options={options} onChange={(_option) => _editCustomer({referralProblem: _option})} />
        </div>
      }
      {customer?.referrerCode && 
        <div>
          <Title tag="h3" styled><IntlText group="referred-customers" id="referral-code"/></Title>
          <div id="referral-code">
            <div style={{display: "flex", gap: 15, flexDirection: "column"}}>
              <span style={{fontSize: "var(--font-l)", fontWeight:"bold"}}>{customer?.referrerCode}</span>
              <Button onClick={copyLink}>
                <IntlText group="referred-customers" id="copy-code"/>
              </Button>
            </div>
          </div>
        </div>
      }
    </div>
  )
}
