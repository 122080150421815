import React from 'react'
import FontAwesome from 'react-fontawesome'
import moment from 'moment'
import { useMutation } from '@apollo/client'

import { CHANGE_STATUS_MUTATION, PROCESS_QUEUED_PURCHASE, APPROVE_PURCHASE } from '../../graphql'
import { toast } from '../../helpers'
import { useAuth, useIntl } from '../../hooks'
import { Loader, Button } from '../../components'
import { CookingTime, AssignRider, VoidOrder, ReadyForCollectionButton, CancelDropdown } from '../../views'

export default function PurchaseStatus({purchase}) {
  const { getTranslation } = useIntl()
  const { userCanDo } = useAuth()
  const [changeStatus, { loading }] = useMutation(CHANGE_STATUS_MUTATION)

  const statusOptions = [
    purchase?.status === "REQUIRES_PAYMENT" ? 
      {id: "REQUIRES_PAYMENT", timestamp: purchase.createdAt, active: ["REQUIRES_PAYMENT"], visited: ["PROCESSED", "INPREPARATION", "READYFORCOLLECTION", "ONTHEWAY", "ATLOCATION", "COMPLETED"]} 
    : purchase?.status === "REQUIRES_CONFIRMATION" ? 
      {id: "REQUIRES_CONFIRMATION", timestamp: purchase.timestamps.flaggedForConfirmation, actions: <ConfirmPurchaseActions purchase={purchase} />, active: ["REQUIRES_CONFIRMATION"], visited: ["PROCESSED", "INPREPARATION", "READYFORCOLLECTION", "ONTHEWAY", "ATLOCATION", "COMPLETED"]} 
    : purchase?.status === "INQUEUE" || !!purchase.timestamps.queued ? 
      {id: "INQUEUE", timestamp: purchase.timestamps.queued, actions: <InqueueActions purchase={purchase} />, active: ["INQUEUE"], visited: ["PROCESSED", "INPREPARATION", "READYFORCOLLECTION", "ONTHEWAY", "ATLOCATION", "COMPLETED"]}
    : {},
    {id: "PROCESSED", timestamp: purchase.timestamps.processed, active: ["PROCESSED"], visited: ["INPREPARATION", "READYFORCOLLECTION", "ONTHEWAY", "ATLOCATION", "COMPLETED"]},
    {id: "INPREPARATION", actions: <CookingTime purchase={purchase}/>, timestamp: purchase.timestamps.vendorConfirmed, active: ["INPREPARATION"], visited: ["READYFORCOLLECTION", "ONTHEWAY", "ATLOCATION", "COMPLETED"]},
    {id: "READYFORCOLLECTION", timestamp: purchase.timestamps.readyForCollection, actions: <ReadyForCollectionButton purchase={purchase} />, active: ["READYFORCOLLECTION"], visited: ["ONTHEWAY", "ATLOCATION", "COMPLETED"]},
    {id: "ONTHEWAY", actions: <AssignRider purchase={purchase}/>, timestamp: purchase.timestamps.collected, active: ["ONTHEWAY"], visited: ["ATLOCATION", "COMPLETED"]},
    {id: "ATLOCATION", timestamp: purchase.timestamps.arrivedAtClient, active: ["ATLOCATION"], visited: ["COMPLETED"]},
    {id: "COMPLETED", timestamp: purchase.timestamps.completed, actions: <VoidOrder purchase={purchase}/>, active: ["COMPLETED"]},
    {id: "CANCELLED", info: purchase.status, timestamp: getTimestamp(), actions: <CancelDropdown purchase={purchase} />, active: ["CANCELLED", "REJECTED", "UNPROCESSED", "DELIVERYFAILED", "PAYMENTFAILED"]},
  ]

  function getTimestamp(){
    switch (purchase.status) {
      case "DELIVERYFAILED":
        return purchase.timestamps["deliveryFailed"]
      case "PAYMENTFAILED":
        return purchase.timestamps["paymentFailed"]
      case "REJECTED":
        return purchase.timestamps["rejected"]
      case "UNPROCESSED":
        return purchase.timestamps["unprocessed"]
      default:
        return purchase.timestamps["cancelled"]
    }

  }

  function isActive(active = [], visited = []){
    if (active.includes(purchase.status))
      return "active"

    if (visited.includes(purchase.status))
      return "visited"

    return ""
  }

  async function _changeStatus(status){
    const alert = toast.loading()
    try {
      if (!userCanDo()) throw new Error("You don't have permission for this action")
      await changeStatus({variables: {id: purchase.id, status}})
      alert.success(getTranslation({id: "change-success"}))
    } catch (e) {
      alert.apolloError(e)
    }
  }
  
  return(
    <div id="order-status" className="status-bar">
      {loading && <Loader theme="main" overlay />}
      {statusOptions.map((status, key) => {
        if (status.id) return(
          <div key={key} className={`status-bar-item ${isActive(status.active, status.visited)}`}>
            <FontAwesome name="check" />
            <div className="status-name" onClick={() => _changeStatus(status.id)}>
              <span>{status.id} {status.info && isActive(status.active, status.visited) && ` - ${purchase.status}`}</span>
              {status.timestamp &&
                <span className="time"><FontAwesome name="clock" />{moment(status.timestamp).format("HH:mm")}</span>
              }
            </div>
            {status.actions &&
              <div className="actions">{status.actions}</div>
            }
          </div>
        )

        return null
      
      })}
    </div>
  )
}

function InqueueActions({purchase}){
  
  const { getTranslation } = useIntl()
  const [processQueuedPurchase, {loading}] = useMutation(PROCESS_QUEUED_PURCHASE)

  async function _processQueuedPurchase(){
    const alert = toast.loading()
    try {
      processQueuedPurchase({variables: {id: purchase.id}})
      alert.success(getTranslation({id: "change-success"}))
    } catch (error) {
      alert.apolloError(error)
    }
  }  

  if (purchase.status === "INQUEUE") return(
    <Button className="approve" loading={loading} onClick={_processQueuedPurchase} icon="check">{getTranslation({group: "purchase-status", id: "process"})}</Button>
  )

  return null
}

function ConfirmPurchaseActions({purchase}){

  const { getTranslation } = useIntl()
  const [approvePurchase] = useMutation(APPROVE_PURCHASE)
  const [changeStatus] = useMutation(CHANGE_STATUS_MUTATION)

  async function _approvePurchase(){
    const alert = toast.loading()
    try {
      approvePurchase({variables: {id: purchase.id}})
      alert.success(getTranslation({id: "change-success"}))
    } catch (error) {
      alert.apolloError(error)
    }
  }

  async function _changeStatus(status){
    const alert = toast.loading()
    try {
      await changeStatus({variables: {id: purchase.id, status}})
      alert.success(getTranslation({id: "change-success"}))
    } catch (e) {
      alert.apolloError(e)
    }
  }  

  if (purchase.status === "REQUIRES_CONFIRMATION") return(
    <div style={{display: "flex", gap: 10}}>
      <Button className="approve" onClick={_approvePurchase} icon="check">{getTranslation({group: "purchase-status", id: "approve"})}</Button>
      <Button className="reject" onClick={() => _changeStatus("CANCELLED")} icon="times">{getTranslation({group: "purchase-status", id: "reject"})}</Button>
    </div>    
  )  

  return null
}