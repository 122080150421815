import { gql } from '@apollo/client'

const REFUND_FRAGMENT = gql`
  fragment REFUND_FRAGMENT on Refund {
    id
    customer
    purchase{
      vendorName
      customerName
    }
    conektaPaymentId
    value
    isTotal
    reason
    note
    status
    statusMessage
    discountableFromVendor
    createdAt
  }
`

export default REFUND_FRAGMENT
