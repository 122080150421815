import React, { useContext } from 'react'
import { useMutation } from '@apollo/client'

import { toast } from '../../../helpers'
import { IntlContext } from '../../../contexts'
import { Form, SelectV2, EditBox } from '../../../components'
import { EDIT_VENDOR_MUTATION } from '../../../graphql'

export function VendorPackage({vendor}) {

  const { getTranslation } = useContext(IntlContext)
  const [editVendor] = useMutation(EDIT_VENDOR_MUTATION)
  const contractTypes = [{id: "FEATURED", label: "Featured"}, {id: "BASIC", label: "Basic"}]
  const serviceFeeChargePeriods = [{id: "WEEKLY", label: "Weekly"}, {id: "MONTHLY", label: "Monthly"}]
  const serviceFeeChargeTypes = [{id: "ONPICKUP", label: "On-pickup"}, {id: "PERIODICAL", label: "Periodical"}]

  async function save(data){
    const alert = toast.loading()

    try {
      await editVendor({variables: {id: vendor.id, data}})
      alert.success(getTranslation({id: "change-success"}))
    } catch (e) {
      alert.apolloError(e)
    }
  }

  return(
    <Form.Form id="vendor-contract" >
      <Form.Field>
        <SelectV2 placeholder={getTranslation({group: "vendor-contract", id: "contract"})} value={(_value) => _value.label}  selected={contractTypes?.find(_t => _t.id === vendor?.contract)} options={contractTypes} onChange={(_contract) => save({contract: _contract.id})} />
      </Form.Field>
      <Form.Field>
        <EditBox.Date placeholder={getTranslation({group: "vendor-contract", id: "contractExpiry"})} save={(value) => save({contractExpiry: value})}>{vendor.contractExpiry}</EditBox.Date>
      </Form.Field>          
      <Form.Field>
        <EditBox.Text placeholder={getTranslation({group: "vendor-contract", id: "contractUrl"})} save={(value) => save({contractUrl: value})}>{vendor.contractUrl}</EditBox.Text>
      </Form.Field>
      <Form.Field>
        <EditBox.Text type='number'  placeholder={getTranslation({group: "vendor-contract", id: "serviceFee"})} save={(value) => save({serviceFee: parseFloat(value)})}>{vendor.serviceFee}</EditBox.Text>
      </Form.Field>
      <Form.Field>
        <SelectV2 placeholder={getTranslation({group: "vendor-contract", id: "serviceFeeChargeType"})} value={(_value) => _value.label} selected={serviceFeeChargeTypes?.find(_t => _t.id === vendor.serviceFeeChargeType)} options={serviceFeeChargeTypes} onChange={(_type) => save({serviceFeeChargeType:  _type.id})} />
      </Form.Field>
      {vendor.serviceFeeChargeType === "PERIODICAL" &&
        <Form.Field>
          <SelectV2 placeholder={getTranslation({group: "vendor-contract", id: "serviceFeeChargePeriod"})} value={(_value) => _value.label} selected={serviceFeeChargePeriods?.find(_t => _t.id === vendor.serviceFeeChargePeriod)} options={serviceFeeChargePeriods} onChange={(_contract) => save({serviceFeeChargePeriod: _contract.id})} />
        </Form.Field>
      }  
    </Form.Form>
  )

}
