import { gql } from '@apollo/client'

import { PURCHASE_FRAGMENT } from './'

export const SET_PREPARATION_TIME_MUTATION = gql`
  mutation setPreparationTime($id: ID!, $time:Int!){
    setPreparationTime(id: $id, time:$time){
      ...PURCHASE_FRAGMENT
    }
  }
  ${PURCHASE_FRAGMENT}
`
