import { toast } from 'react-toastify'

function loading(string){
    const toastID = toast.loading(string || "Please wait...", {autoClose: 3000, closeOnClick: true, theme: localStorage.getItem("app-theme"), pauseOnFocusLoss: false, newestOnTop: true})

    function success(_string){
        toast.update(toastID, { render: _string, type: "success", isLoading: false, autoClose: 3000, closeOnClick: true, theme: localStorage.getItem("app-theme"), pauseOnFocusLoss: false, newestOnTop: true })
    }

    function error(_string){
        toast.update(toastID, { render: _string, type: "error", isLoading: false, autoClose: 3000, closeOnClick: true, theme: localStorage.getItem("app-theme"), pauseOnFocusLoss: false, newestOnTop: true })
    }

    function update(_string, options){
        toast.update(toastID, { render: _string, ...options})
    }

    function hide(_string){
        toast.dismiss(toastID)
    }    
    
    function apolloError(error){
        if (error.graphQLErrors?.length > 0){
            error.graphQLErrors.map(x => toast.update(toastID, { render: x.message, type: "error", isLoading: false, autoClose: 3000, closeOnClick: true, theme: localStorage.getItem("app-theme"), pauseOnFocusLoss: false, newestOnTop: true })) 
        } else if(error.networkError?.result?.errors) {
            error.networkError.result.errors.map(x => toast.update(toastID, { render: x.message, type: "error", isLoading: false, autoClose: 3000, closeOnClick: true, theme: localStorage.getItem("app-theme"), pauseOnFocusLoss: false, newestOnTop: true })) 
        } else {
            toast.update(toastID, { render: error.message, type: "error", isLoading: false, autoClose: 3000, closeOnClick: true, theme: localStorage.getItem("app-theme"), pauseOnFocusLoss: false, newestOnTop: true })
        }        
    }

    return {
        toastID,
        success,
        error,
        apolloError,
        update,
        hide
    }
}

const _toast = {
    ...toast,
    loading
}

export default _toast
