import React from 'react'
import { toast } from 'react-toastify'
import { useQuery } from '@apollo/client'

import { ME_QUERY } from '../../graphql'
import { Loader } from '../../components'

export function Authentication({children}) {

  const { data, loading, error } = useQuery(ME_QUERY)

  if (loading) return <Loader theme="main" />
  if (error) return <Loader theme="main" />

  const { me } = data

  function userCanDo(cb){
    try {
      if (me?.superAdmin) return cb()
      throw new Error("You are not authenticated to do this")
    } catch (e) {
      toast.error(e?.message || e)
    }

  }

  function userCanSee(){
    if (me?.superAdmin) return true

    return false
  }

  return children({userCanSee, userCanDo})
}
