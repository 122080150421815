import { gql } from '@apollo/client'

import { FAQ_FRAGMENT } from './'

export const CREATE_FAQ = gql`
  mutation createFaqItem($data: FaqItemInput!){
    createFaqItem(data: $data){
      ...FAQ_FRAGMENT
    }
  }
  ${FAQ_FRAGMENT}
`
