import { gql } from '@apollo/client'

import { VENDOR_BOOK_FRAGMENT } from './'

export const VENDOR_BOOK = gql`
  query vendorBook($vendor: ID){
     vendorBook(vendor: $vendor, ) {
       ...VENDOR_BOOK_FRAGMENT
     }
  }
  ${VENDOR_BOOK_FRAGMENT}
`
