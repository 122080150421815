import { gql } from '@apollo/client'

import { INVOICE_FRAGMENT } from '../fragments'

const INVOICES_QUERY = gql`
  query invoiceRequests($date: [String]){
     invoiceRequests(date: $date) {
       ...INVOICE_FRAGMENT
     }
  }
  ${INVOICE_FRAGMENT}
`

export default INVOICES_QUERY
