import { gql }from '@apollo/client'

const ADDON_OPTION_FRAGMENT = gql`
  fragment ADDON_OPTION_FRAGMENT on ProductAddonOption {
    id
    name{
      es
      en
    }
    price
    quantifiable
    disabled
  }
`

export default ADDON_OPTION_FRAGMENT
