import { gql } from '@apollo/client'

export const QUERY_PRODUCTS = gql`
  query queryProducts($query: String, $mustAcceptCard: Boolean, $highRatingVendor: Boolean, $canOrderNow: Boolean, $onlyWithPhoto: Boolean, $vendor: ID, $tags: [String], $first: Int, $after: String, $sortBy: PRODUCT_LIST_SORT){
    queryProducts(query: $query, mustAcceptCard: $mustAcceptCard, highRatingVendor: $highRatingVendor, canOrderNow: $canOrderNow, onlyWithPhoto: $onlyWithPhoto, vendor: $vendor, tags: $tags, first: $first, after: $after, sortBy: $sortBy) {
      edges{
        cursor
        node{
          canOrderNow
          category{
            name{
              es
              en
            }
          }
          vendor{
            id
            slug
            name
            logo
          } 
          product{
            id
            rank
            slug
            media{
              cover
              thumbnail
            }
            tags
            name{
              en
              es
            }
            description{
              en
              es
            }
            price
            discount
            discountedPrice
            freeDeliveryAvailable
            freeDeliveryFixRate
            minPurchaseQuantity
            maxPurchaseQuantity
            disabled
            hidden
            addons{
              id
              rank
              options{
                id
                slug
                name{
                  en
                  es
                }
                price
                disabled
              }
              addon{
                id
                slug
                name{
                  en
                  es
                }
                label{
                  en
                  es
                }
                description{
                  en
                  es
                }
                instruction{
                  en
                  es
                }
                minSelect
                maxSelect
                required
                disabled
              }
            } 
          }
        }
      }
      pageInfo{
        limit
        total
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }      
    }
  }
`
