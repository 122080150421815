import { useState, useRef, useEffect } from 'react'
import { useMutation } from '@apollo/client'
import FontAwesome from 'react-fontawesome'

import { MODIFY_PURCHASE, CREATE_VENDOR_BOOK_ENTRY, CREATE_CUSTOMER_BOOK_ENTRY } from '../../../graphql'
import { useIntl, useModal } from '../../../hooks'
import { toast, formatPrice } from '../../../helpers'
import { Button, ContextMenu, IntlText, Modalv3, IntlValue, QuantitySelector } from '../../../components'
import { PurchaseAddProduct, PurchaseProducts, PurchaseTotals, PurchaseRevisions, PurchaseProduct } from '../../../views'

import './style.css'

export function PurchaseCart({purchase, purchase: {products}}) {

  const wrapper = useRef(null)
  const modal = useModal()
  const productModal = useModal()
  const revisionModal = useModal()
  const refundModal = useModal()
  const { getTranslation } = useIntl()
  const [edit, toggleEdit] = useState(false)
  const [cart, updateCart] = useState(products)
  const [modfiyPurchase, { loading: modifyLoading }] = useMutation(MODIFY_PURCHASE)

  useEffect(() => {
    updateCart(products)
  }, [products])  

  function deleteCartItem(key){
    const _cart = [...cart]
    _cart.splice(key, 1)
    updateCart(_cart)
    toggleEdit(true)
  }

  function addItem(_product){
      const _cart = cart.concat([_product])
      updateCart(_cart)
      toggleEdit(true)
  }

  function editItem(_product){
    let _cart = [...cart]
    _cart[_product?.index] = _product
    updateCart(_cart)
    toggleEdit(true)
    productModal.hide()
  }

  function reset(){
      updateCart(products)
      toggleEdit(false)
  }

  async function _modfiyPurchase(){
    try {
        const _products = cart.map((_product) => {
            return({
                product: _product.product.id || _product.product.originalId,
                addons: _product.addons.map((_addon) => ({
                    addon: _addon.addon.id || _addon.addon.originalId,
                    _selectedOptions: _addon.selectedOptions.map((_option) => ({
                        id: _option.id || _option.originalId,
                        quantity: _option.quantity || 1,
                    }))
                })),
                quantity: _product.quantity,
                instructions: _product.instructions,
                forcedPrice: parseFloat(_product.forcedPrice)
            })
        })
      await modfiyPurchase({variables: {id: purchase.id, data: {products: _products}}})
      toggleEdit(false)
      toast.success(getTranslation({id: "edit-success"}))
    } catch (error) {
      if (error.graphQLErrors && error.graphQLErrors.length > 0) error.graphQLErrors.map(x => toast.error(x.message))
    }
  }

  return(
    <div id="purchase-cart">
      <PurchaseProducts products={cart} vendor={purchase.vendor}>
        {({key, product}) => 
          <ContextMenu.Menu id={purchase?.id} wrapper={wrapper}>
            <ContextMenu.Button onClick={() => productModal.show({index: key, product})} icon="trash"><IntlText id="edit" /></ContextMenu.Button>
            <ContextMenu.Button onClick={() => deleteCartItem(key)} icon="trash"><IntlText id="delete" /></ContextMenu.Button>
            <ContextMenu.Button onClick={() => refundModal.show({purchase, product})} icon="undo">Refund item</ContextMenu.Button>
          </ContextMenu.Menu>
        }
      </PurchaseProducts>
      <div style={{marginTop: 15, marginBottom: 15}}>
        <div className='actions'>
          {purchase.revisions?.length > 0 &&
            <Button  onClick={() => revisionModal.show(purchase)}><IntlText group="purchase-cart" id="show-history" /></Button>
          }
          <Button  onClick={() => modal?.show()}><IntlText group="purchase-cart" id="add-items" /></Button>
        </div>
        {edit &&
          <div style={{marginTop: 15}} className='actions'>
            <Button  onClick={reset}><IntlText id="reset" /></Button>
            <Button loading={modifyLoading} theme="main" onClick={_modfiyPurchase}><IntlText id="save" /></Button>
          </div>
        }
      </div>
      {!edit &&
        <PurchaseTotals purchase={purchase}>
          {purchase.revisions?.length > 0 &&
            <div id="revisions" className="totals-info">
              <div className="icon">
                <FontAwesome name="exclamation-triangle"/>
              </div>
              <div className="info" onClick={() => revisionModal.show(purchase)}>
                <IntlText group="purchase-totals" id="purchase-history"/>
              </div>
            </div>              
          }
        </PurchaseTotals>        
      }
      <Modalv3 id="purchase-add-product" modal={modal} headerTitle={<IntlText group="purchase-cart" id="modal-title" />}>
        <PurchaseAddProduct purchase={purchase} addItem={addItem} />
      </Modalv3>      
      <Modalv3 id="purchase-revisions" modal={revisionModal} headerTitle={<IntlText group="purchase-revisions" id="modal-title" />}>
        <PurchaseRevisions purchase={revisionModal.state} />        
      </Modalv3>      
      <Modalv3 id="purchase-product" contentStyle={{overflow: "hidden"}} modal={productModal} headerTitle={<IntlValue>{productModal.state?.product?.product?.name}</IntlValue>}>
        <PurchaseProduct index={productModal.state?.index} {...productModal.state?.product} vendor={purchase.vendor} addItem={editItem} />
      </Modalv3>           
      <Modalv3 id="purchase-product-refund" modal={refundModal} headerTitle={<IntlText group="purchase-product-refund" id="modal-title" />}>
        <RefundProduct modal={refundModal} />
      </Modalv3>           
    </div>
  )
}

function RefundProduct({modal}){

  const { getTranslation, getTranslatedValue } = useIntl()
  const { product, purchase: { vendor, customer } } = modal.state
  const [qty, setQty] = useState(product.quantity)
  const [createVendorBookEntry] = useMutation(CREATE_VENDOR_BOOK_ENTRY)
  const [createCustomerBookEntry] = useMutation(CREATE_CUSTOMER_BOOK_ENTRY)

  function increase(){
    const newValue = qty+1
    if (newValue > product.quantity) return toast.error(getTranslation({group: "purchase-product-refund", id: "max"}))
    setQty(newValue)
}

  function decrease(){
    const newValue = qty-1
    if (newValue < 1) return toast.error(getTranslation({group: "purchase-product-refund", id: "min"}))
    setQty(qty-1)
  }

  async function refund(){
    const alert = toast.loading()
    try {
      await createCustomerBookEntry({variables: {data: {customer: customer?.originalId, event: "CASH_REFUND", note: `Reembolsado ${getTranslatedValue(product?.product?.name)}`, value: parseFloat(qty * product.totalPricePerProduct)}}})
      await createVendorBookEntry({variables: {data: {vendor: vendor?.originalId, event: "CASH_DEBIT", note: `Reembolsado ${getTranslatedValue(product?.product?.name)}`, value: parseFloat(qty * (product.totalPricePerProduct * (1-vendor.serviceFee)))}}})
      alert.success(getTranslation({id: "create-success"}))
      modal.hide()
    } catch (error) {
      alert.error()
    }
  }

  return(
    <div className='purchase-product-refund'>
      <div className='row'>
        <span>{getTranslation({group: "purchase-product-refund", id: "quantity"})}</span>
        <QuantitySelector qty={qty} increase={increase} decrease={decrease}/>      
      </div>
      <div className='row'>
        <span>{getTranslation({group: "purchase-product-refund", id: "customer-value"})}</span>
        <div>{formatPrice(qty * product.totalPricePerProduct)}</div>
      </div>
      <div className='row'>
        <span>{getTranslation({group: "purchase-product-refund", id: "vendor-value"})}</span>
        <div>{formatPrice(qty * (product.totalPricePerProduct * (1-vendor.serviceFee)))}</div>
      </div>
      <Button theme="main" onClick={refund}>{getTranslation({group: "purchase-product-refund", id: "refund"})}</Button>
    </div>
  )
}