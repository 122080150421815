import { useQuery, useMutation } from '@apollo/client'

import { SEND_CALL_TO_WORK_NOTIFICATION, EDIT_SETTING_MUTATION, SETTINGS_QUERY } from '../../../graphql'
import { useAuth, useIntl, useModal } from '../../../hooks'
import { extractSettings, toast } from '../../../helpers'
import { Button, Modalv3, Title, IntlText, Loader, SwitchBox, Form } from '../../../components'

export function DispatcherActions(){

  const modal = useModal()
  const { user } = useAuth()
  const { getTranslation } = useIntl()
  const { loading, data, error } = useQuery(SETTINGS_QUERY, {variables: {keys: ["QUEUEISCLOSED", "PURCHASES_TEMPORARILY_PAUSED", "SERVICE_TEMPORARILY_SUSPENDED"]}})
  const [sendCallToWorkNotificationToContractorRiders] = useMutation(SEND_CALL_TO_WORK_NOTIFICATION)
  const [editSetting] = useMutation(EDIT_SETTING_MUTATION)

  async function _editSetting(key, value){
    try {
      await editSetting({variables: {key, data: {value}}})
    } catch (e) {
      console.log("error: ", e);
    }
  }  

  async function _sendCallToWorkNotificationToContractorRiders(){
    const alert = toast.loading()
    
    try {
      await sendCallToWorkNotificationToContractorRiders()
      alert.success(getTranslation({id: "send-success"}))
    } catch (error) {
      alert.apolloError(error)
    }
  }

  if (loading || error) return <Loader />

  const settings = extractSettings(data.settings)

  return(
    <div className='dispatcher-actions'>
      <Button icon="bars" onClick={modal.show}><IntlText group="dispatcher-actions" id="title" /></Button>
      <Modalv3 id="dispatcher-actions" modal={modal} header={<Title tag="h3"><IntlText group="dispatcher-actions" id="title" /></Title>} onHide={() => modal.hide()}>
        <Form.Form>
          {user?.superAdmin === true ? 
            <>
              <Form.Field>
                <SwitchBox id="queueisclosed" label={getTranslation({group: "settings", id: "queueisclosed-info"})} checked={settings["queueisclosed"]?.value} onChange={({target}) => _editSetting("QUEUEISCLOSED", target.checked.toString())}>
                  <IntlText group='settings' id="queueisclosed" />
                </SwitchBox>            
              </Form.Field>          
              <Form.Field>
                <SwitchBox id="purchases_temporarily_paused" label={getTranslation({group: "settings", id: "purchases_temporarily_paused-info"})} checked={settings["purchases_temporarily_paused"]?.value} onChange={({target}) => _editSetting("PURCHASES_TEMPORARILY_PAUSED", target.checked.toString())}>
                  <IntlText group='settings' id="purchases_temporarily_paused" />
                </SwitchBox>  
              </Form.Field>
              <Form.Field>
                <SwitchBox id="service_temporarily_suspended" label={getTranslation({group: "settings", id: "service_temporarily_suspended-info"})} checked={settings["service_temporarily_suspended"]?.value} onChange={({target}) => _editSetting("SERVICE_TEMPORARILY_SUSPENDED", target.checked.toString())}>
                  <IntlText group='settings' id="service_temporarily_suspended" />
                </SwitchBox>  
              </Form.Field>          
              </>
          : null}
          <Form.Field>
            <Button fluid wrap onClick={_sendCallToWorkNotificationToContractorRiders}><IntlText group="dispatcher-actions" id="sendCallToWorkNotificationToContractorRiders" /></Button>
          </Form.Field>          
        </Form.Form>
      </Modalv3>             
    </div>
  )
  
}
  