import { gql } from '@apollo/client'

import { RIDER_BOOK_ENTRY_FRAGMENT } from './'

export const RIDER_BOOK_ENTRIES = gql`
  query riderBookEntries($rider: ID, $date: [String], $limit: Int, $offset: Int){
    riderBookEntries(rider: $rider, date: $date, limit: $limit, offset: $offset){
      nodes{
        ...RIDER_BOOK_ENTRY_FRAGMENT        
      }
      pageInfo{
        limit
        totalNodes
        firstNode
        lastNode
        totalPages
        actualPage
        hasNextPage         
      }  
    }
  }
  ${RIDER_BOOK_ENTRY_FRAGMENT}
`
