import FontAwesome from 'react-fontawesome'
import { useMutation, useQuery } from '@apollo/client'

import { PURCHASE_QUERY, CREATE_RIDER_MODIFICATION_MUTATION, CREATE_VENDOR_BOOK_ENTRY, CREATE_CUSTOMER_BOOK_ENTRY } from '../../../graphql'
import { useIntl, useValidatedForm } from '../../../hooks'
import { formatPrice, toast } from '../../../helpers'
import { Loader, LabeledInput, LabeledTextarea, Button, Validation, Select, IntlText, Form } from '../../../components'

import './style.css'

const validations = {
  value: [{id: "min0", validation: (val) => !!(val > 0)}],
  reason: [{id: "required", validation: (val) => !!(val && val.id)}],
}

const reasons = [
  {id: "INCOMPLETE_ORDER", label: <div><IntlText>Incomplete order</IntlText><div className="info">The purchase had a missing item and its cost was deducted from the payment upon delivery</div></div>},
  {id: "CLIENT_DISCOUNT", label: <div><IntlText>Customer Discount</IntlText><div className="info">There was a discount applied on the order</div></div>},
  {id: "FAILED_DELIVERY", label: <div><IntlText>Failed delivery</IntlText><div className="info">Purchase was cancelled</div></div>},
  {id: "PRODUCT_DAMAGED", label: <div><IntlText>Product damaged</IntlText><div className="info">Product was damaged by rider</div></div>},
  {id: "RIDER_INSUFFUCIENT_CHANGE", label: <div><IntlText>Rider missing change</IntlText><div className="info">Rider had insufficient change</div></div>},
  {id: "CUSTOMER_INSUFFICIENT_MONEY", label: <div><IntlText>Customer missing money</IntlText><div className="info">Customer had insufficient money to pay</div></div>},
  {id: "OTHER", label: <div><IntlText>Other</IntlText><div className="info">Any other modification not listed here</div></div>}
]

export function PurchaseModifications({id, cancel}){

  const { getTranslation } = useIntl()
  const { loading, data, error, refetch } = useQuery(PURCHASE_QUERY, {variables: {id}, fetchPolicy: "network-only"})
  const [createRiderModification] = useMutation(CREATE_RIDER_MODIFICATION_MUTATION)
  const [createVendorBookEntry] = useMutation(CREATE_VENDOR_BOOK_ENTRY)
  const [createCustomerBookEntry] = useMutation(CREATE_CUSTOMER_BOOK_ENTRY)
  const { form: {reason, note, value}, updateField, updateFields, errors, validate, resetForm } = useValidatedForm({}, validations)

  function changeReason(type){
    if (type.id === "FAILED_DELIVERY"){
      updateFields({fields: [{key: "reason", value: type}, {key: "value", value: purchase?.price?.vendorPrice}]})
    } else {
      updateField({key: "reason", value: type})
    }
  }

  async function addModification(){
    const alert = toast.loading()
    try {
      const { valid } = validate()
      
      if (!purchase?.rider) throw new Error("The purchase has no rider currently")
      if (!valid) throw Error(getTranslation({id: "form-error"}))

      switch (reason.id) {
        case "INCOMPLETE_ORDER":
          await createVendorBookEntry({variables: {data: {vendor: purchase?.vendor?.originalId, event: "CASH_DEBIT", note: `Falta un producto en el pedido para el cliente ${purchase?.customer?.name}`, value: parseFloat(value*(1-purchase?.vendor?.serviceFee))}}})
          await createRiderModification({variables: {data: {purchase: id, rider: purchase?.rider?.originalId, note: `Falta un producto en el pedido para el cliente ${purchase?.customer?.name}`, value: parseInt(value), reason: reason.id}}})
          break
        case "CLIENT_DISCOUNT":
          await createRiderModification({variables: {data: {purchase: id, rider: purchase?.rider?.originalId, note: `Descuento para el cliente ${purchase?.customer?.name}`, value: parseInt(value), reason: reason.id}}})
          break;
        case "FAILED_DELIVERY":
          await createRiderModification({variables: {data: {purchase: id, rider: purchase?.rider?.originalId, note: `Pedido de cliente ${purchase?.customer?.name} fallido`, value: parseInt(value), reason: reason.id}}})
          break;
        case "PRODUCT_DAMAGED":
          await createRiderModification({variables: {data: {purchase: id, rider: purchase?.rider?.originalId, note: `El repartidor dañó los productos del pedido del cliente ${purchase?.customer?.name}`, value: parseInt(value), reason: "OTHER_DEBIT"}}})
          await createCustomerBookEntry({variables: {data: {purchase: id, customer: purchase?.customer?.originalId, event: "CASH_REFUND", note: `El repartidor dañó los productos del pedido del cliente ${purchase?.customer?.name}`, value: parseFloat(value)}}})
          break;
        case "RIDER_INSUFFUCIENT_CHANGE":
          await createRiderModification({variables: {data: {purchase: id, rider: purchase?.rider?.originalId, note: `Repartidor no tenia cambio suficiente para el cliente ${purchase?.customer?.name}`, value: parseInt(value), reason: "OTHER_DEBIT"}}})
          await createCustomerBookEntry({variables: {data: {purchase: id, customer: purchase?.customer?.originalId, event: "CASH_REFUND", note: `Repartidor no tenia cambio suficiente para el cliente ${purchase?.customer?.name}`, value: parseFloat(value)}}})
          break;
        case "CUSTOMER_INSUFFICIENT_MONEY":
          await createRiderModification({variables: {data: {purchase: id, rider: purchase?.rider?.originalId, note: `Cliente ${purchase?.customer?.name} no tenia suficiente dinero`, value: parseInt(value), reason: "OTHER_CREDIT"}}})
          await createCustomerBookEntry({variables: {data: {purchase: id, customer: purchase?.customer?.originalId, event: "CASH_DEBT", note: `Cliente ${purchase?.customer?.name} no tenia suficiente dinero`, value: parseFloat(value)}}})
          break;
        case "OTHER":
          await createRiderModification({variables: {data: {purchase: id, rider: purchase?.rider?.originalId, note: `Cliente ${purchase?.customer?.name} no tenia suficiente dinero`, value: parseInt(value), reason: reason.id}}})
          break;
        default:
          break;
      }

      refetch()
      alert.success(getTranslation({id: "create-success"}))
      resetForm()
    } catch (error) {
      alert.apolloError(error)
    }
  }

  if (loading) return <Loader theme="main"/>
  if (error) return `Error! ${error.message}`

  const { purchase } = data

  return(
    <div id="purchase-modifications">
      <div className="purchase-modifications">
        {purchase?.riderModifications?.map((riderModification, key) =>
          <div className={`purchase-modification ${riderModification.status.toLowerCase()}`} key={key}>
            <div className="info">
              <div className="status"><FontAwesome name="circle" /></div>
              <div className="note">{riderModification.note}</div>
              <div className="price-wrapper">{formatPrice(riderModification.value)}</div>
            </div>
          </div>
        )}
      </div>
      <Form.Form>
        <Form.Field>
          <Validation errors={errors.reason}>
            <Select placeholder="Problem" selected={reason} defaultValue="Please select" options={reasons} onChange={changeReason} />
          </Validation>
        </Form.Field>
        <Form.Field>
          <Validation errors={errors.value}>
            <LabeledInput placeholder="Value" value={value} onChange={({target}) => updateField({key: "value", value: target.value})} />
          </Validation>
        </Form.Field>
        {reason?.id === "OTHER" &&
          <Form.Field>
            <Validation errors={errors.note}>
              <LabeledTextarea placeholder="Write your note here" onChange={({target}) => updateField({key: "note", value: target.value})}>{note}</LabeledTextarea>
            </Validation>
          </Form.Field>
        }
        <Form.Fieldset style={{marginTop: 80}}>
          <Form.Field fluid>
            <Button className="fluid" onClick={cancel}>{getTranslation({id: "cancel"})}</Button>
          </Form.Field>
          <Form.Field fluid>
            <Button className="fluid" theme="main" onClick={addModification}>{getTranslation({id: "add"})}</Button>
          </Form.Field>
        </Form.Fieldset>
      </Form.Form>
    </div>
  )

}
