import React, { useState, useContext } from 'react'
import { useQuery } from '@apollo/client'
import moment from 'moment'

import { useQueryBuilder } from '../../hooks'
import { IntlContext } from '../../contexts'
import { RiderBreaksList } from '../../views'
import { Wrapper, Filters, DatePicker, Placeholder } from '../../components'
import { RIDER_BREAKS } from '../../graphql'

export function RiderBreaks() {

  const [filter, setFilter] = useState()
  const { getTranslation } = useContext(IntlContext)
  const { params, removeParam, runQuery, buildQuery, isQuery, updateQuery } = useQueryBuilder({
    data: {
      date: {key: "date", label: `Date ${moment().format("YYYY-MM-DD")}`, value: moment().format("YYYY-MM-DD")},
    }
  })
  const { data, loading, error, refetch } = useQuery(RIDER_BREAKS, {variables: buildQuery(), skip: !isQuery(), notifyOnNetworkStatusChange: true})

  function onSearch(e){
    if (e.key !== 'Enter') return false
    runQuery()
  }

  function setDate(_date){
    updateQuery({date: {key: "date", label: moment(_date).format("YYYY-MM-DD"), value: moment(_date).format("YYYY-MM-DD"), skip: !_date}})
  }  

  if (error) return `Error! ${error.message}`

  return(
    <Wrapper>
      <div id="riders" className="container">
        <div className="row padding">
          <Filters.Bar >
            <Filters.Input id="query" name="query" value={filter} onChange={({target}) => setFilter(target.value)} reset={() => setFilter(null)} placeholder="Type here for searching" onKeyDown={onSearch}/>
            <Filters.Dropdown style={{marginLeft: "auto"}} placeholder={getTranslation({id: "date"})} value={params?.date?.label} right reset={() => removeParam("date")}>
              <DatePicker.Date date={params?.date?.value && new Date(`${params.date.value}T00:00`)} onChange={setDate} />
            </Filters.Dropdown>                
          </Filters.Bar>
            {isQuery() ?
              <RiderBreaksList loading={loading} data={data} refetch={refetch} filter={filter} />
            :
              <Placeholder.Search />
            }          
        </div>
      </div>
    </Wrapper>
  )
}
