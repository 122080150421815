import { gql } from '@apollo/client'

export const RIDER_FRAGMENT = gql`
  fragment RIDER_FRAGMENT on Rider {
    id
    active
    available
    rank
    name
    username
    fullName
    birthdate
    color
    slug
    type
    whatsappGroup
    phone{
      countryCode
      phoneNumber
    }
    cel{
      phoneCode
      countryCode
      phoneNumber
    }
    celUid
    celVerificationStatus
    celVerifiedAt
    celVerificationRequestCount
    email
    emailVerified 
    emailVerifiedAt
    emergencyContact{
      phoneCode
      countryCode
      phoneNumber      
    }
    emergencyContactName
    emergencyContactRelation
    contract
    contractSigned
    contractExpiry
    contractStatus    
    invoicing{
      rfc
      name
      email
      zip
      street
      exterior
      interior
      neighborhood
      city
      municipality
      state
      taxSystem
    }
    invoicingStatus
    invoicingMessage
    facturApiId
    autoInvoicingEnabledByRider
    taxCertificatesStatus
    taxCertificatesMessage
    taxCertificatesExpiry
    taxCertificatesLastModified 
    suspended
    suspendMessage
    banned
    bannedNote
    identificationStatus
    identificationMessage
    identification
    identificationExpiry
    taxDocStatus
    taxDocMessage
    taxDoc
    taxDocExpiry
    riderProfilePicStatus
    riderProfilePicMessage
    riderProfilePic
    motoLicenseStatus
    motoLicenseMessage
    motoLicense
    motoLicenseBack
    motoLicenseExpiry
    autoLicenseStatus
    autoLicenseMessage
    autoLicense
    autoLicenseBack
    autoLicenseExpiry
    profileStatus
    assignedPurchases
    acceptedPurchases
    completedPurchases
    rejectedPurchases
    clabe
    bankName
    accountHolder
    bankStatement
    bankStatementMessage
    bankStatementStatus
    numOfFeedbacksPositive
    numOfFeedbacksNeutral
    numOfFeedbacksNegative
    numOfFeedbacks
    percentageOfPositive
    lastPurchaseRejected
    lastPurchaseDelivered
    createdAt
    updatedAt
    canDoCard
    canDoCash
  }
`
