import { gql } from '@apollo/client'

export const PURCHASE_CUSTOMER_FRAGMENT = gql`
  fragment PURCHASE_CUSTOMER_FRAGMENT on PurchaseCustomer {
    originalId
    name
    email
    balance
    language
    ip
    tags
    note
    phone{
      countryCode
      phoneNumber
      phoneCode
    }
    abusive
    banned
    referralSource
    referralSourceId
  }
`
