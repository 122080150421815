import { gql } from '@apollo/client'

import { ADDRESS_FRAGMENT } from '../fragments'

const EDIT_ADDRESS_MUTATION = gql`
  mutation editAddress($id: ID!, $data:EditedAddressInput!){
    editAddress(id: $id, data:$data){
      ...ADDRESS_FRAGMENT
    }
  }
  ${ADDRESS_FRAGMENT}
`

export default EDIT_ADDRESS_MUTATION
