import React from 'react'
import moment from 'moment'
import { toast } from 'react-toastify'
import { useMutation } from '@apollo/client'

import { DELETE_RIDER_BREAK, CHANGE_BREAK_STATUS } from '../../../graphql'
import { Filter, Table, ContextMenu, Loader } from '../../../components'

const filters = [
  {filter: (data) => data.rider.name},
  {filter: (data) => data.type},
  {filter: (data) => data.status},
]

export function RiderBreaksList({data, loading, refetch, filter}) {

  const [changeBreakStatus, {loading: editLoading}] = useMutation(CHANGE_BREAK_STATUS)
  const [deleteRiderBreak, {loading: deleteLoading}] = useMutation(DELETE_RIDER_BREAK)

  async function _changeBreakStatus(_data){
    try {
      await changeBreakStatus({variables: _data})
    } catch (e) {
      toast.error(e?.message)
    }

    if (refetch) refetch()
  }

  async function _deleteRiderBreak(id){
    try {
      await deleteRiderBreak({variables: {id}})
    } catch (e) {
      toast.error(e?.message)
    }

    if (refetch) refetch()
  }

  return(
    <div id="riders-break-list">
      {(!!editLoading || !!deleteLoading || !!loading) && <Loader theme="main" overlay/>}
      <Filter id="breaks" data={data?.riderBreaks || []} search={[{key: "search", value: filter}]} filters={filters} sortables={[{id: "name", type: "text", data: (elem) => elem.name}]} >
        {(data) =>
          <Table.Table>
            <Table.Head>
              <Table.Tr>
                <Table.Th>Name</Table.Th>
                <Table.Th>Start</Table.Th>
                <Table.Th>End</Table.Th>
                <Table.Th>Duration</Table.Th>
                <Table.Th>Type</Table.Th>
                <Table.Th>Status</Table.Th>
                <Table.Th></Table.Th>
              </Table.Tr>
            </Table.Head>
            <Table.Body>
              {data?.map((riderBreak, key) =>
                <Table.Tr key={key} className={["riderBreak", riderBreak.active && riderBreak.available ? null : "disabled"].join(" ")}>
                  <Table.Td>{riderBreak.rider.name}</Table.Td>
                  <Table.Td>{riderBreak.start ? moment(riderBreak.start).format("HH:mm") : "-"}</Table.Td>
                  <Table.Td>{riderBreak.end ? moment(riderBreak.end).format("HH:mm") : "-"}</Table.Td>
                  <Table.Td><span style={{whiteSpace: "nowrap"}}>{riderBreak?.start && riderBreak?.end ? `${moment.duration(moment(riderBreak.end).diff(riderBreak.start)).asMinutes().toFixed(0)} minutes` : ""}</span></Table.Td>
                  <Table.Td className="center">{riderBreak.type}</Table.Td>
                  <Table.Td className="center">{riderBreak.status}</Table.Td>
                  <Table.Td>
                    <ContextMenu.Menu id={riderBreak?.id}>
                      {riderBreak?.status === "ONBREAK" &&
                        <ContextMenu.Button onClick={() => _changeBreakStatus({id: riderBreak?.id, status: "FINISHED"})} icon="cog">End break</ContextMenu.Button>
                      }
                      {riderBreak?.status === "REQUESTED" &&
                        <ContextMenu.Button onClick={() => _changeBreakStatus({id: riderBreak?.id, status: "INTRANSFER_TO_BREAK"})} icon="utensils">Send to eat</ContextMenu.Button>
                      }
                      <ContextMenu.Button className="invalid" onClick={() => _deleteRiderBreak(riderBreak?.id)} icon="trash">Delete break</ContextMenu.Button>
                    </ContextMenu.Menu>
                  </Table.Td>
                </Table.Tr>
              )}
            </Table.Body>
          </Table.Table>
        }
      </Filter>
    </div>
  )
}
