import React from 'react'
import { useQuery } from '@apollo/client'

import { REFERRED_CUSTOMERS } from '../../graphql'
import { Wrapper } from '../../components'
import { DeletedCustomersList, NotFound } from '../../views'

export function ReferredCustomers() {
  const { data, loading, error, refetch } = useQuery(REFERRED_CUSTOMERS, {variables: {offset: 0, limit: 10}, notifyOnNetworkStatusChange: true})

  if (error) return (
    <Wrapper>
      <NotFound text={error?.message} fill />
    </Wrapper>
  )

  return(
    <Wrapper>
      <div id="deleted-customers" className="container">
        <div className="row padding">
          <DeletedCustomersList loading={loading} data={data?.deletedCustomers || []} refetch={refetch}/> 
        </div>
      </div>
    </Wrapper>
  )
}
