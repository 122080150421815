
import { Wrapper } from '../../components'
import { NotFound as NotFoundElement } from '../../views'

export function NotFound() {
  return(
    <Wrapper>
      <NotFoundElement fill />
    </Wrapper>
  )
}
