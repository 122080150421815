import { gql }from '@apollo/client'

const ADDON_FRAGMENT = gql`
  fragment ADDON_FRAGMENT on ProductAddon {
    id
    owner
    name{
      es
      en
    }
    label{
      es
      en
    }
    description{
      es
      en
    }
    instruction{
      es
      en
    }
    minSelect
    maxSelect
    required
    disabled
  }
`

export default ADDON_FRAGMENT
