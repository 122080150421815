import { gql } from '@apollo/client'

import { CATEGORY_FRAGMENT } from './'

const CREATE_CATEGORY_MUTATION = gql`
  mutation createProductCategory($data: ProductCategoryInput!){
    createProductCategory(data: $data){
      ...CATEGORY_FRAGMENT
    }
  }
  ${CATEGORY_FRAGMENT}
`

export default CREATE_CATEGORY_MUTATION
