import React, { useContext } from 'react'
import { useQuery, useMutation } from '@apollo/client'
import { useParams } from 'react-router-dom'

import { useValidatedForm } from '../../hooks'
import { toast } from '../../helpers'
import { EDIT_FAQ, FAQ } from '../../graphql'
import { IntlContext } from '../../contexts'
import { HtmlEditor, LabeledInput, Loader, Wrapper, Form, TagEditor, SwitchBox, IntlText } from '../../components'
import { PageHeader, FaqOptions } from '../../views'

export function Faq(){

  const { id } = useParams()
  const { loading, data, error } = useQuery(FAQ, {variables: {id}})

  if (loading || error) return <Loader theme="main" />

  return(
    <Wrapper>
      <div id="faq" className="view">
        <Inner faq={data?.faqItem} />
      </div>
    </Wrapper>
  )
}

function Inner({faq}){

  const { id, language } = useParams()
  const { getTranslation } = useContext(IntlContext)
  const _language = language || "en"  
  const { form, updateField, updateMultilangField, getEditedData } = useValidatedForm({...faq})
  const [editFaq, { loading: editLoading }] = useMutation(EDIT_FAQ)
  const tags = []

  async function _editFaq(){
    const alert = toast.loading()

    try {
      await editFaq({variables: {id, data: getEditedData()}})
      alert.success(getTranslation({id: "edit-success"}))
    } catch (e) {
      alert.apolloError(e)
    }
  }

  async function changeTags(_tags){
    const alert = toast.loading()

    try {
      await editFaq({variables: {id, data: {tags: _tags}}})
      alert.success(getTranslation({id: "edit-success"}))
    } catch (e) {
      console.log("err", e);
      alert.apolloError(e)
    }
  }

  return(
    <div className="row padding">
      {editLoading && <Loader theme="main" overlay />}
      <PageHeader slug={form.slug} title={faq.title} onChange={({target}) => updateField({key: target.name, value: target.value})} url="help/" />
      <div className="grids">
        <div className="grid">
          <Form.Form>
            <Form.Field>
              <LabeledInput name="title" placeholder="Page title" value={form.title?.[_language]} onChange={({target}) => updateMultilangField({key: target.name, value: target.value, language: _language})} />
            </Form.Field>
            <Form.Field>
              <HtmlEditor id={`editor-${_language}`} name="content" placeholder="Page content" value={form.content?.[_language]} onChange={({text, target}) => updateMultilangField({key: target.name, value: text, language: _language})}/>
            </Form.Field>
            <Form.Field>
              <SwitchBox id="hidden" label={<IntlText id="hidden-desc" />} checked={form.hidden} onChange={({target}) => updateField({key: "hidden", value: target.checked})}>
                <IntlText id='hidden' />
              </SwitchBox>                   
            </Form.Field>
            <Form.Field>
              <SwitchBox id="whatsappButton" label={<IntlText group='faq' id="whatsappButton-desc" />} checked={form.whatsappButton} onChange={({target}) => updateField({key: "whatsappButton", value: target.checked})}>
                <IntlText group='faq' id='whatsappButton' />
              </SwitchBox>                  
            </Form.Field>            
            <Form.Field>
              <TagEditor values={faq.tags} options={tags} onChange={changeTags} />
            </Form.Field>            
          </Form.Form>
        </div>
        <div className="grid sidebar">
          <FaqOptions language={_language} save={_editFaq} loading={editLoading} url={`/faqs/${id}`} />
        </div>
      </div>
    </div>
  )
}
