import { useNavigate, useSearchParams } from 'react-router-dom'
import FontAwesome from 'react-fontawesome'

import { useIntl } from '../../../hooks'
import { getCommonAsset} from '../../../helpers'
import { Button, Tooltip } from '../../../components'

import './style.css'

export function LanguageTabs({errors = []}){
  const { getTranslation } = useIntl()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const language = searchParams.get('lang') || 'en'
  const en = errors.some(obj => obj && obj?.includes("en"));
  const es = errors.some(obj => obj && obj?.includes("es"));

  function changeLanguage(languageCode){
    navigate(`?lang=${languageCode}`)
  }

  return(
    <div className='language-selector-tabs'>
      <Button onClick={() => changeLanguage("en")} active={language === "en"} icon={<img style={{width: 30, height: "auto"}} src={getCommonAsset("en.png")} alt="English" />}>
        <span>{getTranslation({id: "en"})}</span>
          {en && <Tooltip text={getTranslation({id: "en-missing"})}><FontAwesome className='error' name="exclamation-triangle" /></Tooltip>}
      </Button>
      <Button onClick={() => changeLanguage("es")} active={language === "es"} icon={<img style={{width: 30, height: "auto"}} src={getCommonAsset("es.png")} alt="Spanish" />}>
        <span>{getTranslation({id: "es"})}</span>
        {es && <Tooltip text={getTranslation({id: "es-missing"})}><FontAwesome className='error' name="exclamation-triangle" /></Tooltip>}
      </Button>
    </div>
  )
}
