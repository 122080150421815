import React from 'react'
import GoogleMapReact from 'google-map-react'

import config from '../../config'

import './style.css'

function Pin({className, children}) {

  return (
    <div className={`map-pin ${className}`}>
      {children}
    </div>
  )
}

function Actions({className, children, ...rest}) {

  return (
    <div className={`map-actions ${className}`} {...rest}>
      {children}
    </div>
  )
}


function Map({center = config.defaultCenter, options, children, ...rest}) {

  const mapOptions = {
    streetViewControl: false,
    scrollwheel: false,
    scaleControl: false,
    mapTypeControl: false,
    gestureHandling: "greedy",
    panControl: false,
    zoomControl: true,
    rotateControl: false,
    fullscreenControl: false,
    ...options
    // styles: mapStyle
  }

  return(
    <GoogleMapReact bootstrapURLKeys={{key: process.env.REACT_APP_GMAPS_KEY}} center={center} zoom={16} options={mapOptions} disableDefaultUI {...rest}>
      {children}
    </GoogleMapReact>
  )
}

const exportedObject = { Map, Pin, Actions, defaultCenter: config.defaultCenter }

export default exportedObject
