import React from 'react'
import { useQuery } from '@apollo/client'

import { Filters, Loader, IntlValue } from '../../../components'
import { CATEGORIES_QUERY } from '../../../graphql'

export function CategorySelectFilter({vendor, category, onSelect, reset, ...rest}){

  const {loading, error, data} = useQuery(CATEGORIES_QUERY, {variables: {owner: vendor}})

  if (loading) return <Loader theme="main"/>
  if (error) return `Error! ${error.message}`

  const { categories } = data

  return(
    <Filters.Select value={category?.label} placeholder="Category" reset={reset} {...rest}>
      {categories.map((_category, key) =>
        <li key={key} onClick={() => onSelect(_category)}><span><IntlValue value={_category.name} /></span></li>
      )}
    </Filters.Select>
  )

}
