import React from 'react'

import { IntlText } from '../'

import './style.css'

function Search({className = "", children, ...rest}) {

  return(
    <div className={`placeholder ${className}`} {...rest}>
        {children ? 
            children
        :
            <div className='search-placeholder'><IntlText id="search-placeholder" /></div>
        }
    </div>
  )
}

const placeholder = { Search }

export default placeholder
