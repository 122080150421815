import React from 'react'
import FontAwesome from 'react-fontawesome'

import { Tooltip, IntlText } from '../'

import './Validation.css'

export default function Validation({children, errors, variables}){

  let _errors = errors && errors.length > 0 && errors.map((error, key) =>
    <div key={key} ><IntlText group="validation" id={error} variables={variables}>{error}</IntlText></div>
  )

  return(
    <div className="validation">
      {children}
      {errors && errors.length > 0 &&
        <div className="errors">
          <Tooltip text={_errors}>
            <FontAwesome className="error-tag" name="times" />
          </Tooltip>
        </div>
      }
    </div>
  )

}
