import { gql } from '@apollo/client'

import { INVOICE_FRAGMENT } from '../fragments'

const UPLOAD_INVOICE_MUTATION = gql`
  mutation uploadInvoice($file: Upload!, $invoiceRequest: ID!){
    uploadInvoice(file: $file, invoiceRequest: $invoiceRequest){
      ...INVOICE_FRAGMENT
    }
  }
  ${INVOICE_FRAGMENT}
`

export default UPLOAD_INVOICE_MUTATION
