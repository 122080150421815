import { gql }from '@apollo/client'

import { PRODUCT_ADDON_FRAGMENT } from './'

const PRODUCT_ADDONS_QUERY = gql`
  query productAnnexAddons($owner: String){
     productAnnexAddons(owner: $owner) {
       ...PRODUCT_ADDON_FRAGMENT
     }
  }
  ${PRODUCT_ADDON_FRAGMENT}
`

export default PRODUCT_ADDONS_QUERY
