import { gql } from '@apollo/client'

export const RIDER_MODIFICATION_FRAGMENT = gql`
  fragment RIDER_MODIFICATION_FRAGMENT on RiderModification {
    id
    rider{
      originalId
      name
    }
    purchase{
      originalId
      vendorName
      customerName
    }
    value
    reason
    note
    status
    voided
    createdAt
    updatedAt
  }
`
