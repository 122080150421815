import { useState } from 'react'

export function useStepNavigation({steps = []}) {
  const [selected, change] = useState(1)

  function _change(num){
    if (num < 1) return null
    if (num > steps.length) return null
    if (change) change(num)
  }

  function next(){
    _change(selected+1)
  }
  
  function prev(){
    _change(selected-1)
  }

  return {
    selected,
    steps,
    change: _change,
    next,
    prev
  }
}
