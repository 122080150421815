import { gql } from '@apollo/client'

import { PURCHASE_NOTE_FRAGMENT } from './'

export const CREATE_PPURCHASE_NOTE_MUTATION = gql`
  mutation createPurchaseNote($purchase: ID!, $note: String!){
    createPurchaseNote(purchase: $purchase, note: $note){
      ...PURCHASE_NOTE_FRAGMENT
    }
  }
  ${PURCHASE_NOTE_FRAGMENT}
`
