import React, { useContext } from 'react'
import { useMutation } from '@apollo/client'
import { toast } from 'react-toastify'

import { IntlContext } from '../../../contexts'
import {  EDIT_RIDER_MUTATION } from '../../../graphql'
import { Form, IntlText, EditBox, ContextMenu } from '../../../components'
import { StatusNotification } from '../../../views'

export function RiderContract({rider}){

  const { getTranslation } = useContext(IntlContext)
  const [editRider] = useMutation(EDIT_RIDER_MUTATION)

  async function save(data){
    try {
      await editRider({variables: {id: rider.id, data}})
      toast.success(getTranslation({id: "edit-success"}))
    } catch (e) {
      e.graphQLErrors.map(x => toast.error(x.message))
    }
  }

  async function changeStatusMutation(_data){
    try {
      await editRider({variables: {id: rider.id, data: _data}})
      toast.success(getTranslation({id: "edit-success"}))
    } catch (e) {
      e.graphQLErrors.map(x => toast.error(x.message))
    }      
  }

  function getStatus(){
    switch (rider.contractStatus) {
      case "INREVIEW":
        return "warning"
      case "ACCEPTED":
        return "success"
      case "REJECTED":
      case "EXPIRED":
        return "error"
      default:
        return "warning"
    }
  }

  function getIcon(){
    switch (rider.contractStatus) {
      case "INREVIEW":
        return "clock"
      case "ACCEPTED":
        return "check"
      case "REJECTED":
      case "EXPIRED":
        return "times"
      default:
        return "clock"
    }    
  }    
  
  return(
    <div id="rider-details">
      <StatusNotification title={<IntlText id={rider?.contractStatus} />} icon={getIcon()} type={getStatus()} actions={[
          <ContextMenu.Button key="accepted" className='valid' onClick={() => changeStatusMutation({contractStatus: "ACCEPTED"})} icon="check"><IntlText id="ACCEPTED" /></ContextMenu.Button>,
          <ContextMenu.Button key="inreview" className='warning' onClick={() => changeStatusMutation({contractStatus: "INREVIEW"})} icon="clock"><IntlText id="INREVIEW" /></ContextMenu.Button>,
          <ContextMenu.Button key="rejected" className='invalid' onClick={() => changeStatusMutation({contractStatus: "REJECTED"})} icon="ban"><IntlText id="REJECTED" /></ContextMenu.Button>
      ]}>
        <span><IntlText group="rider-contract" id={`${rider?.contractStatus}-info`} /></span>     
      </StatusNotification>       
      <Form.Form>
        <Form.Field>
          <EditBox.Text placeholder={getTranslation({group: "rider-contract", id: "contract"})} save={(value) => save({contract: value})}>{rider.contract}</EditBox.Text>
        </Form.Field>            
        <Form.Field>
          <EditBox.Date placeholder={getTranslation({group: "rider-contract", id: "contractSigned"})} save={(value) => save({contractSigned: value})}>{rider.contractSigned}</EditBox.Date>
        </Form.Field>          
        <Form.Field>
          <EditBox.Date placeholder={getTranslation({group: "rider-contract", id: "contractExpiry"})} save={(value) => save({contractExpiry: value})}>{rider.contractExpiry}</EditBox.Date>
        </Form.Field>          
      </Form.Form>
    </div>
  )

}