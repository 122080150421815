import React, { useState, useContext } from 'react'
import { useQuery } from '@apollo/client'

import { FAQS } from '../../graphql'
import { IntlContext } from '../../contexts'
import { useQueryBuilder } from '../../hooks'
import { Loader, Filter, Wrapper, Filters, Placeholder } from '../../components'
import { FaqList } from '../../views'

export function Faqs(){

  const topics = [
    {id: "CUSTOMER_GET_STARTED", label: "Customer get started"},
    {id: "CUSTOMER_PURCHASE", label: "Customer purchases"},
    {id: "CUSTOMER_REFUNDS", label: "Customer refunds"},
    {id: "VENDOR_ONBOARDING", label: "Vendor on boarding"},
    {id: "VENDOR_PURCHASE", label: "Vendor purchases"},
    {id: "VENDOR_REFUNDS", label: "Vendor refunds"},
    {id: "RIDER_REGISTRATION", label: "Rider registration"},
    {id: "RIDER_PURCHASES", label: "Rider purchases"},
    {id: "RIDER_PAYMENTS", label: "Rider payments"},
  ]

  const [filter, setFilter] = useState()
  const { getTranslatedValue } = useContext(IntlContext)
  const {getParams, clearParams, params, removeParam, buildQuery, isQuery, runQuery } = useQueryBuilder()
  const { loading, error, data, refetch } = useQuery(FAQS, {variables: buildQuery(), skip: !isQuery()})

  if (error) return `Error! ${error.message}`

  return(
    <Wrapper>
      <div id="faq" className="view">
        <div className="row padding">
          <Filters.Bar>
            <Filters.Input id="query" name="query" value={filter} onChange={({target}) => setFilter(target.value)} reset={() => setFilter("")} placeholder="Type here for searching" />
            <Filters.Select style={{marginLeft: "auto"}} value={params?.type?.label} placeholder="Faq type" reset={() => removeParam("type")}>
              {topics.map((topic, key) =>
                <li key={key} onClick={() => runQuery({instantParams: {topic: {key: "topic", label: topic?.label, value: topic?.id, skip: !topic?.id}}})}><span>{topic.label}</span></li>
              )}
            </Filters.Select>        
          </Filters.Bar>
          <Filters.Bar vertical>
            <Filters.ActiveParams activeParams={getParams()} removeParam={removeParam} clearParams={clearParams}/>
          </Filters.Bar>
            {isQuery() ?
              <div id="faq-list" className="relative">
                {(loading) && <Loader theme="main" overlay/>}
                <Filter data={data?.faqItems || []} search={[{key: "search", value: filter}]} filters={[{filter: (data) => getTranslatedValue(data.title)}, {filter: (data) => getTranslatedValue(data.content)}]} sort={(a, b) => new Date(a.createdAt) - new Date(b.createdAt)} >
                  {(data) =>
                    <FaqList data={data} refetch={refetch}/>
                  }
                </Filter>
              </div>
            :
              <Placeholder.Search />
            }          
        </div>
      </div>
    </Wrapper>
  )
}
