import { gql } from '@apollo/client'

import { SERVICE_FEE_PROFORMA_INVOICE_FRAGMENT } from './'

export const SERVICE_FEE_PROFORMA_INVOICES = gql`
  query serviceFeeProformaInvoices($vendor: ID, $date: [String], $offset: Int, $limit: Int, $status: SERVICE_FEE_PROFORMA_INVOICE_STATUS, $query : String){
     serviceFeeProformaInvoices(vendor: $vendor, date: $date, offset: $offset, limit: $limit, status: $status, query: $query) {
       nodes{
        ...SERVICE_FEE_PROFORMA_INVOICE_FRAGMENT         
       }
       pageInfo{
        limit
        totalNodes
        firstNode
        lastNode
        totalPages
        actualPage
        hasNextPage         
       }
     }
  }
  ${SERVICE_FEE_PROFORMA_INVOICE_FRAGMENT}
`
