import React, { useContext } from 'react'
import { toast } from 'react-toastify'

import { useValidatedForm } from '../../../hooks'
import { IntlContext } from '../../../contexts'
import { IntlText, Button, Form, Select, Validation, LabeledInput, LabeledTextarea } from '../../../components'

const validations = {
  value: [{id: "min0", validation: (val) => !!(val > 0)}],
  reason: [{id: "required", validation: (val) => !!(val && val.id)}],
}
const reasons = [
  {id: "GAS_SELFSERVE", label: <div><IntlText group='add-modification' id="GAS_SELFSERVE" /></div>},
  {id: "NETWORK_AMIGOSINLIMITE", label: <div><IntlText group='add-modification' id="NETWORK_AMIGOSINLIMITE" /></div>},
  {id: "INCOMPLETE_ORDER", label: <div><IntlText group='add-modification' id="INCOMPLETE_ORDER" /><div className="info"><IntlText group='add-modification' id="INCOMPLETE_ORDER-info" /></div></div>},
  {id: "ITEM_ADDED", label: <div><IntlText group='add-modification' id="ITEM_ADDED" /><div className="info"><IntlText group='add-modification' id="ITEM_ADDED-info" /></div></div>},
  {id: "CLIENT_CREDIT", label: <div><IntlText group='add-modification' id="CLIENT_CREDIT" /><div className="info"><IntlText group='add-modification' id="CLIENT_CREDIT-info" /></div></div>},
  {id: "CLIENT_DEBIT", label: <div><IntlText group='add-modification' id="CLIENT_DEBIT" /><div className="info"><IntlText group='add-modification' id="CLIENT_DEBIT-info" /></div></div>},
  {id: "CLIENT_DISCOUNT", label: <div><IntlText group='add-modification' id="CLIENT_DISCOUNT" /><div className="info"><IntlText group='add-modification' id="CLIENT_DISCOUNT-info" /></div></div>},
  {id: "FAILED_DELIVERY", label: <div><IntlText group='add-modification' id="FAILED_DELIVERY" /><div className="info"><IntlText group='add-modification' id="FAILED_DELIVERY-info" /></div></div>},
  {id: "OTHER_CREDIT", label: <div><IntlText group='add-modification' id="OTHER_CREDIT" /><div className="info"><IntlText group='add-modification' id="OTHER_CREDIT-info" /></div></div>},
  {id: "OTHER_DEBIT", label: <div><IntlText group='add-modification' id="OTHER_DEBIT" /><div className="info"><IntlText group='add-modification' id="OTHER_DEBIT-info" /></div></div>},
]

export function AddModification({submit, loading, cancel}) {

  const { getTranslation } = useContext(IntlContext)
  const { form: {reason, note, value}, updateField, errors, validate, resetForm } = useValidatedForm({}, validations)

  async function _submit(){

    let { valid } = validate()

    if (!valid) return toast.error(getTranslation({id: "form-error"}))

    try {
      await submit({reason, note, value})
      resetForm()
    } catch (e) {
      if (e.graphQLErrors && e.graphQLErrors.length > 0) e.graphQLErrors.map(x => toast.error(x.message))
      console.log("error", e)

    }
  }
  
  return(
    <Form.Form>
      <Form.Field>
        <Validation errors={errors.reason}>
          <Select placeholder={getTranslation({group: "add-modification", id: "reason"})} selected={reason} defaultValue={getTranslation({id: "please-select"})} options={reasons} onChange={(type) => updateField({key: "reason", value: type})} />
        </Validation>
      </Form.Field>
      <Form.Field>
        <Validation errors={errors.value}>
          <LabeledInput inputMode="numeric" pattern="[0-9]*" placeholder={getTranslation({group: "add-modification", id: "amount"})} value={value} onChange={(e) => updateField({key: "value", value: e.target.value})} />
        </Validation>
      </Form.Field>
      <Form.Field>
        <Validation errors={errors.note}>
          <LabeledTextarea placeholder={getTranslation({group: "add-modification", id: "note"})} onChange={(e) => updateField({key: "note", value: e.target.value})}>{note}</LabeledTextarea>
        </Validation>
      </Form.Field>
      <Form.Fieldset>
        <Form.Field fluid>
          <Button className="fluid" onClick={cancel}><IntlText id="cancel" /></Button>
        </Form.Field>
        <Form.Field fluid>
          <Button className="fluid" theme="main" loading={loading} onClick={_submit}><IntlText id="add" /></Button>
        </Form.Field>
      </Form.Fieldset>
    </Form.Form>
  )
}
