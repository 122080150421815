import React from 'react'
import moment from 'moment'
import { useMutation } from '@apollo/client'

import { EDIT_CUSTOMER_MUTATION, REMOVE_CUSTOMER_MUTATION } from '../../../graphql'
import { useConfirm, useIntl } from '../../../hooks'
import { toast } from '../../../helpers'
import { LabeledCheckbox, StatusDot, Loader, Table, ContextMenu, IntlText } from '../../../components'
import { CustomerVerificationBadge } from '../../../views'

export function CustomersList({loading, data, refetch}) {

  const { confirm } = useConfirm()
  const { getTranslation } = useIntl()
  const [deleteCustomer, { loading: deleteLoading }] = useMutation(REMOVE_CUSTOMER_MUTATION, {onCompleted: () => {
    refetch()
  }})
  const [editCustomer, { loading: editLoading }] = useMutation(EDIT_CUSTOMER_MUTATION, {onCompleted: () => {
    refetch()
  }})

  async function _delete(id){
    const alert = toast.loading(getTranslation({group: "confirm", id: "confirm-to-continue"}))
    try {
      await confirm({title: getTranslation({group: "confirm", id: "delete"}), desc: getTranslation({group: "confirm", id: "delete-info"})})
      alert.update(getTranslation({id: "loading"}))
      await deleteCustomer({variables: {id}})
      alert.success(getTranslation({id: "delete-success"}))
      refetch()
    } catch (error) {
      alert.apolloError(error)
    }
  }  

  async function _editCustomer(data){
    try {
      await editCustomer(data)
      refetch()
      toast.success(getTranslation({id: "edit-success"}))
    } catch (e) {
      e.graphQLErrors.map(x => toast.error(x.message))
    }
  }

  return(
    <div id="customers-list" className="relative">
      {(!!deleteLoading || editLoading || loading) && <div className="full-size-loader"><Loader theme="main"/></div>}
      {data?.length > 0 &&
        <Table.Table>
          <Table.Head>
            <Table.Tr>
              <Table.Th></Table.Th>
              <Table.Th className="text-left"><IntlText group="form" id="name" /></Table.Th>
              <Table.Th className="text-left"><IntlText group="form" id="phone" /></Table.Th>
              <Table.Th className="text-left"><IntlText group="form" id="email" /></Table.Th>
              <Table.Th className="text-left"><IntlText group="customer" id="createdAt" /></Table.Th>
              <Table.Th>Abusive</Table.Th>
              <Table.Th></Table.Th>
            </Table.Tr>
          </Table.Head>
          <tbody>
            {data && data.map((customer, key) =>
              <Table.Tr key={key} className={["customer", customer.abusive ? "disabled" : null].join(" ")}>
                <Table.Td className="status"><StatusDot active={!(customer.abusive || customer.banned)} /></Table.Td>
                <Table.Td className="text-left">{customer.name}</Table.Td>
                <Table.Td className="nowrap"><PhoneField customer={customer} /></Table.Td>
                <Table.Td className="text-left">{customer?.email}</Table.Td>
                <Table.Td className="text-left">{moment(customer?.createdAt).format("D. MMM YYYY")}</Table.Td>
                <Table.Td className="center"><LabeledCheckbox id={`abusive-${key}`} checked={customer.abusive} onChange={(e) => _editCustomer({variables: {id: customer.id, data: {abusive: e.target.checked}}})} rounded/></Table.Td>
                <Table.Td>
                  <ContextMenu.Menu id={customer?.id}>
                    <ContextMenu.Link to={`/customers/${customer.id}`} icon="external-link-alt">Open</ContextMenu.Link>
                    <ContextMenu.Button onClick={() => window.open(`whatsapp://send/?phone=${customer.phone.phoneCode}${customer.phone.phoneNumber}}`)} icon={<i className="fab fa-whatsapp" ></i>}>Send what's app</ContextMenu.Button>
                    <ContextMenu.Button theme="invalid" onClick={() => _delete(customer.id)} icon="trash">Delete customer</ContextMenu.Button>
                  </ContextMenu.Menu>
                </Table.Td>
              </Table.Tr>
            )}
          </tbody>
        </Table.Table>
      }
    </div>
  )
}

function PhoneField({customer}){
  return(
    <div style={{display: "flex", alignItems: "center", gap: 10}}>
      <span>{`${customer?.phone?.phoneCode} ${customer?.phone?.phoneNumber}`}</span>
      <CustomerVerificationBadge customer={customer} />
    </div>
  )
}