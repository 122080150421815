import { gql } from '@apollo/client'

export const VEHICLE_FRAGMENT = gql`
  fragment VEHICLE_FRAGMENT on RiderVehicle {
    id
    owner
    alias
    maker
    model
    type
    registrationCard    
    registrationCardExpiry
    registrationCardStatus
    vehiclePhoto
    vehiclePhotoStatus
    storagePhoto
    storagePhotoStatus
    storageCategory
    insurencePolicy
    insurencePolicyStatus
    insurencePolicyExpiry
    insurencePolicyPayment
    insurencePolicyPaymentStatus
    insurencePolicyPaymentExpiry
    status
    statusMessage
    maxCoveredDistance
    maxBasketValue
    onlySmallPackage
    createdAt
    updatedAt
  }
`
