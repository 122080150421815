import React from 'react'
import { Link } from 'react-router-dom'
import { useMutation } from '@apollo/client'
import FontAwesome from 'react-fontawesome'

import { EDIT_PROMOTION_MUTATION, DELETE_PROMOTION_MUTATION } from '../../../graphql'
import { useConfirm, useIntl } from '../../../hooks'
import { toast } from '../../../helpers'
import { LabeledCheckbox, ContextMenu, Loader, IntlText, IntlValue } from '../../../components'

import './style.css'

export function PromotionsList({data, refetch, loading}) {

  const { confirm } = useConfirm()
  const { getTranslation } = useIntl()
  const [remove] = useMutation(DELETE_PROMOTION_MUTATION)
  const [editPromotion, { loading: editLoading}] = useMutation(EDIT_PROMOTION_MUTATION)

  async function _editPromotion(vars){
    try {
      await editPromotion({variables: vars})
      refetch()
      toast.success(getTranslation({id: "edit-success"}))
    } catch (e) {
      e.graphQLErrors.map(x => toast.error(x.message))
    }
  }

  async function _delete(id){
    const alert = toast.loading(getTranslation({group: "confirm", id: "confirm-to-continue"}))
    try {
      await confirm({title: getTranslation({group: "confirm", id: "delete"}), desc: getTranslation({group: "confirm", id: "delete-info"})})
      alert.update(getTranslation({id: "loading"}))
      await remove({variables: {id}})
      alert.success(getTranslation({id: "delete-success"}))
      refetch()
    } catch (error) {
      alert.apolloError(error)
    }
  }    

  return(
    <div id="promotions-list" className="relative">
      {editLoading && <Loader theme="main" overlay/>}
      {data?.promotions?.length > 0 ? data.promotions.map((promotion, key) =>
        <div key={key} className="promotion">
          <LabeledCheckbox id={`${promotion.id}-active`} loading={loading} checked={promotion.active} rounded onChange={({target}) => _editPromotion({id: promotion.id, data: {active: !!target.checked}})}/>
          <div className="info">
            <span><IntlValue>{promotion.label}</IntlValue></span>
            <span className="desc"><IntlValue>{promotion.description}</IntlValue></span>
          </div>
          <ContextMenu.Menu id={promotion?.id} >
            <ContextMenu.Link to={`${promotion.id}`} icon="external-link-alt"><IntlText id="edit" /></ContextMenu.Link>
            <ContextMenu.Button theme="invalid" icon="trash" onClick={() => _delete(promotion.id)}><IntlText id="delete" /></ContextMenu.Button>
          </ContextMenu.Menu>
        </div>
      ) :
        <div className="empty-list">
          <div className='empty-placeholder'>
            <FontAwesome name="folder-open" />
            <span className="text"><IntlText id="empty-list" variables={{type: "promotions"}} /></span>
          </div>
          <Link to="/promotions/new" className='button main'><IntlText group="promotion-list" id="add-new-promotion" /></Link>
        </div>
      }
    </div>
  )
}
