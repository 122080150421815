import { useState } from 'react'
import { findPhoneNumbersInText } from 'libphonenumber-js'
import { useMutation } from '@apollo/client'

import { EDIT_PURCHASE_MUTATION } from '../../../graphql'
import { useIntl } from '../../../hooks'
import { toast } from '../../../helpers'
import { Button, PhoneNumberInput, Link } from '../../../components'

import './style.css'

export function PurchaseCustomer({purchase: {id, customer}, className}) {

  const [step, setStep] = useState(0)
  const [edited, toggleEdit] = useState(false)
  const { getTranslation } = useIntl()
  const [editPurchase, { loading }] = useMutation(EDIT_PURCHASE_MUTATION)
  const [phone, setPhone] = useState(customer.phone)
  const _phoneNumber = ((phone?.phoneCode + phone?.phoneNumber) || "").replace(/[\s+()]/g, "")

  async function editPhone({phone}){
    const _data = {
      phoneCode: phone.phoneCode,
      phoneNumber: phone.phoneNumber,
      countryCode: phone.countryCode
    }

    try {
      await editPurchase({variables: {id, data: {customerPhone: _data, updatePhoneOfCustomer: true}}})
      toggleEdit(false)
      toast.success(getTranslation({id: "save-success"}))
    } catch (e) {
      e.graphQLErrors.map(x => toast.error(x.message))
    }
  }

  function _setPhone(val){
    toggleEdit(true)
    setPhone(val)
  }

  async function autoFixNumber(){
    try {
      const _phone = findPhoneNumbersInText(phone?.phoneNumber, "US")
      if (!_phone?.[0]) return null

      let __phone = {countryCode: _phone[0].number.country, phoneCode: _phone[0].number.countryCallingCode, phoneNumber: _phone[0].number.nationalNumber}
      _setPhone(__phone)
    } catch (e) {
      console.log("error ", e);
    }
  }

  return(
    <div id="purchase-customer" className={className}>
      <div className="customer-name">
        <Link to={`/customers/${customer.originalId}`}>
          <span className="link">{customer.name}</span>
          <span className='email'>{customer.email}</span>
          <span className='email'>{customer.ip}</span>
        </Link>
      </div>
      <div className="customer-contact">
        <div className="phone-number">
          <PhoneNumberInput name="phone" placeholder="Phone" value={phone} defaultCountry="mx" featuredCountries={["us", "ca", "mx"]} onChange={_setPhone}/>
        </div>
        {edited &&
          <Button loading={loading} theme="main" onClick={() => editPhone({phone})}><i className="fa fa-save"></i>Save correct number</Button>
        }
        <Button id="whatsapp"><a href={`https://wa.me/${_phoneNumber}`} target="_blank" rel="noreferrer"><i className="fab fa-whatsapp"></i>Send whatsapp on What's App</a></Button>
        {step >= 1 &&
          <Button id="imessage"><a href={`iMessage://+${_phoneNumber}`}><i className="fab fa-apple imessage"></i>Send iMessage on phone number</a></Button>
        }
        {step >= 2 &&
          <Button id="imessage"><a href={`iMessage://${customer.email}`}><i className="fab fa-apple imessage"></i>Send iMessage on email</a></Button>
        }
        {step >= 3 &&
          <Button type="option" onClick={autoFixNumber}><i className="fa fa-wrench"></i>Try to autofix number</Button>
        }
        {step >= 4 ?
          <Button type="option" id="call"><i className="fa fa-phone"></i>Ask the delivery guy to make a normal voice call or try with the horn</Button>
        :
          <Button type="alt" onClick={() => setStep(step+1)}>Client not responding or the number is wrong</Button>
        }
      </div>
    </div>
  )
}

