import { gql } from '@apollo/client'

import { RIDER_BREAK_FRAGMENT } from './'

export const SEND_RIDER_TO_BREAK = gql`
  mutation sendRiderToBreak($rider: ID!, $type: RIDERBREAK_TYPE_INPUT!){
    sendRiderToBreak(rider: $rider, type: $type){
      ...RIDER_BREAK_FRAGMENT
    }
  }
  ${RIDER_BREAK_FRAGMENT}
`
