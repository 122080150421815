import { gql } from '@apollo/client'

import { VENDOR_FRAGMENT } from './'

export const PAGINATED_VENDORS = gql`
  query paginatedVendors($query: String, $contract: CONTRACT_TYPE, $type: VENDOR_TYPE, $tags: [String], $serviceFee: Float, $offset: Int, $limit: Int){
    paginatedVendors(query: $query, contract: $contract, type: $type, tags: $tags, serviceFee: $serviceFee, offset: $offset, limit: $limit){
      nodes{
        ...VENDOR_FRAGMENT
      }
      pageInfo{
        limit
        totalNodes
        firstNode
        lastNode
        totalPages
        actualPage
        hasNextPage                 
      }
    }
  }
  ${VENDOR_FRAGMENT}
`
