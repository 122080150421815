import moment from 'moment';

export function getEllapsed(start, end = moment(), type = "minutes"){
  let ellapsed = moment.duration(moment(start).diff(end))

    if (type === "minutes")
      ellapsed = Math.floor(-ellapsed.asMinutes())

    if (type === "hours")
      ellapsed = ellapsed.asHours()

  return ellapsed
}

export function formatMinutes(minutes){
  if (!minutes)
   return undefined

  return `${minutes}'`
}
