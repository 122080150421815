import { gql }from '@apollo/client'

import { VENDOR_USER_FRAGMENT } from './'

export const VENDOR_USER = gql`
  query vendorUser($id: ID!){
    vendorUser(id: $id){
      ...VENDOR_USER_FRAGMENT
    }
  }
  ${VENDOR_USER_FRAGMENT}
`
