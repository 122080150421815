import { gql }from '@apollo/client'

import { VENDOR_USER_FRAGMENT } from './'

export const VENDOR_USERS_QUERY = gql`
  query vendorUsers($vendor: ID){
    vendorUsers(vendor: $vendor){
      ...VENDOR_USER_FRAGMENT
    }
  }
  ${VENDOR_USER_FRAGMENT}
`
