import React from 'react'
import { useQuery } from '@apollo/client'

import { AnnouncementList } from '../../views'
import { Loader, Wrapper } from '../../components'
import { ANNOUNCEMENTS_QUERY } from '../../graphql'

export function Announcements(){

  const { loading, error, data } = useQuery(ANNOUNCEMENTS_QUERY)

  if (error) return `Error! ${error.message}`

  return(
    <Wrapper>
      <div id="announcements" className="view">
        <div className="row padding">
          <div className="relative">
            {(loading) && <Loader theme="main" overlay/>}
            <AnnouncementList data={data?.announcements}/>
          </div>
        </div>
      </div>
    </Wrapper>
  )
}
