import React, { useRef } from 'react'
import FontAwesome from 'react-fontawesome'

import { useDetectOutsideClick } from '../../hooks'

import './style.css'

export function Accordion({id, title, children, style, initialState}){

    const wrapper = useRef(null)
    const menu = useRef(null)
    const [opened, toggle] = useDetectOutsideClick({wrapper, menu, initialState: initialState || false, disableInClickClose: true})

    return(
        <div id={id} className={`accordion ${opened ? "opened" : ""}`} ref={wrapper} style={style}>
            <button className='accordion-title' onClick={() => toggle(!opened)}>{title}<FontAwesome name="chevron-right" /></button>
            <div className="collapse" ref={menu} style={opened ? {height: menu?.current?.scrollHeight || "auto"} : null}> 
                <div>
                    {children}
                </div>
            </div>
        </div>
    )
}