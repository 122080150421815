import React, { useContext } from 'react'
import Tippy from '@tippyjs/react'

import { Link, ContextMenu, IntlText } from '../../../../components'
import { CustomerTags } from '../../../../views'
import { ModalContext } from '../../../../contexts'

export function CustomerField({purchase}) {

  const { modals } = useContext(ModalContext)
  const { customer } = purchase

  if (!customer.phone) console.log("Missing phone number", customer)

  let phone = customer.phone?.phoneCode + customer.phone?.phoneNumber
  if (phone) phone = phone.replace(/[\s+()]/g, "")

  return(
    <div className="customer-field">
      <Link to={`/customers/${customer.originalId}`}><span className="link">{customer.name}</span></Link>
      {purchase.price.total > 3000 &&
        <Tippy content={<IntlText group='customer-field' id="high-value" />}>
          <span className="highvalue"><i className="fa fa-search-dollar"></i></span>
        </Tippy>
      }
      <CustomerTags customer={customer} />
      <ContextMenu.Menu id={purchase?.id}>
        <ContextMenu.Button onClick={() => window.open(`whatsapp://send/?phone=${phone}`)} icon={<i className="fab fa-whatsapp" ></i>}><IntlText group="customer-field" id="send-whatsapp" /></ContextMenu.Button>
        <ContextMenu.Button onClick={() => modals.purchaseCustomer?.show(purchase)} icon="phone"><IntlText group="customer-field" id="contact-customer" /></ContextMenu.Button>
        <ContextMenu.Button onClick={() => modals.purchaseAddress?.show(purchase)} icon="map"><IntlText group="customer-field" id="show-location" /></ContextMenu.Button>
        <ContextMenu.Button onClick={() => modals.customerBalance?.show(purchase)} icon="money-bill"><IntlText group="customer-field" id="show-balance" /></ContextMenu.Button>
        <ContextMenu.Link to={`/customers/${customer.originalId}/orders`} icon="shopping-cart"><IntlText group="customer-field" id="show-history" /></ContextMenu.Link>
      </ContextMenu.Menu>
    </div>
  )
}
