import React from 'react'
import FontAwesome from 'react-fontawesome'

import { Tooltip } from '../../../components'

import './style.css'

export function ProductTags({product}) {

  return(
    <div className="product-tags">
      {!!product.discount &&
        <div className="product-tag">
          <Tooltip text="On sale"><i className="fas fa-badge-percent" aria-hidden="true"></i></Tooltip>
        </div>
      }
      {(!!product.media?.cover || !!product.media?.thumbnail) &&
        <div className="product-tag">
          <Tooltip text="Image uploaded"><FontAwesome className="image" name="image" /></Tooltip>
        </div>
      }
      {!!product.tags.includes("vegetarian") &&
        <div className="product-tag">
          <Tooltip text="Vegetarian"><FontAwesome className="vegetarian" name="leaf" /></Tooltip>
        </div>
      }
      {!!product.tags.includes("new") &&
        <div className="product-tag">
          <Tooltip text="New"><FontAwesome className="new" name="star" /></Tooltip>
        </div>
      }
      {!!product.tags.includes("vegan") &&
        <div className="product-tag">
          <Tooltip text="Vegan"><span className="vegan">V</span></Tooltip>
        </div>
      }
      {!!product.tags.includes("houseSpeciality") &&
        <div className="product-tag">
          <Tooltip text="House speciality"><i className="fas fa-hat-chef houseSpeciality"></i></Tooltip>
        </div>
      }
      {!!product.tags.includes("glutenFree") &&
        <div className="product-tag">
          <Tooltip text="Gluten free"><i className="fas fa-wheat glutenFree"></i></Tooltip>
        </div>
      }
      {!!product.tags.includes("spicy") &&
        <div className="product-tag">
          <Tooltip text="Spicy"><i className="fas fa-pepper-hot spicy"></i></Tooltip>
        </div>
      }
    </div>
  )
}
