import React from 'react'

import { Title, Checkbox, IntlValue, IntlText, Button } from '../../../components'
import { formatPrice } from '../../../helpers'

import './style.css'

export function PurchaseProductAddon({selected, addon: {addon, options}, addOption, removeOption}) {

  let option = options.map((option, key) => {
    const _selected = selected?.selectedOptions?.find(_option => _option.id === option.id || _option.originalId === option.id) 

    if (option.quantifiable) return(
      <PurchaseProductAddonOptionQuantifiable key={key} selected={_selected} addon={addon} option={option} addOption={addOption} removeOption={removeOption} />
    )
      
    return (
      <PurchaseProductAddonOption key={key} selected={_selected} addon={addon} option={option} addOption={addOption} removeOption={removeOption} />
    )

  })

  return(
    <div id={addon.id} className="addon-selector">
      <div className="addon-title">
        <Title tag="h3"><IntlValue value={addon.label} /></Title>
        {(addon.required || (addon.minSelect > 0)) && <span className="required"><IntlText id="required" /></span>}
      </div>
      <div className="instructions">
        <IntlValue value={addon.instruction} />
        {addon.minSelect > 0 && " min: " + addon.minSelect}
        {addon.maxSelect > 0 && " max: " + addon.maxSelect}
      </div>
      <div className="addon-options">
        {option}
      </div>
    </div>
  )
}

function PurchaseProductAddonOption({selected, addon, option, addOption, removeOption}){
  const optionName = option.price > 0 ? <div><IntlValue value={option.name} /><span className="divider">-</span><span className="price">{formatPrice(option.price)}</span></div> : <IntlValue value={option.name} />

  if (selected) return(
    <div className="addon-option">
      <Checkbox id={addon.id + "-" + option.id} label={optionName} checked={true} onChange={() => removeOption(addon, option)}/>
    </div>
  )

  return(
    <div className="addon-option">
      <Checkbox id={addon.id + "-" + option.id} label={optionName} checked={false} disabled={option.disabled} onChange={() => addOption(addon, option)}/>
    </div>
  )
}

function PurchaseProductAddonOptionQuantifiable({selected, addon, option, addOption, removeOption}){
  const optionName = option.price > 0 ? <div><IntlValue value={option.name} /><span className="divider">-</span><span className="price">{formatPrice(option.price)}</span></div> : <IntlValue value={option.name} />
  const quantity = selected?.quantity || 0

  function increase(){
    const _quantity = quantity+1

    addOption(addon, option, _quantity)
  }
  
  function decrease(){
    const _quantity = quantity-1

    removeOption(addon, option, _quantity)
  }

  return(
    <div className="addon-option quantifiable">
      <div className='quantity-selector'>
        <Button icon="minus" onClick={decrease} />
        <span className='value'>{quantity}</span>
        <Button icon="plus" onClick={increase}/>
      </div>
      {optionName}
    </div>
  )
}
