import { gql } from '@apollo/client'

import { RIDER_PAYOUT_FRAGMENT } from './'

export const EDIT_RIDER_PAYOUT = gql`
  mutation editRiderPayout($id: ID, $status:RIDER_PAYOUT_STATUS, $statusMessage: String){
    editRiderPayout(id: $id, status: $status, statusMessage: $statusMessage){
      ...RIDER_PAYOUT_FRAGMENT
    }
  }
  ${RIDER_PAYOUT_FRAGMENT}
`
