import React from 'react'
import { useMutation, useQuery } from '@apollo/client'

import { LabeledCheckbox, LabeledInput, Loader, Form, HtmlEditor, EditBox, IntlText } from '../../../components'
import { SETTINGS_QUERY, EDIT_SETTING_MUTATION } from '../../../graphql'
import { extractSettings } from '../../../helpers'

export function InfoModalSettings(){

  const [editSetting, { loading: editLoading }] = useMutation(EDIT_SETTING_MUTATION)
  const { loading, data, error } = useQuery(SETTINGS_QUERY, {variables: {keys: ["infomodal", "infomodal_id", "infomodal_title", "infomodal_title_es", "infomodal_text", "infomodal_text_es"]}})

  async function _editSetting(key, value){
    try {
      editSetting({variables: {key, data: {value}}})
    } catch (e) {
      console.log("Error: ", e);
    }
  }
  
  async function save(key, value){
    try {
      editSetting({variables: {key, data: {value}}})
    } catch (e) {
      console.log("Error: ", e);
    }
  }

  if (loading) return <Loader theme="main"/>
  if (error) return `Error! ${error.message}`

  let settings = extractSettings(data.settings)

  return(
    <div id="info-modal">
      <Form.Form>
        <Form.Field>
          <LabeledCheckbox loading={editLoading} id="infomodal" rounded checked={settings["infomodal"]?.value} onChange={(e) => _editSetting(settings["infomodal"]?.key, e.target.checked.toString())}>Enable / disable</LabeledCheckbox>
        </Form.Field>
        <Form.Field>
          <EditBox.Text placeholder={<div><IntlText group="infomodal" id="id" /></div>} save={(value) => save("infomodal_id", value)}>{settings["infomodal_id"]?.value}</EditBox.Text>
        </Form.Field>
        <Form.Legend>English</Form.Legend>
        <Form.Field>
          <LabeledInput id="infomodal_title" placeholder={settings["infomodal_title"]?.description} value={settings["infomodal_title"]?.value} onChange={(e) => _editSetting(settings["infomodal_title"]?.key, e.target.value)}/>
        </Form.Field>
        <Form.Field>
          <HtmlEditor id="info-modal-text" name="infomodal_text" placeholder="Type the content here" value={settings["infomodal_text"]?.value} save={(text) => save(settings["infomodal_text"]?.key, text)}/>
        </Form.Field>
        <Form.Legend>Spanish</Form.Legend>
        <Form.Field>
          <LabeledInput id="infomodal_title_es" placeholder={settings["infomodal_title_es"]?.description} value={settings["infomodal_title_es"]?.value} onChange={(e) => _editSetting(settings["infomodal_title_es"]?.key, e.target.value)}/>
        </Form.Field>
        <Form.Field>
          <HtmlEditor id="info-modal-text-es" name="infomodal_text_es" placeholder="Type the spanish content here" value={settings["infomodal_text_es"]?.value} save={(text) => save(settings["infomodal_text_es"]?.key, text)}/>
        </Form.Field>
      </Form.Form>
    </div>
  )

}
