import { gql }from '@apollo/client'

import { ADDON_FRAGMENT } from './'

const ADDON_QUERY = gql`
  query productAddon($slug: String, $id: ID){
     productAddon(slug: $slug, id: $id) {
       ...ADDON_FRAGMENT
     }
  }
  ${ADDON_FRAGMENT}
`

export default ADDON_QUERY
