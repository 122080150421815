import { gql } from '@apollo/client'

export const PURCHASE_PRODUCT_FRAGMENT = gql`
  fragment PURCHASE_PRODUCT_FRAGMENT on PurchaseProduct {
    product{
      name{
        en
        es
      }
      rank
      price
      originalId
    }
    category{
      name{
        en
        es
      }
      originalId
    }
    addons{
      addon{
        originalId
        name{
          es
          en
        }
      }
      selectedOptions{
        originalId
        name{
          en
          es
        }
        price
        quantity
      }
    }
    instructions
    totalPrice
    alternative
    quantity
    totalPricePerProduct
  }
`
