import { gql } from '@apollo/client'

import { CATEGORY_FRAGMENT } from './'

const CATEGORY_QUERY = gql`
  query category($id: ID, $slug: String){
     category(id: $id, slug: $slug) {
       ...CATEGORY_FRAGMENT
     }
  }
  ${CATEGORY_FRAGMENT}
`

export default CATEGORY_QUERY
