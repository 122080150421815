import { gql } from '@apollo/client'

import { PURCHASE_FRAGMENT } from './'

export const REMOVE_RIDER_MUTATION = gql`
  mutation removeRider($id: ID!){
    removeRider(id: $id){
      ...PURCHASE_FRAGMENT
    }
  }
  ${PURCHASE_FRAGMENT}
`
