import React, { useState } from 'react'
import { useQuery, useMutation } from '@apollo/client'
import moment from 'moment'
import FontAwesome from 'react-fontawesome'

import { OrdersList } from '../../../views'
import { Loader, Checkbox, Title, Actions, Button, IntlText } from '../../../components'
import { PURCHASES_PAGINATED_QUERY, GROUP_PURCHASES_MUTATION } from '../../../graphql'

import './style.css'

export function DispatcherSearch({buildQuery, isQuery}) {

  function groupAdd(_purchase){
    let array = group.concat(_purchase)
    setGroup(array)
  }

  function groupRemove(id){
    let array = group.filter((item) => item.id !== id)
    setGroup(array)
  }

  function isInGroup(id){
    return !!group.find((element) => element.id === id)
  }

  function clearGroup(){
    setGroup([])
  }

  async function _groupPurchase(){
    try {
      const _purchases = group?.map((_item) => _item.id)
      await groupPurchase({variables: {purchases: _purchases}})
      clearGroup()
    } catch (e) {
      console.log("Error: ", e)
    }
  }

  const [group, setGroup] = useState([])
  const [groupPurchase, {loader: groupingLoading}] = useMutation(GROUP_PURCHASES_MUTATION)
  const { data, error, loading, networkStatus } = useQuery(PURCHASES_PAGINATED_QUERY, {skip: !isQuery(), variables: {date: moment().format("YYYY-MM-DD"), ...buildQuery()}, fetchPolicy: "network-only"})

  if (loading && (networkStatus === 1 || networkStatus === 2)) return <Loader theme="main"/>
  if (error) return `Error! ${error.message}`

  const ordered = Array.from(data?.purchasesPaginated?.nodes)

  if (ordered?.length > 0) ordered.sort((a,b) => new Date(b.createdAt) - new Date(a.createdAt))

  return(
    <div id="dispatcher-search">
      {loading && <Loader theme="main" overlay />}
      {group?.length > 0 &&
        <div id="group-box">
          <div>
            <Title tag="h4">Selected orders</Title>
            <table className="items">
              <tbody>
                {group.map((groupItem, key) =>
                  <tr className="group-item" key={key}>
                    <td><Checkbox id={groupItem.id} checked={isInGroup(groupItem.id)} onChange={!!isInGroup(groupItem.id) ? () => groupRemove(groupItem.id) : () => groupAdd(groupItem.id)}/></td>
                    <td>{groupItem?.vendor?.name}</td>
                    <td>{groupItem?.customer?.name}</td>
                    <td>{groupItem?.address?.zone}</td>
                  </tr>
                )}
              </tbody>
            </table>
            <Actions>
              <Button type="alt" onClick={clearGroup}>Clear</Button>
              <Button loading={groupingLoading} theme="main" onClick={_groupPurchase}><FontAwesome name="link" />Create group</Button>
            </Actions>
          </div>
        </div>
      }
      {isQuery() ?
        <OrdersList data={ordered} status="processed" group={group} selectItem={groupAdd} unselectItem={groupRemove} selected={isInGroup} clearGroup={clearGroup} dateFormat="HH:mm" visibleFields={{feedback: false}} />
      :
        <div className='search-placeholder' style={{paddingTop: 0}}><IntlText id="search-placeholder" /></div>
      }      
    </div>
  )
}
