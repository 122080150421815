import { gql } from '@apollo/client'

import { ADMIN_FRAGMENT } from '../admin'

export const CREATE_ADMIN = gql`
  mutation createAdmin($data: AdminInput){
     createAdmin(data: $data){
       ...ADMIN_FRAGMENT
     }
  }
  ${ADMIN_FRAGMENT}
`
