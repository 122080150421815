import moment from 'moment'

function getLabel({start, period}){
    switch (period) {
        case "MONTH":
            return moment(start).format("MMMM")
        case "WEEK":
            return `Week ${moment(start).format("W")}`
        case "DAY":
            return moment(start).format("MMM DD")
        default:
            return null
    }
}

export const vendorStat = { getLabel }
