import { gql } from '@apollo/client'

import { CUSTOMER_FRAGMENT } from './'

export const CUSTOMER_QUERY = gql`
  query customer($slug:String, $id:ID){
    customer(slug: $slug, id:$id){
      ...CUSTOMER_FRAGMENT
    }
  }
  ${CUSTOMER_FRAGMENT}
`
