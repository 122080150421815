import React from 'react'
import FontAwesome from 'react-fontawesome'
import { DndContext, closestCenter, KeyboardSensor, PointerSensor, useSensor, useSensors } from '@dnd-kit/core'
import { arrayMove, SortableContext, sortableKeyboardCoordinates, verticalListSortingStrategy} from '@dnd-kit/sortable'
import { restrictToVerticalAxis } from "@dnd-kit/modifiers"
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'

import { useIntl } from '../../hooks'
import { Table } from '../'

import './style.css'

function SortableTable({data, onSort, renderItem, renderHeader}){

  const { getTranslation } = useIntl()

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  )

  function _onSort(event){
    const {active, over} = event
    
    if (active.id === over.id) throw new Error(getTranslation({id: "nothing-to-order"}))
  
    const oldIndex = data.findIndex(_item => _item.id === active.id)
    const newIndex = data.findIndex(_item => _item.id === over.id)
    const array = arrayMove(data, oldIndex, newIndex)
    const orderedArray = array.map((item, key) => ({id: item.id, rank: key}))

    onSort(orderedArray)
  }


  
  return(
    <DndContext 
      sensors={sensors} 
      collisionDetection={closestCenter} 
      onDragEnd={_onSort}
      modifiers={[restrictToVerticalAxis]}
    >
      <Table.Table>
        <Table.Head>
          <Table.Tr>
            {renderHeader()}
          </Table.Tr>
        </Table.Head>                
        <Table.Body>
          <SortableContext items={data} strategy={verticalListSortingStrategy}>
            {data.map((item, key) => <TableItem key={key} item={item} renderItem={renderItem} />)}
          </SortableContext>
        </Table.Body>
      </Table.Table>
    </DndContext>   
  )
}

function TableItem({item, renderItem}){
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({id: item.id})

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  }

  return(
    <Table.Tr ref={setNodeRef} style={style}>
      <Table.Td className="drag-handlar"><span className="drag-handler" {...attributes} {...listeners}><FontAwesome name="grip-vertical" /></span></Table.Td>
      {renderItem(item)}
    </Table.Tr>
  )  
}

const sortable = { Table: SortableTable }

export default sortable
