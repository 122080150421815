export function validateEmail(str) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(String(str).toLowerCase())
}

export function validateEmailList(str) {
    const re = /^([\w+-.%]+@[\w-.]+\.[A-Za-z]{2,4}\s*,?\s*)+$/
    return re.test(String(str).toLowerCase())
}

export function validateRfc(str) {
    const re = /^[a-zA-Z]{3,4}(\d{6})((\D|\d){2,3})?$/
    return re.test(String(str).toLowerCase())
}

export function validateName(str) {
    const re = /^(?!.*\b(?:S\.? de R\.?L\.?|S\.?C\.?|S\.?A\.?S\.?|S\.?A\.?|S\.?A\.?P\.?I\.?)\b)[A-Za-zÀ-ÖØ-öø-ÿ0-9\s&/.-]{1,254}$/i
    return re.test(String(str).toLowerCase())
}
