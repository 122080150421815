import React from 'react'
import FontAwesome from 'react-fontawesome'
import { useMutation } from '@apollo/client'

import { IntlText, Title, LabeledCheckbox } from '../../components'
import { EDIT_FEEDBACK_MUTATION } from '../../graphql'

import './OrderFeedback.css'

export default function OrderFeedback({purchase: { feedback }}) {

  const [editFeedback, { loading }] = useMutation(EDIT_FEEDBACK_MUTATION)

  async function _editFeedback(data){
    try {
      await editFeedback({variables: data})
    } catch (e) {
      console.log("Error: ", e);
    }
  }

  if (!feedback) return (
    <div id="order-feedback">
      <div>
        <div className="product-feedback">
          <span className="no-feedback"><IntlText group="order-feedback" id="no-feedback">There are no feedbacks for this order</IntlText></span>
        </div>
      </div>
    </div>
  )

  return(
    <div id="order-feedback" className="tab">
      <div>
        <div className="product-feedback">
          <Title tag="h3">Delivery feedback</Title>
          <div className="faces">
            <div className={`face ${feedback.deliveryQuantitative === 1 ? "active" : ""}`}>
              <FontAwesome name="frown" />
            </div>
            <div className={`face ${feedback.deliveryQuantitative === 2 ? "active" : ""}`}>
              <FontAwesome name="meh" />
            </div>
            <div className={`face ${feedback.deliveryQuantitative === 3 ? "active" : ""}`}>
              <FontAwesome name="smile" />
            </div>
          </div>
          {feedback.deliveryQualitative &&
            <span>{feedback.deliveryQualitative}</span>
          }
        </div>
        <div className="product-feedback">
          <Title tag="h3">Products feedback</Title>
          <div className="faces">
            <div className={`face ${feedback.productsQuantitative === 1 ? "active" : ""}`}>
              <FontAwesome name="frown" />
            </div>
            <div className={`face ${feedback.productsQuantitative === 2 ? "active" : ""}`}>
              <FontAwesome name="meh" />
            </div>
            <div className={`face ${feedback.productsQuantitative === 3 ? "active" : ""}`}>
              <FontAwesome name="smile" />
            </div>
          </div>
          {feedback.productsQualitative &&
            <span>{feedback.productsQualitative}</span>
          }
          <div className="void-box">
            <LabeledCheckbox loading={loading} id="feedback-voided" checked={feedback.productsVoided} rounded onChange={({target}) => _editFeedback({id: feedback.id, data: {productsVoided: target.checked}})}>Void feedback</LabeledCheckbox>
          </div>
        </div>
      </div>
    </div>
  )
  
}
