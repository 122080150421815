import { gql } from '@apollo/client'

import { PURCHASE_FEEDBACK_FRAGMENT } from './'

export const PURCHASE_FEEDBACKS_PAGINATED = gql`
  query purchaseFeedbacksPaginated($date: [String], $vendor: ID, $rider: ID, $offset: Int, $limit: Int){
    purchaseFeedbacksPaginated(date: $date, vendor: $vendor, rider: $rider, offset: $offset, limit: $limit){
        nodes{
            ...PURCHASE_FEEDBACK_FRAGMENT
        }
        pageInfo{
            limit
            totalNodes
            firstNode
            lastNode
            totalPages
            actualPage
            hasNextPage                 
        }        
     }
  }
  ${PURCHASE_FEEDBACK_FRAGMENT}
`
