import React, { useContext } from 'react'
import { useQuery } from '@apollo/client'
import { useLocation } from 'react-router-dom'
import moment from 'moment'

import { RIDER_PAYOUTS } from '../../graphql'
import { IntlContext } from '../../contexts'
import { useQueryBuilder } from '../../hooks'
import { Wrapper, DatePicker, Filters, Pagination, QueryResults, Placeholder } from '../../components'
import { RiderPayouts as RiderPayoutsView, SelectRider } from '../../views'

export function RidersPayouts() {

  const { getTranslation } = useContext(IntlContext)
  const location = useLocation()
  const locationSearch = new URLSearchParams(location.search)
  const { params, addParam, removeParam, isQuery, buildQuery, runQuery, updateQuery, setPage, page, clearParams } = useQueryBuilder({
    data: {
      date: {key: "date", label: `${locationSearch.getAll("date")[0]} - ${locationSearch.getAll("date")[1]}`, value: locationSearch.getAll("date")?.length === 2 ? [locationSearch.getAll("date")[0], locationSearch.getAll("date")[1]] : undefined, skip: !locationSearch.get("date")},
      rider: {key: "rider", label: `${locationSearch.get("rider")}`, value: locationSearch.get("rider")},
    },
    page: locationSearch.get("page"),
    limit: 50
  }, [{id: "date", validation: (obj) => !!obj?.value, error: "You have to select date"}])  
  const { data, loading, error, refetch } = useQuery(RIDER_PAYOUTS, {variables: buildQuery(), skip: !isQuery()})

  function setDate({selection: {startDate, endDate}}){
    const _date = [
      moment(startDate).format("YYYY-MM-DD"),
      moment(endDate).add(1, 'd').format("YYYY-MM-DD")
    ]
    addParam({date: {key: "date", value: [_date[0], _date[1]], label: `${_date[0]} - ${_date[1]}`, skip: !(_date[0] && _date[1])}})
  }  

  return(
    <Wrapper loading={loading || error}>
      <div id="rider-payouts" className="container">
        <div className="row padding">
          <Filters.Bar style={{justifyContent: "flex-end"}}>
            <SelectRider rider={params?.rider} reset={() => removeParam("rider")} onSelect={(_rider) => updateQuery({rider: {value: _rider?.id, id: "rider", label: _rider?.name, skip: !_rider?.id}})}/>            
            <Filters.Button onClick={() => updateQuery({showEmpty: {value: !params?.showEmpty?.value}})} reset={() => removeParam("showEmpty")} active={!!params?.showEmpty?.value}>Show empty</Filters.Button>
            <Filters.Dropdown placeholder={getTranslation({group: "history-filter", id: "date"})} value={params?.date?.label} disableInClickClose right run={runQuery} reset={() => removeParam("date")}>
              <DatePicker.Range startDate={params?.date?.value[0] ? new Date(`${params.date.value[0]}T00:00`) : new Date()} endDate={params?.date?.value[1] ? new Date(`${params.date.value[1]}T00:00`) : new Date()} onChange={setDate} />
            </Filters.Dropdown>
          </Filters.Bar>          
          <QueryResults pageInfo={data?.riderPayouts?.pageInfo} reset={clearParams} />
          {isQuery() ?
            <RiderPayoutsView riderPayouts={data?.riderPayouts} page={page} setPage={setPage} refetch={refetch} />            
          :
            <Placeholder.Search />
          }
          <Pagination page={page} setPage={setPage} pageInfo={data?.riderPayouts?.pageInfo}/>              
        </div>
      </div>
    </Wrapper>
  )
}
