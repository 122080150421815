import React from 'react'

import { Button } from '../../components'

import './style.css'

export function ColorSchemeSelector(){

    function changeTheme(scheme){
        localStorage.setItem('app-theme', scheme)
        window.location.reload()
      }

    return(
        <div id='color-scheme-selector'>
            {localStorage.getItem("app-theme") === "dark" ? 
                <Button onClick={() => changeTheme("light")} icon="sun" />
            :
                <Button onClick={() => changeTheme("dark")} icon="moon" />
            }
        </div>
    )

}