import { useState } from 'react'

export default function useForm(initialData) {
  const [form, setValues] = useState(initialData)
  const [edited, toggleEdited] = useState(false)

  function updateField(field){
    setValues({
      ...form,
      ...field
    })
    toggleEdited(true)
  }

  function resetForm(){
    setValues({
      ...initialData
    })
    toggleEdited(false)
  }

  return {
    form,
    updateField,
    resetForm,
    setValues,
    edited
  }
}
