import { gql } from '@apollo/client'

import { RIDER_PAYOUT_FRAGMENT } from './'

export const RIDER_PAYOUT = gql`
  query riderPayout($id: ID!){
    riderPayout(id: $id){
      ...RIDER_PAYOUT_FRAGMENT        
    }
  }
  ${RIDER_PAYOUT_FRAGMENT}
`
