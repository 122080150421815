import React from 'react'
import { useMutation } from '@apollo/client'
import { toast } from 'react-toastify'

import { EDIT_VENDOR_MUTATION } from '../../../graphql'
import { TagEditor } from '../../../components'

const tags = [
  {id: "confirmContainers", value: {en: "Eco settings configration", es: "Configuración de ajustes ecológicos"}},
  {id: "disabledEcoSettings", value: {en: "Eco settings disabled", es: "Configuración ecológica deshabilitada"}},
  {id: "bio", value: {en: "Eco friendly packaging", es: "Embalaje ecológico"}},
  {id: "new", value: {en: "New parter", es: "Nuevo socio"}},
  {id: "firstLogin", value: {en: "First login wizard", es: "Assistente de inicio"}},
  {id: "promotion", value: {en: "Promotion", es: "Promoción"}},
  {id: "promotionSlider", value: {en: "Promotion slider", es: "Promoción slider"}},
  {id: "freeDelivery", value: {en: "Free delivery", es: "Entrega gratis"}},
  {id: "freeDeliveryOptions", value: {en: "Free delivery options", es: "Opciones de entrega gratis"}},
  {id: "acceptedTerms", value: {en: "Terms accepted", es: "Términos aceptados"}},
  {id: "verified", value: {en: "Verified", es: "Verificado"}},
  {id: "canUploadPhotos", value: {en: "Can upload photos", es: "Can upload photos"}},
  {id: "exclusive", value: {en: "Exclusive", es: "Exclusivo"}},
]

export function VendorTagEditor({vendor}) {

  const [editVendor] = useMutation(EDIT_VENDOR_MUTATION)

  async function changeTags(_tags){
    try {
      await editVendor({variables: {id: vendor.id, data: {tags: _tags}}})
      return toast.success("Successfully changed")
    } catch (e) {
      e.graphQLErrors.map(x => toast.error(x.message))
    }
  }

  return(
    <div id="vendor-tag-editor">
      <TagEditor values={vendor.tags} options={tags} onChange={changeTags} style={{marginBottom: 20}} />
    </div>
  )

}
