import React, { useState } from 'react'
import { node } from 'prop-types'

import { useModal } from '../../hooks'

const ModalContext = React.createContext()
const ModalConsumer = ModalContext.Consumer

function ModalProvider({children}) {

  const [modal, toggleModal] = useState(null)
  const [attributes, setAttributes] = useState({})
  const modals = {
    customerBalance: useModal(),
    cancelPurchase: useModal(),
    riderModifications: useModal(),
    purchaseProducts: useModal(),
    purchaseAddress: useModal(),
    purchaseCustomer: useModal(),
    purchaseStatus: useModal(),
  }

  function isOpened(name){
    return modal === name
  }

  function openModal(name, attributes = {}){
    toggleModal(name)
    setAttributes(attributes)
  }

  function closeModal(){
    toggleModal(null)
    setAttributes({})
  }

  return (
    <ModalContext.Provider
      value={{
        isOpened: isOpened,
        openModal: openModal,
        closeModal: closeModal,
        attributes: attributes,
        modals
      }}
    >
      {children}
    </ModalContext.Provider>
  )
}

ModalProvider.propTypes = {
  children: node.isRequired
}

export { ModalContext, ModalProvider, ModalConsumer }
