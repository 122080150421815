import { gql } from '@apollo/client'

import { ADMIN_FRAGMENT } from '../admin'

const ME_QUERY = gql`
  query me{
     me {
       ...ADMIN_FRAGMENT
     }
  }
  ${ADMIN_FRAGMENT}
`

export default ME_QUERY
