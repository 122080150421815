import { gql }from '@apollo/client'

export const VENDOR_USER_FRAGMENT = gql`
  fragment VENDOR_USER_FRAGMENT on VendorUser {
    id
    slug
    username
    password
    vendor
    name
    canReadPurchases
    canEditPurchases
    canReadFeedback
    canSetProductAvailability
    role
    createdAt
    updatedAt
  }
`
