import { gql } from '@apollo/client'

import { RIDER_WORKSHIFT_FRAGMENT } from './'

export const GET_RIDER_WORKSHIFT = gql`
  query getRiderWorkShift($id: ID!){
    getRiderWorkShift(id: $id){
      ...RIDER_WORKSHIFT_FRAGMENT
    }
  }
  ${RIDER_WORKSHIFT_FRAGMENT}
`
