import React, { useContext, useState } from 'react'

import { IntlContext } from '../../../contexts'
import { IntlText, LabeledInput, Button, Modalv3, Title } from '../../../components'

export function MessageModal({modal, message, edit}){

    const { getTranslation } = useContext(IntlContext)
    const [_message, setMessage] = useState(message)
  
    function save(){
      edit(_message)
      modal.hide()
    }        
  
    function hideModal(){
      setMessage(message)
      modal.hide()
    }        
  
    return(
      <Modalv3 modal={modal} header={<Title tag="h3"><IntlText group="verified-document" id="edit-message" /></Title>} onHide={() => modal.hide()}>
        <LabeledInput placeholder={getTranslation({group: "verified-document", id: "message"})} value={_message} onChange={(e) => setMessage(e.target.value)}/>
        <div className='actions' style={{marginTop: 15}}>
          <Button onClick={hideModal}><IntlText id="cancel" /></Button>
          <Button theme="main" onClick={save}><IntlText id="save" /></Button>
        </div>
      </Modalv3>             
    )    
  }