import { gql } from '@apollo/client'

export const RIDER_BOOK_FRAGMENT = gql`
  fragment RIDER_BOOK_FRAGMENT on RiderBook {
    id
    owner
    cashBalance
    cashCreditBalance
    createdAt
    updatedAt
  }
`
