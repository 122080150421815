import React from 'react'

import { IntlValue } from '../../../components'

import './style.css'

export function PromotionPreview({type}){

  if (type?.id) return(
    <div className="promotion-preview">
      <i className="fas fa-badge-percent"></i>
      <div className="info">
        <div className="title"><IntlValue>{type.label}</IntlValue></div>
        <span><IntlValue>{type.description}</IntlValue></span>
      </div>
    </div>
  )

  return null
}
