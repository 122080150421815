import { gql } from '@apollo/client'

import { VEHICLE_FRAGMENT } from './'

export const EDIT_RIDER_VEHICLE = gql`
  mutation editRiderVehicle($id: ID!, $data: EditedRiderVehicleInput!){
    editRiderVehicle(id: $id, data: $data){
      ...VEHICLE_FRAGMENT        
    }
  }
  ${VEHICLE_FRAGMENT}
`
