import { gql } from '@apollo/client'

import { VENDOR_FRAGMENT } from './'

export const UPLOAD_VENDOR_DOC = gql`
  mutation uploadVendorDoc($file: Upload!, $type: VENDOR_UPLOAD_TYPE!, $vendor: ID){
    uploadVendorDoc(file: $file, type: $type, vendor: $vendor){
      ...VENDOR_FRAGMENT
    }
  }
  ${VENDOR_FRAGMENT}
`
