import { gql } from '@apollo/client'

import { PAGE_FRAGMENT } from './'

export const PAGE_QUERY = gql`
  query page($id: String, $slug: String){
     page(id: $id, slug: $slug){
       ...PAGE_FRAGMENT
     }
  }
  ${PAGE_FRAGMENT}
`
