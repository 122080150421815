import React, { useState, useContext } from 'react'
import { useQuery, useMutation } from '@apollo/client'

import { EDIT_CATEGORY_MUTATION, CATEGORIES_QUERY, REORDER_PRODUCT_CATEGORIES_MUTATION, DELETE_CATEGORY_MUTATION } from '../../../graphql'
import { ModalContext } from '../../../contexts'
import { useConfirm, useIntl } from '../../../hooks'
import { toast } from '../../../helpers'
import { LabeledCheckbox, Loader, IntlText, IntlValue, Filter, Table, ContextMenu, StatusDot, Filters, Sortable } from '../../../components'
import { CategoryModal } from '../../../views'

export function CategoriesList({vendor}) {

  const { confirm } = useConfirm()
  const { getTranslation, getTranslatedValue } = useIntl()
  const [filter, setFilter] = useState()
  const { openModal } = useContext(ModalContext)
  const {loading, error, data, refetch} = useQuery(CATEGORIES_QUERY, {variables: {owner: vendor.id}})
  const [deleteCategory] = useMutation(DELETE_CATEGORY_MUTATION)
  const [editProductCategory, { loading: editLoading }] = useMutation(EDIT_CATEGORY_MUTATION)
  const [reorder, { loading: reorderLoading }] = useMutation(REORDER_PRODUCT_CATEGORIES_MUTATION)

  if (loading || error) return <Loader theme='main' />

  async function _delete(id){
    const alert = toast.loading(getTranslation({group: "confirm", id: "confirm-to-continue"}))
    try {
      await confirm({title: getTranslation({group: "confirm", id: "delete"}), desc: getTranslation({group: "confirm", id: "delete-info"})})
      alert.update(getTranslation({id: "loading"}))
      await deleteCategory({variables: {id}})
      alert.success(getTranslation({id: "delete-success"}))
      refetch()
    } catch (error) {
      alert.apolloError(error)
    }
  }  

  async function onSort(orderedItems){
    try {
      await reorder({variables: {data: orderedItems}})
      refetch()
      toast.success(getTranslation({id: "order-success"}))
    } catch (e) {
      e.graphQLErrors.map(x => toast.error(x.message))
    }
  }

  const renderHeader = () => (
    <>
      <Table.Th></Table.Th>
      <Table.Th></Table.Th>
      <Table.Th className="text-left"><IntlText group="categories-list" id="table-header-name" /></Table.Th>
      <Table.Th><IntlText group="categories-list" id="table-header-disabled" /></Table.Th>
      <Table.Th><IntlText group="categories-list" id="table-header-hidden" /></Table.Th>
      <Table.Th></Table.Th>    
    </>
  )

  const renderItem = (category) => (
    <>
      <Table.Td><StatusDot active={!(category.disabled || category.hidden)} /></Table.Td>
      <Table.Td className="text-left"><IntlValue value={category.name} /></Table.Td>
      <Table.Td centered><LabeledCheckbox id={`disabled-${category.id}`} checked={category.disabled} onChange={(e) => editProductCategory({variables: {id: category.id, data: {disabled: e.target.checked}}})} rounded/></Table.Td>
      <Table.Td centered><LabeledCheckbox id={`hidden-${category.id}`} checked={category.hidden} onChange={(e) => editProductCategory({variables: {id: category.id, data: {hidden: e.target.checked}}})} rounded/></Table.Td>
      <Table.Td>
        <ContextMenu.Menu id={category?.id}>
          <ContextMenu.Button onClick={() => openModal("category", {category})} icon="edit"><IntlText id="edit" /></ContextMenu.Button>
          <ContextMenu.Button theme="invalid" onClick={() => _delete(category.id)} icon="trash"><IntlText id="delete" /></ContextMenu.Button>
        </ContextMenu.Menu>
      </Table.Td>
    </>
  )  

  return(
    <div id="vendor-categories">
      {(reorderLoading || editLoading) && <Loader theme="main" overlay />}
      <Filters.Bar>
        <Filters.Input id="query" name="query" value={filter} onChange={({target}) => setFilter(target.value)} reset={() => setFilter("")} placeholder="Type here for searching" />
      </Filters.Bar>
      <div id="categories-list" className="relative">
        <Filter search={[{key: "search", value: filter}]}  data={data?.categories || []} filters={[{filter: (data) => data.id}, {filter: (data) => getTranslatedValue(data.name)}, {filter: (data) => data.disabled}]}>
          {(data) =>
            <Sortable.Table data={data} onSort={onSort} renderItem={renderItem} renderHeader={renderHeader} />
          }
        </Filter>
      </div>
      <CategoryModal />
    </div>
  )
}