import { gql } from '@apollo/client'

import { CUSTOMER_BOOK_ENTRY_FRAGMENT } from './'

export const CREATE_CUSTOMER_BOOK_ENTRY = gql`
  mutation createCustomerBookEntry($data: CustomerBookEntryInput!){
     createCustomerBookEntry(data: $data){
       ...CUSTOMER_BOOK_ENTRY_FRAGMENT
     }
  }
  ${CUSTOMER_BOOK_ENTRY_FRAGMENT}
`
