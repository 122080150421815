import React, { useContext } from 'react'
import { useMutation } from '@apollo/client'
import { useParams, useNavigate } from 'react-router-dom'

import { SERVICE_FEE_PROFORMA_INVOICES, CREATE_SERVICE_FEE_PROFORMA_INVOICE } from '../../../graphql'
import { useValidatedForm } from '../../../hooks'
import { IntlContext } from '../../../contexts'
import { IntlText, Form, Button, LabeledInput, SwitchBox } from '../../../components'

export function VendorNewInvoice() {
  
  const { id } = useParams()
  const navigate = useNavigate()
  const { getTranslation } = useContext(IntlContext)
  const [createInvoice] = useMutation(CREATE_SERVICE_FEE_PROFORMA_INVOICE)
  const { form: { amount, sendToEmail }, updateField } = useValidatedForm({})

  async function _createInvoice(){
    try {
      await createInvoice({variables: {vendor: id, amount: parseFloat(amount), sendToEmail},  refetchQueries: [{query: SERVICE_FEE_PROFORMA_INVOICES, variables: {vendor: id}}]})
      navigate("../invoices")
    } catch (error) {
      console.log("error", error);
    }
  }

  return(
    <div id="vendor-new-invoice">
      <Form.Form>
        <Form.Field>
          <LabeledInput inputMode="numeric" pattern="[0-9]*" placeholder={getTranslation({group: "vendor-new-invoice", id: "amount"})} value={amount} onChange={({target}) => updateField({key: "amount", value: target.value})} />
        </Form.Field>
        <Form.Field>
          <SwitchBox id="sendToEmail" label={getTranslation({group: "vendor-new-invoice", id: "send-to-email-info"})} checked={sendToEmail} onChange={({target}) => updateField({key: "sendToEmail", value: target.checked})}>{getTranslation({group: "vendor-new-invoice", id: "send-to-email"})}</SwitchBox>
        </Form.Field>
        <Form.Field>
          <Button theme="main" fluid onClick={_createInvoice}><IntlText group="vendor-new-invoice" id="create" /></Button>
        </Form.Field>
      </Form.Form>
    </div>
  )
}
