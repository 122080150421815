import React, { useState } from 'react'
import Lightbox from 'yet-another-react-lightbox'
import { Download, Share, Captions } from "yet-another-react-lightbox/plugins";

import './style.css'

export function Thumbnail({url, title, style, images = []}){

    const [open, setOpen] = useState(false)
    const hasSlider = images.length > 0 ? undefined : () => null

    if (!url) return null
    
    return(
        <div className='thumbnail' onClick={() => setOpen(true)} style={style}>
            <img alt={title} src={url} title={title} />
            <Lightbox
                open={open}
                close={() => setOpen(false)}
                slides={[{ src: url, title }, ...images]}
                carousel={{ finite: hasSlider }}
                render={{ buttonPrev: hasSlider, buttonNext: hasSlider }}
                plugins={[Download, Share, Captions]}
            />          
            {images.length > 0 &&
                <span className='counter'>{images.length+1} photos</span>
            }
        </div>        
    )
}