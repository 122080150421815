import React, { useRef } from 'react'
import { NavLink } from 'react-router-dom'
import FontAwesome from 'react-fontawesome'

import { useDetectOutsideClick } from '../../hooks'

import './style.css'

function Dropdown({button = <span>User</span>, icon = <FontAwesome name="user" />, children, ...rest}) {
  const wrapper = useRef(null)
  const menu = useRef(null)
  const [isActive, setIsActive] = useDetectOutsideClick({wrapper, menu, initialState: false})

  function onClick(){
    setIsActive(!isActive)
  }

  return (
    <div className="dropdown" {...rest} ref={wrapper}>
      <button onClick={onClick} className="menu-trigger">
        {button}
        {icon}
      </button>
      <nav
        ref={menu}
        className={`menu ${isActive ? "active" : "inactive"}`}
      >
        <ul>
          {children}
        </ul>
      </nav>
    </div>
  )
}

function Item({children, icon, ...rest}) {
  return(
    <li {...rest}>
      <div>
        {icon && <div className="icon">{icon}</div>}
        {children}
      </div>
    </li>
  )
}

function Link({children, icon, to, ...rest}) {
  return (
    <li {...rest}>
      <NavLink activeClassName="active-link" to={to}>
        {icon && <div className="icon">{icon}</div>}
        {children}
      </NavLink>
    </li>
  )
}

const dropdown = { Dropdown, Item, Link }

export default dropdown
