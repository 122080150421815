export function scrollTo(wrapper, target, offset, duration) {
    if (!target) return console.log("Error: No valid object to scroll")

    var to = (target.offsetTop - wrapper.offsetTop) - offset

    var start = wrapper.scrollTop,
        change = to - start,
        currentTime = 0,
        increment = 20
        
    var animateScroll = function(){
        currentTime += increment
        var val = Math.easeInOutQuad(currentTime, start, change, duration)
        wrapper.scrollTop = val
        if(currentTime < duration) {
            setTimeout(animateScroll, increment)
        }
    }

    animateScroll()

    return true
}

Math.easeInOutQuad = function (t, b, c, d) {
  t /= d/2
	if (t < 1) return c/2*t*t + b
	t--
	return -c/2 * (t*(t-2) - 1) + b
}
