import React from 'react'
import { toast } from 'react-toastify'
import { useMutation } from '@apollo/client'

import { useForm } from '../../../hooks'
import { LabeledInput, Button, Select, MultilangInput, Form } from '../../../components'
import { EDIT_VENDOR_MUTATION } from '../../../graphql'

export function VendorDetails({vendor}) {

  const [editVendor, { loading }] = useMutation(EDIT_VENDOR_MUTATION)
  const vendorTypes = [{id: "RESTAURANT", label: "Restaurant"}, {id: "SHOP", label: "Shop"}, {id: "CANTEEN", label: "Canteen"}]

  const { form, edited, updateField, resetForm } = useForm({
    name: vendor.name,
    slug: vendor.slug,
    intro: vendor.intro,
    type: vendorTypes.find(_type => _type.id === vendor.type),
  })

  const { name, slug, intro, type } = form

  async function save(){
    let data = {}

    if (name) data.name = name
    if (slug && slug !== vendor.slug) data.slug = slug
    if (intro) data.intro = {en: intro.en, es: intro.es}
    if (type) data.type = type.id

    if (data.name === "" || data.slug === "") return toast.error("Missing state")

    try {
      await editVendor({variables: {id: vendor.id, data: data}})
      resetForm()
      return toast.success("Successfully saved")
    } catch (e) {
      e.graphQLErrors.map(x => toast.error(x.message))
      resetForm()
    }
  }

  return(
    <Form.Form id="vendor-details" >
      <Form.Field>
        <LabeledInput name="name" placeholder="Name" value={name} onChange={(e) => updateField({[e.target.name]: e.target.value})}/>
      </Form.Field>
      <Form.Field>
        <LabeledInput name="username" placeholder="Username" value={vendor.username}  disabled/>
      </Form.Field>
      <Form.Field>
        <LabeledInput name="slug" placeholder="Slug" value={slug} onChange={(e) => updateField({[e.target.name]: e.target.value})}/>
      </Form.Field>
      <Form.Field>
        <Select placeholder="Restaurant type" selected={type} defaultValue="Please select" options={vendorTypes} onChange={(_type) => updateField({type: _type})} />
      </Form.Field>
      <Form.Field>
        <MultilangInput name="intro" value={intro} placeholder="Intro" onChange={(e, value) => updateField({[e.target.name]: value})}/>
      </Form.Field>
      <Form.Field>
        {edited && <Button theme="main" className="full" loading={loading} onClick={() => save()}>Save</Button>}
      </Form.Field>
    </Form.Form>
  )

}
