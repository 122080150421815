import { gql }from '@apollo/client'

import { ADDON_FRAGMENT } from './'

const ADDONS_QUERY = gql`
  query productAddons($owner: String){
     productAddons(owner: $owner) {
       ...ADDON_FRAGMENT
     }
  }
  ${ADDON_FRAGMENT}
`

export default ADDONS_QUERY
