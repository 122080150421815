import { toast } from 'react-toastify'
import { useMutation } from '@apollo/client'

import { UPLOAD_VENDOR_MEDIA_MUTATION, DELETE_VENDOR_MEDIA_MUTATION } from '../../../graphql'
import { useIntl } from '../../../hooks'
import { Form, ImageCropper  } from '../../../components'

export function VendorMedia({vendor}) {

  const { getTranslation } = useIntl()
  const [uploadMedia, {loading: uploading}] = useMutation(UPLOAD_VENDOR_MEDIA_MUTATION)
  const [deleteMedia, {loading: deleting}] = useMutation(DELETE_VENDOR_MEDIA_MUTATION)

  async function _uploadMedia({file, type}){
    try {
      await uploadMedia({variables: {vendor: vendor.id, file, type}})
      return toast.success("Successfull upload")
    } catch (e) {
      e.graphQLErrors.map(x => toast.error(x.message))
    }
  }

  async function _deleteMedia(type){
   try {
     await deleteMedia({variables: {id: vendor.id, type}})
     return toast.success("Successfull delete")
   } catch (e) {
     e.graphQLErrors.map(x => toast.error(x.message))
   }
 }

  return(
    <div id="vendor-media">
      <Form.Form>
        <Form.Field>
          <ImageCropper id="logo" title={getTranslation({group: "vendor-media", id: "logo"})} placeholder={getTranslation({group: "vendor-media", id: "logo"})} image={vendor.logo} uploadImage={(file) => _uploadMedia({file, type: "IMAGE_VENDOR_LOGO"})} deleteImage={() => _deleteMedia("IMAGE_VENDOR_LOGO")} uploading={uploading} deleting={deleting} />                    
        </Form.Field>
        <Form.Field>
          <ImageCropper id="cover" aspect={1200/460} title={getTranslation({group: "vendor-media", id: "cover"})} placeholder={getTranslation({group: "vendor-media", id: "cover"})} image={vendor.cover} uploadImage={(file) => _uploadMedia({file, type: "IMAGE_VENDOR_COVER"})} deleteImage={() => _deleteMedia("IMAGE_VENDOR_COVER")} uploading={uploading} deleting={deleting} />                    
        </Form.Field>
        <Form.Field>
          <ImageCropper id="wallpaper" aspect={1920/280} title={getTranslation({group: "vendor-media", id: "wallpaper"})} placeholder={getTranslation({group: "vendor-media", id: "wallpaper"})} image={vendor.wallpaper} uploadImage={(file) => _uploadMedia({file, type: "IMAGE_VENDOR_WALLPAPER"})} deleteImage={() => _deleteMedia("IMAGE_VENDOR_WALLPAPER")} uploading={uploading} deleting={deleting} />                    
        </Form.Field>
      </Form.Form>
    </div>
  )

}
