import { gql } from '@apollo/client'

import { PURCHASE_FRAGMENT } from './'

export const MODIFY_PURCHASE = gql`
  mutation modifyPurchase($id: ID!, $data: ModifiedPurchaseInput){
    modifyPurchase(id: $id, data: $data){
      ...PURCHASE_FRAGMENT
    }
  }
  ${PURCHASE_FRAGMENT}
`
