import { gql } from '@apollo/client'

import { ANNOUNCEMENT_FRAGMENT } from './'

export const ANNOUNCEMENT_QUERY = gql`
  query announcement($id: String, $slug: String){
    announcement(id: $id, slug: $slug){
       ...ANNOUNCEMENT_FRAGMENT
     }
  }
  ${ANNOUNCEMENT_FRAGMENT}
`
