import React from 'react'
import moment from 'moment'
import { useQuery } from '@apollo/client'

import { DAILY_STATS } from '../../graphql'
import { useQueryBuilder } from '../../hooks'
import { Wrapper, Filters, DatePicker, Loader } from '../../components'
import { DashboardDiagrams } from '../../views'

import './style.css'

export function Dashboard() {

  const { params, addParam, isQuery, buildQuery, runQuery } = useQueryBuilder({
    data: {
      startDate: {key: "startDate", value: moment().subtract(14, 'days').format("YYYY-MM-DD")},
      endDate: {key: "endDate", value: moment().add(1, 'days').format("YYYY-MM-DD")},
    }
  })

  const { loading, data } = useQuery(DAILY_STATS, {variables: buildQuery(),  skip: !isQuery()})

  function setDate({selection: {startDate, endDate}}){
    addParam({
      startDate: {key: "startDate", value: moment(startDate).format("YYYY-MM-DD"), skip: !moment(startDate).format("YYYY-MM-DD")},
      endDate: {key: "endDate", value: moment(endDate).add(1, 'd').format("YYYY-MM-DD"), skip: !moment(endDate).add(1, 'd').format("YYYY-MM-DD")}
    })
  }

  return (
    <Wrapper> 
      <div id="dashboard" className="view">
        <div className="row padding">  
          <div className="filter-bar">
            <div style={{marginLeft: "auto"}}>
              <Filters.Dropdown placeholder="Date" value={`${params?.startDate?.value} ${params?.endDate?.value ? " - " + params?.endDate?.value : null}`} disableInClickClose right run={runQuery} >
                <DatePicker.Range startDate={params?.startDate?.value ? new Date(`${params.startDate.value}T00:00`) : new Date()} endDate={params?.endDate?.value ? new Date(`${params.endDate.value}T00:00`) : new Date()} onChange={setDate} />
              </Filters.Dropdown>
            </div>
          </div>      
          <div className="relative">
            {loading && <Loader theme="main" overlay/>}
            <DashboardDiagrams dailyStats={data?.dailyStats || []} />
          </div>
        </div>
      </div>
    </Wrapper>
  )
}
