import { gql } from '@apollo/client'

export const CUSTOMER_FRAGMENT = gql`
  fragment CUSTOMER_FRAGMENT on Customer {
    id
    name
    email
    slug
    verified
    celVerificationStatus
    abusive
    banned
    note
    balance
    tags
    phone{
      countryCode
      phoneNumber
      phoneCode
    }
    cel{
      countryCode
      phoneNumber
      phoneCode
    }
    subscribedToNewsletter
    referralSource
    referralSourceId
    referralTermsAccepted   
    referrerCode
    referrerPurchasesCount
    referrerLevel
    referrerPercentage
    totalReferrerIncome
    validReferral
    referralProblem
    createdAt
  }
`