import React from 'react'
import FontAwesome from 'react-fontawesome'
import { useQuery } from '@apollo/client'

import { VENDOR_BOOK } from '../../../graphql'
import { Loader } from '../../../components'

import './style.css'

export function VendorBalance({vendor}) {

  const { data, loading, error } = useQuery(VENDOR_BOOK, {variables: {vendor}})

  if (loading) return <Loader theme="main" />
  if (error) return <Loader theme="main" />

  const { vendorBook } = data

  return(
    <div className="vendor-balance">
      <div className="balance">
        <FontAwesome className="icon" name="dollar-sign" />
        <span className="value">{vendorBook?.cashBalance || 0}</span>
      </div>
    </div>
  )
}
