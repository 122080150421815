import React from 'react'

import { Link, IntlText, ContextMenu } from '../../../components'

import './style.css'

export function RiderName({purchase}){

  if (!purchase?.rider?.originalId) return(
    <span><IntlText id="not-set" /></span>
  )

  let phone = purchase.rider.cel?.phoneCode + purchase.rider.cel?.phoneNumber
  if (phone) phone = phone?.replace(/[\s+()]/g, "")  

  return(
    <div className="rider-name customer-field">
      <Link to={`/riders/${purchase.rider.originalId}`}>
        <span className="link">{purchase.rider.name}</span>
      </Link>
      {purchase.rider.type === "CONTRACTOR" &&
        <ContextMenu.Menu id={purchase.rider.originalId}>
          <ContextMenu.Button onClick={() => window.open(`whatsapp://send/?phone=${phone}`)} icon={<i className="fab fa-whatsapp" ></i>}><IntlText group="customer-field" id="send-whatsapp" /></ContextMenu.Button>      
        </ContextMenu.Menu>
      }
    </div>
  )

}
