import { gql } from '@apollo/client'

import { VENDOR_FRAGMENT } from './'

export const DELETE_VENDOR_MEDIA_MUTATION = gql`
  mutation deleteVendorMedia($id: ID!, $type: VENDOR_UPLOAD_TYPE!){
    deleteVendorMedia(id: $id, type: $type){
      ...VENDOR_FRAGMENT
    }
  }
  ${VENDOR_FRAGMENT}
`
