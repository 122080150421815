import React from 'react'

import { getAsset } from '../../helpers'

import './style.css'

function Logo({theme = "", size = "",  full = false, loading = true}) {

  if (!loading) return null

  return(
    <div className={`loaderV2 ${full ? "full" : ""}`}>
      <img className='logo' src={getAsset("icon.png")} alt="Loading..." />
    </div>
  )
}

function Spinner({theme = "", size = "",  overlay = false, loading = true}) {

  if (!loading) return null

  return(
    <div className={`${overlay ? "loader-overlay" : ""}`}>
      <div className={`loader ${theme} ${size}`}>
        <span></span>
      </div>
    </div>
  )
}


const loader = { Logo, Spinner }

export default loader