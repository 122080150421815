import { gql } from '@apollo/client'

import { ANNOUNCEMENT_FRAGMENT } from './'

export const CREATE_ANNOUNCEMENT_MUTATION = gql`
  mutation createAnnouncement($data: AnnouncementInput!){
    createAnnouncement(data: $data){
      ...ANNOUNCEMENT_FRAGMENT
    }
  }
  ${ANNOUNCEMENT_FRAGMENT}
`
