import { gql } from '@apollo/client'

export const VENDOR_FRAGMENT = gql`
  fragment VENDOR_FRAGMENT on Vendor {
    id
    slug
    username
    name
    email
    whatsappGroup
    logo
    cover
    wallpaper
    contract
    contractSigned
    contractExpiry
    contractUrl
    type
    location{
      type
      coordinates
    }
    rank
    tags
    isOpen
    productTypes
    serviceFee
    vendorBalanceOverDue
    cashPaymentsDisabled
    bankCardPaymentsAccepted
    bankCardPaymentsAllowed
    numOfFeedbacks
    percentageOfPositive
    networkTransactionsDisabled
    expressAccountId
    expressAccountDetailsSubmitted
    expressFlowEnabled
    serviceFeeChargeType
    serviceFeeChargePeriod
    needsInvoiceOfServicefee
    taxDoc
    taxDocMessage
    taxDocExpiry
    taxDocStatus
    invoicing{
      name
      rfc
      email
      street
      exterior
      interior
      neighborhood
      city
      zip
      municipality
      state
      taxSystem
    }    
    utensilsAvailable
    openingHours{
      from
      to
      isOpen
    }
    intro{
      en
      es
    }
    hidden
    suspended
    technicalBreak
    slow
    onVacation
    vendorSystem
    description{
      en
      es
    }
    promotions{
      id
      slug
      name
      description{
        en
        es
      }
      vendorDescription
      label{
        en
        es
      }
      type
      restrictions{
        key
        value
      }
      level
      active
    }
    createdAt
    lastPurchaseProcessed
  }
`
