import { gql }from '@apollo/client'

import { ADDON_OPTION_FRAGMENT } from './'

const PRODUCT_ADDON_FRAGMENT = gql`
  fragment PRODUCT_ADDON_FRAGMENT on ProductAnnexAddon {
    id
    addon{
      id
      name{
        es
        en
      }
      description{
        es
        en
      }
    }
    options{
      ...ADDON_OPTION_FRAGMENT
    }
  }
  ${ADDON_OPTION_FRAGMENT}
`

export default PRODUCT_ADDON_FRAGMENT
