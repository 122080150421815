import React, { useContext } from 'react'
import { toast } from 'react-toastify'
import { useQuery, useMutation } from '@apollo/client'
import { useParams } from 'react-router-dom'

import { useValidatedForm } from '../../hooks'
import { EDIT_PAGE_MUTATION, PAGE_QUERY } from '../../graphql'
import { IntlContext } from '../../contexts'
import { HtmlEditor, LabeledInput, Loader, Wrapper, Form, LabeledTextarea, TagEditor, SwitchBox, IntlText } from '../../components'
import { PageOptions, PageHeader } from '../../views'

export function Page(){

  const { id } = useParams()
  const { loading, data, error } = useQuery(PAGE_QUERY, {variables: {id}})

  if (loading || error) return <Loader theme="main" />

  return(
    <Wrapper>
      <div id="page" className="view">
        <Inner page={data?.page} />
      </div>
    </Wrapper>
  )
}

function Inner({page}){

  const { id, language } = useParams()
  const { getTranslation } = useContext(IntlContext)
  const _language = language || "en"
  const { form, updateField, updateMultilangField, getEditedData } = useValidatedForm({...page})
  const [editPage, { loading: editLoading }] = useMutation(EDIT_PAGE_MUTATION)
  const tags = []  

  async function _editPage(){
    try {
      editPage({variables: {id, data: getEditedData()}})
      return toast.success(getTranslation({id: "edit-success"}))
    } catch (e) {
      e.graphQLErrors.map(x => toast.error(x.message))
    }
  }

  async function changeTags(_tags){
    const alert = toast.loading()

    try {
      await editPage({variables: {id, data: {tags: _tags}}})
      alert.success(getTranslation({id: "edit-success"}))
    } catch (e) {
      console.log("err", e);
      alert.apolloError(e)
    }
  }  

  return(
    <div className="row padding">
      {editLoading && <Loader theme="main" overlay />}
      <PageHeader slug={form.slug} title={page.title} onChange={({target}) => updateField({key: target.name, value: target.value})} />
      <div className="grids">
        <div className="grid">
          <Form.Form>
            <Form.Field>
              <LabeledInput name="title" placeholder="Page title" value={form.title?.[_language]} onChange={({target}) => updateMultilangField({key: target.name, value: target.value, language: _language})} />
            </Form.Field>
            <Form.Field>
              <LabeledTextarea id={`editor-${_language}`} name="description" placeholder="Page description" value={form.description?.[_language]} onChange={({target}) => updateMultilangField({key: target.name, value: target.value, language: _language})}/>
            </Form.Field>
            <Form.Field>
              <HtmlEditor id={`editor-${_language}`} name="content" placeholder="Page content" value={form.content?.[_language]} onChange={({text, target}) => updateMultilangField({key: target.name, value: text, language: _language})}/>
            </Form.Field>
            <Form.Field>
              <SwitchBox id="hidden" label={<IntlText id="hidden-desc" />} checked={form.hidden} onChange={({target}) => updateField({key: "hidden", value: target.checked})}>
                <IntlText id='hidden' />
              </SwitchBox>                   
            </Form.Field>            
            <Form.Field>
              <TagEditor values={page.tags} options={tags} onChange={changeTags} />              
            </Form.Field>
          </Form.Form>
        </div>
        <div className="grid sidebar">
          <PageOptions language={_language} page={page} save={_editPage} loading={editLoading} url={`/pages/${id}`} templates={["DEFAULT", "RIDE_WITH_US", "BECOME_PARTNER", "SAVE_AND_SHARE", "CUSTOM"]} />
        </div>
      </div>
    </div>
  )
}
