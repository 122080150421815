import React, { useState, useContext } from 'react'
import { useMutation } from '@apollo/client'
import FontAwesome from 'react-fontawesome'
import { toast } from 'react-toastify'

import { IntlContext } from '../../../contexts'
import { Map, IntlText, Button, EditBox, Form } from '../../../components'
import { CORRECT_ADDRESS } from '../../../graphql'

export function PurchaseAddress({purchase: {id, address}}) {

  const { getTranslation } = useContext(IntlContext)
  const [edit, toggleEdit] = useState(false)
  const [editAddress, { loading }] = useMutation(CORRECT_ADDRESS)
  const [coordinates, setLocation] = useState(address?.location?.coordinates)

  function changeLocation(e){
    if (!edit) return false

    setLocation([e.lng, e.lat])
  }

  async function save(data){
    try {
      await editAddress({variables: {id, updateOriginal: true, address: data}})
      toggleEdit(false)
      return toast.success(getTranslation({id: "edit-success"}))
    } catch (e) {
      if (e.graphQLErrors && e.graphQLErrors.length > 0) e.graphQLErrors.map(x => toast.error(x.message))
      toggleEdit(false)
    }
  }

  return(
    <div className="purchase-address">
      <Form.Form>
        <Form.Field>
          <EditBox.Text placeholder={<div><FontAwesome name="address-card" /><IntlText group="address-form" id="name" /></div>} save={(value) => save({name: value})}>{address.name}</EditBox.Text>
        </Form.Field>
        <Form.Field>
          <EditBox.Text placeholder={<div><FontAwesome name="building" /><IntlText group="address-form" id="building" /></div>} save={(value) => save({building: value})}>{address.building}</EditBox.Text>
        </Form.Field>
        <Form.Field>
          <EditBox.Text placeholder={<div><FontAwesome name="map-marker" /><IntlText group="address-form" id="unit" /></div>} save={(value) => save({unit: value})}>{address.unit}</EditBox.Text>
        </Form.Field>
        <Form.Field>
          <EditBox.Text placeholder={<div><FontAwesome name="sticky-note" /><IntlText group="address-form" id="instructions" /></div>} save={(value) => save({instructions: value})}>{address.instructions}</EditBox.Text>
        </Form.Field>
        <Form.Field>
          <div className="map">
            {edit ?
             <Map.Actions>
                <Button theme="main" loading={loading} onClick={() => save({location: {type: "Point", coordinates: coordinates}})}><IntlText id="save" /></Button>
                <Button onClick={() => toggleEdit(false)}><IntlText id="cancel" /></Button>
              </Map.Actions>            
            :
              <Map.Actions>
                <Button onClick={() => toggleEdit(true)}><IntlText id="edit" /></Button>
              </Map.Actions>                  
            }
            <Map.Map center={{lng: coordinates[0], lat: coordinates[1]}} onClick={(e) => changeLocation(e)}>
              <Map.Pin lng={coordinates[0]} lat={coordinates[1]}><FontAwesome name="map-marker" /></Map.Pin>
            </Map.Map>
          </div>          
        </Form.Field>
      </Form.Form>
    </div>
  )
}
