import { useState } from 'react'

export function useModal(initialState){
    const [opened, toggleOpen] = useState(false)
    const [state, setState] = useState(initialState)
  
    function show(obj){
      toggleOpen(true)
      setState(obj)
    }

    function hide(){
      toggleOpen(false)
      setState(undefined)
    }
  
    return ({
      opened,
      state,
      show,
      hide
    })
  }
  