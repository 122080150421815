import React from 'react'
import { useQuery } from '@apollo/client'

import { DAY_CLOSING } from '../../graphql'
import { formatPrice } from '../../helpers'
import { Loader } from '../../components'

import './style.css'

export function StatBar({isQuery, buildQuery}){
  
    const { loading, data, error } = useQuery(DAY_CLOSING, {variables: buildQuery(), skip: !isQuery(), notifyOnNetworkStatusChange: true})
  
    if (loading || error) return <Loader />
  
    const {
      revenue,
      collected,
      deficit,
      mods,
      totalTips,
      totalStartingBalance,
    } = data.dayClosing && data.dayClosing.length > 0 ? data.dayClosing.reduce((acc, closing) => {
      acc.revenue += closing.totalRevenue;
      acc.totalStartingBalance += closing.openingBalance;
      acc.totalTips += closing.totalTips;
      acc.collected += closing.collectedCash;
      acc.deficit += closing.deficit;
      acc.mods += closing.totalModifications;
      return acc;
    }, {
      revenue: 0,
      collected: 0,
      deficit: 0,
      mods: 0,
      totalTips: 0,
      totalStartingBalance: 0,
    }) : {
      revenue: 0,
      collected: 0,
      deficit: 0,
      mods: 0,
      totalTips: 0,
      totalStartingBalance: 0,
    }  
  
    return(
      <div className='stat-bar'>
        <div>
          <span>Revenue</span>
          <span className='value'>{formatPrice(revenue, false)}</span>
        </div>
        <div>
          <span>Collected</span>
          <span className='value'>{formatPrice(collected, false)}</span>
        </div>
        <div>
          <span>Deficit</span>
          <span className='value'>{formatPrice(deficit, false)}</span>
        </div>
        <div>
          <span>Modifications</span>
          <span className='value'>{formatPrice(mods, false)}</span>
        </div>
        <div>
          <span>Tips</span>
          <span className='value'>{formatPrice(totalTips, false)}</span>
        </div>
        <div>
          <span>Starting balance</span>
          <span className='value'>{formatPrice(totalStartingBalance, false)}</span>
        </div>
      </div>
    )
  }