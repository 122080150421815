import React, { useContext, useState } from 'react'
import FontAwesome from 'react-fontawesome'

import { IntlContext } from '../../contexts'
import { Title, IntlText, IntlValue, Button, LabeledInput } from '../'

import './style.css'

export function TagEditor({values, options = [], onChange}) {

  const { getTranslation } = useContext(IntlContext)
  const [customTag, editCustomTag] = useState("")
  const tags = new Set(values)
  const availableOptions = options.filter((option, key) => !tags.has(option.id))

  function add(key){
    tags.add(key)
    onChange([...tags])
  }
  
  function addCustom(){
    if (customTag !==""){
      add(customTag)
      editCustomTag("")
    }
  }

  function remove(key){
    tags.delete(key)
    onChange([...tags])
  }

  return (
    <div className='tag-editor'>        
      <Title tag="h3"><IntlText group="tag-editor" id="options" /></Title>
        <div className='tags active'>
          <div className='add-custom'>
              <LabeledInput placeholder={getTranslation({group: "tag-editor", id: "custom-tag"})} value={customTag} onChange={({target}) => editCustomTag(target.value)} />
              <Button icon="plus" onClick={addCustom}><IntlText id="add" /></Button>
          </div>
          {availableOptions.length > 0 && availableOptions.map((item, key) =>
            <div key={key} className="item" onClick={() => add(item.id)}>
              {item.icon && <span className="icon">{item.icon}</span>}
              <span><IntlValue>{item.value}</IntlValue></span>
              <FontAwesome name="plus" />
            </div>
          )}
        </div>
      <Title tag="h3"><IntlText group="tag-editor" id="values" /></Title>
      <div className='tags active'>
        {tags.size !== 0 ? [...tags].map((item, key) => {
          const option = options.find(_opt => _opt.id === item)

          return(
            <div key={key} className="item" onClick={() => remove(item)}>
              {option?.icon && <span className="icon">{option?.icon}</span>}
              <span>
                {option?.value ?
                  <IntlValue>{option?.value}</IntlValue>
                :
                  item
                }
              </span>
              <FontAwesome name="trash" />
            </div>
          )
        })
        :
          <IntlText group="tag-editor" id="empty" />
        }
      </div>
    </div>
)

}
