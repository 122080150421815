export function getPromotionTypes({minBasket, discount}){
    return [
        {
            id: "FREEDELIVERYOVERBASKETVALUE", 
            name: {en: "Free delivery over basket value", es: "Entrega gratis sobre el valor del carrito"},
            label: {
                en: minBasket > 0 ? `Free delivery for orders over ${minBasket} MXN` : "Free delivery",
                es: minBasket > 0 ? `Envío gratis desde ${minBasket} MXN` : "Envío gratis",
            },      
            description: {
                en: minBasket > 0 ? `If the client basket is over ${minBasket} MXN the base delivery fee is covered by the restaurant` : "The base delivery fee is covered by the restaurant",
                es: minBasket > 0 ? `Si la canasta del cliente es superior a ${minBasket} MXN, la tarifa base de envío está cubierto por el restaurante ` : "La tarifa base de envío está cubierto por el restaurante",
            },
            vendorDescription: {
                en: "Important! In the case of free delivery promotion, the base delivery fee charged to the restaurant",
                es: "Importante! En el caso de la promoción de envío gratuito, la tarifa de envío base se cobra al restaurante"
            }
        },
        {
            id: "PERCENTAGEOVERBASKETVALUE", 
            name: {en: "Discount over basket value", es: "Descuento sobre el valor del carrito"},
            label: {
                en: minBasket > 0 ? `${discount}% discount for orders over ${minBasket} MXN` : `${discount}% discount`,
                es: minBasket > 0 ? `${discount}% descuento desde ${minBasket} MXN` : `${discount}% descuento`
            },
            description: {
                en: minBasket > 0 ? `If the client basket is over ${minBasket} MXN he has discount on the order` : `Discount on all orders`,
                es: minBasket > 0 ? `Si la canasta del cliente es superior a ${minBasket} MXN, tiene descuento en su pedido` : `Descuento en todos pedidos`
            },      
            vendorDescription: null
        }
    ]  
}