import { gql } from '@apollo/client'

import { RIDER_BREAK_FRAGMENT } from './'

export const REQUEST_DAILY_BREAK = gql`
  mutation requestDailyBreak($rider:ID){
    requestDailyBreak(rider: $rider){
      ...RIDER_BREAK_FRAGMENT
    }
  }
  ${RIDER_BREAK_FRAGMENT}
`
