import React, { useState, useContext } from 'react'
import moment from 'moment'
import { useQuery } from '@apollo/client'
import FontAwesome from 'react-fontawesome'

import { IntlContext } from '../../contexts'
import { Wrapper, Loader, Table, IntlValue, Filters, Filter } from '../../components'
import { useQueryBuilder } from '../../hooks'
import { AUDITLOGS } from '../../graphql'

export function Audit() {

  const [filter, setFilter] = useState()
  const { getTranslatedValue } = useContext(IntlContext)
  const {addParam, getParams, clearParams, params, removeParam, buildQuery, isQuery, runQuery } = useQueryBuilder()
  const { data, loading, error } = useQuery(AUDITLOGS, {variables: {type: "INTERNAL", ...buildQuery()}, skip: !isQuery(), fetchPolicy: "network-only"})

  if (error) return <Loader theme="main" />

  return(
    <Wrapper>
      <div id="audit" className="container">
        <div className="row padding">
          <Filters.Bar>
            <Filters.Input id="query" name="query" value={filter} onChange={({target}) => setFilter(target.value)} reset={() => setFilter("")} placeholder="Type here for searching" />
            <Filters.Select style={{marginLeft: "auto"}} value={params?.maxAge?.label} placeholder="Max age" reset={() => removeParam("maxAge")}>
              <li onClick={() => addParam({maxAge: {key: "maxAge", label: "6 hour", value: 360}})}><span>6 hour</span></li>
              <li onClick={() => addParam({maxAge: {key: "maxAge", label: "12 hour", value: 720}})}><span>12 hour</span></li>
              <li onClick={() => addParam({maxAge: {key: "maxAge", label: "24 hour", value: 1440}})}><span>24 hour</span></li>
              <li onClick={() => addParam({maxAge: {key: "maxAge", label: "48 hour", value: 2880}})}><span>48 hour</span></li>
              <li onClick={() => addParam({maxAge: {key: "maxAge", label: "72 hour", value: 4320}})}><span>72 hour</span></li>
            </Filters.Select>
            <Filters.Submit onClick={runQuery}><FontAwesome name="search" /></Filters.Submit>       
          </Filters.Bar>
          <Filters.Bar >
            <Filters.ActiveParams activeParams={getParams()} removeParam={removeParam} clearParams={clearParams}/>
          </Filters.Bar>
          <div id="logs-list" className="relative">
            {(loading) && <Loader theme="main" overlay/>}
            <Filter data={data?.auditlogs || []} search={[{key: "search", value: filter}]} filters={[{filter: (d) => getTranslatedValue(d.message)}, {filter: (d) => d.metadata.join(" ")}]} >
              {(_data) =>
                <Table.Table>
                  <Table.Head>
                    <Table.Tr>
                      <Table.Th className="text-left">Date</Table.Th>
                      <Table.Th className="text-left">Description</Table.Th>
                      <Table.Th className="text-left">Meta</Table.Th>
                    </Table.Tr>
                  </Table.Head>
                  <Table.Body>
                    {_data.length > 0 ? _data.map((da, key) =>
                      <Table.Tr key={key}>
                        <Table.Td className="text-left">{moment(da.createdAt).format("DD/MM/YYYY HH:mm")}</Table.Td>
                        <Table.Td className="text-left"><IntlValue value={da.message} /></Table.Td>
                        <Table.Td className="text-left">{da.metadata.join(" ")}</Table.Td>
                      </Table.Tr>
                    ) :
                      <Table.Tr>
                        <Table.Td colSpan={3}>There is nothing here, try to search</Table.Td>
                      </Table.Tr>
                    }
                  </Table.Body>
                </Table.Table>
              }
            </Filter>
          </div>
        </div>
      </div>
    </Wrapper>
  )
}
