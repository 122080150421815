import { gql } from '@apollo/client'

import { CUSTOMER_FRAGMENT } from './'

export const MANUALLY_VERIFY_CUSTOMER_CEL = gql`
  mutation manuallyVerifyCustomerCel($cel: PhoneNumberInput!){
    manuallyVerifyCustomerCel(cel: $cel){
      ...CUSTOMER_FRAGMENT
    }
  }
  ${CUSTOMER_FRAGMENT}
`
