import React from 'react'
import { useMutation } from '@apollo/client'
import { useNavigate } from 'react-router-dom'

import { EDIT_RIDER_MUTATION, DELETE_RIDER } from '../../../graphql'
import { useConfirm, useIntl } from '../../../hooks'
import { toast } from '../../../helpers'
import { EditBox, Button, IntlText, Form } from '../../../components'

export function RiderReset({rider}) {

  const navigate = useNavigate()
  const { confirm } = useConfirm()
  const { getTranslation } = useIntl()
  const [deleteRider] = useMutation(DELETE_RIDER)
  const [edit] = useMutation(EDIT_RIDER_MUTATION)

  async function _reset(_value){
    const alert = toast.loading(getTranslation({group: "confirm", id: "confirm-to-continue"}))
    try {
      if (!_value) throw new Error(getTranslation({id: "empty-error"}))

      await confirm({title: getTranslation({group: "customer-reset", id: "confirm"}), desc: getTranslation({group: "customer-reset", id: "confirm-info", variables: {name: rider.name}})})
      alert.update(getTranslation({id: "loading"}))
      await edit({variables: {id: rider.id, data: {password: _value}}})
      alert.success(getTranslation({id: "reset-success"}))
    } catch (error) {
      alert.apolloError(error)
    }
  }


  async function _deleteUser(){
    const alert = toast.loading(getTranslation({group: "confirm", id: "confirm-to-continue"}))
    try {
      await confirm({title: getTranslation({group: "confirm", id: "delete"}), desc: getTranslation({group: "confirm", id: "delete-info"})})
      alert.update(getTranslation({id: "loading"}))
      await deleteRider({variables: {id: rider.id}})
      navigate('/riders')
      alert.success(getTranslation({id: "delete-success"}))
    } catch (error) {
      alert.apolloError(error)
    }
  }

  return(
    <div id="rider-reset">
      <Form.Form>
        <Form.Field>
          <EditBox.Text text="*******" placeholder="Reset account password" save={_reset} />
        </Form.Field>
        <Form.Field>
          <Button theme="reject" fluid onClick={_deleteUser}><IntlText group="rider-settings" id="delete-rider" /></Button>
        </Form.Field>
      </Form.Form>
    </div>
  )

}
