import FontAwesome from 'react-fontawesome'

import { Title } from '../'

import './style.css'

export function Notification({title, description, children, icon = "check", type = "success", actions}) {

  function showIcon(){
    switch (typeof icon) {
      case "string":
        return <div className="icon"><FontAwesome name={icon} /></div>
      default:
        return <div className="icon">{icon}</div>
    }
  }

  return(
    <div className={`notification ${type}`}>
        <div className="notification-title">
            {icon && showIcon()}
            <div className="info">
                <Title tag="h3">{title}</Title>
                <span>{children || description}</span>
            </div>
            {actions && <div style={{marginLeft: "auto"}}>{actions}</div>}
        </div>
    </div>
  )
}
