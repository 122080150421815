import React from 'react'
import FontAwesome from 'react-fontawesome'
import { useQuery } from '@apollo/client'

import { RIDER_BOOK } from '../../../graphql'
import { IntlText, Loader } from '../../../components'

import './style.css'

export function RiderBalance({rider}) {

  const { data, loading, error } = useQuery(RIDER_BOOK, {variables: {rider: rider?.id}})

  if (loading || error) return <Loader theme="main" />

  const { riderBook } = data

  return(
    <div className="rider-balance">
      <div className="balance">
        <FontAwesome className="icon" name="dollar-sign" />
        <div className='inner'>
          <span className='placeholder'><IntlText group='rider-balance' id='cashBalance' /></span>
          <span className="value">{riderBook?.cashBalance || 0}</span>
        </div>
      </div>
      <div className="balance">
        <FontAwesome className="icon" name="money-bill" />
        <div className='inner'>
          <span className='placeholder'><IntlText group='rider-balance' id='cashCreditBalance' /></span>
          <span className="value">{riderBook?.cashCreditBalance || 0}</span>
        </div>
      </div>
    </div>
  )
}