import { gql }from '@apollo/client'

import { ADDON_OPTION_FRAGMENT } from './'

const ADDON_OPTION_QUERY = gql`
  query productAddonOption($id: ID, $slug: String){
     productAddonOption(id: $id, slug: $slug) {
       ...ADDON_OPTION_FRAGMENT
     }
  }
  ${ADDON_OPTION_FRAGMENT}
`

export default ADDON_OPTION_QUERY
