import React from 'react'
import { Mutation } from '@apollo/client/react/components'

import { LabeledCheckbox } from '../../components'
import { EDIT_PURCHASE_MUTATION } from '../../graphql'

import './VoidOrder.css'

export default function VoidOrder({purchase}) {

    return(
      <Mutation mutation={EDIT_PURCHASE_MUTATION}>
        {(editPurchase) =>
          <div className="confirm-order-field">
            <LabeledCheckbox rounded placeholder="Voided" id="voided" checked={purchase.voided} onChange={(e) => editPurchase({variables: {id: purchase.id, data: {[e.target.id]: e.target.checked}}})}/>
          </div>
        }
      </Mutation>
    )

}
