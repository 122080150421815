import React from 'react'

import './style.css'

export function InfoText({info, children, ...rest}){

    return(
        <div className='info-text' {...rest}>
            <span>{children}</span>
            <span>{info}</span>
        </div>
    )
}