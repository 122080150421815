import React, { useState, useEffect, useRef } from 'react'
import FontAwesome from 'react-fontawesome'

import './style.css'

export function Select({selected, options, placeholder, defaultValue, onChange}) {

  const [opened, toggle] = useState(false)
  const element = useRef(null)

  useEffect(() => {
    const handleClick = (e) => {
      if (!element.current || element.current.contains(e.target)) return false
      toggle(false)
    }

    document.addEventListener("click", handleClick)
    document.addEventListener("touchend", handleClick)

    return () => {
      document.removeEventListener("click", handleClick)
      document.removeEventListener("touchend", handleClick)
    }
  }, [])

  const _onChange = (option) => {
    onChange(option)
    toggle(false)
  }

  let value = selected || { label: defaultValue || placeholder }

  return(
    <div className={`select ${placeholder ? "placeholder" : ""}`} ref={element}>
      {placeholder && <span className="placeholder">{placeholder}</span>}
      <span className="selected" onClick={() => toggle(!opened)}>{value.label}</span>
      {opened &&
        <ul>
          {options.map((option, key) =>
            <li key={key} onClick={() => _onChange(option)}>{option.label}</li>
          )}
        </ul>
      }
      <FontAwesome name={opened ? "chevron-up" : "chevron-down"} />
    </div>
  )
}
