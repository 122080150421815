import { gql } from '@apollo/client'

import { PAGE_FRAGMENT } from './'

export const PAGES_QUERY = gql`
  query pages{
     pages{
       ...PAGE_FRAGMENT
     }
  }
  ${PAGE_FRAGMENT}
`
