import React, { useContext } from 'react'
import FontAwesome from 'react-fontawesome'

import { useModal } from '../../hooks'
import { IntlContext } from '../../contexts'
import { IntlText, Button, Modalv3, Title, Form, CheckboxField } from '../../components'

import './style.css'


function Plain({className = "", children, withScroller, ...rest}) {

  return(
    <table className={`table ${className}`} {...rest}>
      {children}
    </table>
  )
}

function Table({className = "", children, withScroller, ...rest}) {

  return(
    <div className={`table-scroller with-scroller`}>
      <table className={`responsive-table ${className}`} {...rest}>
        {children}
      </table>
    </div>
  )
}

function Head({children, ...rest}) {

  return(
    <thead {...rest}>
      {children}
    </thead>
  )
}

function Body({children, ...rest}) {

  return(
    <tbody {...rest}>
      {children}
    </tbody>
  )
}

function Foot({children, ...rest}) {

  return(
    <tfoot {...rest}>
      <tr>{children}</tr>
    </tfoot>
  )
}

function Tr({children, ...rest}, ref) {
  return(
    <tr ref={ref} {...rest}>{children}</tr>
  )
}

function Th({children, ...rest}) {

  return(
    <th scope="col" {...rest}>{children}</th>
  )
}

function Td({label, centered, children, ...rest}) {

  return(
    <td data-label={label} {...rest}><div className={`cell ${centered ? "centered" : ""}`}>{children}</div></td>
  )
}

function VisibleFieldsFilter({visibleFields, setVisibleFields}){
  
  const modal = useModal()
  const { getTranslation } = useContext(IntlContext)

  return(
    <div className='visible-fields-filter'>
      <Button onClick={() => modal.show()} ><FontAwesome name="cog" /></Button>
      <Modalv3 modal={modal} header={<Title tag="h3"><IntlText group="visible-fields-filter" id="title" /></Title>}>
        <Form.Form>
          {Object.entries(visibleFields).map(([key, value]) => 
            <Form.Field key={key}>
              <CheckboxField id={`${key}-field`} checked={value} onChange={() => setVisibleFields({...visibleFields, [key]: !value})} label={getTranslation({group: "visible-fields-filter", id: key})}/>
            </Form.Field>
          )}
        </Form.Form>
      </Modalv3>  
    </div>
  )
}

const table = { Table, Plain, Head, Body, Foot, Tr: React.forwardRef(Tr), Th, Td, VisibleFieldsFilter }

export default table
