import React from 'react'

import { Theme } from './components'
import { ModalProvider } from './contexts'

import { Router } from './router'

function App() {
  return (
      <ModalProvider>
        <Theme>
          <Router />
        </Theme>
      </ModalProvider>

  )
}

export default App
