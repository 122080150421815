import React, { useContext } from 'react'

import history from '../../history'
import { ModalContext } from '../../contexts'
import { LoaderV2, MediaQuery } from '../../components'
import { LeftMenu, Header, Breadcrumbs } from '../../views'

export function Wrapper({children, loading}) {

  const { closeModal } = useContext(ModalContext)

  history.listen(() => {
    closeModal()
  })

  return(
    <div id="app">
      <LeftMenu />
      <div id="main">
        <Header />
        <MediaQuery max={800}>
          <div className="row wrapped">
            <Breadcrumbs />
          </div>
        </MediaQuery>        
        {loading ?
          <LoaderV2.Logo full />
        :
          children
        }
      </div>
    </div>
  )
}
