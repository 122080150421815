import React, { useState, useEffect } from 'react'
import { EditorState, ContentState } from 'draft-js'
import { convertToHTML } from 'draft-convert';
import htmlToDraft from 'html-to-draftjs';
import FontAwesome from 'react-fontawesome'
import { Editor } from 'react-draft-wysiwyg'

import { Loader, IntlText } from '../../components'

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import './style.css'

export function HtmlEditor({id, name, placeholder, value = "", save, loading, onChange}) {

  const [editorState, changeState] = useState(init())

  useEffect(() => {
    changeState(init())
  // eslint-disable-next-line
  }, [id])

  function init(){
    const contentBlock = htmlToDraft(value || "")
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks)
      const editorState = EditorState.createWithContent(contentState)

      return editorState
    }
  }

  function onEditorStateChange(state){
    changeState(state)
    if (onChange) onChange({target: {name}, text: convertToHTML(state.getCurrentContent())})
  }
  
  return(
    <Editor
      wrapperId={id}
      editorState={editorState}
      placeholder={placeholder}
      toolbarClassName="toolbarClassName"
      wrapperClassName="wrapperClassName"
      editorClassName="editorClassName"
      toolbarCustomButtons={save ? [
        <CustomOption save={save} loading={loading} />
      ] : null}
      onEditorStateChange={onEditorStateChange}
    />    
  )

}

function CustomOption({save, editorState, loading}){
  
  function addStar() {
    save(convertToHTML(editorState.getCurrentContent()))
  }

  return (
    <div className="rdw-remove-wrapper" aria-label="rdw-remove-control">
      <div className="rdw-option-wrapper" title={<IntlText id="save" />} style={{display: "flex", alignItems: "center", gap: 3, justifyContent: "center"}} onClick={addStar}>{loading ? <Loader size="small" theme='main' /> : <FontAwesome name="save" />}<IntlText id="save" /></div>
    </div>    
  )
}