import React, { useState } from 'react'
import { useQuery } from '@apollo/client'

import { AdminList } from '../../views'
import { Filter, Wrapper, Filters, Loader } from '../../components'
import { ADMINS } from '../../graphql'

export function Admins() {

  const filters = [
    {filter: (data) => data.name}
  ]

  const sortables = [
    {id: "name", type: "text", data: (elem) => elem.name}
  ]

  const [filter, setFilter] = useState()
  const {loading, error, data} = useQuery(ADMINS)

  if (error) return `Error! ${error.message}`

  return(
    <Wrapper loading={loading}>
      <div id="admins" className="container">
        <div className="row padding">
          <Filters.Bar>
            <Filters.Input id="query" name="query" value={filter} onChange={({target}) => setFilter(target.value)} reset={() => setFilter("")} placeholder="Type here for searching" />
          </Filters.Bar>
          <div id="admins" className="relative">
            {loading && <Loader theme="main" overlay/>}
            <Filter data={data?.admins || []} search={[{key: "search", value: filter}]} filters={filters} sortables={sortables} defaultSort="rank" filterKeys={["status"]}>
              {(data) =>
                <AdminList data={data} />
              }
            </Filter>
          </div>
        </div>
      </div>
    </Wrapper>
  )
}
