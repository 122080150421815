import React, { useContext, useState } from 'react'
import FontAwesome from 'react-fontawesome'
import { toast } from 'react-toastify'
import { useMutation, useQuery } from '@apollo/client'

import { useValidatedForm, useQueryBuilder } from '../../../hooks'
import { CUSTOMER_BOOK_ENTRIES, CREATE_CUSTOMER_BOOK_ENTRY, CUSTOMER_BOOK } from '../../../graphql'
import { Actions, Validation, Select, LabeledInput, LabeledTextarea, IntlText, Button, Loader, Table, Pagination } from '../../../components'
import { IntlContext } from '../../../contexts'
import { CustomerBookEntries } from '../../../views'

import './style.css'

export function CustomerBalanceHistory({customer}) {

  const [add, toggleAdd] = useState(false)
  const { getTranslation } = useContext(IntlContext)
  const types = [
    {id: "CASH_REFUND", label: getTranslation({group: "customer-balance", id: "select-cash-refund"})},
    {id: "CASH_COMPENSATION", label: getTranslation({group: "customer-balance", id: "select-cash-compensation"})},
    {id: "CASH_SPENT", label: getTranslation({group: "customer-balance", id: "select-cash-spent"})},
    {id: "CASH_DEBT", label: getTranslation({group: "customer-balance", id: "select-cash-debt"})},
    {id: "TOMATO_POINT_REFUND", label: getTranslation({group: "customer-balance", id: "select-tomato-point-refund"})},
    {id: "TOMATO_POINT_DEDUCTION", label: getTranslation({group: "customer-balance", id: "select-tomato-point-deduct"})},
  ]

  const validations = {
    value: [{id: "required", validation: (val) => !!val}],
    event: [{id: "required", validation: (val) => !!val?.id}],
  }

  const {buildQuery, isQuery, setPage, page, processQuery } = useQueryBuilder({
    data: {
      customer: {key: "customer", label: `${customer}`, value: customer},
    }
  })
  const [createCustomerBookEntry, { loading: createLoading }] = useMutation(CREATE_CUSTOMER_BOOK_ENTRY)
  const { form: { event, value, note }, errors, updateField, resetForm, validate } = useValidatedForm({}, validations)
  const { data, loading, error, refetch } = useQuery(CUSTOMER_BOOK_ENTRIES, {variables: buildQuery(), skip: !isQuery(), onCompleted: (_data) => processQuery(_data?.customerBookEntries?.pageInfo), notifyOnNetworkStatusChange: true})

  async function _createCustomerBookEntry(){
    const { valid } = validate()

    if (!valid) return toast.error(getTranslation({id: "form-error"}))

    try {
      await createCustomerBookEntry({variables: { data: {customer, event: event?.id, note, value: parseFloat(value)}}, refetchQueries: [{query: CUSTOMER_BOOK_ENTRIES, variables: {customer}}, {query: CUSTOMER_BOOK, variables: {customer}}] })
      resetForm()
      toggleAdd(false)
      toast.success(getTranslation({id: "create-success"}))
    } catch (e) {
      toast.error(e?.message)
      resetForm()
      toggleAdd(false)
    }
  }

  if (loading) return <Loader theme="main" />
  if (error) return <Loader theme="main" />

  if (add) return(
    <div id="customer-book-entry" className="form">
      <Validation errors={errors.event}>
        <Select placeholder="Type" selected={event} defaultValue="Please select" options={types} onChange={(type) => updateField({key: "event", value: type})} />
      </Validation>
      <Validation errors={errors.value}>
        <LabeledInput name="value" placeholder="Amount" value={value} onChange={({target}) => updateField({key: "value", value: target.value})} />
      </Validation>
      <LabeledTextarea name="note" placeholder="Note" value={note} onChange={({target}) => updateField({key: "note", value: target.value})} />
      <Actions>
        <Button type="alt" onClick={() => toggleAdd(false)}><IntlText id="cancel" /></Button>
        <Button loading={createLoading} theme="main" onClick={_createCustomerBookEntry}>Create</Button>
      </Actions>
    </div>
  )

  return(
    <div id="balance-history">
      <CustomerBookEntries customer={customer} data={data} loading={loading} refetch={refetch} history header={
        <Table.Tr>
          <Table.Td colSpan="6">
            <Actions>
              <Button type="alt" onClick={() => toggleAdd(true)}><FontAwesome name="plus-circle" />Add new item</Button>
            </Actions>
          </Table.Td>
        </Table.Tr>
      }/>
      {data?.customerBookEntries.nodes.length > 0 &&
        <Pagination page={page} setPage={setPage} pageInfo={data?.customerBookEntries?.pageInfo}/>
      }
    </div>
  )
}
