import React, { useState } from 'react'
import { Query } from '@apollo/client/react/components'
import { toast } from 'react-toastify'

import client from '../../client'
import { Loader, Select, LabeledSelect, FileUploader, Button, Wrapper } from '../../components'
import { VENDORS_QUERY, IMPORT_MUTATION } from '../../graphql'

import './Import.css'

export default function Import() {

  const importTypes = [
    {id: "VENDORS", label: "Vendors"},
    {id: "PRODUCTCATEGORIES", label: "Categories"},
    {id: "PRODUCTS", label: "Products"},
    {id: "PRODUCTADDONS", label: "Addons"},
    {id: "PRODUCTADDONOPTIONS", label: "Addon options"},
    {id: "PRODUCTANNEXADDONS", label: "Product addons"}
  ]

  const delimiters = [
    {id: "colon", label: ","},
    {id: "semicolon", label: ";"},
  ]

  const [loading, toggleLoading] = useState(false)
  const [form, setValues] = useState({delimiter: {id: "semicolon", label: ";"}})
  const { file, type, vendor, delimiter } = form

  function updateField(field){
    setValues({
      ...form,
      ...field
    })
  }

  function setFile(e){
    if (!e.target.files || !e.target.files.length > 0) return toast.error("There is nothing to upload")

    updateField({file: e.target.files[0]})
  }

  async function startImport(){

    if (!type) return toast.error("No import type selected")
    if (!vendor && type.id !== "VENDORS") return toast.error("No vendor selected")
    if (!file) return toast.error("No file selected")

    let data = {file: file, type: type.id, delimiter: delimiter.label}
    if (vendor) data.vendor = vendor.id

    toggleLoading(true)

    try {
      await client.mutate({mutation: IMPORT_MUTATION, variables:data})
      toggleLoading(false)
      return toast.success("Import was successful")
    } catch (e) {
      if (e.graphQLErrors && e.graphQLErrors.length > 0) e.graphQLErrors.map(x => toast.error(x.message))
      console.log("error", e)
      toggleLoading(false)
    }
  }

  return(
    <Wrapper>
      <div id="import" className="view">
        <div className="row padding">
          <div id="importer" className="grids">
            <div className="grid">
              <Select placeholder="Import type" selected={type} defaultValue="Please select" options={importTypes} onChange={(type) => updateField({type})} />
              <Select placeholder="Delimiter type" selected={delimiter} defaultValue="Please select" options={delimiters} onChange={(delimiter) => updateField({delimiter})} />
              {type && type.id !== "VENDORS" &&
                <Query query={VENDORS_QUERY}>
                  {({ loading, error, data }) => {
                    if (loading) return <Loader theme="main"/>
                    if (error) return `Error! ${error.message}`

                    const { vendors } = data

                    return(
                      <LabeledSelect placeholder="Vendor" field="name" value={vendor} options={vendors} onChange={(_vendor) => updateField({vendor: _vendor})} />
                    )
                  }}
                </Query>
              }
              <Button theme="main" loading={loading} onClick={() => startImport()}>Import</Button>
            </div>
            <div className="grid">
              <FileUploader id="import-file" value={file} onChange={(e) => setFile(e)}/>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  )
}
