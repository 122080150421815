import { useMutation } from '@apollo/client'
import { useSearchParams, useNavigate } from 'react-router-dom'

import { CREATE_CHANGELOG, CHANGELOGS } from '../../graphql'
import { toast } from '../../helpers'
import { useIntl, useValidatedForm } from '../../hooks'
import { HtmlEditor, LabeledInput, Wrapper, Form, IntlText, SelectV2, Button, SwitchBox, Validation } from '../../components'
import { LanguageTabs } from '../../views'

export function NewChangelog(){

  const validations = {
    "type": [{id: "required", validation: (val) => !!val}],
    "title": [{id: "en", validation: (val) => !!val.en}, {id: "es", validation: (val) => !!val.es}],
    "content": [{id: "en", validation: (val) => !!val.en}, {id: "es", validation: (val) => !!val.es}],
  }  
  const navigate = useNavigate()
  const { getTranslation } = useIntl()
  const { form, updateField, updateMultilangField, getEditedData, validate, errors } = useValidatedForm({hidden: true}, validations)
  const [searchParams] = useSearchParams()
  const [create] = useMutation(CREATE_CHANGELOG, {refetchQueries: [{query: CHANGELOGS}]})
  const types = ["CUSTOMER_WEB", "CUSTOMER_MOBILE", "CUSTOMER_MOBILE_IOS", "CUSTOMER_MOBILE_ANDROID", "VENDOR_WEB", "VENDOR_MOBILE"]
  const selectedType = types.find(_type => _type === form.type)
  const language = searchParams.get('lang') || 'en'

  async function _create(){
    const alert = toast.loading()
    try {
      const { valid } = validate()
      if (!valid) throw new Error(getTranslation({group: "form", id: "error"}))
      await create({variables: {data: getEditedData()}})
      navigate("/changelogs")
      alert.success(getTranslation({id: "create-success"}))
    } catch (e) {
      alert.apolloError(e)
    }
  }

  return(
    <Wrapper>
      <div id="new-changelog" className="view">
        <div className="row padding">
          <LanguageTabs errors={[errors.title, errors.content]} />
          <div className="grids">
            <div className="grid">
              <Form.Form>
                <Form.Field>
                  <Validation errors={errors["type"]}>
                    <SelectV2 value={(_value) => getTranslation({group: "changelogs", id: _value})} placeholder={getTranslation({group: "changelogs", id: "type"})} selected={selectedType} defaultValue={getTranslation({id: "please-select"})} options={types} onChange={(_type) => updateField({key: "type", value:  _type})} />
                  </Validation>
                </Form.Field>
                <Form.Field>
                  <Validation errors={errors["title"]}>
                    <LabeledInput name="title" placeholder="Title" value={form.title?.[language]} onChange={({target}) => updateMultilangField({key: target.name, value: target.value, language: language})} />
                  </Validation>
                </Form.Field>
                <Form.Field>
                  <LabeledInput name="description" placeholder="Description" value={form.description?.[language]} onChange={({target}) => updateMultilangField({key: target.name, value: target.value, language: language})}/>
                </Form.Field>
                <Form.Field>
                  <Validation errors={errors["content"]}>
                    <HtmlEditor id={`editor-${language}`} name="content" placeholder="Content" value={form.content?.[language]} onChange={({text, target}) => updateMultilangField({key: target.name, value: text, language: language})}/>
                  </Validation>
                </Form.Field>
              </Form.Form>
            </div>
            <div className="grid sidebar">
              <Form.Form>
                <Form.Field>
                  <Button fluid theme="main" onClick={_create}>{<IntlText id="create" />}</Button>
                </Form.Field>                            
                <Form.Field>
                  <SwitchBox id="hidden" label={getTranslation({group: "options", id: "hidden-desc"})} checked={!form.hidden} onChange={({target}) => updateField({key: target.id, value: !form.hidden})}>
                    <IntlText group='options' id='hidden' />
                  </SwitchBox>      
                </Form.Field>                            
              </Form.Form>              
            </div>
          </div>
        </div>       
      </div>
    </Wrapper>
  )
}
