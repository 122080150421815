import { gql } from '@apollo/client'

const REORDER_PRODUCT_CATEGORIES_MUTATION = gql`
  mutation reorderProductCategories($data: [ProductCategoryReorderInput]!){
    reorderProductCategories(data: $data){
      success
    }
  }
`

export default REORDER_PRODUCT_CATEGORIES_MUTATION
