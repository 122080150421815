import React, { useContext } from 'react'
import { useQuery } from '@apollo/client'

import { IntlContext } from '../../../contexts'
import { Loader, Select} from '../../../components'
import { CATEGORIES_QUERY } from '../../../graphql'

export function CategorySelect({owner, category, onChange, placeholder, defaultValue}) {
  const { getTranslation, getTranslatedValue } = useContext(IntlContext)
  const {loading, error, data} = useQuery(CATEGORIES_QUERY, {variables: {owner}})

  if (loading) return <Loader theme="main"/>
  if (error) return `Error! ${error.message}`

  const _placeholder = placeholder || getTranslation({group: "categories-select", id: "placeholder"})
  const _defaultvalue = defaultValue || getTranslation({id: "please-select"})
  const { categories } = data

  let selectedCategory = categories.find((_cat) => _cat.id === category)
  selectedCategory = selectedCategory ? {id: selectedCategory?.id, label: getTranslatedValue(selectedCategory?.name)} : undefined

  return(
    <div className="categories-select">
      <Select placeholder={_placeholder} selected={selectedCategory} defaultValue={_defaultvalue} options={categories.map((current, key) => ({id: current.id, label: getTranslatedValue(current.name)}))} onChange={(_category) => onChange(_category)} />
    </div>
  )
}
