import React, { useState, useContext } from 'react'
import { useMutation } from '@apollo/client'
import { toast } from 'react-toastify'

import { IntlContext } from '../../../contexts'
import { EDIT_CUSTOMER_MUTATION, CHANGE_STATUS_MUTATION, DELETE_ADDRESS_MUTATION, CREATE_RIDER_MODIFICATION_MUTATION, DELETE_RIDER_MODIFICATION_MUTATION } from '../../../graphql'
import { Button, Timer, LabeledCheckbox, IntlText } from '../../../components'
import { copyToClipboard, getEllapsed } from '../../../helpers'

import './style.css'

export function PurchaseCancel({purchase, onSuccess}){

  const _tags = purchase?.customer?.tags || []
  const { getTranslation } = useContext(IntlContext)
  const vendorConfirmed = getEllapsed(purchase?.timestamps?.vendorConfirmed)
  const [addModification, toggleAddModification] = useState(!!(purchase?.rider && purchase?.timestamps?.collected))
  const [editCustomer] = useMutation(EDIT_CUSTOMER_MUTATION)
  const [changeStatus, { loading }] = useMutation(CHANGE_STATUS_MUTATION)
  const [deleteAddress] = useMutation(DELETE_ADDRESS_MUTATION)
  const [createRiderModification] = useMutation(CREATE_RIDER_MODIFICATION_MUTATION)
  const [deleteRiderModification] = useMutation(DELETE_RIDER_MODIFICATION_MUTATION)

  async function wrongAddress(){
    try {
      await editCustomer({variables: {id: purchase?.customer?.originalId, data: {tags: [...new Set([..._tags, "incorrectAddress"])]}}})
      await deleteAddress({variables: {id: purchase?.address?.originalId}})
      await changeStatus({variables: {id: purchase?.id, status: "DELIVERYFAILED"}})
      if (addModification && purchase.rider && purchase.price.vendorCashPayment > 0) await createRiderModification({variables: {data: {purchase: purchase?.id, rider: purchase.rider.originalId, note: `Failed delivery reason: wrongAddress`, value: parseInt(purchase.price.vendorCashPayment), reason: "FAILED_DELIVERY"}}})
      onSuccess && onSuccess()
      return toast.success(getTranslation({group:"cancel-purchase", id: "order-cancel-success"}))
    } catch (e) {
      if (e.graphQLErrors) e.graphQLErrors.map(x => toast.error(x.message))
    }
  }

  async function duplicatedOrder(){
    try {
      await editCustomer({variables: {id: purchase?.customer?.originalId, data: {tags: [...new Set([..._tags, "duplicatedOrder"])]}}})
      await changeStatus({variables: {id: purchase?.id, status: "DELIVERYFAILED"}})
      if (addModification && purchase.rider && purchase.price.vendorCashPayment > 0) await createRiderModification({variables: {data: {purchase: purchase?.id, rider: purchase.rider.originalId, note: `Failed delivery reason: incorrectContact`, value: parseInt(purchase.price.vendorCashPayment), reason: "FAILED_DELIVERY"}}})
      onSuccess && onSuccess()
      return toast.success(getTranslation({group:"cancel-purchase", id: "order-cancel-success"}))
    } catch (e) {
      if (e.graphQLErrors) e.graphQLErrors.map(x => toast.error(x.message))
    }
  }

  async function cancelWithinTime(){
    try {
      await changeStatus({variables: {id: purchase?.id, status: "CANCELLED"}})
      copyToClipboard(`Hola! El pedido del cliente ${purchase?.customer?.name} esta cancellado! Él canceló dentro del tiempo de cancelación aceptado. Gracias por su comprension!`)
      onSuccess && onSuccess()
      return toast.success(getTranslation({group:"cancel-purchase", id: "order-cancel-success"}))
    } catch (e) {
      if (e.graphQLErrors) e.graphQLErrors.map(x => toast.error(x.message))
    }
  }

  async function noCash(){
    try {
      await editCustomer({variables: {id: purchase?.customer?.originalId, data: {tags: [...new Set([..._tags, "noCash"])]}}})
      await changeStatus({variables: {id: purchase?.id, status: "DELIVERYFAILED"}})
      if (addModification && purchase.rider && purchase.price.vendorCashPayment > 0) await createRiderModification({variables: {data: {purchase: purchase?.id, rider: purchase.rider.originalId, note: `Failed delivery reason: noCash`, value: parseInt(purchase.price.vendorCashPayment), reason: "FAILED_DELIVERY"}}})
      onSuccess && onSuccess()
      return toast.success(getTranslation({group:"cancel-purchase", id: "order-cancel-success"}))
    } catch (e) {
      if (e.graphQLErrors) e.graphQLErrors.map(x => toast.error(x.message))
    }
  }

  async function deliveryFailed(){
    try {
      await editCustomer({variables: {id: purchase?.customer?.originalId, data: {tags: [...new Set([..._tags, "deliveryFailed"])]}}})
      await changeStatus({variables: {id: purchase?.id, status: "DELIVERYFAILED"}})
      if (addModification && purchase.rider && purchase.price.vendorCashPayment > 0) await createRiderModification({variables: {data: {purchase: purchase?.id, rider: purchase.rider.originalId, note: `Failed delivery reason: deliveryFailed`, value: parseInt(purchase.price.vendorCashPayment), reason: "FAILED_DELIVERY"}}})
      onSuccess && onSuccess()
      return toast.success(getTranslation({group:"cancel-purchase", id: "order-cancel-success"}))
    } catch (e) {
      if (e.graphQLErrors) e.graphQLErrors.map(x => toast.error(x.message))
    }
  }

  async function cancelDueToVendor(){
    try {
      await changeStatus({variables: {id: purchase?.id, status: "REJECTED"}})
      onSuccess && onSuccess()
      return toast.success(getTranslation({group:"cancel-purchase", id: "order-cancel-success"}))
    } catch (e) {
      if (e.graphQLErrors) e.graphQLErrors.map(x => toast.error(x.message))
    }
  }

  async function emergenyCancel(){
    try {
      await changeStatus({variables: {id: purchase?.id, status: "CANCELLED"}})
      if (addModification && purchase.rider && purchase.price.vendorCashPayment > 0) await createRiderModification({variables: {data: {purchase: purchase?.id, rider: purchase.rider.originalId, note: `Failed delivery reason: emergenyCancel`, value: parseInt(purchase.price.vendorCashPayment), reason: "FAILED_DELIVERY"}}})
      onSuccess && onSuccess()
      return toast.success(getTranslation({group:"cancel-purchase", id: "order-cancel-success"}))
    } catch (e) {
      if (e.graphQLErrors) e.graphQLErrors.map(x => toast.error(x.message))
    }
  }

  async function resetOrder(_status){
    try {
      await editCustomer({variables: {id: purchase?.customer?.originalId, data: {abusive: false}}})
      await changeStatus({variables: {id: purchase?.id, status: _status}})

      if (purchase.riderModifications?.length > 0){
        for (const modification of purchase.riderModifications) {
          if (modification.reason === "FAILED_DELIVERY") await deleteRiderModification({variables: {id: modification.id}})
        }
      }

      onSuccess && onSuccess()
      return toast.success(getTranslation({group:"cancel-purchase", id: "order-cancel-success"}))
    } catch (e) {
      if (e.graphQLErrors) e.graphQLErrors.map(x => toast.error(x.message))
    }
  }

  if (["DELIVERYFAILED", "CANCELLED", "REJECTED", "UNPROCESSED", "PAYMENTFAILED", "COMPLETED"].includes(purchase.status)) return(
    <div id="cancel-purchase" className="grids">
      <div className="grid">
        <div>
          {purchase.status === "REJECTED" || purchase.status === "UNPROCESSED" ?
            <div style={{display: "flex", flexDirection: "column", gap: 15, marginTop: 15, }}>
              <Button fluid loading={loading} onClick={() => resetOrder("PROCESSED")}><IntlText group="cancel-purchase" id="reset-to-processed" /></Button>
            </div>
            :
            <div style={{display: "flex", flexDirection: "column", gap: 15, marginTop: 15, }}>
              <Button fluid loading={loading} onClick={() => resetOrder("PROCESSED")}><IntlText group="cancel-purchase" id="reset-to-processed" /></Button>
              <Button fluid loading={loading} onClick={() => resetOrder("ONTHEWAY")}><IntlText group="cancel-purchase" id="reset-to-on-the-way" /></Button>
            </div>
          }
        </div>
      </div>
    </div>
  )

  return(
    <div id="cancel-purchase" className="grids">
      <div className="grid">
        <div className="cancel-time">
          {purchase.status === "INPREPARATION" ?
            <Timer from={purchase?.timestamps?.vendorConfirmed || purchase?.createdAt} time={5} />
          : (purchase.status === "INQUEUE" || purchase.status === "PROCESSED") ?
            <div>
              <div className="timer"><span><IntlText group="cancel-purchase" id="pending" /></span></div>
              <span className='help'><IntlText group="cancel-purchase" id="can-be-cancelled" /></span>
            </div>
          :
            <div>
              <div className="timer"><span className="expired"><IntlText group="cancel-purchase" id="expired" /></span></div>
              <span className='help'><IntlText group="cancel-purchase" id="can-not-be-cancelled" /></span>
            </div>
          }
        </div>      
        {(vendorConfirmed <= 5 && purchase.status === "INPREPARATION") || purchase.status === "INQUEUE" || purchase.status === "PROCESSED" ?
          <div style={{display: "flex", flexDirection: "column", gap: 15, marginTop: 15, }}>
            <Button fluid loading={loading} onClick={cancelWithinTime}><IntlText group="cancel-purchase" id="cancel-within-time" /></Button>
            <Button fluid loading={loading} onClick={cancelDueToVendor}><IntlText group="cancel-purchase" id="vendor-error" /></Button>
          </div>        
        :
          <div style={{display: "flex", flexDirection: "column", gap: 15, marginTop: 15, }}>
            <div style={{display: "flex", justifyContent: "center", marginBottom: 15, alignItems: "center"}}>
              {!!purchase.rider &&
                <LabeledCheckbox id="add-modification" checked={addModification} onChange={() => toggleAddModification(!addModification)} rounded>
                  <IntlText group="cancel-purchase" id="add-rider-modification" />
                </LabeledCheckbox>
              }
            </div>              
            <Button fluid loading={loading} onClick={noCash}><IntlText group="cancel-purchase" id="no-cash" /></Button>
            <Button fluid loading={loading} onClick={duplicatedOrder}><IntlText group="cancel-purchase" id="duplicated-order" /></Button>
            <Button fluid loading={loading} onClick={wrongAddress}><IntlText group="cancel-purchase" id="wrong-address" /></Button>
            <Button fluid loading={loading} onClick={deliveryFailed}><IntlText group="cancel-purchase" id="customer-error" /></Button>
            <Button fluid loading={loading} onClick={cancelDueToVendor}><IntlText group="cancel-purchase" id="vendor-error" /></Button>
            <Button fluid loading={loading} onClick={emergenyCancel}><IntlText group="cancel-purchase" id="emergency" /></Button>
          </div>        
        }
      </div>
    </div>
  )

}
