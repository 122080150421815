import { gql } from '@apollo/client'

import { INVOICE_FRAGMENT } from '../'

export const COMPLETE_INVOICE_REQUEST_CUSTOMER = gql`
  mutation completeInvoiceRequest($id: ID!){
    completeInvoiceRequest(id: $id){
      ...INVOICE_FRAGMENT
    }
  }
  ${INVOICE_FRAGMENT}
`
