import { gql } from '@apollo/client'

import { PURCHASE_FRAGMENT } from './'

export const ASSIGN_RIDER_MUTATION = gql`
  mutation assignRider($id: ID!, $rider: ID){
    assignRider(id: $id, rider: $rider){
      ...PURCHASE_FRAGMENT
    }
  }
  ${PURCHASE_FRAGMENT}
`
