import React from 'react'

import { Tooltip, Badge } from '../../../components'
import { useIntl } from '../../../hooks'

export function CustomerVerificationBadge({customer}){
    const { getTranslation } = useIntl()
  
    function getVerifiedStatus(){
      switch (customer?.celVerificationStatus) {
        case "SMS_VERIFIED":
        case "WHATSAPP_VERIFIED":
        case "MANUALLY_VERIFIED":
          return true    
        default:
          return false
      }
    }
  
    if (getVerifiedStatus()) return(
        <Tooltip text={getTranslation({group: 'customer', id: customer?.celVerificationStatus})}>
            <Badge theme='valid'>{getTranslation({group: 'customer', id: "verified"})}</Badge>
        </Tooltip>
    )

    return null
  }