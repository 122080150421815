import { gql } from '@apollo/client'

import { RIDER_PAYOUT_FRAGMENT } from './'

export const CREATE_CFDI_OF_RIDER_PAYOUT = gql`
  mutation createCFDIOfRiderPayout($id: ID!){
    createCFDIOfRiderPayout(id: $id){
      ...RIDER_PAYOUT_FRAGMENT
    }
  }
  ${RIDER_PAYOUT_FRAGMENT}
`
