import React, { useContext } from 'react'
import { useQuery } from '@apollo/client'
import { useLocation, useParams } from 'react-router-dom'
import moment from 'moment'

import { RIDER_PAYOUTS } from '../../graphql'
import { IntlContext } from '../../contexts'
import { useQueryBuilder } from '../../hooks'
import { Loader, DatePicker, Filters, Pagination } from '../../components'
import { RiderPayouts as RiderPayoutsView } from '../../views'

export function RiderPayouts() {

  const { id } = useParams()
  const { getTranslation } = useContext(IntlContext)
  const location = useLocation()
  const locationSearch = new URLSearchParams(location.search)
  const { params, addParam, removeParam, buildQuery, runQuery, setPage, page } = useQueryBuilder({
    data: {
      date: {key: "date", label: `${locationSearch.getAll("date")[0]} - ${locationSearch.getAll("date")[1]}`, value: locationSearch.getAll("date")?.length === 2 ? [locationSearch.getAll("date")[0], locationSearch.getAll("date")[1]] : undefined, skip: !locationSearch.get("date")},
    },
    page: locationSearch.get("page"),
  }, [{id: "date", validation: (obj) => !!obj?.value, error: "You have to select date"}])  
  const { data, loading, error, refetch } = useQuery(RIDER_PAYOUTS, {variables: {rider: id, ...buildQuery()}})

  function setDate({selection: {startDate, endDate}}){
    const _date = [
      moment(startDate).format("YYYY-MM-DD"),
      moment(endDate).add(1, 'd').format("YYYY-MM-DD")
    ]
    addParam({date: {key: "date", value: [_date[0], _date[1]], label: `${_date[0]} - ${_date[1]}`, skip: !(_date[0] && _date[1])}})
  }  

  if (loading || error) return <Loader theme="main" />

  return(
    <div id="rider-payouts">
      <div className="row">
        <Filters.Bar style={{justifyContent: "flex-end"}}>
          <Filters.Dropdown placeholder={getTranslation({group: "history-filter", id: "date"})} value={params?.date?.label} disableInClickClose right run={runQuery} reset={() => removeParam("date")}>
            <DatePicker.Range startDate={params?.date?.value[0] ? new Date(`${params.date.value[0]}T00:00`) : new Date()} endDate={params?.date?.value[1] ? new Date(`${params.date.value[1]}T00:00`) : new Date()} onChange={setDate} />
          </Filters.Dropdown>
        </Filters.Bar>          
        <RiderPayoutsView riderPayouts={data?.riderPayouts} refetch={refetch} visibleFields={{rider: false}} />            
        <Pagination page={page} setPage={setPage} pageInfo={data?.riderPayouts?.pageInfo}/>              
      </div>
    </div>
  )
}
