import React, { useContext, useState } from 'react'
import FontAwesome from 'react-fontawesome'

import { useModal } from '../../../hooks'
import { IntlContext } from '../../../contexts'
import { IntlText, Badge, ContextMenu, Title, Modalv3, LabeledInput, Button, Thumbnail } from '../../../components'

const defaultStatusOptions = {
  ACCEPTED: {icon: "check", theme: 'valid', value: "ACCEPTED"},
  REJECTED: {icon: "times", theme: 'invalid', value: "REJECTED"},
  EXPIRED: {icon: "times", theme: 'invalid', value: "EXPIRED"},
  INREVIEW: {icon: "clock", theme: 'warning', value: "INREVIEW"},
  DEFAULT: {icon: "clock", theme: 'ui', value: "WAITING_FOR_UPLOAD"},
}

export function VerifiedPhoto({name, url, status, accept, reject, info, message, editMessage, statusOptions = defaultStatusOptions}){

  const rejectModal = useModal()
  const messageModal = useModal()

  function _reject() {
    if(editMessage){
      rejectModal?.show() 
    } else{
      reject()
    }
  }

  return(
    <div className='verified-upload'>
      <div className='icon'>
        <FontAwesome name={statusOptions[status]?.icon || statusOptions["DEFAULT"]?.icon} style={{color: `var(--${statusOptions[status]?.theme || statusOptions["DEFAULT"]?.theme}-color)`}}/>
      </div>
      {url &&
        <Thumbnail url={url} title={name} />
      }
      <div className='name'>{name}</div>
      {info && 
        <div className='info'>
          <span className='placeholder'>size</span>
          <span>{info}</span>
        </div>
      }
      <Badge  style={{marginLeft: "auto"}} theme={statusOptions[status]?.theme || statusOptions["DEFAULT"]?.theme}>
        {status || "WAITING_FOR_UPLOAD"}
      </Badge>           
      <div className='actions'>
        <ContextMenu.Menu>
          {url && 
          <>
            {accept && status !== "ACCEPTED" && <ContextMenu.Button className='valid' onClick={accept} icon="check"><IntlText id="accept" /></ContextMenu.Button>}
            {status === "REJECTED" && editMessage && <ContextMenu.Button onClick={() => messageModal?.show()} icon="comment"><IntlText group="verified-document" id="edit-message" /></ContextMenu.Button>}
            {reject && status !== "REJECTED" && <ContextMenu.Button className='invalid' onClick={_reject} icon="times"><IntlText id="reject" /></ContextMenu.Button>}
          </>
          }
        </ContextMenu.Menu>
      </div>           
      {editMessage && 
        <>
          <MessageModal modal={messageModal} message={message} edit={editMessage} />
          <RejectModal modal={rejectModal} message={message} reject={reject} />
        </>
      }      
    </div>    
  )
}

function RejectModal({modal, message, reject}){

  const { getTranslation } = useContext(IntlContext)
  const [_message, setMessage] = useState(message)

  function save(){
    try {
      reject(_message)
      modal.hide()
    } catch (error) {
      console.log("Error", error);
    }
  }        

  function hideModal(){
    setMessage(message)
    modal.hide()
  }        

  return(
    <Modalv3 modal={modal} header={<Title tag="h3"><IntlText group="verified-document" id="edit-message" /></Title>} onHide={() => modal.hide()}>
      <LabeledInput placeholder={getTranslation({group: "verified-document", id: "message"})} value={_message} onChange={(e) => setMessage(e.target.value)}/>
      <div className='actions' style={{marginTop: 15}}>
        <Button onClick={hideModal}><IntlText id="cancel" /></Button>
        <Button theme="main" onClick={save}><IntlText id="save" /></Button>
      </div>
    </Modalv3>             
  )    
}

function MessageModal({modal, message, edit}){

  const { getTranslation } = useContext(IntlContext)
  const [_message, setMessage] = useState(message)

  function save(){
    try {
      edit(_message)
      modal.hide()
    } catch (error) {
      console.log("Error", error);
    }
  }        

  function hideModal(){
    setMessage(message)
    modal.hide()
  }        

  return(
    <Modalv3 modal={modal} header={<Title tag="h3"><IntlText group="verified-document" id="edit-message" /></Title>} onHide={() => modal.hide()}>
      <LabeledInput placeholder={getTranslation({group: "verified-document", id: "message"})} value={_message} onChange={(e) => setMessage(e.target.value)}/>
      <div className='actions' style={{marginTop: 15}}>
        <Button onClick={hideModal}><IntlText id="cancel" /></Button>
        <Button theme="main" onClick={save}><IntlText id="save" /></Button>
      </div>
    </Modalv3>             
  )    
}