import { gql }from '@apollo/client'

import { VENDOR_USER_FRAGMENT } from './'

export const CREATE_VENDOR_USER = gql`
  mutation createVendorUser($data: VendorUserInput!){
    createVendorUser(data: $data){
      ...VENDOR_USER_FRAGMENT
    }
  }
  ${VENDOR_USER_FRAGMENT}
`
