import { gql }from '@apollo/client'

import { ADDON_OPTION_FRAGMENT } from './'

const CREATE_ADDON_OPTION_MUTATION = gql`
  mutation createProductAddonOption($data: ProductAddonOptionInput!){
    createProductAddonOption(data: $data){
      ...ADDON_OPTION_FRAGMENT
    }
  }
  ${ADDON_OPTION_FRAGMENT}
`

export default CREATE_ADDON_OPTION_MUTATION
