import { gql } from '@apollo/client'

import { ADMIN_FRAGMENT } from '../admin'

const LOGIN_MUTATION = gql`
  mutation login($uid: String!, $password:String!){
    login(uid: $uid, password:$password, role:ADMIN){
      token
      user{
        ...ADMIN_FRAGMENT
      }
    }
  }
  ${ADMIN_FRAGMENT}
`

export default LOGIN_MUTATION
