import React, { useState } from 'react'
import { useMutation } from '@apollo/client'

import { EDIT_PRODUCT_MUTATION } from '../../../graphql'
import { useConfirm, useIntl } from '../../../hooks'
import { formatPrice, toast } from '../../../helpers'
import { Button, IntlText, LabeledInput, Actions, SegmentedControl, Checkbox, InfoText } from '../../../components'
import { ProductPrice } from '../../../views'

export function PurchaseProductCustomPrice({product, vendor, price, setPrice, save, reset}){

  const { confirm } = useConfirm()
  const { getTranslation } = useIntl()
  const [editProduct, { loading }] = useMutation(EDIT_PRODUCT_MUTATION)
  const [extraPrice, setExtraPrice] = useState(null)
  const [updateCurrent, toggleUpdate] = useState(false)
  const [priceType, setPriceType] = useState("price")
  const _options = [
    {id: "price", label: "Price"}, 
    {id: "add-extra-price", label: "Add extra price"}
  ]

  async function savePrice(){
    const alert = toast.loading()
    try {
      save()
      if (updateCurrent){
        alert.update(getTranslation({group: "confirm", id: "confirm-to-continue"}))
        await confirm({title: getTranslation({group: "purchase-product-custom-price", id: "change-confirm"}),desc: getTranslation({group: "purchase-product-custom-price", id: "change-confirm-info", variables: {price}})})
        alert.update(getTranslation({id: "loading"}))
        await editProduct({variables: {id: product.id, data: {price: parseInt(price)}}})
        alert.success(getTranslation({group: "purchase-product-custom-price", id: "change-success"}))
      } else {
        alert.hide()
      }
    } catch (error) {
      alert.apolloError(error)
    }
  }

  function updatePrice(prc){
    setExtraPrice(prc)
    setPrice(price)
  }

  async function calculateClientPriceWithAddon(){
    const _extraPrice = extraPrice/(1-vendor.serviceFee)
    setExtraPrice(extraPrice)
    setPrice(parseFloat(price)+parseFloat(_extraPrice))
    setPriceType("price")
  }

  return(
    <div>
      <div style={{display: "flex", justifyContent: "center", marginBottom: 15}}>
        <SegmentedControl value={priceType} options={_options} onClick={(_option) => setPriceType(_option.id)}/>          
      </div>
      {priceType === "price" &&
        <div>
          <ProductPrice vendor={vendor} price={price} setPrice={setPrice} />
          <Checkbox style={{marginTop: 15}} id="update-current" checked={updateCurrent} onChange={() => toggleUpdate(!updateCurrent)}>
            <InfoText info={<IntlText group='purchase-product-custom-price' id='update-current-price-info' />}><IntlText group='purchase-product-custom-price' id='update-current-price' /></InfoText>
          </Checkbox>
        </div>
      }
      {priceType === "add-extra-price" &&      
        <div>
          <div style={{display: "flex", justifyContent: "center", marginBottom: 15}} >
            <IntlText group="purchase-product-custom-price" id="add-extra-price" />
          </div>          
          <span className='current-price' style={{display: "flex", flexDirection: "column", alignItems: "center", marginBottom: 15}}>
            <span style={{fontSize: "var(--font-l)", fontWeight: "bold"}}>{formatPrice(parseFloat(price)+parseFloat(extraPrice/(1-vendor.serviceFee)))}</span>
            <span style={{fontSize: "var(--font-s)", color: "var(--ui-placeholder)"}}><IntlText group="purchase-product-custom-price" id="current-price" /></span>
          </span>
          <LabeledInput id="custom-price" type="number" onChange={({target}) => updatePrice(target.value)} placeholder={getTranslation({group: "purchase-product-custom-price", id:"add-addon-price"})} value={extraPrice} />
          <Button style={{marginTop: 15}} fluid onClick={calculateClientPriceWithAddon} ><IntlText group="purchase-product-custom-price" id="update-price" /></Button>
        </div>
      }
      {priceType === "price" &&
        <Actions style={{marginTop: 20}}>
          <Button onClick={reset}><IntlText id="reset" /></Button>
          <Button loading={loading} theme="main" onClick={savePrice}><IntlText id="save" /></Button>
        </Actions>
      }
    </div>
  )
}