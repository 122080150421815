import { gql } from '@apollo/client'

import { ADDRESS_FRAGMENT } from '../fragments'

const ADDRESSES_QUERY = gql`
  query addresses($owner: String!){
     addresses(owner: $owner){
       ...ADDRESS_FRAGMENT
     }
  }
  ${ADDRESS_FRAGMENT}
`

export default ADDRESSES_QUERY
