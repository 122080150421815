import React from 'react'
import moment from 'moment'
import { useMutation } from '@apollo/client'

import { PROCESS_RIDER_MODIFICATION_MUTATION, DELETE_RIDER_MODIFICATION_MUTATION } from '../../../graphql'
import { useConfirm, useIntl } from '../../../hooks'
import { formatPrice, toast } from '../../../helpers'
import { ContextMenu, ConfirmButtons, StatusDot, Table } from '../../../components'

export function ModificationsList({data, refetch}) {

  const { confirm } = useConfirm()
  const { getTranslation } = useIntl()
  const [processModification] = useMutation(PROCESS_RIDER_MODIFICATION_MUTATION)
  const [remove] = useMutation(DELETE_RIDER_MODIFICATION_MUTATION)

  async function _delete(id){
    const alert = toast.loading(getTranslation({group: "confirm", id: "confirm-to-continue"}))
    try {
      await confirm()
      alert.update(getTranslation({id: "loading"}))
      await remove({variables: {id}})
      alert.success(getTranslation({id: "delete-success"}))
      refetch()
    } catch (error) {
      alert.apolloError(error)
    }
  }    

  return(
    <Table.Table>
      <Table.Head>
        <Table.Tr>
          <Table.Th></Table.Th>
          <Table.Th className="text-left">Rider</Table.Th>
          <Table.Th className="text-left">Vendor</Table.Th>
          <Table.Th className="text-left">Customer</Table.Th>
          <Table.Th>Note</Table.Th>
          <Table.Th>Amount</Table.Th>
          <Table.Th>Reason</Table.Th>
          <Table.Th>Date</Table.Th>
          <Table.Th></Table.Th>
          <Table.Th></Table.Th>
        </Table.Tr>
      </Table.Head>
      <Table.Body>
        {data.length > 0 ? data.map((modification, key) =>
          <Table.Tr key={key} className="modification">
            <Table.Td><StatusDot active={modification.status === "APPROVED"} pending={modification.status === "PENDING"}/></Table.Td>
            <Table.Td className="text-left">{modification.rider?.name}</Table.Td>
            <Table.Td className="text-left">{modification?.purchase?.vendorName}</Table.Td>
            <Table.Td className="text-left">{modification?.purchase?.customerName}</Table.Td>
            <Table.Td>{modification?.note}</Table.Td>
            <Table.Td>{formatPrice(modification.value)}</Table.Td>
            <Table.Td>{modification.reason}</Table.Td>
            <Table.Td>{moment(modification.createdAt).format('DD/MM/YYYY HH:mm')}</Table.Td>
            <Table.Td>
              {(modification.status !== "APPROVED" && modification.status !== "REJECTED") &&
                <ConfirmButtons approve={() => processModification({variables: {id: modification.id, status: "APPROVED"}})} reject={() => processModification({variables: {id: modification.id, status: "REJECTED"}})} />
              }
            </Table.Td>
            <Table.Td>
              <ContextMenu.Menu id={modification?.id}>
                <ContextMenu.Link to={`/modifications/${modification.id}`} icon="external-link-alt">View & Edit</ContextMenu.Link>
                <ContextMenu.Button onClick={() => _delete(modification.id)} theme='invalid' icon="trash">Delete</ContextMenu.Button>
              </ContextMenu.Menu>
            </Table.Td>
          </Table.Tr>
        ) :
          <Table.Tr>
            <Table.Td colSpan={10}>There aren't any items to show, try to search</Table.Td>
          </Table.Tr>
        }
      </Table.Body>
    </Table.Table>
  )
}
