import React, { useState, useContext } from 'react'
import { useQuery } from '@apollo/client'

import { PageList } from '../../views'
import { Loader, Filter, Wrapper, Filters } from '../../components'
import { IntlContext } from '../../contexts'
import { useQueryBuilder } from '../../hooks'
import { PAGES_QUERY } from '../../graphql'

export function Pages(){

  const [filter, setFilter] = useState()
  const { getTranslatedValue } = useContext(IntlContext)
  const {getParams, clearParams, removeParam, buildQuery } = useQueryBuilder()
  const { loading, error, data } = useQuery(PAGES_QUERY, {variables: buildQuery()})

  if (error) return `Error! ${error.message}`

  return(
    <Wrapper>
      <div id="pages" className="view">
        <div className="row padding">
          <Filters.Bar>
            <Filters.Input id="query" name="query" value={filter} onChange={({target}) => setFilter(target.value)} reset={() => setFilter("")} placeholder="Type here for searching" />
          </Filters.Bar>
          <Filters.Bar>
            <Filters.ActiveParams activeParams={getParams()} removeParam={removeParam} clearParams={clearParams}/>
          </Filters.Bar>
          <div id="pages-list" className="relative">
            {(loading) && <Loader theme="main" overlay/>}
            <Filter data={data?.pages || []} search={[{key: "search", value: filter}]} filters={[{filter: (data) => getTranslatedValue(data.title)}]} sort={(a, b) => new Date(a.createdAt) - new Date(b.createdAt)} >
              {(data) =>
                <PageList data={data}/>
              }
            </Filter>
          </div>
        </div>
      </div>
    </Wrapper>
  )
}
