import { gql } from '@apollo/client'

import { PURCHASE_FRAGMENT } from './'

export const CORRECT_ADDRESS = gql`
  mutation correctAddress($id: ID!, $address: EditedAddressInput, $addressId: ID, $updateOriginal: Boolean){
    correctAddress(id: $id, address: $address, addressId: $addressId, updateOriginal: $updateOriginal){
      ...PURCHASE_FRAGMENT
    }
  }
  ${PURCHASE_FRAGMENT}
`
