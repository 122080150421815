import React, { useState } from 'react'

import './style.css'

export function LabeledInput({id, type = "text", value = "", placeholder, disabled, onFocus, onBlur, ...rest}){
  const [focused, toggle] = useState(false)

  function _onFocus(){
    if (onFocus) onFocus()
    toggle(true)
  }

  function _onBlur(){
    if (onBlur) onBlur()
    toggle(false)
  }

  return(
    <div id={`${id}-input`} className={`labeled-input ${focused ? "focused" : ""} ${value !== "" ? "populated" : ""} ${disabled ? "disabled" : ""}`}>
      <label>
        <input
          id={id}
          type={type}
          value={value || ""}
          placeholder={placeholder}
          disabled={disabled}
          onFocus={() => _onFocus(true)}
          onBlur={() => _onBlur(true)}
          {...rest}
          />
        <span>{placeholder}</span>
      </label>
    </div>
  )
}
