import { gql }from '@apollo/client'

import { PRODUCT_ADDON_FRAGMENT } from './'

const CREATE_PRODUCT_ADDON_MUTATION = gql`
  mutation createProductAnnexAddon($data: ProductAnnexAddonInput!){
    createProductAnnexAddon(data: $data){
      ...PRODUCT_ADDON_FRAGMENT
    }
  }
  ${PRODUCT_ADDON_FRAGMENT}
`

export default CREATE_PRODUCT_ADDON_MUTATION
