import { gql } from '@apollo/client'

import { CUSTOMER_BOOK_FRAGMENT } from './'

export const CUSTOMER_BOOK = gql`
  query customerBook($customer: ID){
     customerBook(customer: $customer, ) {
       ...CUSTOMER_BOOK_FRAGMENT
     }
  }
  ${CUSTOMER_BOOK_FRAGMENT}
`
