import { gql }from '@apollo/client'

const DELETE_ADDON_MUTATION = gql`
  mutation deleteProductAddon($id: ID!){
    deleteProductAddon(id: $id){
      success
    }
  }
`

export default DELETE_ADDON_MUTATION
