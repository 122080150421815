import { gql } from '@apollo/client'

import { PAGE_FRAGMENT } from './'

export const CREATE_PAGE_MUTATION = gql`
  mutation createPage($data: PageInput!){
    createPage(data: $data){
      ...PAGE_FRAGMENT
    }
  }
  ${PAGE_FRAGMENT}
`
