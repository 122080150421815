import { gql } from '@apollo/client'

export const FAQ_FRAGMENT = gql`
  fragment FAQ_FRAGMENT on FaqItem {
    id
    slug
    topic
    title{
      en
      es
    }
    content{
      en
      es
    }
    rank
    hidden
    whatsappButton
    tags
  }
`
