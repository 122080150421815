import React from 'react'
import FontAwesome from 'react-fontawesome'

import "./Checkbox.css"

export default function Checkbox({id, checked, onChange, label, disabled, children, ...rest}){

  return(
    <div className="checkbox" {...rest}>
      <input id={id} type="checkbox" disabled={disabled} checked={checked} onChange={onChange}/>
      <label htmlFor={id}>
        {disabled ? <FontAwesome name="times" /> : <span></span>}
        {(label || children) && <div className="label">{label || children}</div>}
      </label>
    </div>
  )
}
