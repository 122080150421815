import { gql } from '@apollo/client'

import { RIDER_FRAGMENT } from '../rider'

const RIDERS_QUERY = gql`
  query riders($active: Boolean, $type: RIDER_TYPE, $sort: RIDER_SORT_ORDER){
     riders(active: $active, type: $type, sort: $sort){
       ...RIDER_FRAGMENT
     }
  }
  ${RIDER_FRAGMENT}
`

export default RIDERS_QUERY
