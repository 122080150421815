import { useMutation, useQuery } from '@apollo/client'
import { useParams, Routes, Route } from 'react-router-dom'
import { toast } from 'react-toastify'

import { VENDOR_QUERY, EDIT_VENDOR_MUTATION } from '../../graphql'
import { useIntl } from '../../hooks'
import { IntlText, Wrapper, SidebarMenu, Sidebar } from '../../components'
import { VendorUser, VendorUsers, VendorInsights, VendorInsight, VendorPurchases, VendorContactDetails, VendorPackage, VendorInvoicingDetails, VendorInvoice, VendorNewInvoice, Promotion, NewPromotion, VendorDeliverySettings, VendorInvoices, VendorFeedbacks, VendorAddons, VendorAddonOptions, VendorBalance, VendorBalanceHistory, VendorSettings, VendorMedia, VendorDetails, VendorReset, VendorTagEditor, VendorPromotions, OpeningHours, VendorPaymentSettings, Preview, VendorLocation, CategoriesList, ProductsList } from '../../views'

export function Vendor() {

  const { id } = useParams()
  const { getTranslation } = useIntl()
  const [editVendor, { loading: editLoading }] = useMutation(EDIT_VENDOR_MUTATION)
  const { data, loading, error, refetch } = useQuery(VENDOR_QUERY, { variables: {id}})

  async function _editVendor(_data){
    try {
      await editVendor({variables: {id, data: _data}})
      return toast.success(getTranslation({id: "save-success"}))
    } catch (e) {
      if (e.graphQLErrors) e.graphQLErrors.map(x => toast.error(x.message))
      console.log("Error: ", e)
    }
  }

  return(
    <Wrapper loading={loading || error}>
      <div id="vendor" className="container">
        <div className="row padding">
          <Preview vendor={data?.vendor}/>    
        </div>              
        <div className="row padding">
          <Sidebar.View>
            <Sidebar.Menu style={{maxWidth: 280}} size={1}>
              <SidebarMenu.Menu bordered rounded>
                <SidebarMenu.Link to="" exact="true"><IntlText group="vendor" id="tab-menu-details" /></SidebarMenu.Link>
                <SidebarMenu.Link to="balance"><IntlText group="vendor" id="tab-menu-balance" /></SidebarMenu.Link>
                <SidebarMenu.Link to="orders"><IntlText group="vendor" id="tab-menu-orders" /></SidebarMenu.Link>
                <SidebarMenu.Link to="insights"><IntlText group="vendor" id="tab-menu-insights" /></SidebarMenu.Link>
                <SidebarMenu.Link to="feedbacks"><IntlText group="vendor" id="tab-menu-feedbacks" /></SidebarMenu.Link>
                <SidebarMenu.Link to="package"><IntlText group="vendor" id="tab-menu-package" /></SidebarMenu.Link>
                <SidebarMenu.Link to="invoicing"><IntlText group="vendor" id="tab-menu-invoicing-details" /></SidebarMenu.Link>
                <SidebarMenu.Link to="settings"><IntlText group="vendor" id="tab-menu-settings" /></SidebarMenu.Link>
                <SidebarMenu.Link to="location"><IntlText group="vendor" id="tab-menu-location" /></SidebarMenu.Link>
                <SidebarMenu.Link to="payment"><IntlText group="vendor" id="tab-menu-payment-settings" /></SidebarMenu.Link>
                <SidebarMenu.Link to="delivery"><IntlText group="vendor" id="tab-menu-delivery-settings" /></SidebarMenu.Link>
                <SidebarMenu.Link to="contact"><IntlText group="vendor" id="tab-menu-contact-details" /></SidebarMenu.Link>
                <SidebarMenu.Link to="opening-hours"><IntlText group="vendor" id="tab-menu-opening-hours" /></SidebarMenu.Link>
                <SidebarMenu.Link to="tags"><IntlText group="vendor" id="tab-menu-tags" /></SidebarMenu.Link>
                <SidebarMenu.Link to="media"><IntlText group="vendor" id="tab-menu-media" /></SidebarMenu.Link>
                <SidebarMenu.SubMenu label={<IntlText group="vendor" id="tab-menu-invoices" />} path="/vendors/:id/invoices">
                  <SidebarMenu.Link to="invoices"><IntlText group="vendor" id="tab-menu-all-invoices" /></SidebarMenu.Link>
                  <SidebarMenu.Link to="invoices/new"><IntlText group="vendor" id="tab-menu-new-invoice" /></SidebarMenu.Link>
                </SidebarMenu.SubMenu>                
                <SidebarMenu.SubMenu label={<IntlText group="vendor" id="tab-menu-promotions" />} path="/vendors/:id/promotions">
                  <SidebarMenu.Link to="promotions"><IntlText group="vendor" id="tab-menu-all-promotions" /></SidebarMenu.Link>
                  <SidebarMenu.Link to="promotions/new"><IntlText group="vendor" id="tab-menu-new-promotion" /></SidebarMenu.Link>
                </SidebarMenu.SubMenu>                
                <SidebarMenu.SubMenu label={<IntlText group="vendor" id="tab-menu-menu" />} path="/vendors/:id/products">
                  <SidebarMenu.Link to="categories"><IntlText group="vendor" id="tab-menu-categories" /></SidebarMenu.Link>
                  <SidebarMenu.Link to="products"><IntlText group="vendor" id="tab-menu-products" /></SidebarMenu.Link>
                  <SidebarMenu.Link to="addons">Addons</SidebarMenu.Link>
                  <SidebarMenu.Link to="addon-options">Options</SidebarMenu.Link>
                </SidebarMenu.SubMenu>                
                <SidebarMenu.Link to="users"><IntlText group="vendor" id="tab-menu-users" /></SidebarMenu.Link>
                <SidebarMenu.Link to="reset"><IntlText group="vendor" id="tab-menu-reset" /></SidebarMenu.Link>
              </SidebarMenu.Menu>
            </Sidebar.Menu>
            <Sidebar.Content size={3}>
              <Routes>
                <Route path="" element={<VendorDetails vendor={data?.vendor} />} />
                <Route path="package" element={<VendorPackage vendor={data?.vendor} />} />
                <Route path="balance" element={
                  <>
                    <VendorBalance vendor={data?.vendor?.id} />
                    <VendorBalanceHistory vendor={data?.vendor?.id} />                  
                  </>
                }></Route>
                <Route path="opening-hours" element={<OpeningHours openingHours={data?.vendor?.openingHours} onChange={_editVendor} loading={editLoading} />} />
                <Route path="location" element={<VendorLocation pickupInformation={data?.vendor?.pickupInformation} location={data?.vendor?.location} onChange={_editVendor} loading={editLoading} />} />
                <Route path="settings" element={<VendorSettings vendor={data?.vendor} />} />
                <Route path="payment" element={<VendorPaymentSettings vendor={data?.vendor} />} />
                <Route path="delivery" element={<VendorDeliverySettings vendor={data?.vendor} />} />
                <Route path="feedbacks" element={<VendorFeedbacks vendor={data?.vendor} />} />
                <Route path="categories" element={<CategoriesList vendor={data?.vendor} />} />
                <Route path="products" element={<ProductsList vendor={data?.vendor} />} />
                <Route path="addons" element={<VendorAddons vendor={data?.vendor} />} />
                <Route path="addon-options" element={<VendorAddonOptions vendor={data?.vendor} />} />
                <Route path="media" element={<VendorMedia vendor={data?.vendor} />} />
                <Route path="tags" element={<VendorTagEditor vendor={data?.vendor} />} />
                <Route path="insights" element={<VendorInsights vendor={data?.vendor} />} />
                <Route path="insights/:id" element={<VendorInsight vendor={data?.vendor} />} />
                <Route path="orders/*" element={<VendorPurchases />} />
                <Route path="promotions" element={<VendorPromotions vendor={data?.vendor} refetch={refetch} loading={loading}/>} />
                <Route path="promotions/new" element={<NewPromotion />} />
                <Route path="promotions/:id" element={<Promotion />} />
                <Route path="contact" element={<VendorContactDetails vendor={data?.vendor} />} />
                <Route path="invoicing" element={<VendorInvoicingDetails vendor={data?.vendor}  />} />
                <Route path="invoices" element={<VendorInvoices vendor={data?.vendor} />} />
                <Route path="invoices/new" element={<VendorNewInvoice />} />
                <Route path="invoices/:invoiceId" element={<VendorInvoice />} />
                <Route path="users">
                  <Route path="" element={<VendorUsers vendor={data?.vendor} />} />
                  <Route path=":id" element={<VendorUser />} />
                </Route>      
                <Route path="reset" element={<VendorReset vendor={data?.vendor} />} />
              </Routes>
            </Sidebar.Content>
          </Sidebar.View>
        </div>
      </div>
    </Wrapper>
  )
}
