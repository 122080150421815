import { gql } from '@apollo/client'

import { RIDER_PAYOUT_FRAGMENT } from './'

export const UPLOAD_RIDER_PAYOUT_INVOICE = gql`
  mutation uploadRiderPayoutInvoice($file: Upload!, $id: ID){
    uploadRiderPayoutInvoice(file: $file, id: $id){
      ...RIDER_PAYOUT_FRAGMENT
    }
  }
  ${RIDER_PAYOUT_FRAGMENT}
`
