import { toast } from 'react-toastify'

export function returnGraphqlError(e) {
  if (!e.graphQLErrors) return console.log("Error: ", e)
  e.graphQLErrors.map(x => console.log(`Error: ${x.message}`))
}

export function error(_error){
  if (_error?.graphQLErrors?.length > 0){
    return _error.graphQLErrors.map(x => toast.error(x.message))
  }

  if (_error.message){
    return toast.error(_error.message)
  }

  console.log("Error: ", _error)
}

export function showError(_error){
  if (_error?.graphQLErrors?.length > 0){
    return _error.graphQLErrors.map(x => toast.error(x.message))
  }

  if (_error.message){
    return toast.error(_error.message)
  }

  console.log("Error: ", _error)
}