import { gql } from '@apollo/client'

export const SAVED_CARDS = gql`
  query savedStripeCards($customer: ID){
     savedStripeCards(customer: $customer){
        id
        card{
          brand
          exp_month
          exp_year
          last4
        }
        created
        canBeUsed
        hasExpired
     }
  }
`
