import React, { useContext } from 'react'
import { toast } from 'react-toastify'
import { useMutation } from '@apollo/client'
import { useNavigate } from 'react-router-dom'

import { useValidatedForm } from '../../../hooks'
import { showError } from '../../../helpers'
import { CREATE_RIDER_VEHICLE, VEHICLES } from '../../../graphql'
import { IntlContext } from '../../../contexts'
import { SelectV2, Validation, Form, Button, IntlText, LabeledInput } from '../../../components'

export function RiderNewVehicle({rider}){
  const navigate = useNavigate()
  const { getTranslation } = useContext(IntlContext)
  const [createVehicle, { loading }] = useMutation(CREATE_RIDER_VEHICLE, {refetchQueries: [{query: VEHICLES, variables: {owner: rider?.id}}]})
  const types = ["MOTORBIKE", "AUTOMOBILE", "BICYCLE"]
  const validations = {
    maker: [{id: "required", validation: (val) => !!val}],
    model: [{id: "required", validation: (val) => !!val}],
    type: [{id: "required", validation: (val) => !!val}],
  }     
  const { form: {maker, model, type, alias}, updateField, validate, errors } = useValidatedForm({}, validations)

  async function _createVehicle(){
    try {
      let { valid } = validate()

      if (!valid) return toast.error(getTranslation({id: "form-error"}))

      const data = {
        alias,
        maker,
        model,
        type,
        owner: rider?.id
      }

      await createVehicle({variables: {data}})
      toast.success(getTranslation({id: "create-success"}))
      navigate("../")
    } catch (error) {
      showError(error)
    }
  }

  return(
    <div id="rider-vehicles">
      <Form.Form>
        <Form.Field>
          <LabeledInput name="alias" placeholder={getTranslation({group: "rider-vehicle", id:"alias"})} value={alias} onChange={({target}) => updateField({key: target.name, value: target.value})} />
        </Form.Field>
        <Form.Field>
          <Validation errors={errors.maker}>
            <LabeledInput name="maker" placeholder={getTranslation({group: "rider-vehicle", id:"maker"})} value={maker} onChange={({target}) => updateField({key: target.name, value: target.value})} />
          </Validation>
        </Form.Field>
        <Form.Field>
          <Validation errors={errors.model}>
            <LabeledInput name="model" placeholder={getTranslation({group: "rider-vehicle", id:"model"})} value={model} onChange={({target}) => updateField({key: target.name, value: target.value})} />
          </Validation>
        </Form.Field>
        <Form.Field>
          <Validation errors={errors.type}>
            <SelectV2 value={(_value) => <IntlText group="rider-vehicle" id={_value} />} placeholder={getTranslation({group: "rider-vehicle", id:"type"})} selected={types?.find(_t => _t === type)} defaultValue={getTranslation({id: "please-select"})} options={types} onChange={(_type) => updateField({key: "type", value:  _type})} />
          </Validation>
        </Form.Field>
        <Form.Field fluid>
          <Button fluid loading={loading} theme="main" onClick={_createVehicle}><IntlText id="create" /></Button>
        </Form.Field>
      </Form.Form>
    </div>
  )

}
