import { gql } from '@apollo/client'

import { ANNOUNCEMENT_FRAGMENT } from './'

export const EDIT_ANNOUNCEMENT = gql`
  mutation editAnnouncement($id: ID!, $data:EditedAnnouncementInput!){
    editAnnouncement(id: $id, data:$data){
      ...ANNOUNCEMENT_FRAGMENT
    }
  }
  ${ANNOUNCEMENT_FRAGMENT}
`
