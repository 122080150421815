import moment from 'moment'
import 'moment/locale/es'

import { useIntl, useValidatedForm } from '../hooks'
import { Wrapper, Form, EditBox, Button } from '../components'
import { SelectRider } from '../views'

export function ActaGenerator() {

  const { form, updateField } = useValidatedForm({})
  const { getTranslation } = useIntl()


  function printText() {
    try {
      const printWindow = window.open('', '_blank')
      moment.locale('es')
      printWindow.document.write(`
        <html>
        <head>
          <title>ACTA ADMINISTRATIVA</title>
          <style>
            body { font-family: Arial, sans-serif; font-size: 14px }
            p {line-height: 1.4}
            pre { font-size: 14px; }
            h1 {text-align: center; font-size: 18px; margin-bottom: 40px}
            ul, ul li {list-style: none}
          </style>
        </head>
        <body>
          <h1>ACTA ADMINISTRATIVA</h1>
          <p>En la Ciudad de Tulum, Quintana Roo, siendo las <b>22:00 horas del día ${moment(form.dateOfAbsence).format('D [de] MMMM [de] YYYY')}</b>, en las instalaciones de las oficinas de “CONSTRUCCIONES Y TERRACERIAS MESANEX, S.A. DE C.V.”, en su calidad de patrón, se levanta la presente acta administrativa al trabajador: C. ${form.rider?.fullName} encontrándose presentes en dicho acto el C. LUIS GERARDO ALBA PEREZ, en su carácter de representante de la parte patronal, y los C. RODOLFO VAZQUEZ HERNANDEZ y C. NORBERT SZUCS ambos en su carácter de testigos de los hechos de la presente acta. Se hace constar que El “Trabajador” presta sus servicios como OPERATIVO; y a continuación se describen las faltas en las que ha incurrido:</p>
          <p><u>El trabajador en cuestión no se presentó a laborar el <b>${moment(form.dateOfAbsence).format('D [de] MMMM [de] YYYY')}</b> sin motivo valido, para justificar sus acciones.</u></p>
          <p>Lo anterior, pone en riesgo a la empresa pudiendo ocasionar daños y perjuicios, afectando la reputación y trayendo daños económicos y repercutiendo así a todos los colaboradores de la empresa, en consecuencia, no cumple con lo señalado dentro de los marcos que especifica la Ley Federal del Trabajo en su Artículo 47, numeral X y que a continuación se cita, y que son causas de rescisión de contrato sin responsabilidad para el patrón.</p>
          <ul><li>X. Tener el trabajador más de 3 faltas de asistencia en un periodo de treinta días, sin permiso del patrón o causas justificadas.</li></ul>
          <p>Con base en lo anterior el trabajador C. ${form.rider?.fullName} que se desempeña como operador administrativo acepta el haber incurrido en este tipo de comportamiento, y acepta las medidas disciplinarias que correspondan, aceptando que al presentar una falta de que amerite acta administrativa, se procederá a la baja inmediata sin responsabilidad para el Patrón.</p>
          <p>Para constancia de lo anteriormente plasmado, se firma en la ciudad de Tulum, Quintana Roo en fecha <b>${moment(form.dateOfSignature).format('D [de] MMMM [de] YYYY')}</b> a las 16:00 horas, lo firman de enterado cada uno de los presentes para los efectos legales a que haya lugar.</p>
          <div style="display: flex">
            <div style="flex: 1; text-align: center; align-items: center; display: flex; flex-direction: column;">
              <p style="margin-bottom: 60px"><b>“EL PATRÓN“</b></p>
              <div style="width: 60%; border-bottom: 1px solid #000"></div>
              <p><b>LUIS GERARDO ALBA PEREZ</b></p>
              <p><b>REPRESENTANTE LEGAL DE CONSTRUCCIONES Y TERRACERIAS MESANEX, S.A. DE C.V.</b></p>
            </div>
            <div style="flex: 1; text-align: center; align-items: center; display: flex; flex-direction: column;">
              <p style="margin-bottom: 60px"><b>“EL TRABAJADOR“</b></p>
              <div style="width: 60%; border-bottom: 1px solid #000"></div>
              <p><b>C. ${form.rider?.fullName}</b></p>
            </div>
          </div>
          <div style="display: flex">
          <div style="flex: 1; text-align: center; align-items: center; display: flex; flex-direction: column;">
            <p style="margin-bottom: 60px"><b>TESTIGO</b></p>
            <div style="width: 60%; border-bottom: 1px solid #000"></div>
          </div>
          <div style="flex: 1; text-align: center; align-items: center; display: flex; flex-direction: column;">
            <p style="margin-bottom: 60px"><b>TESTIGO</b></p>
            <div style="width: 60%; border-bottom: 1px solid #000"></div>
          </div>
        </div>
        </body>
        </html>
      `)
      printWindow.document.close()
      printWindow.print()
      printWindow.close()
      
    } catch (error) {
      console.error('Failed to read from clipboard: ', error);
    }
}

  return(
    <Wrapper>
      <div id="acta-generator" className="container">
        <div className="row padding">
          <Form.Form>
            <Form.Field>
              <SelectRider rider={{value: form?.rider?.id}} onSelect={(_rider) => updateField({key: "rider", value: _rider})}/>            
            </Form.Field>
            <Form.Field>
              <EditBox.Date placeholder={getTranslation({group: "acta-generator", id: "dateOfAbsence"})} save={(value) => updateField({key: "dateOfAbsence", value})}>{form.dateOfAbsence}</EditBox.Date>
            </Form.Field>
            <Form.Field>
              <EditBox.Date placeholder={getTranslation({group: "acta-generator", id: "dateOfSignature"})} save={(value) => updateField({key: "dateOfSignature", value})}>{form.dateOfSignature}</EditBox.Date>
            </Form.Field>
            <Form.Field>
              <Button fluid onClick={printText} theme='main'>Print</Button>
            </Form.Field>
          </Form.Form>
        </div>
      </div>
    </Wrapper>
  )
}
