import { gql } from '@apollo/client'

import { DAY_CLOSING_SNAPSHOT } from './'

export const DAY_CLOSING = gql`
  query dayClosing($date: String){
    dayClosing(date: $date){
      ...DAY_CLOSING_SNAPSHOT
    }
  }
  ${DAY_CLOSING_SNAPSHOT}
`
