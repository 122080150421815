import React, { useContext } from 'react'
import { useMutation } from '@apollo/client'
import { toast } from 'react-toastify'

import { error } from '../../../helpers'
import { useValidatedForm } from '../../../hooks'
import { IntlContext } from '../../../contexts'
import { MAKE_PURCHASE_DISCOUNT } from '../../../graphql'
import { IntlText, Button, LabeledInput, Form, Validation } from '../../../components'

export function PurchaseAddDiscount({purchase}){

    const { getTranslation } = useContext(IntlContext)
    const [addDiscount, { loading }] = useMutation(MAKE_PURCHASE_DISCOUNT)
    const validations = {
      discount: [{id: "min0", validation: (val) => val > 0}],
    }
    const { form: { discount }, validate, updateField, errors } = useValidatedForm({}, validations)  
  
    async function _addDiscount(){
      try {
        let { valid } = validate()
  
        if (!valid) return toast.error(getTranslation({group: "form", id: "error"}))
    
        addDiscount({variables: {id: purchase.id, discount: parseFloat(discount)}})
      } catch (e) {
        error(e)
      }
    }
  
    return(
      <div>
        <Form.Form>
          <Form.Field>
            <Validation errors={errors["discount"]}>
              <LabeledInput type="number" placeholder={getTranslation({group: "purchase-add-discount", id: "discount"})} value={discount} onChange={({target}) => updateField({key: "discount", value: target.value})} />
            </Validation>
          </Form.Field>
          <Form.Field>
            <Button loading={loading} fluid theme="main" onClick={_addDiscount}><IntlText group="purchase-add-discount" id="add-discount" /></Button>
          </Form.Field>
        </Form.Form>
      </div>
    )
  }
  