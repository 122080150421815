import { gql }from '@apollo/client'

const DELETE_ADDON_OPTION_MUTATION = gql`
  mutation deleteProductAddonOption($id: ID!){
    deleteProductAddonOption(id: $id){
      success
    }
  }
`

export default DELETE_ADDON_OPTION_MUTATION
