import React from 'react'
import { useQuery } from '@apollo/client'
import { useLocation } from 'react-router-dom'

import { useQueryBuilder } from '../../hooks'

import { CustomersList } from '../../views'
import { PAGINATED_CUSTOMERS_QUERY } from '../../graphql'
import { Wrapper, Filters, IntlText, Pagination } from '../../components'

export function Customers() {

  const location = useLocation()
  const search = new URLSearchParams(location.search)
  const { params, addParam, removeParam, isQuery, buildQuery, runQuery, page, setPage, updateQuery } = useQueryBuilder({
    data: {
      query: {key: "query", label: `Search for ${search.get("query")}`, value: search.get("query")},
    },
    limit: 50,
  })

  const { data, loading, error, refetch } = useQuery(PAGINATED_CUSTOMERS_QUERY, {variables: {offset: 0, limit: 50, ...buildQuery()}, skip: !isQuery(), notifyOnNetworkStatusChange: true})

  function onSearch(e){
    if (e.key !== 'Enter') return false
    runQuery()
  }

  if (error) return `Error! ${error.message}`

  return(
    <Wrapper>
      <div id="customers" className="container">
        <div className="row padding">
          <Filters.Bar>
            <Filters.Input id="query" name="query" value={params?.query?.value} onChange={({target}) => addParam({query: {value: target?.value, id: "search", label: `Search for ${target.value}`, skip: !target?.value}})} reset={() => removeParam("query")} placeholder="Type here for searching" onKeyDown={onSearch}/>
            <Filters.Button style={{marginLeft: "auto"}} id="all" onClick={() => updateQuery({isReferrer: {value: !params?.isReferrer?.value, id: "isReferrer", label: `Referrers`}})} reset={() => removeParam("isReferrer")} active={params?.isReferrer?.value}>Referrers</Filters.Button>
          </Filters.Bar>
          {isQuery() ?
          <>
            <CustomersList loading={loading} data={data?.paginatedCustomers?.nodes || []} refetch={refetch}/>
            <Pagination page={page} setPage={setPage} pageInfo={data?.paginatedCustomers?.pageInfo}/>
          </>
          :
            <div className='search-placeholder'><IntlText id="search-placeholder" /></div>
          }                   
        </div>
      </div>
    </Wrapper>
  )
}
