import { gql } from '@apollo/client'

const REMOVE_GROUP_MUTATION = gql`
  mutation removeGroup($group: ID!){
    removeGroup(group: $group){
      success
    }
  }
`

export default REMOVE_GROUP_MUTATION
