import React from 'react'
import FontAwesome from 'react-fontawesome'

import './CloseButton.css'

export default function CloseButton({onClick}){

  return(
    <div className="close-button" onClick={onClick}>
      <FontAwesome name="times" />
    </div>
  )
}
