import React, { useState, useEffect, useRef } from 'react'
import FontAwesome from 'react-fontawesome'

import { LabeledInput } from '../'

import './LabeledSelect.css'

export default function LabeledSelect({field, value, placeholder, options, onChange, onChangeWithAction, renderItem}) {

  const [opened, toggleOpen] = useState(false)
  const [search, setValue] = useState(value?.[field])
  const element = useRef(null)

  function _onChange(obj){
    onChange(obj)
    setValue(undefined)
  }

  function onSearch(e){
    if (e.target.value === "" && onChange) onChange({})
    setValue(e.target.value)
  }

  function clear(){
    setValue("")
    toggleOpen(false)
  }

  function _onClick(next){
    if (onChangeWithAction) {
      onChangeWithAction(next)
    }  else {
      _onChange(next)
    }
    setValue(null)
    toggleOpen(false)
  }

  useEffect(() => {
    const handleClick = (e) => {
      if (!element.current || element.current.contains(e.target)) return false
      toggleOpen(false)
    }

    document.addEventListener("click", handleClick)
    document.addEventListener("touchend", handleClick)

    return () => {
      document.removeEventListener("click", handleClick)
      document.removeEventListener("touchend", handleClick)
    }
  }, [])

  let filteredOptions = options ? options.reduce((current, next) => {
    if (search && next[field].toLowerCase().search(search.toLowerCase()) !== -1){
      current.filtered.push(<li key={next[field]} onClick={() => _onClick(next)}>{renderItem ? renderItem(next) : next[field]}</li>)
    } else {
      current.options.push(<li key={next[field]} onClick={() => _onClick(next)}>{renderItem ? renderItem(next) : next[field]}</li>)
    }

    return current
  }, {filtered: [], options: []}) : {options: options, filtered: []}

  if (filteredOptions.filtered.length > 0) filteredOptions.filtered.push(<li key="separator" className="separator"></li>)

  return(
    <div className={`labeled-select ${opened ? "opened" : ""}`} ref={element}>
      <LabeledInput
        placeholder={placeholder}
        value={search || value?.[field]}
        onChange={(e) => onSearch(e)}
        onFocus={() => toggleOpen(true)}
        />
        {(filteredOptions.filtered?.length > 0 || filteredOptions.options?.length > 0) &&
          <ul>
            {filteredOptions.filtered}
            {filteredOptions.options}
          </ul>
        }
      {search?
        <FontAwesome className="clear" name="times" onClick={() => clear()}/>
      :
        <FontAwesome className="down" name="chevron-down" />
      }
    </div>
  )

}
