import React from 'react'
import { useQuery, useMutation } from '@apollo/client'

import { VEHICLES, DELETE_RIDER_VEHICLE } from '../../../graphql'
import { useConfirm, useIntl } from '../../../hooks'
import { toast } from '../../../helpers'
import { IntlText, Loader, Table, ContextMenu } from '../../../components'

export function RiderVehicles({rider}){

  const { loading, data, error } = useQuery(VEHICLES, {variables: {owner: rider?.id}})

  if (loading || error) return <Loader theme="main" />

  return(
    <div id="rider-vehicles">
      {data?.vehicles?.length > 0 &&  
        <Table.Table>
          <Table.Head>
            <Table.Tr>
              <Table.Th className="text-left"><IntlText group="rider-vehicle" id="alias" /></Table.Th>
              <Table.Th className="text-left"><IntlText group="rider-vehicle" id="maker" /></Table.Th>
              <Table.Th className="text-left"><IntlText group="rider-vehicle" id="model" /></Table.Th>
              <Table.Th className="text-left"><IntlText group="rider-vehicle" id="type" /></Table.Th>
              <Table.Th></Table.Th>
            </Table.Tr>
          </Table.Head>
          <Table.Body>
            {data.vehicles.map((vehicle, key) =>
              <Table.Tr key={key}>
                <Table.Td className="text-left">{vehicle.alias}</Table.Td>
                <Table.Td className="text-left">{vehicle.maker}</Table.Td>
                <Table.Td className="text-left">{vehicle.model}</Table.Td>
                <Table.Td className="text-left"><IntlText group="rider-vehicle" id={vehicle.type} /></Table.Td>
                <Table.Td style={{width: 100}}>
                  <VehicleMenu vehicle={vehicle} />
                </Table.Td>
              </Table.Tr>
            )}
          </Table.Body>
        </Table.Table>
      }      
    </div>
  )

}

function VehicleMenu({vehicle}){

  const { confirm } = useConfirm()
  const { getTranslation } = useIntl()
  const [deleteVehicle, {loading: deleteLoading}] = useMutation(DELETE_RIDER_VEHICLE, {refetchQueries: [{query: VEHICLES, variables: {owner: vehicle?.owner}}]})

  async function _deleteVehicle(_id){
    const alert = toast.loading(getTranslation({group: "confirm", id: "confirm-to-continue"}))
    try {
      await confirm()
      alert.update(getTranslation({id: "loading"}))
      await deleteVehicle({variables: {id: _id}})
      alert.success(getTranslation({id: "delete-success"}))
    } catch (error) {
      alert.apolloError(error)
    }
  }

  return(
    <ContextMenu.Menu loading={deleteLoading} id={vehicle?.id}>
      <ContextMenu.Link to={vehicle.id} icon="external-link-alt"><IntlText id="view" /></ContextMenu.Link>
      <ContextMenu.Button className='invalid' onClick={() => _deleteVehicle(vehicle.id)} icon="trash"><IntlText id="delete" /></ContextMenu.Button>
    </ContextMenu.Menu> 
  )
}