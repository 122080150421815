import { gql } from '@apollo/client'

const EDIT_FEEDBACK_MUTATION = gql`
  mutation editPurchaseFeedback($id: ID, $data:EditedPurchaseFeedbackInput!){
    editPurchaseFeedback(id: $id, data:$data){
      id
      purchase
      customer
      deliveryQuantitative
      deliveryQualitative
      productsQuantitative
      productsQualitative
      productsVoided
      createdAt
    }
  }
`

export default EDIT_FEEDBACK_MUTATION
