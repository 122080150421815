import { useContext } from 'react'

import { IntlContext } from '../../contexts'

export default function IntlValue({value, children}){

  let { selectedLanguage } = useContext(IntlContext)

  if (!value && !children) return null
  let data = value || children

  return data[selectedLanguage.shortcode] || null
}
