import React from 'react'
import moment from 'moment'
import { useQuery, useMutation } from '@apollo/client'

import { GET_RIDER_WORKSHIFTS_OF_RIDER, DELETE_RIDER_WORKSHIFT } from '../../../graphql'
import { useConfirm, useIntl } from '../../../hooks'
import { formatPrice, toast } from '../../../helpers'
import { Table, IntlText, ContextMenu, StatusDot } from '../../../components'

export function RiderWorkshifts({rider}) {

  const { confirm } = useConfirm()
  const { getTranslation } = useIntl()
  const { data, error, refetch } = useQuery(GET_RIDER_WORKSHIFTS_OF_RIDER, {variables: {id: rider?.id, limit: 50, offset: 0}})
  const [deleteWorkShift] = useMutation(DELETE_RIDER_WORKSHIFT)

  async function _deleteWorkShift(_id){
    const alert = toast.loading(getTranslation({group: "confirm", id: "confirm-to-continue"}))
    try {
      await confirm({title: getTranslation({group: "confirm", id: "delete"}), desc: getTranslation({group: "confirm", id: "delete-info"})})
      alert.update(getTranslation({id: "loading"}))
      await deleteWorkShift({variables: {id: _id}})
      alert.success(getTranslation({id: "delete-success"}))
      refetch()        
    } catch (error) {
      alert.apolloError(error)
    }
  }

  if (error) return `Error! ${error.message}`

  return(
    <div id="rider-workshifts">
      <Table.Table>
        <Table.Head>
          <Table.Tr>
            <Table.Th />
            <Table.Th><IntlText group="form" id="name" /></Table.Th>
            <Table.Th><IntlText group="form" id="start-date" /></Table.Th>
            <Table.Th><IntlText group="form" id="end-date" /></Table.Th>
            <Table.Th><IntlText group="form" id="duration" /></Table.Th>
            <Table.Th><IntlText group="form" id="completed-deliveries" /></Table.Th>
            <Table.Th><IntlText group="form" id="revenue" /></Table.Th>
            <Table.Th><IntlText group="form" id="modifications" /></Table.Th>
            <Table.Th><IntlText group="form" id="tips" /></Table.Th>
            <Table.Th><IntlText group="form" id="actions" /></Table.Th>
          </Table.Tr>
        </Table.Head>
        <Table.Body> 
          {data?.getRiderWorkShiftsOfRider?.nodes?.map((riderWorkshift, key) => 
            <Table.Tr key={key} className="rider-workshift">
              <Table.Td style={{width: 20}}><StatusDot active={riderWorkshift.status === "ACTIVE"} /></Table.Td>
              <Table.Td >{riderWorkshift.rider.name}</Table.Td>                    
              <Table.Td>{riderWorkshift.start ? moment(riderWorkshift.start).format("DD/MM/YYYY HH:mm") : "-"}</Table.Td>
              <Table.Td>{riderWorkshift.end ? moment(riderWorkshift.end).format("DD/MM/YYYY HH:mm") : "-"}</Table.Td>            
              <Table.Td><span style={{whiteSpace: "nowrap"}}>{riderWorkshift?.start && riderWorkshift?.end ? `${moment.duration(moment(riderWorkshift.end).diff(riderWorkshift.start)).asMinutes().toFixed(0)} minutes` : ""}</span></Table.Td>
              <Table.Td className="center">{riderWorkshift.account.completedDeliveries}</Table.Td>
              <Table.Td className="center">{formatPrice(riderWorkshift.account.totalRevenue)}</Table.Td>
              <Table.Td className="center">{formatPrice(riderWorkshift.account.totalModifications)}</Table.Td>
              <Table.Td className="center">{formatPrice(riderWorkshift.account.totalTips)}</Table.Td>
              <Table.Td>
                <ContextMenu.Menu id={riderWorkshift?.id}>
                  {riderWorkshift.status === "ACTIVE" ?
                    <ContextMenu.Link to={`../../workshifts/${riderWorkshift?.id}/active`} icon="clock"><IntlText group="rider-workshifts" id="finish-workshift" /></ContextMenu.Link>
                    :
                    <ContextMenu.Link to={riderWorkshift?.id} icon="external-link-alt"><IntlText id="open" /></ContextMenu.Link>
                  }
                  {riderWorkshift.status === "FINISHED" &&
                    <ContextMenu.Button className='invalid' onClick={() => _deleteWorkShift(riderWorkshift?.id)} icon="trash"><IntlText id="delete" /></ContextMenu.Button>
                  }
                </ContextMenu.Menu>
              </Table.Td>                      
            </Table.Tr>
          )}         
        </Table.Body>          
      </Table.Table>       
    </div>
  )
}