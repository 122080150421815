import { gql } from '@apollo/client'

export const DAILY_STATS_FRAGMENT = gql`
  fragment DAILY_STATS_FRAGMENT on DailyStats {
    id
    date
    completedPurchases
    voidedPurchases
    failedDeliveries
    cancelledPurchases
    rejectedPurchases
    unprocessedPurchases
    failedPayments
    currentCustomerCashReceivables
    currentCustomerCashLiabilities
    currentVendorCashReceivables
    currentVendorCashLiabilities
    signUps
    createdAddresses
    referralPurchasesCompleted
    referralSignUps
    numberOfReferrers
    referralRewardPaid
    avgBasketSize
    numberOfVendors
    numberOfVendorUsers
    createdAt
  }
`