import { gql } from '@apollo/client'

import { RIDER_WORKSHIFT_FRAGMENT } from './'

export const EDIT_RIDER_WORKSHIFT = gql`
  mutation editRiderWorkShift($id: ID!, $data: EditedWorkShiftInput!){
    editRiderWorkShift(id: $id, data: $data){
      ...RIDER_WORKSHIFT_FRAGMENT
    }
  }
  ${RIDER_WORKSHIFT_FRAGMENT}
`
