import React from 'react'
import moment from 'moment'
import { useLocation } from 'react-router-dom'
import { useQuery } from '@apollo/client'

import { useQueryBuilder } from '../../hooks'
import { CUSTOMER_BOOK_ENTRIES } from '../../graphql'
import { Wrapper, Filters, Pagination, DatePicker } from '../../components'
import { CustomerBookEntries as CustomerBookEntriesView } from '../../views'

export function CustomerBookEntries() {

  const location = useLocation()
  const search = new URLSearchParams(location.search)
  const {addParam, params, removeParam, buildQuery, isQuery, setPage, page, processQuery, runQuery } = useQueryBuilder({
    data: {
      date: {key: "date", label: `${search.getAll("date")[0]} - ${search.getAll("date")[1]}`, value: search.getAll("date")?.length === 2 ? [search.getAll("date")[0], search.getAll("date")[1]] : undefined, skip: !search.get("date")},
      customer: {key: "customer", label: `${search.get("customer")}`, value: search.get("customer")},
    },
    page: search.get("page")
  })
  const { data, loading, error, refetch } = useQuery(CUSTOMER_BOOK_ENTRIES, {variables: buildQuery(), skip: !isQuery(), notifyOnNetworkStatusChange: true, onCompleted: (_data) => processQuery(_data?.customerBookEntries?.pageInfo)})

  function setDate({selection: {startDate, endDate}}){
    const _date = [
      moment(startDate).format("YYYY-MM-DD"),
      moment(endDate).add(1, 'd').format("YYYY-MM-DD")
    ]
    addParam({date: {key: "date", label: `${_date[0]} - ${_date[1]}`, value: [_date[0], _date[1]], skip: !(_date[0] && _date[1])}})
  }

  if (error) return `Error! ${error.message}`

  return(
    <Wrapper>
      <div id="customer-book-entries" className="container">
        <div className="row padding">
          <Filters.Bar>
            <Filters.Dropdown style={{marginLeft: "auto"}} placeholder="Date" value={params?.date?.label} disableInClickClose right run={runQuery} reset={() => removeParam("date")}>
              <DatePicker.Range startDate={params?.date?.value[0] ? new Date(`${params.date.value[0]}T00:00`) : new Date()} endDate={params?.date?.value[1] ? new Date(`${params.date.value[1]}T00:00`) : new Date()} onChange={setDate} />
            </Filters.Dropdown>          
          </Filters.Bar>
          <CustomerBookEntriesView data={data} loading={loading} refetch={refetch} />
          <Pagination page={page} setPage={setPage} pageInfo={data?.customerBookEntries?.pageInfo}/>
        </div>
      </div>
    </Wrapper>
  )
}
