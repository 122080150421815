import { gql } from '@apollo/client'

import { PURCHASE_FRAGMENT } from './'

export const MAKE_PURCHASE_DISCOUNT = gql`
  mutation makePurchaseDiscount($id: ID!, $discount: Float){
    makePurchaseDiscount(id: $id, discount: $discount){
      ...PURCHASE_FRAGMENT
    }
  }
  ${PURCHASE_FRAGMENT}
`
