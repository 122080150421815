import { gql }from '@apollo/client'

import { VENDOR_STAT_FRAGMENT } from './'

export const VENDOR_STAT = gql`
  query vendorStat($id: ID, $vendor: ID, $start: Date, $periodicity: VENDOR_STAT_PERIOD){
    vendorStat(id: $id, vendor: $vendor, start: $start, periodicity: $periodicity){
      ...VENDOR_STAT_FRAGMENT
    }
  }
  ${VENDOR_STAT_FRAGMENT}
`
