import { gql } from '@apollo/client'

const DELETE_ADDRESS_MUTATION = gql`
  mutation deleteAddress($id: ID!){
    deleteAddress(id: $id){
      success
    }
  }
`

export default DELETE_ADDRESS_MUTATION
