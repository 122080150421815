import React from 'react'

import './style.css'

export function LabeledTextarea({value = undefined, placeholder, children, ...rest}) {

    return(
      <div className={`labeled-textarea ${value !== "" && value !== undefined ? "populated" : ""}`}>
        <label>
          <textarea
            value={value || children || ""}
            placeholder={placeholder}
            {...rest}
          />
          <span>{placeholder}</span>
        </label>
      </div>
    )
}
