import { gql } from '@apollo/client'

import { CUSTOMER_BOOK_ENTRY_FRAGMENT, CUSTOMER_BOOK_FRAGMENT } from './'

export const CUSTOMER_BALANCE = gql`
  query customerBalance($customer: ID){
    customerBook(customer: $customer, ) {
      ...CUSTOMER_BOOK_FRAGMENT
    }

    customerBookEntries(customer: $customer) {
      nodes{
        ...CUSTOMER_BOOK_ENTRY_FRAGMENT        
      }
    }
  }
  ${CUSTOMER_BOOK_FRAGMENT}
  ${CUSTOMER_BOOK_ENTRY_FRAGMENT}
`
