import { gql } from '@apollo/client'

import { CUSTOMER_BOOK_ENTRY_FRAGMENT } from './'

export const CUSTOMER_BOOK_ENTRIES = gql`
  query customerBookEntries($customer: ID, $date: [String], $offset: Int, $limit: Int){
     customerBookEntries(customer: $customer, date: $date, offset: $offset, limit: $limit) {
       nodes{
         ...CUSTOMER_BOOK_ENTRY_FRAGMENT
       }
       pageInfo{
         limit
         totalNodes
         firstNode
         lastNode
         totalPages
         actualPage
         hasNextPage
       }
     }
  }
  ${CUSTOMER_BOOK_ENTRY_FRAGMENT}
`
