import React, { useContext, useState } from 'react'
import { toast } from 'react-toastify'
import { useMutation } from '@apollo/client'

import { EDIT_CATEGORY_MUTATION, CREATE_CATEGORY_MUTATION, CATEGORIES_QUERY } from '../../../graphql'
import { sentenceCase, returnGraphqlError } from '../../../helpers'
import { HourPicker, Button, IntlText, LabeledInput, Validation, LabeledCheckbox } from '../../../components'
import { IntlContext } from '../../../contexts'
import { useValidatedForm } from '../../../hooks'

import './style.css'

const validations = {
  "nameEn": [{id: "required", validation: (val) => !!val}],
  "nameEs": [{id: "required", validation: (val) => !!val}]
}

export function Category({category, success}) {

  const { getTranslation } = useContext(IntlContext)
  const [hasAvailability, _toggleAvailability] = useState(!!(category?.availability?.from || category?.availability?.to))
  const [editCategory, { loading: editLoading }] = useMutation(EDIT_CATEGORY_MUTATION)
  const [createCategory, { loading: createLoading }] = useMutation(CREATE_CATEGORY_MUTATION)
  const { form: { nameEn, nameEs, descriptionEn, descriptionEs, availability }, updateField, errors, validate } = useValidatedForm({nameEn: category?.name?.en, nameEs: category?.name?.es, descriptionEn: category?.description?.en, descriptionEs: category?.description?.es, availability: category?.availability || {}}, validations)

  async function save(){
    let { valid } = validate()

    if (!valid) return toast.error(getTranslation({group: "form", id: "error"}))

    let data = {
      name: {
        es: nameEs,
        en: nameEn
      },
      description: {
        es: descriptionEs || "",
        en: descriptionEn || ""
      },
      availability: {
        from: availability?.from,
        to: availability?.to
      }
    }

    if (availability) data.availability = {from: availability.from, to: availability.to}

    if (category && category?.id){
      await edit(data)
    } else {
      await create(data)
    }

  }

  async function create(data){
    try {
      await createCategory({variables: {data}, refetchQueries: [{query: CATEGORIES_QUERY}]})
      toast.success(getTranslation({id: "save-success"}))
      if (success) success()
    } catch (e) {
      returnGraphqlError(e)
    }
  }

  async function edit(data){
    try {
      await editCategory({variables: {id: category?.id, data}})
      toast.success(getTranslation({id: "edit-success"}))
      if (success) success()
    } catch (e) {
      console.log("e", e);
      returnGraphqlError(e)
    }
  }

  function setAvaibility(key, time){
    availability[key] = time.hours.toString() + time.mins.toString()
    updateField({"availability": availability})
  }

  function toggleAvailability(){
    updateField({key: "availability", value: {}})
    _toggleAvailability(!hasAvailability)
  }

  return(
    <div id="category">
      <div className="form">
        <div className="warning"><IntlText group="form" id="responsibility-warning">Please, fill out all the details carefully to avoid errors. Problems derived from inaccuracies of the uploaded menu are 100% responsibility of the uploading party.</IntlText></div>
        <Validation errors={errors["nameEs"]}>
          <LabeledInput name="nameEs" placeholder={getTranslation({group: "form", id: "name-es", defaultValue: "Category name spanish"})} value={nameEs} onChange={(e) => updateField({key: "nameEs", value: sentenceCase(e.target.value)})} />
        </Validation>
        <LabeledInput name="descriptionEs" placeholder={getTranslation({group: "form", id: "description-es", defaultValue: "Category description spanish"})} value={descriptionEs} onChange={(e) => updateField({key: "descriptionEs", value: sentenceCase(e.target.value)})} />
        <Validation errors={errors["nameEn"]}>
          <LabeledInput name="nameEn" placeholder={getTranslation({group: "form", id: "name-en", defaultValue: "Category name english"})} value={nameEn} onChange={(e) => updateField({key: "nameEn", value: sentenceCase(e.target.value)})} />
        </Validation>
        <LabeledInput name="descriptionEn" placeholder={getTranslation({group: "form", id: "description-en", defaultValue: "Category description english"})} value={descriptionEn} onChange={(e) => updateField({key: "descriptionEn", value: sentenceCase(e.target.value)})} />
        <LabeledCheckbox rounded id="hasAvailability" checked={hasAvailability} onChange={toggleAvailability} placeholder={getTranslation({group: "form", id: "availability"})}/>
        {hasAvailability &&
          <div className="availability-time">
            <HourPicker placeholder={getTranslation({group: "form", id: "available-from", defaultValue: "Available from"})} time={availability?.from || "0000"} onChange={(data) => setAvaibility("from", data)}/>
            <HourPicker placeholder={getTranslation({group: "form", id: "available-to", defaultValue: "Available to"})} time={availability?.to || "0000"} onChange={(data) => setAvaibility("to", data)}/>
          </div>
        }
        <hr />
        <div className="actions">
          <Button onClick={() => success()}><IntlText id="back">Back</IntlText></Button>
          <Button theme="main" loading={editLoading || createLoading} onClick={() => save()}><IntlText id="save">Save</IntlText></Button>
        </div>
      </div>
    </div>
  )
}
