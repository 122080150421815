import { gql } from '@apollo/client'

import { SETTING_FRAGMENT } from './'

export const SETTINGS_QUERY = gql`
  query settings($keys: [String]){
    settings(keys: $keys){
      ...SETTING_FRAGMENT
    }
  }
  ${SETTING_FRAGMENT}
`
