import { gql } from '@apollo/client'

import { CATEGORY_FRAGMENT } from './'

const CATEGORIES_QUERY = gql`
  query categories($owner: String){
     categories(owner: $owner) {
       ...CATEGORY_FRAGMENT
     }
  }
  ${CATEGORY_FRAGMENT}
`

export default CATEGORIES_QUERY
