import { useState, useReducer } from 'react'
import { useQuery, useMutation } from '@apollo/client'
import { useParams, useNavigate } from 'react-router-dom'
import moment from 'moment'

import { SERVICE_FEE_PROFORMA_INVOICES, DELETE_SERVICE_FEE_INVOICE, EDIT_SERVICE_FEE_INVOICE, SET_SERVICE_FEE_INVOICE_COMPLETED, SERVICE_FEE_PROFORMA_INVOICE, SEND_CFDI_OF_SERVICE_FEE, SEND_SERVICE_FEE_INVOICE_EMAIL, UPDATE_INVOICING_DATA_OF_SERVICE_FEE_INVOICE, CREATE_PDF_OF_INVOICE, CREATE_CFDI_OF_SERVICE_FEE, EDIT_VENDOR_MUTATION } from '../../../graphql'
import { useConfirm, useIntl, useModal } from '../../../hooks'
import { formatPrice, showError, toast } from '../../../helpers'
import { IntlText, ContextMenu, Loader, Button, Badge, Tooltip, Modalv3, Title, SwitchBox, Form, LabeledInput, EditBox } from '../../../components'

import './style.css'

export function VendorInvoice() {
  
  const modal = useModal()
  const processInvoice = useModal()
  const editModal = useModal()
  const { id, invoiceId } = useParams()
  const { confirm } = useConfirm()
  const { getTranslation } = useIntl()
  const navigate = useNavigate()
  const [sendInvoiceToEmail, {loading: sendEmailLoading}] = useMutation(SEND_SERVICE_FEE_INVOICE_EMAIL)
  const [updateInvoicing, {loading: updateInvoicingLoading}] = useMutation(UPDATE_INVOICING_DATA_OF_SERVICE_FEE_INVOICE)
  const [createPDF, { loading: createPDFLoading}] = useMutation(CREATE_PDF_OF_INVOICE)
  const [createCFDI, { loading: createCFDILoading}] = useMutation(CREATE_CFDI_OF_SERVICE_FEE)
  const [deleteInvoice, { loading: deleteLoading}] = useMutation(DELETE_SERVICE_FEE_INVOICE)
  const [sendCFDI, { loading: sendCFDILoading}] = useMutation(SEND_CFDI_OF_SERVICE_FEE)
  const { loading, data, error } = useQuery(SERVICE_FEE_PROFORMA_INVOICE, {variables: {id: invoiceId}})

  async function _sendInvoiceToEmail(){
    try {
      await sendInvoiceToEmail({variables: {id: invoiceId}})
      toast.success(getTranslation({id: "send-success"}))
    } catch (error) {
      showError(error)
    }
  }    

  async function _createPDF(){
    try {
      await createPDF({variables: {id: invoiceId}})
      toast.success(getTranslation({id: "create-success"}))
    } catch (error) {
      showError(error)
    }
  }  

  async function _createCFDI(){
    try {
      await createCFDI({variables: {id: invoiceId}})
      toast.success(getTranslation({id: "create-success"}))
    } catch (error) {
      showError(error)
    }
  }  

  async function _sendCFDI(){
    try {
      await sendCFDI({variables: {id: invoiceId}})
      toast.success(getTranslation({id: "send-success"}))
    } catch (error) {
      showError(error)
    }
  }  

  async function _updateInvoicing(){
    try {
      await updateInvoicing({variables: {id: invoiceId}})
      toast.success(getTranslation({id: "update-success"}))
    } catch (error) {
      showError(error)
    }
  }    

  async function _deleteInvoice(){
    const alert = toast.loading(getTranslation({group: "confirm", id: "confirm-to-continue"}))
    try {
      await confirm({title: getTranslation({group: "confirm", id: "delete"}), desc: getTranslation({group: "confirm", id: "delete-info"})})
      alert.update(getTranslation({id: "loading"}))
      await deleteInvoice({variables: {id: invoiceId}, refetchQueries: [{query: SERVICE_FEE_PROFORMA_INVOICES, variables: {vendor: id}}]})
      alert.success(getTranslation({id: "delete-success"}))     
      navigate("../invoices")
    } catch (error) {
      alert.apolloError(error)
    }
  }    

  if (loading || error) return <Loader theme="main" />

  const { serviceFeeProformaInvoice } = data

  return(
    <div id="vendor-invoice">
      <Form.Form>
        <Form.Fieldset highlighted>
          <Title tag='h3'><IntlText id="details" /></Title>
          <Form.Field>
            <EditBox.Data icon="hashtag" placeholder={<div><IntlText group="vendor-service-fee-invoices" id="reference" /></div>}>{serviceFeeProformaInvoice.reference}</EditBox.Data>
          </Form.Field>
          <Form.Field>
            <EditBox.Data icon="money-bill" placeholder={<div><IntlText group="vendor-service-fee-invoices" id="grossInvoiceAmount" /></div>}>{formatPrice(serviceFeeProformaInvoice.grossInvoiceAmount)}</EditBox.Data>
          </Form.Field>
          <Form.Field>
            <EditBox.Data icon="calendar" placeholder={<div><IntlText group="vendor-service-fee-invoices" id="created-at" /></div>}>{moment(serviceFeeProformaInvoice?.createdAt).format("DD/MM/YYYY HH:mm")}</EditBox.Data>
          </Form.Field>
          <Form.Field>
            <EditBox.Data placeholder={<div><IntlText group="vendor-service-fee-invoices" id="status" /></div>}><Badge theme={serviceFeeProformaInvoice.status === "PAID" ? "valid" : serviceFeeProformaInvoice.status === "WAITING_FOR_PAYMENT" ? "warning" : null}><IntlText group="vendor-service-fee-invoices" id={serviceFeeProformaInvoice.status} /></Badge></EditBox.Data>
          </Form.Field>
          <Form.Field>
          <div className="details-bar">     
            <div className='detail left'>
              <div style={{display: "flex", gap: 15, justifyContent: "flex-end"}}>
                <Button className='main' onClick={() => processInvoice.show()}><IntlText group="vendor-service-fee-invoices" id="process-invoice" /></Button>
                <ContextMenu.Menu loading={deleteLoading || sendCFDILoading || updateInvoicingLoading || createPDFLoading || createCFDILoading} id={invoiceId}>
                  <ContextMenu.Button className='invalid' onClick={_deleteInvoice} icon="trash"><IntlText group="vendor-service-fee-invoices" id="delete-invoice" /></ContextMenu.Button>
                  <ContextMenu.Button onClick={_updateInvoicing} icon="sync-alt"><IntlText group="vendor-service-fee-invoices" id="update-invoicing" /></ContextMenu.Button>
                  <ContextMenu.Button onClick={() => editModal.show()} icon="edit"><IntlText group="vendor-service-fee-invoices" id="edit-invoice" /></ContextMenu.Button>
                  <ContextMenu.Button onClick={_createPDF} icon="file-pdf"><IntlText group="vendor-service-fee-invoices" id="create-pdf" /></ContextMenu.Button>
                  <ContextMenu.Button onClick={_createCFDI} icon="file-invoice"><IntlText group="vendor-service-fee-invoices" id="create-cfdi" /></ContextMenu.Button>
                  <ContextMenu.Button onClick={_sendCFDI} icon="envelope"><IntlText group="vendor-service-fee-invoices" id="send-cfdi" /></ContextMenu.Button>
                  <ContextMenu.Button onClick={() => modal.show()} icon="check"><IntlText group="vendor-service-fee-invoices" id="set-completed" /></ContextMenu.Button>
                </ContextMenu.Menu>      
              </div>
            </div>
          </div>        
          </Form.Field>
        </Form.Fieldset>
        <Title tag='h3'><IntlText id="additional-details" /></Title>
        <Form.Field>
          <EditBox.Data placeholder={<div><IntlText group="vendor-service-fee-invoices" id="periodicity" /></div>}>
            {serviceFeeProformaInvoice?.periodicity}
          </EditBox.Data>
        </Form.Field>        
        <Form.Field>
          <EditBox.Data placeholder={<div><IntlText group="vendor-service-fee-invoices" id="paymentTermDays" /></div>}>
            {serviceFeeProformaInvoice?.paymentTermDays}
          </EditBox.Data>
        </Form.Field>        
        <Form.Field>
          <EditBox.Data placeholder={<div><IntlText group="vendor-service-fee-invoices" id="due-at" /></div>}>
            {moment(serviceFeeProformaInvoice?.dueAt).format("DD/MM/YYYY HH:mm")}
            {serviceFeeProformaInvoice.overdue &&
              <Badge style={{marginLeft: 10}} icon="exclamation-triangle" theme='invalid'><IntlText group="vendor-service-fee-invoices" id="overdue" /></Badge>
            }
          </EditBox.Data>
        </Form.Field>        
        {serviceFeeProformaInvoice.approvedAt &&
          <Form.Field>
            <EditBox.Data placeholder={<div><IntlText group="vendor-service-fee-invoices" id="approved-at" /></div>}>
              {moment(serviceFeeProformaInvoice?.approvedAt).format("DD/MM/YYYY HH:mm")}
            </EditBox.Data>
          </Form.Field>        
        }      
        {!serviceFeeProformaInvoice.approvedAt && 
          <Form.Field>
            <EditBox.Data placeholder={<div><IntlText group="vendor-service-fee-invoices" id="last-email-sent" /></div>}>
              <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                {serviceFeeProformaInvoice?.lastEmailSent ?
                  <span style={{marginRight: 10}}>{moment(serviceFeeProformaInvoice.lastEmailSent).format("DD/MM/YYYY HH:mm")}</span>
                : 
                  <span style={{marginRight: 10}}><IntlText group="vendor-service-fee-invoices" id="email-not-sent" /></span>
                }
                <Button loading={sendEmailLoading} onClick={_sendInvoiceToEmail}><IntlText id={serviceFeeProformaInvoice?.lastEmailSent ? "resend" : "send"} /></Button>
              </div>
            </EditBox.Data>
          </Form.Field>        
        }      
        <Form.Field>
          <EditBox.Data placeholder={<div><IntlText group="vendor-service-fee-invoices" id="proformaLink" /></div>}>
            <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
              {serviceFeeProformaInvoice?.proformaLink ?
                <Button theme="ui"><a href={serviceFeeProformaInvoice.proformaLink}><IntlText id="download" /></a></Button>
              :
                <IntlText group="vendor-service-fee-invoices" id="not-generated-yet" />
              }
            </div>
          </EditBox.Data>
        </Form.Field>        
        <Form.Field>
          <EditBox.Data placeholder={<div><IntlText group="vendor-service-fee-invoices" id="invoiceLink" /></div>}>
            <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>               
              {serviceFeeProformaInvoice?.invoiceLink ?
                <>
                  <Button theme="ui"><a href={serviceFeeProformaInvoice.invoiceLink}><IntlText id="download" /></a></Button>
                  <Tooltip text={moment(serviceFeeProformaInvoice?.invoiceCreatedAt).format("DD/MM/YYYY HH:mm")}>
                    <Badge style={{marginLeft: 10}} icon="check" theme='valid'><IntlText group="vendor-service-fee-invoices" id="invoice-created-at" /></Badge>
                  </Tooltip>   
                </>
              :
                <IntlText group="vendor-service-fee-invoices" id="not-generated-yet" />
              }
              {serviceFeeProformaInvoice.invoiceFailed &&
                <Tooltip text={serviceFeeProformaInvoice.invoiceFailed}>
                  <Badge style={{marginLeft: 10}} icon="exclamation-triangle" theme='invalid'><IntlText id="failed" /></Badge>
                </Tooltip>
              }
            </div>
          </EditBox.Data>
        </Form.Field>        
      </Form.Form>
      <Modalv3 id="set-invoice-confirmed" contentStyle={{overflow: "hidden"}} modal={modal} header={<Title tag="h3"><IntlText group="vendor-service-fee-invoices" id="set-completed" /></Title>}>
        <VendorInvoiceSetCompleted id={invoiceId} modal={modal} />
      </Modalv3>          
      <Modalv3 id="process-invoice" contentStyle={{overflow: "hidden"}} modal={processInvoice} header={<Title tag="h3"><IntlText group="vendor-service-fee-invoices" id="process-invoice" /></Title>}>
        <VendorInvoiceProcess id={invoiceId} modal={processInvoice} invoice={serviceFeeProformaInvoice} />
      </Modalv3>          
      <Modalv3 id="edit-invoice" contentStyle={{overflow: "hidden"}} modal={editModal} header={<Title tag="h3"><IntlText group="vendor-service-fee-invoices" id="edit-invoice" /></Title>}>
        <EditVendorInvoice id={invoiceId} modal={editModal} invoice={serviceFeeProformaInvoice} />
      </Modalv3>          
    </div>
  )
}

function VendorInvoiceSetCompleted({id, modal}){

  const [setCompleted, { loading }] = useMutation(SET_SERVICE_FEE_INVOICE_COMPLETED)
  const [_createEntry, setCreateEntry] = useState(true)

  async function _setCompleted(){
    try {
      await setCompleted({variables: {id, createEntry: _createEntry}})
      toast.success({id: "update-success"})
      modal.hide()
    } catch (error) {
      showError(error)
    }
  }

  return(
    <div>
      <Form.Form>
        <Form.Field>
          <SwitchBox id="createEntry" label={<IntlText group="vendor-service-fee-invoices" id="create-entry-desc" />} checked={_createEntry} onChange={({target}) => setCreateEntry(target.checked)}>
            <IntlText group='vendor-service-fee-invoices' id='create-entry' />
          </SwitchBox>                  
        </Form.Field>
        <Form.Field>
          <Button theme="main" fluid loading={loading} onClick={_setCompleted}>Set completed</Button>
        </Form.Field>
      </Form.Form>
    </div>
  )
}

function VendorInvoiceProcess({id, modal, invoice}){

  const [state, dispatch] = useReducer((state, action) => {
    switch (action.type) {
      case 'process':
        return {step: action.step, loading: true, completed: false}
      case 'completed':
        if (action.last)
          return {...state, loading: false, completed: true}

        return {...state, completed: true}
      case 'error':
        return {...state, loading: false, error: action.error, completed: false}
      default:
        throw new Error()
    }  
  }, {loading: false, error: null, step: null})
  const [createCFDI] = useMutation(CREATE_CFDI_OF_SERVICE_FEE)
  const [setCompleted] = useMutation(SET_SERVICE_FEE_INVOICE_COMPLETED)
  const [sendCFDI] = useMutation(SEND_CFDI_OF_SERVICE_FEE)
  const [editVendor] = useMutation(EDIT_VENDOR_MUTATION)
  const [reactivate, setReactivate] = useState(invoice?.overdue)

  function sleep(ms) {
    return new Promise(val => setTimeout(val, ms));
  }  

  async function processInvoice(){
    try {
      dispatch({type: "process", step: "create-cfdi"})
      await createCFDI({variables: {id}})
      dispatch({type: "completed"})
      await sleep(3000)
      dispatch({type: "process", step: "send-cfdi"})
      await sendCFDI({variables: {id}})
      dispatch({type: "completed"})
      await sleep(3000)
      if (reactivate){
        dispatch({type: "process", step: "reactivate"})
        await editVendor({variables: {id: invoice?.vendor?.originalId, data: {suspended: false}}})
        dispatch({type: "completed"})
        await sleep(3000)
      }
      dispatch({type: "process", step: "set-completed"})
      await setCompleted({variables: {id, createEntry: true}})
      dispatch({type: "completed", last: true})     
      await sleep(3000)
      modal.hide()
    } catch (error) {
      dispatch({type: "error", error})
    }
  }

  return(
    <div>
      <div style={{display: "flex", alignItems: "center", gap: 15, margin: "15px 0", fontSize: 18}}>
        {state.loading &&
          <div>
            <Loader theme='main' />
          </div>
        }
        {state.step &&
          <div><IntlText group="vendor-service-fee-invoices" id={`${state.step}${state.completed ? '-completed' : ''}`}></IntlText></div>
        }
      </div>
      {state.error &&
        <div>{state?.error?.message}</div>
      }
      <Form.Form>
        {invoice?.overdue ? 
          <Form.Field>
            <SwitchBox id="hidden" label={<IntlText group="vendor-service-fee-invoices"  id="reactivate-desc" />} checked={reactivate} onChange={({target}) => setReactivate(target.checked)}>
              <IntlText group='vendor-service-fee-invoices' id='reactivate' />
            </SwitchBox>      
          </Form.Field>
        : null}
        <Form.Field>
          <Button theme="main" disabled={state.loading} fluid onClick={processInvoice}>Process invoice</Button>
        </Form.Field>
      </Form.Form>
    </div>
  )
}

function EditVendorInvoice({id, modal, invoice}){

  const [editServiceFeeInvoice, { loading }] = useMutation(EDIT_SERVICE_FEE_INVOICE)
  const [paymentTermDays, setPaymentTermDays] = useState(invoice?.paymentTermDays)

  async function _editServiceFeeInvoice(){
    try {
      await editServiceFeeInvoice({variables: {id, data: {paymentTermDays: parseInt(paymentTermDays)}}})
      toast.success({id: "update-success"})
      modal.hide()
    } catch (error) {
      showError(error)
    }
  }

  return(
    <div>
      <Form.Form>
        <Form.Field>
          <LabeledInput name="paymentTermDays" placeholder="Payment day terms" value={paymentTermDays} onChange={({target}) => setPaymentTermDays(target.value)} />
        </Form.Field>
        <Form.Field>
          <Button theme="main" fluid loading={loading} onClick={_editServiceFeeInvoice}>Edit invoice</Button>
        </Form.Field>
      </Form.Form>
    </div>
  )
}
