import React from 'react'
import { useQuery } from '@apollo/client'

import { IS_LOGGED_IN, ME_QUERY } from '../../graphql'
import { LoaderV2 } from '../../components'
import { Login } from '../../containers'

export function PrivateRoute({auth = () => true, fallback, children}) {

  const {data} = useQuery(IS_LOGGED_IN)
  const {loading, error, data: userData} = useQuery(ME_QUERY, {skip: !data?.isLoggedIn, notifyOnNetworkStatusChange: true})

  if (loading || error) return(
    <LoaderV2.Logo full />
  )
  
  const { isLoggedIn } = data || {}
  const { me } = userData || {}

  if (!isLoggedIn) return <Login />
  if (!auth(me)) return fallback

  return children

}

