import { useState } from 'react'
import { useQuery, useMutation } from '@apollo/client'

import { SETTINGS_QUERY, DELETE_SETTING_MUTATION } from '../../../graphql'
import { useConfirm, useIntl, useModal } from '../../../hooks'
import { toast } from '../../../helpers'
import { Loader, IntlText, Filter, Filters, Table, ContextMenu, Modalv3 } from '../../../components'
import { SettingForm } from '../../../views'

import './style.css'

const filters = [
  {filter: (data) => data.key},
  {filter: (data) => data.type},
  {filter: (data) => data.description},
]

export function SettingsList(){

  const [filter, setFilter] = useState()
  const modal = useModal()
  const { confirm } = useConfirm()
  const { getTranslation } = useIntl()
  const { loading, data, error, refetch } = useQuery(SETTINGS_QUERY)
  const [remove] = useMutation(DELETE_SETTING_MUTATION)

  async function _delete(id){
    const alert = toast.loading(getTranslation({group: "confirm", id: "confirm-to-continue"}))
    try {
      await confirm({title: getTranslation({group: "confirm", id: "delete"}), desc: getTranslation({group: "confirm", id: "delete-info"})})
      alert.update(getTranslation({id: "loading"}))
      await remove({variables: {id}})
      alert.success(getTranslation({id: "delete-success"}))      
      refetch()
    } catch (e) {
      alert.apolloError(e)
    }
  }

  if (loading || error) return <Loader theme="main" />

  const { settings } = data

  return(
    <div id="settings-list">
      <Filters.Bar>
        <Filters.Input id="query" name="query" value={filter} onChange={({target}) => setFilter(target.value)} reset={() => setFilter("")} placeholder="Type here for searching" />
        <Filters.Button style={{marginLeft: "auto"}} id="new" onClick={() => modal?.show()}>Add new</Filters.Button>
      </Filters.Bar>
      <Filter id="settings-filter" data={settings} search={[{key: "search", value: filter}]} filters={filters} sort={(a, b) => new Date(a.createdAt) - new Date(b.createdAt)} filterKeys={["description", "key", "type"]}>
        {(data) =>
          <Table.Table id="settings-list" className="table">
            <Table.Head>
              <Table.Tr>
                <Table.Th className="text-left">Name</Table.Th>
                <Table.Th>Type</Table.Th>
                <Table.Th></Table.Th>
              </Table.Tr>
            </Table.Head>
            <Table.Body>
              {data.map((setting, key) =>
                <Table.Tr key={key} className="settings-list-item">
                  <Table.Td className="text-left">{setting.description}</Table.Td>
                  <Table.Td>{setting.type}</Table.Td>
                  <Table.Td>
                  <ContextMenu.Menu id={setting?.id}>
                    <ContextMenu.Button onClick={() => modal?.show({setting})} icon="edit"><IntlText id="edit" /></ContextMenu.Button>
                    <ContextMenu.Button onClick={() => _delete(setting.id)} theme="invalid" icon="trash"><IntlText id="delete" /></ContextMenu.Button>
                  </ContextMenu.Menu>
                  </Table.Td>
                </Table.Tr>
              )}
            </Table.Body>
          </Table.Table>
        }
      </Filter>
      <Modalv3 modal={modal} headerTitle={"Setting"}>
        <SettingForm initialData={modal?.state?.setting} onSuccess={modal?.hide}/>
      </Modalv3>
    </div>
  )

}
