import React from 'react'
import { useQuery } from '@apollo/client'
import { useLocation } from 'react-router-dom'

import { useQueryBuilder, useIntl } from '../../hooks'
import { VendorsList } from '../../views'
import { Wrapper, Filters, IntlText, Pagination } from '../../components'
import { PAGINATED_VENDORS } from '../../graphql'

const contracts = [
  {id: "FEATURED", label: "Featured"},
  {id: "BASIC", label: "Basic"}
]

const types = [
  {id: "RESTAURANT", label: "Restaurant"},
  {id: "SHOP", label: "Shop"},
]

const serviceFee = [
  {id: "10", value: 0.1},
  {id: "12", value: 0.12},
  {id: "15", value: 0.15},
  {id: "20", value: 0.2},
]

export function Vendors() {

  const location = useLocation()
  const search = new URLSearchParams(location.search)
  const { getTranslation } = useIntl()
  const { params, addParam, removeParam, isQuery, buildQuery, runQuery, page, setPage, updateQuery } = useQueryBuilder({
    data: {
      query: {key: "query", label: `Search for ${search.get("query")}`, value: search.get("query")},
      type: {key: "type", label: `${search.get("type")}`, value: search.get("type")},
      contract: {key: "contract", label: `${search.get("contract")}`, value: search.get("contract")},
      serviceFee: {key: "serviceFee", label: `${search.get("serviceFee")}`, value: parseFloat(search.get("serviceFee")) || 0},
    },
    limit: 50
  })

  const { data, loading, error, refetch } = useQuery(PAGINATED_VENDORS, {variables: buildQuery(), skip: !isQuery(), notifyOnNetworkStatusChange: true})

  function onSearch(e){
    if (e.key !== 'Enter') return false
    runQuery()
  }

  if (error) return `Error! ${error.message}`

  return(
    <Wrapper>
      <div id="vendors" className="container">
        <div className="row padding">
          <Filters.Bar>
            <Filters.Input id="query" name="query" value={params?.query?.value} onChange={({target}) => addParam({query: {value: target?.value, id: "search", label: `Search for ${target.value}`, skip: !target?.value}})} reset={() => removeParam("query")} placeholder="Type here for searching" onKeyDown={onSearch}/>
            <Filters.Button style={{marginLeft: "auto"}} id="all" onClick={() => runQuery({instantParams: {query: {value: "", id: "search", label: `All partners`, force: true}}})} reset={() => removeParam("query")} active={!!params?.query && !params?.query?.value}>All partners</Filters.Button>
            <Filters.Button onClick={() => updateQuery({tags: {value: ["new"], id: "tags", label: `New partners`}})} reset={() => removeParam("tags")} active={!!params?.tags?.value?.includes("new")}>New partners</Filters.Button>
            <Filters.Button onClick={() => updateQuery({tags: {value: ["exclusive"], id: "tags", label: `Exclusive partners`}})} reset={() => removeParam("tags")} active={!!params?.tags?.value?.includes("exclusive")}>Exclusive partners</Filters.Button>
            <Filters.Select value={params?.contract?.label} placeholder="Contract" reset={() => removeParam("contract")}>
              {contracts.map((_contract, key) =>
                <li key={key} onClick={() => updateQuery({contract: {key: "contract", label: _contract?.label, value: _contract?.id, skip: !_contract?.id}})}><span>{_contract.label}</span></li>
              )}
            </Filters.Select>
            <Filters.Select value={params?.serviceFee?.label && getTranslation({group: "vendor-filter", id: params?.serviceFee?.label})} placeholder="Service fee" reset={() => removeParam("serviceFee")}>
              {serviceFee.map((_item, key) =>
                <li key={key} onClick={() => updateQuery({serviceFee: {key: "serviceFee", label: _item?.id, value: parseFloat(_item?.value), skip: !_item?.id}})}><span>{getTranslation({group: "vendor-filter", id: _item.id})}</span></li>
              )}
            </Filters.Select>
            <Filters.Select value={params?.type?.label} placeholder="Type" reset={() => removeParam("type")}>
              {types.map((_type, key) =>
                <li key={key} onClick={() => updateQuery({type: {key: "type", label: _type?.label, value: _type?.id, skip: !_type?.id}})}><span>{_type.label}</span></li>
              )}
            </Filters.Select>            
          </Filters.Bar>
          {isQuery() ?
            <>
              <VendorsList loading={loading} data={data?.paginatedVendors?.nodes || []} refetch={refetch}/>
              <Pagination page={page} setPage={setPage} pageInfo={data?.paginatedVendors?.pageInfo}/>
            </>
          :
            <div className='search-placeholder'><IntlText id="search-placeholder" /></div>
          }          
        </div>
      </div>
    </Wrapper>
  )
}
