import { gql } from '@apollo/client'

import { RIDER_WORKSHIFT_FRAGMENT } from './'

export const FINISH_CONTRACTOR_RIDER_WORKSHIFT = gql`
  mutation finishContractorRiderWorkShift($rider: ID, $reason: RIDERWORKSHIFT_FINISH_REASON!){
    finishContractorRiderWorkShift(rider: $rider, reason: $reason){
      ...RIDER_WORKSHIFT_FRAGMENT
    }
  }
  ${RIDER_WORKSHIFT_FRAGMENT}
`
