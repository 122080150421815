import { gql } from '@apollo/client'

export const CUSTOMER_BOOK_ENTRY_FRAGMENT = gql`
  fragment CUSTOMER_BOOK_ENTRY_FRAGMENT on CustomerBookEntry {
    id
    description{
      en
      es
    }
    customer
    value
    type
    event
    auditMessage
    relatedPurchase
    note
    voided
    createdAt
    updatedAt
  }
`
