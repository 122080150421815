import { gql }from '@apollo/client'

import { PURCHASE_FEEDBACK_FRAGMENT } from './'

export const PURCHASE_FEEDBACKS_QUERY = gql`
  query purchaseFeedbacksPaginated($vendor: ID, $date: [String], $offset: Int, $limit: Int){
     purchaseFeedbacksPaginated(vendor: $vendor, date: $date, offset: $offset, limit: $limit) {
       nodes{
         ...PURCHASE_FEEDBACK_FRAGMENT
       }
       pageInfo{
         limit
         totalNodes
         firstNode
         lastNode
         totalPages
         actualPage
         hasNextPage
       }       
     }
  }
  ${PURCHASE_FEEDBACK_FRAGMENT}
`
