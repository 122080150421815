import React from 'react'

import { formatPrice } from '../../../helpers'
import { IntlText } from '../../../components'

import './style.css'

export function RiderWorkshiftDetails({data}) {

  return(
    <div className='workshift-details'>
      <div className='detail'>
        <span className='amount'>{data.completedDeliveries}</span>
        <span className='note'><IntlText group="day-closing" id="completed-deliveries" /></span>
      </div>
      <div className='detail'>
        <span className='amount'>{formatPrice(data.openingBalance)}</span>
        <span className='note'><IntlText group="day-closing" id="starting-balance" /></span>
      </div>
      <div className='detail plus'>
        <span className='amount'>{formatPrice(data.totalRevenue)}</span>
        <span className='note'><IntlText group="day-closing" id="revenue" /></span>
      </div>
      <div className='detail minus'>
        <span className='amount'>{formatPrice(data.totalModifications)}</span>
        <span className='note'><IntlText group="day-closing" id="modifications" /></span>
      </div>
      <div className='detail minus'>
        <span className='amount'>{formatPrice(data.totalTips)}</span>
        <span className='note'><IntlText group="day-closing" id="tips" /></span>
      </div>
    </div>   
  )
  
}