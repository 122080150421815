import { gql } from '@apollo/client'

export const RIDER_BOOK_ENTRY_FRAGMENT = gql`
  fragment RIDER_BOOK_ENTRY_FRAGMENT on RiderBookEntry {
    id
    rider
    relatedPurchase
    value
    type
    event
    auditMessage
    note
    voided
    createdAt
    updatedAt
  }
`
