import FontAwesome from 'react-fontawesome'

import { stringToColor } from '../../../../helpers'
import { Tooltip } from '../../../../components'

export function OrderTagsField({purchase}) {

  return(
    <div className="order-tags-field">
      <div className="tags">
        {purchase.voided ?
          <Tooltip text="Voided"><div><FontAwesome name="gift" /></div></Tooltip>
        :null}
        {purchase.refunds?.length > 0 ?
          <Tooltip text="Has refund"><div><FontAwesome name="undo" /></div></Tooltip>
        :null}
        {purchase.banknote ?
          <Tooltip text={`Change for ${purchase.banknote}`}><div><FontAwesome name="money-bill" /></div></Tooltip>
        :null}
        {(purchase?.payment === "CARD_CONEKTA" || purchase?.payment === "CARD_MERCADOPAGO" || purchase?.payment === "CARD_STRIPE") ?
          <Tooltip text={`Paid with online payment via ${purchase.payment}. Current status ${purchase.paymentStatus}`}><div><FontAwesome name="credit-card" /></div></Tooltip>
        :null}
        {purchase.customerNote ?
          <Tooltip text={`${purchase.customerNote}`}><div><FontAwesome name="info-circle" /></div></Tooltip>
        :null}
        {purchase.isMobileApp ?
          <Tooltip text={`Mobile app order`}><div><FontAwesome name="mobile" style={{color: "#a3a3a3"}}/></div></Tooltip>
        :null}
        {purchase?.notes?.length > 0 ?
          <Tooltip text={`${purchase.notes.length} ${purchase.notes.length === 1 ? "note" : "notes"}`}><div><FontAwesome name="sticky-note" /></div></Tooltip>
        :null}
        {purchase.group ? <FontAwesome name="link" style={{color:  stringToColor(purchase.group)}}/>:null}
      </div>
    </div>
  )
}
