import { gql } from '@apollo/client'

import { PURCHASE_FRAGMENT } from './'

export const CHANGE_STATUS_MUTATION = gql`
  mutation changeStatus($id: ID!, $status:PURCHASE_STATUS){
    changeStatus(id: $id, status:$status){
      ...PURCHASE_FRAGMENT
    }
  }
  ${PURCHASE_FRAGMENT}
`
