import React from 'react'
import { useMutation } from '@apollo/client'

import { useConfirm, useIntl } from '../../../hooks'
import { toast } from '../../../helpers'
import { ADMINS, DELETE_ADMIN } from '../../../graphql'
import { Loader, Table, ContextMenu } from '../../../components'

export function AdminList({data}) {

  const { confirm } = useConfirm()
  const { getTranslation } = useIntl()
  const [deleteAdmin, {loading: deleteLoading}] = useMutation(DELETE_ADMIN, {refetchQueries: [{query: ADMINS}]})

  async function _delete(id){
    const alert = toast.loading(getTranslation({group: "confirm", id: "confirm-to-continue"}))
    try {
      await confirm({title: getTranslation({group: "confirm", id: "delete"}), desc: getTranslation({group: "confirm", id: "delete-info"})})
      alert.update(getTranslation({id: "loading"}))
      await deleteAdmin({variables: {id}})
      alert.success(getTranslation({id: "delete-success"}))
    } catch (error) {
      alert.apolloError(error)
    }
  }    

  return(
    <div id="admins-list">
      <Loader loading={deleteLoading} theme="main" overlay/>
      <Table.Table>
        <Table.Head>
          <Table.Tr>
            <Table.Th>Name</Table.Th>
            <Table.Th>Role</Table.Th>
            <Table.Th></Table.Th>
          </Table.Tr>
        </Table.Head>
        <Table.Body>
          {data && data.map((admin, key) =>
            <Table.Tr key={key} className="admin">
              <Table.Td className="nowrap">{admin.name}</Table.Td>
              <Table.Td className="spacer">{admin.role}</Table.Td>
              <Table.Td>
                <ContextMenu.Menu id={admin?.id}>
                  <ContextMenu.Button theme="invalid" onClick={() => _delete(admin.id)} icon="trash">Delete</ContextMenu.Button>
                </ContextMenu.Menu>
              </Table.Td>
            </Table.Tr>
          )}
        </Table.Body>
      </Table.Table>
    </div>
  )
}
