import React from 'react'
import moment from 'moment'
import { useNavigate } from 'react-router-dom'
import { useMutation } from '@apollo/client'

import { FINISH_RIDER_WORKSHIFT } from '../../../graphql'
import { useValidatedForm, useModal, useIntl, useConfirm } from '../../../hooks'
import { toast } from '../../../helpers'
import { IntlText, Button, Form, Validation, LabeledInput, HourPicker, Modalv3, DatePicker, Title } from '../../../components'
import { RiderWorkshiftTotal } from '../../../views'

const validations = {
  collectedCash: [{id: "min0", validation: (val) => !!(parseInt(val) >= 0)}]
}

export function FinishRiderWorkshift({data, loading, cancel}) {

  const modal = useModal()
  const navigate = useNavigate()
  const { confirm } = useConfirm()
  const { getTranslation } = useIntl()
  const [finishWorkshift] = useMutation(FINISH_RIDER_WORKSHIFT)
  const { form: { collectedCash, time, date }, updateField, errors, validate, resetForm } = useValidatedForm({
    date: moment().format("YYYY-MM-DD"),
    time: moment().format("HHmm"),
  }, validations)

  async function _finishWorkshift(){
    const alert = toast.loading(getTranslation({group: "confirm", id: "confirm-to-continue"}))
    try {
      const { valid } = validate()

      if (!valid) throw new Error(getTranslation({id: "form-error"}))

      await confirm({title: getTranslation({group: "finish-rider-workshift", id: "confirm"}), desc: getTranslation({group: "finish-rider-workshift", id: "confirm-info", variables: {amount: data.netRiderBalance-(collectedCash || 0)}})})
      alert.update(getTranslation({id: "loading"}))
      await finishWorkshift({variables: {
        rider: data.rider.originalId, 
        data: {
          collectedCash: parseInt(collectedCash), 
          time: moment(`${date} ${time}`, "YYYY-MM-DD HHmm").format("YYYY-MM-DD HH:mm"),
        }
      }})
      resetForm()
      cancel()
      alert.success(getTranslation({group: "finish-rider-workshift", id: "finish-success", variables: {name: data.rider.name}}))
      navigate(-1)
    } catch (e) {
      alert.apolloError(e)
    }
  }

  function setDate(obj){
    updateField({key: "date", value: moment(obj).format("YYYY-MM-DD")})
    modal.hide()
  }  
  
  return(
    <>
      <Form.Form>
        <Form.Field>
          <RiderWorkshiftTotal data={data} />             
        </Form.Field>
        <Form.Field>
          <Validation errors={errors.collectedCash}>
            <LabeledInput inputMode="numeric" placeholder={getTranslation({group: "finish-rider-workshift", id: "collected-money"})} value={collectedCash} onChange={(e) => updateField({key: "collectedCash", value: e.target.value})} />
          </Validation>
        </Form.Field>
        <Form.Fieldset style={{alignItems: "stretch"}}>
          <Form.Field>
            <Button style={{height: "100%", fontSize: "var(--font-m)"}} onClick={() => modal.show()}>{date}</Button>
          </Form.Field>
          <Form.Field style={{justifyContent: "center", display: "flex"}}>
            <HourPicker minsStep={5} time={time} onChange={(data) => updateField({key: "time", value: `${data.hours}${data.mins}`})}/>
          </Form.Field>
        </Form.Fieldset>
        <Form.Fieldset>
          <Form.Field fluid>
            <Button className="fluid" onClick={cancel}><IntlText id="cancel" /></Button>
          </Form.Field>
          <Form.Field fluid>
            <Button className="fluid" theme="main" loading={loading} onClick={_finishWorkshift}><IntlText group="finish-rider-workshift" id="collect" /></Button>
          </Form.Field>
        </Form.Fieldset>
      </Form.Form>
      <Modalv3 id="select-date" contentStyle={{overflow: "hidden"}} modal={modal} header={<Title tag="h3"><IntlText group="select-date" id="modal-title" /></Title>}>
        <DatePicker.Date fluid date={date ? new Date(`${date}T00:00`) : new Date()} onChange={setDate} />
      </Modalv3>   
    </>
  )
}
