import React from 'react'

import { VendorCard } from '../../views'
import { Title, IntlText } from '../../components'

import './Preview.css'

export default function Preview({vendor}) {

  return(
    <div id="preview">
      <Title tag="h3"><IntlText group="preview" id="title" /></Title>
      <VendorCard vendor={vendor} />
    </div>
  )
}
