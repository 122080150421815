import React, { useContext } from 'react'
import { toast } from 'react-toastify'
import { useMutation } from '@apollo/client'
import { useParams } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'

import { useValidatedForm } from '../../hooks'
import { CREATE_PAGE_MUTATION } from '../../graphql'
import { IntlContext } from '../../contexts'
import { HtmlEditor, LabeledInput, Loader, Wrapper, Form, LabeledTextarea, IntlText } from '../../components'
import { PageHeader, PageOptions } from '../../views'

export function NewPage(){

  const { language } = useParams()
  const navigate = useNavigate()
  const { getTranslation } = useContext(IntlContext)
  const _language = language || "en"
  const { form, updateField, updateMultilangField, getEditedData } = useValidatedForm({})
  const [createPage, { loading }] = useMutation(CREATE_PAGE_MUTATION)

  async function _createPage(){
    try {
      createPage({variables: {data: getEditedData()}})
      navigate("/pages")
      return toast.success(getTranslation({id: "create-success"}))
    } catch (e) {
      e.graphQLErrors.map(x => toast.error(x.message))
    }
  }

  return(
    <Wrapper>
      <div id="new-page" className="view">
        <div className="row padding">
          {loading && <Loader theme="main" overlay />}
          <PageHeader slug={form.slug} title={form.title} titlePlaceholder="New Page" onChange={({target}) => updateField({key: target.name, value: target.value})} />
          <div className="grids">
            <div className="grid">
              <Form.Form>
                <Form.Field>
                  <LabeledInput name="title" placeholder="Page title" value={form.title?.[_language]} onChange={({target}) => updateMultilangField({key: target.name, value: target.value, language: _language})} />
                </Form.Field>
                <Form.Field>
                  <LabeledTextarea id={`editor-${_language}`} name="description" placeholder="Page description" value={form.description?.[_language]} onChange={({target}) => updateMultilangField({key: target.name, value: target.value, language: _language})}/>
                </Form.Field>
                <Form.Field>
                  <HtmlEditor id={`editor-${_language}`} name="content" placeholder="Page content" value={form.content?.[_language]} onChange={({text, target}) => updateMultilangField({key: target.name, value: text, language: _language})}/>
                </Form.Field>
              </Form.Form>
            </div>
            <div className="grid sidebar">
              <PageOptions placeholder={<IntlText id="create" />} language={_language} save={_createPage} loading={loading} url={`/pages/new`} templates={["DEFAULT", "RIDE_WITH_US", "BECOME_PARTNER", "SAVE_AND_SHARE", "CUSTOM"]} />
            </div>
          </div>
        </div>       
      </div>
    </Wrapper>
  )
}
