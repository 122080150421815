import { gql } from '@apollo/client'

import { PRODUCT_ADDON_OPTION_FRAGMENT } from '../fragments'

const EDIT_PRODUCT_ADDON_OPTION_MUTATION = gql`
  mutation editProductAddonOption($id: ID!, $data:EditedProductAddonOptionInput!){
    editProductAddonOption(id: $id, data:$data){
      ...PRODUCT_ADDON_OPTION_FRAGMENT
    }
  }
  ${PRODUCT_ADDON_OPTION_FRAGMENT}
`

export default EDIT_PRODUCT_ADDON_OPTION_MUTATION
