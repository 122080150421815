import { gql } from '@apollo/client'

import { INVOICE_FRAGMENT } from '../fragments'

const EDIT_INVOICE_MUTATION = gql`
  mutation editInvoiceRequest($id: ID, $data:EditedInvoiceRequestInput!){
    editInvoiceRequest(id: $id, data:$data){
      ...INVOICE_FRAGMENT
    }
  }
  ${INVOICE_FRAGMENT}
`

export default EDIT_INVOICE_MUTATION
