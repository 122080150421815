import { gql } from '@apollo/client'

import { DAY_CLOSING_SNAPSHOT } from './'

export const GET_DAY_CLOSING_SNAPSHOT = gql`
  query getDayClosingSnapshot($date: String, $workShiftId: ID, $id: ID){
    getDayClosingSnapshot(date: $date, workShiftId: $workShiftId, id: $id){
      ...DAY_CLOSING_SNAPSHOT
    }
  }
  ${DAY_CLOSING_SNAPSHOT}
`
