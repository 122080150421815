import React from 'react'
import { useMutation } from '@apollo/client'
import { toast } from 'react-toastify'

import { CHANGE_STATUS_MUTATION } from '../../../graphql'
import { ContextMenu, IntlText } from '../../../components'

export function CancelDropdown({purchase}){

  const [changeStatus, { loading }] = useMutation(CHANGE_STATUS_MUTATION)

  async function _changeStatus(status){
    try {
      await changeStatus({variables: {id: purchase.id, status}})
    } catch (e) {
      e.graphQLErrors.map(x => toast.error(x.message))
    }
  }

  return(
    <ContextMenu.Menu id="cancel-dropdown" loading={loading}>
      <ContextMenu.Button  onClick={() => _changeStatus("REJECTED")}><IntlText group="cancel-dropdown" id="REJECTED" /></ContextMenu.Button>
      <ContextMenu.Button  onClick={() => _changeStatus("UNPROCESSED")}><IntlText group="cancel-dropdown" id="UNPROCESSED" /></ContextMenu.Button>
      <ContextMenu.Button  onClick={() => _changeStatus("DELIVERYFAILED")}><IntlText group="cancel-dropdown" id="DELIVERYFAILED" /></ContextMenu.Button>
      <ContextMenu.Button  onClick={() => _changeStatus("CANCELLED")}><IntlText group="cancel-dropdown" id="CANCELLED" /></ContextMenu.Button>
    </ContextMenu.Menu>
  )
}
