import { gql } from '@apollo/client'

import { AUDITLOG_FRAGMENT } from './'

export const AUDITLOG_PURCHASE = gql`
  query auditlogsOfPurchase($purchase: ID!, $type: [AUDIT_LOG_TYPE]!){
     auditlogsOfPurchase(purchase: $purchase, type: $type) {
       ...AUDITLOG_FRAGMENT
     }
  }
  ${AUDITLOG_FRAGMENT}
`
