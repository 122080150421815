import React, { useContext, useState } from 'react'
import FontAwesome from 'react-fontawesome'
import { toast } from 'react-toastify'
import { useMutation } from '@apollo/client'

import { useValidatedForm } from '../../../hooks'
import { VENDOR_BOOK_ENTRIES, CREATE_VENDOR_BOOK_ENTRY, VENDOR_BOOK } from '../../../graphql'
import { Actions, Validation, Select, LabeledInput, LabeledTextarea, IntlText, Button, Form } from '../../../components'
import { IntlContext } from '../../../contexts'
import { VendorBookEntries } from '../../../views'

import './style.css'

export function VendorBalanceHistory({vendor}) {

  const [add, toggleAdd] = useState(false)
  const { getTranslation } = useContext(IntlContext)
  const types = [
    {id: "CASH_CREDIT", label: <div className="valid"><FontAwesome name="plus" />{getTranslation({group: "vendor-balance", id: "select-cash-credit"})}</div>},
    {id: "CASH_DEBIT", label: <div className="invalid"><FontAwesome name="minus" />{getTranslation({group: "vendor-balance", id: "select-cash-debit"})}</div>},
  ]
  const validations = {
    value: [{id: "required", validation: (val) => !!val}],
    event: [{id: "required", validation: (val) => !!val?.id}],
  }

  const [createVendorBookEntry, { loading: createLoading }] = useMutation(CREATE_VENDOR_BOOK_ENTRY)
  const { form: { event, value, note }, errors, updateField, resetForm, validate } = useValidatedForm({}, validations)

  async function _createVendorBookEntry(){
    const { valid } = validate()

    if (!valid) return toast.error(getTranslation({id: "form-error"}))

    try {
      await createVendorBookEntry({variables: { data: {vendor, event: event?.id, note, value: parseFloat(value)}}, refetchQueries: [{query: VENDOR_BOOK_ENTRIES, variables: {vendor}}, {query: VENDOR_BOOK, variables: {vendor}}] })
      resetForm()
      toggleAdd(false)
      toast.success(getTranslation({id: "create-success"}))
    } catch (e) {
      toast.error(e?.message)
      resetForm()
      toggleAdd(false)
    }
  }

  if (add) return(
    <Form.Form>
      <Form.Field>
        <Validation errors={errors.event}>
          <Select placeholder="Type" selected={event} defaultValue="Please select" options={types} onChange={(type) => updateField({key: "event", value: type})} />
        </Validation>
      </Form.Field>
      <Form.Field>
        <Validation errors={errors.value}>
          <LabeledInput name="value" placeholder="Amount" value={value} onChange={({target}) => updateField({key: "value", value: target.value})} />
        </Validation>
      </Form.Field>
      <Form.Field>
        <LabeledTextarea name="note" placeholder="Note" value={note} onChange={({target}) => updateField({key: "note", value: target.value})} />
      </Form.Field>
      <Form.Fieldset>
        <Form.Field fluid>
          <Button className="fluid" type="alt" onClick={() => toggleAdd(false)}><IntlText id="cancel" /></Button>
        </Form.Field>
        <Form.Field fluid>
          <Button className="fluid" loading={createLoading} theme="main" onClick={_createVendorBookEntry}>Create</Button>
        </Form.Field>
      </Form.Fieldset>
    </Form.Form>
  )

  return(
    <div id="balance-history">
      <VendorBookEntries vendor={vendor} history footer={
        <Actions>
          <Button type="alt" onClick={() => toggleAdd(true)}><FontAwesome name="plus-circle" />Add new item</Button>
        </Actions>
      }/>
    </div>
  )
}
