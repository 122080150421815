import React, { useState, useContext } from 'react'
import { useQuery } from '@apollo/client'
import { useLocation } from 'react-router-dom'

import { RIDERS_QUERY } from '../../graphql'
import { useQueryBuilder } from '../../hooks'
import { IntlContext } from '../../contexts'
import { Wrapper, Filters, IntlText } from '../../components'
import { RidersList } from '../../views'

export function Riders() {

  const { getTranslation } = useContext(IntlContext)
  const location = useLocation()
  const locationSearch = new URLSearchParams(location.search)
  const { params, removeParam, buildQuery, updateQuery } = useQueryBuilder({
    data: {
      sort: {key: "sort", label: getTranslation({group: "riders", id: locationSearch.get("sort")}) || getTranslation({group: "riders", id: "CREATED_AT"}), value: locationSearch.get("sort") || "CREATED_AT"},
      type: {key: "type", label: getTranslation({group: "riders", id: locationSearch.get("type")}), value: locationSearch.get("type")},
    },
    page: locationSearch.get("page"),
    limit: 50
  })    
  const [filter, setFilter] = useState()
  const { data, loading, error, refetch } = useQuery(RIDERS_QUERY, {variables: buildQuery(), notifyOnNetworkStatusChange: true})

  if (error) return `Error! ${error.message}`

  return(
    <Wrapper>
      <div id="riders" className="container">
        <div className="row padding">
          <Filters.Bar>
            <Filters.Input id="query" name="query" value={filter} onChange={({target}) => setFilter(target.value)} reset={() => setFilter("")} placeholder={getTranslation({id: "search-type"})} />
            <Filters.Select style={{marginLeft: "auto"}} value={params?.type && <IntlText group="riders" id={params?.type?.value} />} placeholder={getTranslation({group: "riders", id: "type"})} reset={() => removeParam("type")}>
              {["CONTRACTOR", "FULL_TIME_WORKER"].map((elem, key) =>
                <li key={key} onClick={() => updateQuery({type: {key: "type", value: elem}})}><span><IntlText group="riders" id={elem} /></span></li>
              )}
            </Filters.Select>               
            <Filters.Select value={params?.sort && <IntlText group="riders" id={params?.sort?.value} />} placeholder={getTranslation({group: "riders", id: "sort"})} reset={() => removeParam("sort")}>
              {["CREATED_AT", "NAME"].map((elem, key) =>
                <li key={key} onClick={() => updateQuery({sort: {key: "sort", value: elem}})}><span><IntlText group="riders" id={elem} /></span></li>
              )}
            </Filters.Select>               
          </Filters.Bar>
          <RidersList loading={loading} data={data?.riders || []} refetch={refetch} filter={filter} />
        </div>
      </div>
    </Wrapper>
  )
}
