import { gql } from '@apollo/client'

import { VENDOR_FRAGMENT } from './'

export const VENDORS_QUERY = gql`
  query vendors($query: String, $contract: CONTRACT_TYPE, $type: VENDOR_TYPE){
    vendors(query: $query, contract: $contract, type: $type){
      ...VENDOR_FRAGMENT
    }
  }
  ${VENDOR_FRAGMENT}
`
