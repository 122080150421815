import { useContext, useEffect } from 'react'
import { useQuery, useMutation } from '@apollo/client'
import moment from 'moment'
import FontAwesome from 'react-fontawesome'

import { PURCHASES_QUERY, CHANGE_STATUS_MUTATION, EDIT_CUSTOMER_MUTATION, MY_PURCHASES_UPDATED_SUBSCRIPTION, APPROVE_PURCHASE, NEW_PURCHASE_SUBSCRIPTION } from '../../../graphql'
import { getEllapsed, copyToClipboard, toast } from '../../../helpers'
import { useAwakeDetection, useConfirm, useIntl } from '../../../hooks'
import { ModalContext } from '../../../contexts'
import { Loader, ContextMenu, IntlText, Table, Modalv3, Title, Tooltip, Placeholder } from '../../../components'
import { VendorField, CustomerField, RiderName, OrderTagsField, PurchaseCancel, PurchaseCustomer, PurchaseAddress, CustomerBalance, CustomerBalanceHistory } from '../../../views'

import './style.css'

export function DispatcherTasks({runQuery}) {
  const { modals } = useContext(ModalContext)
  const { data, error, loading, networkStatus, subscribeToMore, refetch } = useQuery(PURCHASES_QUERY, {variables: {date: moment().format("YYYY-MM-DD"), status: ["REQUIRES_CONFIRMATION", "PROCESSED", "INPREPARATION", "READYFORCOLLECTION", "ONTHEWAY", "ATLOCATION"]}, fetchPolicy: "network-only", notifyOnNetworkStatusChange: false})

  useAwakeDetection({callback: refetch, minutes: 1})
  useEffect(() => {
    const unsub =  subscribeToMore({
      document: MY_PURCHASES_UPDATED_SUBSCRIPTION,
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data) return prev
        const _data = Array.from(prev?.purchases || [])
        const index = _data.findIndex((purchase) => purchase.id === subscriptionData.data.myPurchasesUpdated.id)
        if (index >= 0){
          if (["CANCELLED", "DELIVERYFAILED", "PAYMENTFAILED", "REJECTED", "UNPROCESSED", "COMPLETED"].includes(_data[index].status)){
            _data.splice(index, 1)
          } else {
            _data[index] = subscriptionData.data.myPurchasesUpdated
          }
          return Object.assign({}, prev, {
            purchases: _data
          })
        }
      }
    })

    const newPurchase = subscribeToMore({
      document: NEW_PURCHASE_SUBSCRIPTION,
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data) return prev
        const _data = Array.from(prev?.purchases || [])
        return Object.assign({}, prev, {
          purchases: [subscriptionData.data.newPurchase, ..._data]
        })
      }
    })


    return () => {
      unsub()
      newPurchase()
    }
  // eslint-disable-next-line
  }, [])    

  if ((loading || error) && networkStatus === 1) return <Loader theme="main"/>

  const tasks = data?.purchases?.reduce((current, next) => {
    const processTime = getEllapsed(next?.timestamps?.processed || next?.createdAt)
    const lastModifiedTime = getEllapsed(next?.updatedAt)
    const createdAtTime = getEllapsed(next?.createdAt)
    
    if (!next?.reportTimestamps && (lastModifiedTime <= 2 && !next?.problem && createdAtTime > 2)) return current

    if (next?.problem){
      current = [...current, {purchase: next, task: "MARKED"}]
      return current
    }

    if (next?.status === "REQUIRES_CONFIRMATION"){

      if (next?.customer?.banned){
        current = [...current, {purchase: next, task: "BANNED_USER", info: next?.requiresConfirmation?.[0], time: processTime, message: next?.customer?.note, isAction: true}]
        return current
      }
      
      if (getEllapsed(next?.createdAt) >= 10){
        current = [...current, {purchase: next, task: "REJECT_ORDER", info: next?.requiresConfirmation?.[0], time: getEllapsed(next?.createdAt) - 10, isAction: true}]
        return current
      }

      current = [...current, {purchase: next, task: "CONFIRM_CLIENT", info: next?.requiresConfirmation?.[0], time: processTime, isAction: true}]
      return current
    }

    if (next?.status === "PROCESSED"){
      console.log("ASd");
      if (processTime >= 10){
        current = [...current, {purchase: next, task: "UNPROCESSED", time: processTime - 10, isAction: true}]
        return current
      }
    }

    if (next?.status === "INPREPARATION" || next?.status === "READYFORCOLLECTION"){
      const assignedToRiderTime = getEllapsed(next?.timestamps?.assignedToRider)
      const inPreparationTime = getEllapsed(next?.timestamps?.vendorConfirmed)
      const arrivedAtVendorTime = getEllapsed(next?.timestamps?.arrivedAtVendor)

      if (next?.rider && next?.reportTimestamps?.riderDidNotArrive){
        current = [...current, {purchase: next, task: "RIDER_NOT_ARRIVED_TO_VENDOR", time: getEllapsed(next?.reportTimestamps?.riderDidNotArrive)}]
        return current        
      }

      if (next?.reportTimestamps?.vendorIsNotReady){
        current = [...current, {purchase: next, task: "VENDOR_NOT_READY", time: getEllapsed(next?.reportTimestamps?.vendorIsNotReady)}]
        return current        
      }

      if (next?.rider && next?.riderConfirmed && next?.arrivedAtVendor && arrivedAtVendorTime >= 10 && (inPreparationTime > next?.preparationTime + 5)){
        current = [...current, {purchase: next, task: "VENDOR_DELAYED", time: arrivedAtVendorTime - 10, isAction: true}]
        return current
      }

      if (next?.rider && next?.riderConfirmed && next?.arrivedAtVendor && arrivedAtVendorTime >= 5 && (inPreparationTime > next?.preparationTime + 5)){
        current = [...current, {purchase: next, task: "VENDOR_NOT_READY", time: Math.min(arrivedAtVendorTime - 5, lastModifiedTime - 2)}]
        return current
      }

      if (next?.rider && next?.riderConfirmed && assignedToRiderTime >= 10 && !next?.arrivedAtVendor && inPreparationTime > next?.preparationTime){
        current = [...current, {purchase: next, task: "RIDER_NOT_ARRIVED_TO_VENDOR", time: assignedToRiderTime - 10}]
        return current
      }

      if (next?.rider && !next?.riderConfirmed && assignedToRiderTime > 5){

        if (assignedToRiderTime >= 10){
          current = [...current, {purchase: next, task: "CHANGE_RIDER", time: assignedToRiderTime - 10, isAction: true}]
          return current
        }
        current = [...current, {purchase: next, task: "CONFIRM_RIDER", time: assignedToRiderTime - 5}]
        return current
      }

      if (inPreparationTime > (next?.preparationTime + 15) && !next?.rider){
        current = [...current, {purchase: next, task: "WAIT_FOR_RIDER", time: next?.preparationTime - (next?.preparationTime + 15)}]
        return current
      }

    }

    if (next?.status === "ONTHEWAY"){
      const onTheWayTime = getEllapsed(next?.timestamps?.collected)
      const estimatedTime = next?.estimatedDeliveryTime || 10

      if (onTheWayTime >= estimatedTime + 10){
        current = [...current, {purchase: next, task: "ON_THE_WAY_LONG", time: onTheWayTime - (estimatedTime + 10)}]
        return current
      }
    }

    if (next?.status === "ATLOCATION"){
      const arrivedTime = getEllapsed(next?.timestamps?.arrivedAtClient)

      if (next?.reportTimestamps?.clientDoesNotRepond){
        current = [...current, {purchase: next, task: "WAIT_FOR_CLIENT_LONG", time: getEllapsed(next?.reportTimestamps?.clientDoesNotRepond)}]
        return current        
      }      

      if (arrivedTime >= 15){
        current = [...current, {purchase: next, task: "DELIVERY_FAILED", time: arrivedTime - 15, isAction: true}]
        return current
      }

      if (arrivedTime >= 5){
        current = [...current, {purchase: next, task: "WAIT_FOR_CLIENT_LONG", time: Math.min(arrivedTime - 5, lastModifiedTime - 2)}]
        return current
      }

    }

    return current
  }, [])

  if (!tasks?.length > 0) return <Placeholder.Search>There is nothing to do at the moment</Placeholder.Search>
  
  const _tasks = tasks.filter((_t) => !_t.isAction)
  const _actions = tasks.filter((_t) => !!_t.isAction)

  return(
    <div id="tasks">
      {_actions?.length > 0 &&
        <div className='block'>
          <div className='block-title'>
            <Title tag="h3">Active tasks</Title>
            <span className='info'>Orders that requirme immediate action</span>
          </div>          
          <Table.Table headers={["", "Vendor", "Customer", "Zone", "Rider", "Task", "Tags", "Time", "Actions"]} >
            <Table.Body>
              {_actions.sort(({time: a}, {time: b}) => (b || 1)-(a || 1)).map((data, key) => <Task key={key} {...data} runQuery={runQuery} />)}
            </Table.Body>
          </Table.Table>
        </div>
      }
      {_tasks?.length > 0 && 
        <div className='block'>
          <div className='block-title'>
            <Title tag="h3">Warnings</Title>
            <span className='info'>Pay attention on this orders, they took longer than expected</span>
          </div>
          <Table.Table headers={["", "Vendor", "Customer", "Zone", "Rider", "Task", "Tags", "Time", "Actions"]} >
            <Table.Body>
              {_tasks.sort(({time: a}, {time: b}) => (b || 1)-(a || 1)).map((data, key) => <Task key={key} {...data} runQuery={runQuery} />)}
            </Table.Body>
          </Table.Table>
        </div>
      }
      <Modalv3 id="cancel-purchase" contentStyle={{overflow: "hidden"}} modal={modals.cancelPurchase} header={<Title tag="h3"><IntlText group="cancel-purchase" id="modal-title" /></Title>}>
        <PurchaseCancel purchase={modals.cancelPurchase.state} />
      </Modalv3>        
      <Modalv3 modal={modals.purchaseAddress} header={<Title tag="h3"><IntlText group="purchase-address-modal" id="title" /></Title>}>
        <PurchaseAddress purchase={modals.purchaseAddress.state} />
      </Modalv3>        
      <Modalv3 modal={modals.purchaseCustomer} header={<Title tag="h3"><IntlText group="purchase-customer-modal" id="title" /></Title>}>
        <PurchaseCustomer purchase={modals.purchaseCustomer.state} />
      </Modalv3>        
      <Modalv3 modal={modals.customerBalance} header={<Title tag="h3"><IntlText group="customer-balance-modal" id="title" /></Title>}>
        <div>
          <CustomerBalance customer={modals.customerBalance.state?.customer?.originalId}/>
          <CustomerBalanceHistory customer={modals.customerBalance.state?.customer?.originalId}/>
        </div>
      </Modalv3>     
    </div>
  )
}


function Task({purchase, task, time, info, message, runQuery}){

  const { modals } = useContext(ModalContext)
  const { getTranslation } = useIntl()
  const { confirm } = useConfirm()
  const [editCustomer] = useMutation(EDIT_CUSTOMER_MUTATION)
  const [changeStatus] = useMutation(CHANGE_STATUS_MUTATION)
  const [approvePurchase] = useMutation(APPROVE_PURCHASE)  

  async function _changeStatus({purchase, status, title, desc}) {
    const alert = toast.loading(getTranslation({group: "confirm", id: "confirm-to-continue"}))
    try {
      await confirm({title, desc})
      alert.update(getTranslation({id: "loading"}))
      await changeStatus({variables: {id: purchase.id, status }})
      alert.success(getTranslation({id: "change-success"}))
    } catch (e) {
      alert.apolloError(e)
    }
  }
 
  async function _approvePurchase({purchase, title, desc}) {
    const alert = toast.loading(getTranslation({group: "confirm", id: "confirm-to-continue"}))
    try {
      await confirm({title, desc})
      alert.update(getTranslation({id: "loading"}))
      editCustomer({variables: {id: purchase?.customer.originalId, data: {abusive: false, tags: null}}})
      await approvePurchase({variables: {id: purchase.id }})
      alert.success(getTranslation({id: "change-success"}))
    } catch (e) {
      alert.apolloError(e)
    }
  }

  function selectPurchase(purchase){
    runQuery({instantParams: {query: {value: purchase?.id, id: "search", label: `Search for ${purchase.id}`}}})
  }

  function getActions({task, purchase}){
    let riderText = ""
    let customerText = {}
    let customerName = purchase?.customer?.name
    let riderName = purchase?.rider?.name

    switch (task) {
      case "UNPROCESSED":
         customerText = {
           ES: `Hola ${customerName}. Lo sentimos, el restaurante no procesó su pedido y se canceló..`,
           EN: `Hola ${customerName}. Sorry the restaurant didn't process your order and it got cancelled.`
         }

        return(
          <ContextMenu.Menu id={purchase?.id} placeholder="Select action">
            <ContextMenu.Button onClick={() => copyToClipboard(customerText[purchase.customer.language])} icon="user">Message customer</ContextMenu.Button>
            <ContextMenu.Button onClick={() => selectPurchase(purchase)} icon="eye">Show purchase</ContextMenu.Button>
            <ContextMenu.Button className="invalid" onClick={() => _changeStatus({purchase, status: "UNPROCESSED", title: getTranslation({group: "dispatcher-tasks", id: "unprocessed-confirm"}), desc: getTranslation({group: "dispatcher-tasks", id: "unprocessed-confirm-info"})})} icon="times">Cancel order</ContextMenu.Button>
          </ContextMenu.Menu>
        )
      case "ON_THE_WAY_LONG":
         riderText = `${riderName} todo bien en camino? No olvides a cambiar tu estado?`

        return(
          <ContextMenu.Menu id={purchase?.id} placeholder="Select action">
            <ContextMenu.Button onClick={() => copyToClipboard(riderText)} icon="motorcycle">Message rider</ContextMenu.Button>
            <ContextMenu.Button onClick={() => selectPurchase(purchase)} icon="eye">Show purchase</ContextMenu.Button>
          </ContextMenu.Menu>
        )
      case "CONFIRM_CLIENT":
         customerText = {
           ES: `Hola soy de ${process.env.REACT_APP_CLIENT_HOME} y me gustaría confirmar un pedido para ${customerName}.`,
           EN: `Hello I'm from ${process.env.REACT_APP_CLIENT_HOME} and I would like to confirm an order for ${customerName}.`
         }

        return(
          <ContextMenu.Menu id={purchase?.id} placeholder="Select action">
            <ContextMenu.Button onClick={() => copyToClipboard(customerText[purchase.customer.language])} icon="user">Message customer</ContextMenu.Button>
            <ContextMenu.Button className="valid" onClick={() => _approvePurchase({purchase, title: getTranslation({group: "dispatcher-tasks", id: "process-confirm"}), desc: getTranslation({group: "dispatcher-tasks", id: "process-confirm-info"})})} icon="check">Confirm order</ContextMenu.Button>
            <ContextMenu.Button className="invalid" onClick={() => _changeStatus({purchase, status: "CANCELLED", title: getTranslation({group: "dispatcher-tasks", id: "reject-confirm"}), desc: getTranslation({group: "dispatcher-tasks", id: "reject-confirm-info"})})} icon="times">Reject order</ContextMenu.Button>
            <ContextMenu.Button onClick={() => selectPurchase(purchase)} icon="eye">Show purchase</ContextMenu.Button>
          </ContextMenu.Menu>
        )
      case "REJECT_ORDER":
        return(
          <ContextMenu.Menu id={purchase?.id} placeholder="Select action">
            <ContextMenu.Button className="valid" onClick={() => _approvePurchase({purchase, title: getTranslation({group: "dispatcher-tasks", id: "process-confirm"}), desc: getTranslation({group: "dispatcher-tasks", id: "process-confirm-info"})})} icon="check">Confirm order</ContextMenu.Button>
            <ContextMenu.Button className="invalid" onClick={() => _changeStatus({purchase, status: "CANCELLED", title: getTranslation({group: "dispatcher-tasks", id: "reject-confirm"}), desc: getTranslation({group: "dispatcher-tasks", id: "reject-confirm-info"})})} icon="times">Reject order</ContextMenu.Button>
            <ContextMenu.Button onClick={() => selectPurchase(purchase)} icon="eye">Show purchase</ContextMenu.Button>
          </ContextMenu.Menu>
        )
      case "BANNED_USER":
        return(
          <ContextMenu.Menu id={purchase?.id} placeholder="Select action">
            <ContextMenu.Button className="invalid" onClick={() => _changeStatus({purchase, status: "CANCELLED", title: getTranslation({group: "dispatcher-tasks", id: "reject-confirm"}), desc: getTranslation({group: "dispatcher-tasks", id: "reject-confirm-info"})})} icon="times">Reject order</ContextMenu.Button>
            <ContextMenu.Button onClick={() => selectPurchase(purchase)} icon="eye">Show purchase</ContextMenu.Button>
          </ContextMenu.Menu>
        )
      case "DELIVERY_FAILED":
        riderText = `${riderName} todo bien con el cliente? Aun no contesto? Ya vamos a cancelar y regresamos mas tarde si contesta`
        customerText = {
         ES: `Hola ${customerName}, soy de ${process.env.REACT_APP_CLIENT_HOME}. Nuestro repartidor ${riderName}, ya esta esperando afuera hace tiempo. Tenemos que canceler el pedido en unos minutes si ni puede entregarle, disculpa.`,
         EN: `Hello ${customerName}, I'm from ${process.env.REACT_APP_CLIENT_HOME}. Our delivery guy, ${riderName} is waiting outside a while ago. Soon we have to cancel the order if he can not deliver it, sorry.`
        }

        return(
          <ContextMenu.Menu id={purchase?.id} placeholder="Select action">
            <ContextMenu.Button onClick={() => copyToClipboard(customerText[purchase.customer.language])} icon="user">Message customer</ContextMenu.Button>
            <ContextMenu.Button onClick={() => copyToClipboard(riderText)} icon="motorcycle">Message rider</ContextMenu.Button>
            <ContextMenu.Button className="invalid" onClick={() => modals.cancelPurchase.show(purchase)} icon="times">Delivery failed</ContextMenu.Button>
            <ContextMenu.Button onClick={() => selectPurchase(purchase)} icon="eye">Show purchase</ContextMenu.Button>
          </ContextMenu.Menu>
        )
      case "CONFIRM_RIDER":
        riderText = `${riderName} tienes un pedido confirmale por favor!`

        return(
          <ContextMenu.Menu id={purchase?.id} placeholder="Select action">
            <ContextMenu.Button onClick={() => copyToClipboard(riderText)} icon="motorcycle">Message rider</ContextMenu.Button>
            <ContextMenu.Button onClick={() => selectPurchase(purchase)} icon="eye">Show purchase</ContextMenu.Button>
          </ContextMenu.Menu>
        )
      case "RIDER_NOT_ARRIVED_TO_VENDOR":
        riderText = `${riderName} todo bien en el camino? No olvides confirmar tu estado!`

        return(
          <ContextMenu.Menu id={purchase?.id} placeholder="Select action">
            <ContextMenu.Button onClick={() => copyToClipboard(riderText)} icon="motorcycle">Message rider</ContextMenu.Button>
            <ContextMenu.Button onClick={() => selectPurchase(purchase)} icon="eye">Show purchase</ContextMenu.Button>
          </ContextMenu.Menu>
        )
      case "WAIT_FOR_RIDER":
        return(
          <ContextMenu.Menu id={purchase?.id} placeholder="Select action">
            <ContextMenu.Button onClick={() => selectPurchase(purchase)} icon="eye">Show purchase</ContextMenu.Button>
          </ContextMenu.Menu>
        )
      case "VENDOR_NOT_READY":
        riderText = `${riderName} como va en el restaurante, no estan listos todavia? Pregunta cuanto falta por favor.`

        return(
          <ContextMenu.Menu id={purchase?.id} placeholder="Select action">
            <ContextMenu.Button onClick={() => copyToClipboard(riderText)} icon="motorcycle">Message rider</ContextMenu.Button>
            <ContextMenu.Button onClick={() => selectPurchase(purchase)} icon="eye">Show purchase</ContextMenu.Button>
          </ContextMenu.Menu>
        )
      case "VENDOR_DELAYED":
        riderText = `${riderName} pregunta por favor cuanto mas falta y si aun no esta, cambiamos tu pedido porque ya no podemos esperar mas.`

        return(
          <ContextMenu.Menu id={purchase?.id} placeholder="Select action">
            <ContextMenu.Button onClick={() => copyToClipboard(riderText)} icon="motorcycle">Message rider</ContextMenu.Button>
            <ContextMenu.Button onClick={() => selectPurchase(purchase)} icon="eye">Show purchase</ContextMenu.Button>
          </ContextMenu.Menu>
        )
      case "WAIT_FOR_CLIENT_LONG":
        riderText = `${riderName} todo bien con el cliente?`
        customerText = {
          ES: `Hola ${customerName}, soy de ${process.env.REACT_APP_CLIENT_HOME}. El repartidor llega a su ubicacion, puedes salir por un momento por favor? Gracias y buen provecho!`,
          EN: `Hello ${customerName}, I'm from ${process.env.REACT_APP_CLIENT_HOME} The delivery guy has arrived to your location, can you please check it? Thank you and enjoy your meal!`
        }

        return(
          <ContextMenu.Menu id={purchase?.id} placeholder="Select action">
            <ContextMenu.Button onClick={() => copyToClipboard(riderText)} icon="motorcycle">Message rider</ContextMenu.Button>
            <ContextMenu.Button onClick={() => copyToClipboard(customerText[purchase.customer.language])} icon="user">Message customer</ContextMenu.Button>
            <ContextMenu.Button onClick={() => selectPurchase(purchase)} icon="eye">Show purchase</ContextMenu.Button>
          </ContextMenu.Menu>
        )
      default:
        return(
          <ContextMenu.Menu id={purchase?.id} placeholder="Select action">
            <ContextMenu.Button onClick={() => selectPurchase(purchase)} icon="eye">Show purchase</ContextMenu.Button>
          </ContextMenu.Menu>
        )

    }
  }

  return(
    <Table.Tr className={`task ${purchase.status.toLowerCase()}`}>
      <Table.Td className="status"><Tooltip text={purchase.status}><span><FontAwesome name="circle" /></span></Tooltip></Table.Td>
      <Table.Td className="nowrap"><VendorField purchase={purchase} /></Table.Td>
      <Table.Td><CustomerField purchase={purchase} /></Table.Td>
      <Table.Td className="nowrap"><span>{purchase.address?.zone}</span></Table.Td>
      <Table.Td><RiderName purchase={purchase} /></Table.Td>
      <Table.Td className="task-field">
        <Tooltip text={message ? message : <IntlText group="dispatcher-tasks" id={task} />}>
          <div className="help">
            {task}
            {info && <span className='info'>{info}</span>}
          </div>
        </Tooltip>
      </Table.Td>
      <Table.Td>
        <OrderTagsField purchase={purchase} />
      </Table.Td>
      <Table.Td className="center">{time}</Table.Td>
      <Table.Td className="task-actions">
        {getActions({task, purchase})}
      </Table.Td>
    </Table.Tr>    
  )
}