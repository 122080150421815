import { useMutation } from '@apollo/client'

import { CREATE_SETTING_MUTATION, EDIT_SETTING_MUTATION, SETTINGS_QUERY } from '../../../graphql'
import { useValidatedForm } from '../../../hooks'
import { toast } from '../../../helpers'
import { IntlText, LabeledInput, LabeledTextarea, Select, Button, Validation } from '../../../components'
import { Setting } from '../../../views'

import './style.css'

const types = [
  {id: "NUMBER", label: "Number"},
  {id: "PERCENTAGE", label: "Percentage"},
  {id: "TEXT", label: "Text"},
  {id: "BOOLEAN", label: "Boolean"},
  {id: "JSON", label: "JSON"},
  {id: "LIST", label: "List"}
]

const validations = {
  key: [{id: "required", validation: (val) => !!val}],
  description: [{id: "required", validation: (val) => !!val}],
  type: [{id: "required", validation: (val) => !!(val && val.id)}],
}

export function SettingForm({refetch, initialData = {}, onSuccess}){

  function init(data){

    let _type = types.find(_type => data.type === _type.id) || types[0]
    let _value = (() => {
      switch (_type.id) {
        case "NUMBER":
          return Number(data.value)
        case "BOOLEAN":
          return data.value === "true" ? true : false
        default:
          return data.value
      }
    })()

    return {
      description: data.description,
      key: data.key,
      value: _value,
      type: _type
    }
  }

  const { form, form: {key, description, type, value}, updateField, errors, validate, resetForm } = useValidatedForm(init(initialData), validations)
  const [createSetting, {loading: createLoading}] = useMutation(CREATE_SETTING_MUTATION, {refetchQueries: [{query: SETTINGS_QUERY}]})
  const [editSetting, {loading: editLoading}] = useMutation(EDIT_SETTING_MUTATION)

  async function _createSetting(){
    const alert = toast.loading()
    const { valid } = validate()

    if (!valid) throw new Error("There are errors in the form, please review it")

    let data = {
      ...form,
      value: value.toString(),
      type: type.id,
    }

    try {
      await createSetting({variables: {data}})
      if (onSuccess) onSuccess()
      if (refetch) refetch()
      alert.success("Successfully created")
    } catch (e) {
      alert.apolloError(e)
    }
  }

  async function _editSetting(){
    const alert = toast.loading()
    const { valid } = validate()

    if (!valid) throw new Error("There are errors in the form, please review it")

    let data = {
      ...form,
      value: value.toString(),
      type: type.id,
    }

    try {
      await editSetting({variables: {id: initialData.id, data}})
      if (onSuccess) onSuccess()
      alert.success("Successfully edited")
    } catch (e) {
      alert.apolloError(e)
    }
  }

  return(
    <div id="setting-form">
      <Validation errors={errors.type}>
        <Select placeholder="Setting type" selected={type} defaultValue="Please select" options={types} onChange={(type) => updateField({key: "type", value: type})} />
      </Validation>
      <Validation errors={errors.key}>
        <LabeledInput name="key" placeholder="Setting key" value={key} onChange={(e) => updateField({key: e.target.name, value: e.target.value})} />
      </Validation>
      <Validation errors={errors.description}>
        <LabeledTextarea name="description" placeholder="Setting description" value={description} onChange={(e) => updateField({key: e.target.name, value: e.target.value})} />
      </Validation>
      <Setting type={type} value={value} onChange={updateField} />
      <div className="actions">
        <Button onClick={() => resetForm()}><IntlText id="reset">Reset</IntlText></Button>
        {initialData.id ?
          <Button loading={editLoading} theme="main" onClick={() => _editSetting()}>Edit</Button>
        :
          <Button loading={createLoading} theme="main" onClick={() => _createSetting()}>Create</Button>
        }
      </div>
    </div>
  )

}
