import React from 'react'
import FontAwesome from 'react-fontawesome'
import { toast } from 'react-toastify'

import { IntlText } from '../'

import './RangeSlider.css'

export default function RangeSlider({value = 0, step = 1, onChange, min = 0, max = 100}){

  function _onChange(val){
    if (val < min) return toast.error(<IntlText group="range-slider" id="min-error" />)
    if (val > max) return toast.error(<IntlText group="range-slider" id="max-error" />)
    onChange(Number(val))
  }

  return(
    <div className="range-slider">
      <button onClick={() => _onChange(Number(value)-step)}><FontAwesome name="minus" /></button>
      <input type="range" min="1" max="100" value={value} className="range-slider" id="rider-tip" onChange={(e) => onChange(Number(e.target.value))}/>
      <button onClick={() => _onChange(Number(value)+step)}><FontAwesome name="plus" /></button>
      <div className="value">
        <span>{value.toFixed(0)}</span>
        <span>%</span>
      </div>
    </div>
  )

}
