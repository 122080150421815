import { gql } from '@apollo/client'

import { PRODUCT_FRAGMENT } from './'

export const SOLD_OUT_MUTATION = gql`
  mutation soldOut($id: ID!, $productData: EditedProductInput!){

    editProduct(id: $id, data: $productData){
      ...PRODUCT_FRAGMENT
    }

  }
  ${PRODUCT_FRAGMENT}
`
