import { useQuery, useMutation } from '@apollo/client'

import { SETTINGS_QUERY, EDIT_SETTING_MUTATION } from '../../../graphql'
import { useIntl } from '../../../hooks'
import { toast } from '../../../helpers'
import { Loader, Form, SwitchBox } from '../../../components'

export function PaymentSettings() {

  const { getTranslation } = useIntl()
  const { loading, data, error } = useQuery(SETTINGS_QUERY, {variables: {keys: ["CARD_STRIPE_PAYMENTS_AVAILABLE"]}})
  const [_editSetting] = useMutation(EDIT_SETTING_MUTATION)
  const stripeAvailable = data?.settings?.find(_setting => _setting.key === "CARD_STRIPE_PAYMENTS_AVAILABLE")

  async function editSetting(key, value){
    const alert = toast.loading()
    try {
      await _editSetting({variables: {key, data: {value}}})
      alert.success(getTranslation({id: "change-success"}))
    } catch (e) {
      alert.apolloError(e)
    }
  }

  if (loading || error) return <Loader theme="main"/>

  return(
    <div id="payment-settings">
      <Form.Form>
        <Form.Field>
          <SwitchBox id="CARD_STRIPE_PAYMENTS_AVAILABLE" label={getTranslation({group: "payment-settings", id: `${stripeAvailable?.key}-desc`})} checked={stripeAvailable?.value === "true"} onChange={({target}) => editSetting(stripeAvailable?.key, target.checked.toString())}>{getTranslation({group: "payment-settings", id: stripeAvailable?.key})}</SwitchBox>
        </Form.Field>
      </Form.Form>
    </div>
  )
}
