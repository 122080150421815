import { gql } from '@apollo/client'

const PRODUCT_ADDON_OPTION_FRAGMENT = gql`
  fragment PRODUCT_ADDON_OPTION_FRAGMENT on ProductAddonOption {
    id
    name{
      es
      en
    }
    price
    disabled
  }
`

export default PRODUCT_ADDON_OPTION_FRAGMENT
