import { gql } from '@apollo/client'

import { CHANGELOG_FRAGMENT } from './'

export const CREATE_CHANGELOG = gql`
  mutation createChangelog($data: ChangelogInput!){
    createChangelog(data: $data){
      ...CHANGELOG_FRAGMENT
    }
  }
  ${CHANGELOG_FRAGMENT}
`
