import React, { useContext } from 'react'
import { useMutation } from '@apollo/client'

import { EDIT_PAGE_MUTATION } from '../../../graphql'
import { IntlContext } from '../../../contexts'
import { getCommonAsset, toast } from '../../../helpers'
import { Button, EditBox, Form, IntlText, Link, SelectV2 } from '../../../components'

export function PageOptions({placeholder, loading, save, language, url, page, templates}){

  const [editPage] = useMutation(EDIT_PAGE_MUTATION)
  const { getTranslation } = useContext(IntlContext)

  async function _editPage(data){
    const alert = toast.loading()

    try {
      editPage({variables: {id: page?.id, data}})
      alert.success(getTranslation({id: "edit-success"}))
    } catch (e) {
      alert.apolloError(e)
    }
  }

  return(
    <Form.Form>
        <Form.Field>
            <Button fluid loading={loading} disabled={loading} theme="main" onClick={save}>{placeholder ? placeholder : <IntlText id="save" />}</Button>
        </Form.Field>            
        <Form.Field>
          <SelectV2 value={(_value) => <IntlText group="pages" id={_value} />} placeholder={<IntlText group="pages" id="template" />} selected={templates?.find(_t => _t === page?.template)} options={templates} onChange={(_option) => _editPage({template: _option})} />
        </Form.Field>            
        <Form.Field>
          {(page?.template === "CUSTOM" || !templates?.includes(page?.template)) &&
            <EditBox.Text placeholder={<div><IntlText group="pages" id="custom-template" /></div>} save={(value) => _editPage({template: value})}>{page?.template}</EditBox.Text>
          }
        </Form.Field>            
        <Form.Legend style={{textAlign: "center", fontWeight: "bold"}}>Available languages</Form.Legend>
        <Form.Field>
            <Link to={`${url}/en`}><Button fluid active={language === "en"} innerStyle={{display: "flex", alignItems: "center"}}><img style={{width: 30, height: "auto", marginRight: 5}} src={getCommonAsset("en.png")} alt="English" />English</Button></Link>
        </Form.Field>            
        <Form.Field>
            <Link to={`${url}/es`}><Button fluid active={language === "es"} innerStyle={{display: "flex", alignItems: "center"}}><img style={{width: 30, height: "auto", marginRight: 5}} src={getCommonAsset("es.png")} alt="Spanish" />Spanish</Button></Link>
        </Form.Field>                
    </Form.Form>
  )
}
