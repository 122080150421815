import { gql } from '@apollo/client'

const REORDER_VENDORS_MUTATION = gql`
  mutation reorderVendors($data: [VendorReorderInput]){
    reorderVendors(data: $data){
      success
    }
  }
`

export default REORDER_VENDORS_MUTATION
