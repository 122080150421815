import { gql } from '@apollo/client'

import { SERVICE_FEE_PROFORMA_INVOICE_FRAGMENT } from './'

export const CREATE_CFDI_OF_SERVICE_FEE = gql`
  mutation createCFDIOfServiceFeeProformaInvoice($id: ID!){
    createCFDIOfServiceFeeProformaInvoice(id: $id){
      ...SERVICE_FEE_PROFORMA_INVOICE_FRAGMENT
    }
  }
  ${SERVICE_FEE_PROFORMA_INVOICE_FRAGMENT}
`
