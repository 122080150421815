import { gql } from '@apollo/client'

import { RIDER_FRAGMENT } from '../rider'

const RIDER_QUERY = gql`
  query rider($slug:String, $id:ID){
    rider(slug: $slug, id:$id){
      ...RIDER_FRAGMENT
    }
  }
  ${RIDER_FRAGMENT}
`

export default RIDER_QUERY
