import { gql }from '@apollo/client'

import { ADDON_FRAGMENT } from './'

const CREATE_ADDON_MUTATION = gql`
  mutation createProductAddon($data: ProductAddonInput!){
    createProductAddon(data: $data){
      ...ADDON_FRAGMENT
    }
  }
  ${ADDON_FRAGMENT}
`

export default CREATE_ADDON_MUTATION
