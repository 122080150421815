import { gql } from '@apollo/client'

export const RIDER_BREAK_FRAGMENT = gql`
  fragment RIDER_BREAK_FRAGMENT on RiderBreak {
    id
    rider{
      originalId
      name
      username
      color
    }
    allowedLength
    start
    end
    type
    status
    note
    createdAt
  }
`
