import React from 'react'

import { PromotionsList } from '../../../views'

import './style.css'

export function VendorPromotions({vendor, refetch, loading}){

  return(
    <div id="vendor-promotions">
      <PromotionsList data={vendor} refetch={refetch} loading={loading} />
    </div>
  )

}
