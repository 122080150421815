import React from 'react'

import { Table } from '../../components'
import { getEllapsed, formatMinutes } from '../../helpers'

export default function DeliveryTimeField({purchase}) {

  const getTime = (status) => {
    switch (purchase.status) {
      case "DELIVERYFAILED":
        return getEllapsed(purchase.createdAt, purchase.timestamps.deliveryFailed)
      case "CANCELLED":
        return getEllapsed(purchase.createdAt, purchase.timestamps.cancelled)
      case "REJECTED":
        return getEllapsed(purchase.createdAt, purchase.timestamps.rejected)
      case "UNPROCESSED":
        return getEllapsed(purchase.createdAt, purchase.timestamps.unprocessed)
      default:
        return getEllapsed(purchase.createdAt, purchase.timestamps.completed ? purchase.timestamps.completed : undefined)
    }
  }

  let time = getTime(purchase.status)

  return(
    <Table.Td label="Delivery time"  className="center">
      {formatMinutes(time)}
    </Table.Td>
  )

}
