import moment from 'moment'
import FontAwesome from 'react-fontawesome'
import { useState } from 'react'
import { useQuery } from '@apollo/client'
import { useLocation } from 'react-router-dom'


import { useQueryBuilder } from '../../hooks'
import { ModificationsList, SelectRider } from '../../views'
import { Loader, Filter, Wrapper, Filters, DatePicker } from '../../components'
import { RIDER_MODIFICATIONS_QUERY } from '../../graphql'

import './Modifications.css'

export default function Modifications() {

  const filters = [
    {filter: (data) => data.id},
    {filter: (data) => data.customer.name},
    {filter: (data) => data.email},
    {key: "customer", filter: (data) => data.customer ? data.customer.name || "" : ""},
    {key: "vendor", filter: (data) => data.vendor ? data.vendor.name || "" : ""},
    {key: "status", filter: (data) => data.status.toLowerCase()},
  ]

  const location = useLocation()
  const search = new URLSearchParams(location.search)
  const [filter, setFilter] = useState()
  const {addParam, getParams, params, clearParams, removeParam, buildQuery, isQuery, runQuery } = useQueryBuilder({
    data: {
      date: {key: "date", label: `${moment(search.get("date")).format("YYYY-MM-DD")}`, value: search.get("date") ? moment(search.get("date")).format("YYYY-MM-DD") : null, skip: !search.get("date")},
      rider: {key: "rider", label: `${search.get("rider")}`, value: search.get("rider"), skip: !search.get("date")},
    }
  })
  const {loading, error, data, refetch} = useQuery(RIDER_MODIFICATIONS_QUERY, {variables: buildQuery(), skip: !isQuery()})

  if (error) return <Loader theme="main" />

  function setDate(date){
    addParam({date: {key: "date", label: moment(date).format("YYYY-MM-DD"), value: moment(date).format("YYYY-MM-DD"), skip: !date}})
  }

  return(
    <Wrapper>
      <div id="modifications" className="view">
        <div className="row padding">
          <Filters.Bar>
            <Filters.Input id="query" name="query" value={filter} onChange={({target}) => setFilter(target.value)} reset={() => setFilter("")} placeholder="Type here for searching" />
            <SelectRider style={{marginLeft: "auto"}} rider={params?.rider} reset={() => removeParam("rider")} onSelect={(_rider) => addParam({rider: {value: _rider?.id, id: "rider", label: _rider?.name, skip: !_rider?.id}})}/>
            <Filters.Dropdown placeholder="Date" value={params?.date?.value} disableInClickClose right reset={() => removeParam("date")}>
              <DatePicker.Date date={params?.date?.value && new Date(`${params.date.value}T00:00`)} onChange={setDate} />
            </Filters.Dropdown>
            <Filters.Submit onClick={runQuery}><FontAwesome name="search" /></Filters.Submit>     
          </Filters.Bar>
          <Filters.Bar>
            <Filters.ActiveParams activeParams={getParams()} removeParam={removeParam} clearParams={clearParams}/>
          </Filters.Bar>
          <div id="modifications-list" className="relative">
            {(loading) && <Loader theme="main" overlay/>}
            <Filter data={data?.riderModifications || []} search={[{key: "search", value: filter}]} filters={filters} sort={(a, b) => new Date(a.createdAt) - new Date(b.createdAt)} filterKeys={["rider", "status", "vendor", "customer", "zone", "hasNotes", "paymentMethod"]}>
              {(data) =>
                <ModificationsList data={data} refetch={refetch}/>
              }
            </Filter>
          </div>
        </div>
      </div>
    </Wrapper>
  )
}
