import React, { useState, useEffect } from 'react'

import { isFunction } from '../../helpers'

import './Filter.css'

export function Filter({data, filters, sortables, filterKeys, children, search = undefined, sortOrder = "ASC", sortBy}) {

  const getFilters = () => {
    let _activeFilters = []
    if (search){
      search.forEach(({value, key}) => {
        if (key === "search" || (filterKeys && filterKeys.includes(key))) return _activeFilters = _activeFilters.concat({key, value})
      })
    }

    return _activeFilters
  }

  const [filteredData, setData] = useState(data)
  const activeFilters = getFilters()

  useEffect(() => {
    filter(activeFilters)
    // eslint-disable-next-line
  }, [data, search])

  function filter(){
    if (!filters) return setData(data)
    let filteredData = sort(data.filter((elem) => filterMultiple(elem)))

    setData(filteredData)
  }

  function filterMultiple(data){
    let hasMatch = activeFilters.every((string) => {
      let filter = filters.find(obj => obj.key === string.key) || null

      if (filter) return isFunction(filter.filter) && filter.filter(data)?.toString() === string.value

      return filters.some((filterObj) =>
        isFunction(filterObj.filter) && filterObj.filter(data)?.toString()?.toLowerCase().search(string.value?.toLowerCase()) !== -1
      )
    })

    return hasMatch
  }

  function sort(arr){
    if (!sortables) return arr

    let sortable = sortables.find(sortable => sortable.id === sortBy) || undefined

    if (!sortable) return arr

    arr = arr.sort((a, b) => {

      if (sortable.type === "number"){
        return sortable.data(a) - sortable.data(b);
      }

      if (sortable.type === "text")
        return sortable.data(a).localeCompare(sortable.data(b), undefined, {sensitivity: "base"})

      if (sortable.type === "date")
        return new Date(sortable.data(a)) - new Date(sortable.data(b))

      return false;
    })

    if (sortOrder === "DESC") arr = arr.reverse()

    return arr
  }

  return(
    <div className="filter">
      {children(filteredData)}
    </div>
  )

}
