import { gql }from '@apollo/client'

import { PROMOTION_FRAGMENT } from './'

export const PROMOTION = gql`
  query promotion($id: ID!){
     promotion(id: $id) {
       ...PROMOTION_FRAGMENT
     }
  }
  ${PROMOTION_FRAGMENT}
`
