import React from 'react'
import FontAwesome from 'react-fontawesome'

import { Button } from '../'

import './ConfirmButtons.css'

export default function ConfirmButtons({approve, reject}) {

  return(
    <div className="confirm-buttons">
      <Button className="approve" onClick={approve}><FontAwesome name="check" /></Button>
      <Button className="reject" onClick={reject}><FontAwesome name="times" /></Button>
    </div>
  )
}
