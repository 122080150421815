import { gql } from '@apollo/client'

import { VEHICLE_FRAGMENT } from './'

export const CREATE_RIDER_VEHICLE = gql`
  mutation createRiderVehicle($data: RiderVehicleInput){
    createRiderVehicle(data: $data){
      ...VEHICLE_FRAGMENT        
    }
  }
  ${VEHICLE_FRAGMENT}
`
