import React from 'react'

import { Switch } from '../'

import "./style.css"

export function SwitchBox({id, checked, onChange, label, disabled, children, ...rest}){

  return(
    <div className="switch-box" {...rest}>
      <Switch rounded id={id} checked={checked} onChange={onChange} disabled={disabled}>
        <span>{children}</span>
        {label && 
          <span className="help">{label}</span>
        }
      </Switch>
    </div>
  )
}
