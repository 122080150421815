import { gql } from '@apollo/client'

import { PURCHASE_FRAGMENT } from './'

export const VENDOR_DELAY_MUTATION = gql`
  mutation vendorDelay($id: ID!, $time: Int){

    setPreparationTime(id: $id, time:$time){
      ...PURCHASE_FRAGMENT
    }

    removeRider(id: $id){
      ...PURCHASE_FRAGMENT
    }

  }
  ${PURCHASE_FRAGMENT}
`
