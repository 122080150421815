import React, { useRef } from 'react'
import { useQuery } from '@apollo/client'
import FontAwesome from 'react-fontawesome'

import { ADDON_OPTIONS_QUERY } from '../../../graphql'
import { formatPrice } from '../../../helpers'
import { Loader, IntlValue, OptionList, Modal, Title, IntlText } from '../../../components'
import { AddonOption } from '../../../views'

import './style.css'

export function AddProductAddonOption({product, options, onChange}) {

  const modal = useRef(null)
  const {loading, error, data} = useQuery(ADDON_OPTIONS_QUERY, { variables: {owner: product?.owner}})

  function filterOptions(_options, _selected){
    let filtered = _options.reduce((current, next) => {
      if (_selected.findIndex(_addonOption => next.id === _addonOption.id) < 0){
        current.options = current.options.concat(next)
      } else {
        current.selected = current.selected.concat(next)
      }

      return current
    }, {selected: [], options: []})

    return filtered
  }

  if (loading) return <Loader theme="main"/>
  if (error) return `Error! ${error.message}`

  const { productAddonOptions } = data
  const {options: _options, selected: _selected} = filterOptions(productAddonOptions, options)

  return(
    <div id="add-product-addon-option">
      <OptionList options={_options} selected={_selected} onChange={onChange}>
        {(_option) =>
          <div>
            <span className="name"><IntlValue>{_option.name}</IntlValue></span>
            {_option.price && _option.price > 0 ? formatPrice(_option.price) : ""}
          </div>
        }
      </OptionList>
      <span id="add-new" onClick={() =>  modal?.current?.show()}><FontAwesome name="plus-circle" /><IntlText group="add-product-addon-modal" id="add-new-option" /></span>
      <Modal id="add-addon-option" ref={modal} header={<Title tag="h3"><IntlText group="add-product-addon" id="add-addon-modal-title" /></Title>}>
        {() =>
          <AddonOption product={product} success={() => modal?.current?.hide()} cancel={() => modal?.current?.hide()} />
        }
      </Modal>         
    </div>
  )
}
