import { gql } from '@apollo/client'

import { PRODUCT_FRAGMENT } from './'

export const EDIT_PRODUCT_MUTATION = gql`
  mutation editProduct($id: ID!, $data: EditedProductInput!){
    editProduct(id: $id, data: $data){
      ...PRODUCT_FRAGMENT
    }
  }
  ${PRODUCT_FRAGMENT}
`
