import { gql } from '@apollo/client'

import { SETTING_FRAGMENT } from './'

export const EDIT_SETTING_MUTATION = gql`
  mutation editSetting($id: ID, $key: String, $data: EditedSettingInput){
    editSetting(id: $id, key: $key, data: $data){
      ...SETTING_FRAGMENT
    }
  }
  ${SETTING_FRAGMENT}
`
