import React, { useState, useEffect } from 'react'
import moment from 'moment'


export function Timer({from = undefined, time = 0, expirationText = "Expired"}){

  const [_time, setTime] = useState({})

  useEffect(() => {
    let interval = undefined

    function updateTimer(){
      if (!time) return false

      let now = moment(from).add(time, "m")
      let future = moment()
      let diff = future - now

      if (diff >= 0){
        setTime({mins: 0, secs: 0})
        return clearInterval(interval)
      }

      let mins  = Math.floor( diff / (1000*60) )
      let secs  = Math.floor( diff / 1000 ) - mins  * 60

      mins = secs === 0 ? -mins : -mins - 1
      secs = secs === 0 ? secs : 60 - secs

      setTime({mins, secs})
    }

    updateTimer()
    interval = setInterval(() => updateTimer(), 1000 )

    return () => clearInterval(interval)
  }, [time, from])

  let { mins, secs } = _time

  return(
    <div className="timer">
      {(mins === 0 && secs === 0) ?
        <div className="expired">
          <span className="text">{expirationText}</span>
        </div>
      :
        <div>
          <span className="mins">{mins?.toString().padStart("2", "0")}</span>
          <span className="separator">:</span>
          <span className="secs">{secs?.toString().padStart("2", "0")}</span>
        </div>
      }
    </div>
  )

}
