import React, { useContext } from 'react'
import { toast } from 'react-toastify'
import FontAwesome from 'react-fontawesome'
import { useMutation } from '@apollo/client'

import { IntlContext } from '../../../contexts'
import { EDIT_PRODUCT_MUTATION } from '../../../graphql'
import { MultiTag, Loader } from '../../../components'

const productTags = [
  {id: "vegetarian", value: {en: "vegetarian", es: "vegetarian"}, icon: <div className="product-tag"><FontAwesome className="vegetarian" name="leaf" /></div>},
  {id: "vegan", value: {en: "vegan", es: "vegan"}, icon: <div className="product-tag"><span className="vegan">V</span></div>},
  {id: "houseSpeciality", value: {en: "house speciality", es: "especialidad de la casa"}, icon: <div className="product-tag"><i className="fas fa-hat-chef houseSpeciality"></i></div>},
  {id: "spicy", value: {en: "spicy", es: "picante"}, icon: <div className="product-tag"><i className="fas fa-pepper-hot spicy"></i></div>},
  {id: "glutenFree", value:{en: "gluten free", es: "sin gluten"}, icon: <div className="product-tag"><i className="fas fa-wheat glutenFree"></i></div>},
  {id: "new", value:{en: "new", es: "nuevo"}, icon: <div className="product-tag"><FontAwesome name="star" /></div>}
]

export function ProductTagEditor({product}) {

  const { getTranslation } = useContext(IntlContext)
  const [editProduct, { loading }] = useMutation(EDIT_PRODUCT_MUTATION)

  async function changeTags(id, {values}){
    let data = values ? values.map((value) => value.id) : undefined

    if (!data) return toast.error(getTranslation({id: "nothing-to-add"}))

    try {
      await editProduct({variables: {id: product.id, data: {[id]: data}}})
      return toast.success(getTranslation({id: "edit-success"}))
    } catch (e) {
      e.graphQLErrors.map(x => toast.error(x.message))
    }
  }

  return(
    <div id="product-tag-editor">
      {loading && <Loader theme="main" overlay />}
      <MultiTag values={product.tags} options={productTags} onChange={(data) => changeTags("tags", data)}/>
    </div>
  )
}
