import { gql } from '@apollo/client'

import { PURCHASE_FRAGMENT } from './'

export const PURCHASE_ASSIGNED_SUBSCRIPTION = gql`
  subscription purchaseAssigned{
    purchaseAssigned{
      ...PURCHASE_FRAGMENT
    }
  }
  ${PURCHASE_FRAGMENT}
`
