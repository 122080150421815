export function isFunction(functionToCheck) {
 return functionToCheck && {}.toString.call(functionToCheck) === '[object Function]';
}

export function pad(number){
  if (number?.toString()?.length > 2) return number

  return ('0' + number).slice(-2)
}

export function extractSettings(settings){

  const _settings = settings.reduce((current, next) => {
    let item = {...next}

    switch (next.type) {
      case "BOOLEAN":
        item.value = next.value && next.value === "true" ? true : false
        break;
      case "PERCENTAGE":
      case "NUMBER":
        item.value = Number(next.value)
        break;
      default:
        item.value = next.value
    }

    current[next.key.toLowerCase()] = item

    return current
  }, {})

  return _settings
}
