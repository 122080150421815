import { gql } from '@apollo/client'

import { RIDER_WORKSHIFT_FRAGMENT } from './'

export const GET_RIDER_WORKSHIFTS_OF_RIDER = gql`
  query getRiderWorkShiftsOfRider($id: ID, $limit: Int, $offset: Int){
    getRiderWorkShiftsOfRider(id: $id, limit: $limit, offset: $offset){
        nodes{
            ...RIDER_WORKSHIFT_FRAGMENT
        }
    }
  }
  ${RIDER_WORKSHIFT_FRAGMENT}
`
