import { gql }from '@apollo/client'

import { ADDON_FRAGMENT } from './'

const EDIT_ADDON_MUTATION = gql`
  mutation editProductAddon($id: ID!, $data:EditedProductAddonInput!){
    editProductAddon(id: $id, data:$data){
      ...ADDON_FRAGMENT
    }
  }
  ${ADDON_FRAGMENT}
`

export default EDIT_ADDON_MUTATION
