import React from 'react'
import { useMutation } from '@apollo/client'

import { EDIT_FAQ, DELETE_FAQ } from '../../../graphql'
import { ContextMenu, LabeledCheckbox, IntlValue, StatusDot, Loader, Table } from '../../../components'

export function FaqList({data, refetch}) {

  const [editFaq, { loading: editLoading }] = useMutation(EDIT_FAQ)
  const [deleteFaq, { loading: deleteLoading }] = useMutation(DELETE_FAQ)

  async function _deleteFaq(id){
    try {
      await deleteFaq({variables: {id}})
      refetch()
    } catch (e) {
      console.log("Error: ", e)
    }
  }

  return(
    <Table.Table>
      {deleteLoading && <Loader theme="main" overlay />}
      <Table.Head>
        <Table.Tr>
          <Table.Th></Table.Th>
          <Table.Th className="text-left">Name</Table.Th>
          <Table.Th className="text-left">Topic</Table.Th>
          <Table.Th>Hidden</Table.Th>
          <Table.Th></Table.Th>
        </Table.Tr>
      </Table.Head>
      <Table.Body>
        {data && data.length > 0 ? data.map((page, key) =>
          <Table.Tr key={key} className="page">
            <Table.Td><StatusDot active={!page.hidden} /></Table.Td>
            <Table.Td className="text-left"><IntlValue>{page.title}</IntlValue></Table.Td>
            <Table.Td className="text-left">{page.topic}</Table.Td>
            <Table.Td centered><LabeledCheckbox loading={editLoading} id={`active-${page.id}`} checked={page.hidden} onChange={(e) => editFaq({variables: {id: page.id, data: {hidden: e.target.checked}}})} rounded/></Table.Td>
            <Table.Td>
              <ContextMenu.Menu id={page?.id}>
                <ContextMenu.Link to={`/faqs/${page.id}`} icon="external-link-alt">View & Edit</ContextMenu.Link>
                <ContextMenu.Button onClick={() => _deleteFaq(page.id)} icon="trash">Delete</ContextMenu.Button>
              </ContextMenu.Menu>
            </Table.Td>
          </Table.Tr>
        ) :
          <Table.Tr>
            <Table.Td colSpan={5}>There aren't any items to show, try to search</Table.Td>
          </Table.Tr>
        }
      </Table.Body>
    </Table.Table>
  )
}
