import { gql } from '@apollo/client'

import { RIDER_WORKSHIFT_FRAGMENT } from './'

export const GET_RIDER_WORKSHIFTS = gql`
  query getRiderWorkShifts($date: String!){
    getRiderWorkShifts(date: $date){
      ...RIDER_WORKSHIFT_FRAGMENT
    }
  }
  ${RIDER_WORKSHIFT_FRAGMENT}
`
