import React, { useContext } from 'react'
import { toast } from 'react-toastify'
import { useMutation } from '@apollo/client'

import { EDIT_VENDOR_MUTATION, DELETE_STRIPE_EXPRESS_ACCOUNT } from '../../../graphql'
import { IntlContext } from '../../../contexts'
import { IntlText, EditBox, Form, Button, SwitchBox } from '../../../components'

export function VendorPaymentSettings({vendor}) {

  const { getTranslation } = useContext(IntlContext)
  const [editVendor] = useMutation(EDIT_VENDOR_MUTATION)
  const [deleteStripeExpress] = useMutation(DELETE_STRIPE_EXPRESS_ACCOUNT)

  async function changeSwitch(data){
    try {
      await editVendor({variables: {id: vendor.id, data: data}})
      return toast.success(getTranslation({id: "change-success"}))
    } catch (e) {
      e.graphQLErrors.map(x => toast.error(x.message))
    }
  }

  async function save(data){
    try {
      await editVendor({variables: {id: vendor.id, data: data}})
      return toast.success(getTranslation({id: "change-success"}))
    } catch (e) {
      e.graphQLErrors.map(x => toast.error(x.message))
    }
  }

  async function _deleteStripeExpress(){
    try {
      await deleteStripeExpress({variables: {id: vendor.id}})
      return toast.success(getTranslation({id: "delete-success"}))
    } catch (e) {
      e.graphQLErrors.map(x => toast.error(x.message))
    }
  }

  return(
    <div id="vendor-payment-settings">
      <Form.Form>
        <Form.Field>
          <SwitchBox id="cashPaymentsDisabled" label={<IntlText group="vendor-payment-settings" id="cash-accepted-desc" />} checked={!vendor.cashPaymentsDisabled} onChange={(e) => changeSwitch({[e.target.id]: !e.target.checked})}>
            <IntlText group='vendor-payment-settings' id='cash-accepted' />
          </SwitchBox>                
        </Form.Field>          
        <Form.Field>
          <SwitchBox id="expressFlowEnabled" label={<IntlText group="vendor-payment-settings" id="express-account-enabled-desc" />} checked={vendor.expressFlowEnabled} onChange={(e) => changeSwitch({[e.target.id]: e.target.checked})}>
            <IntlText group='vendor-payment-settings' id='express-account-enabled' />
          </SwitchBox>                
        </Form.Field>
        {vendor.expressFlowEnabled &&
          <>
            <Form.Field>
              <SwitchBox id="bankCardPaymentsAllowed" label={<IntlText group="vendor-payment-settings" id="card-allowed-desc" />} checked={vendor.bankCardPaymentsAllowed} onChange={(e) => changeSwitch({[e.target.id]: e.target.checked})}>
                <IntlText group='vendor-payment-settings' id='card-allowed' />
              </SwitchBox>                
            </Form.Field>
            {vendor.bankCardPaymentsAllowed &&
              <Form.Field>
                <SwitchBox id="bankCardPaymentsAccepted" label={<IntlText group="vendor-payment-settings" id="card-accepted-desc" />} checked={vendor.bankCardPaymentsAccepted} onChange={(e) => changeSwitch({[e.target.id]: e.target.checked})}>
                  <IntlText group='vendor-payment-settings' id='card-accepted' />
                </SwitchBox>                
              </Form.Field>
            }
            {vendor.expressAccountDetailsSubmitted &&
              <Form.Field>
                <EditBox.Text placeholder="Stripe Express ID" save={(value) => save({expressAccountId: value})} actions={<Button icon="trash" onClick={_deleteStripeExpress} />}>{vendor?.expressAccountId}</EditBox.Text>
              </Form.Field>
            }
          </>
        }
        <Form.Field>
          <SwitchBox id="networkTransactionsDisabled" label={<IntlText group="vendor-payment-settings" id="network-transactions-desc" />} checked={!vendor.networkTransactionsDisabled} onChange={(e) => changeSwitch({[e.target.id]: !e.target.checked})}>
            <IntlText group='vendor-payment-settings' id='network-transactions' />
          </SwitchBox>                
        </Form.Field>              
      </Form.Form>
    </div>
  )
}
