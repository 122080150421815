import React from 'react'
import { useQuery } from '@apollo/client'
import FontAwesome from 'react-fontawesome'

import { CUSTOMER_QUERY } from '../../../graphql'
import { Link } from '../../../components'

export function ReferralUser({id}){  
  const {loading, data, error } = useQuery(CUSTOMER_QUERY, {variables: {id}})

  if (loading || error) return null

  return(
    <Link to={`/customers/${id}`} style={{display: "flex", alignItems: "center", gap: 15 , cursor: "pointer", background: "var(--ui-element)", padding: 15, borderRadius: 5}}>
      <FontAwesome name='star' size="2x" style={{color: "var(--main-color)"}} />
      <span style={{fontSize: 30, fontWeight: "bold", marginTop: 10}}>{data?.customer?.referrerLevel}</span>
      <div style={{display: "flex", flexDirection: "column", marginTop: 10}}>
        <span>{data?.customer?.name}</span>
        <span style={{color: "var(--ui-placeholder)", fontSize: "var(--font-xs)"}}>{data?.customer?.email}</span>        
      </div>
      <div style={{display: "flex", flexDirection: "column", marginTop: 10}}>
        <span>#{data?.customer?.referrerCode}</span>
      </div>
    </Link>
  )
}
