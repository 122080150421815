import React from 'react'
import moment from 'moment'
import { useMutation } from '@apollo/client'

import { VOID_CUSTOMER_BOOK_ENTRY, CUSTOMER_BOOK } from '../../../graphql'
import { useConfirm, useIntl } from '../../../hooks'
import { formatPrice, toast } from '../../../helpers'
import { IntlValue, StatusDot, Loader, Table, ContextMenu } from '../../../components'
import { tomato } from '../../../assets'

import './style.css'

export function CustomerBookEntries({customer, loading, data, refetch, header}) {

  const { confirm } = useConfirm()
  const { getTranslation } = useIntl()

  const [voidCustomerBookEntry] = useMutation(VOID_CUSTOMER_BOOK_ENTRY, {refetchQueries: [{query: CUSTOMER_BOOK, variables: {customer}}]})

  async function _void(id){
    const alert = toast.loading(getTranslation({group: "confirm", id: "confirm-to-continue"}))
    try {
      await confirm()
      alert.update(getTranslation({id: "loading"}))
      await voidCustomerBookEntry({variables: {id}})
      alert.success(getTranslation({id: "edit-success"}))
      refetch()
    } catch (error) {
      alert.apolloError(error)
    }
  }   

  return(
    <div id="customer-book-entries" className="relative">
      {loading && <Loader theme="main" overlay/>}
        <Table.Table>
          <Table.Head>
            {header && header}
            {data?.customerBookEntries?.nodes?.length ?
              <Table.Tr>
                <Table.Th></Table.Th>
                <Table.Th>Date</Table.Th>
                <Table.Th>Type</Table.Th>
                <Table.Th>Note</Table.Th>
                <Table.Th>Amount</Table.Th>
                <Table.Th></Table.Th>
              </Table.Tr>
            : null}
          </Table.Head>
          <Table.Body>
            {data?.customerBookEntries?.nodes?.length > 0 && data.customerBookEntries.nodes.map((historyItem, key) =>
              <Table.Tr className="item" key={key}>
                <Table.Td><StatusDot active={!historyItem.voided} /></Table.Td>
                <Table.Td className="date">{moment(historyItem?.createdAt).format("YYYY MMMM DD. HH:mm:ss")}</Table.Td>
                <Table.Td className="description-field">
                  <div>
                    <span><IntlValue>{historyItem?.description}</IntlValue></span>
                    <span className="message">{historyItem.auditMessage}</span>
                  </div>
                </Table.Td>
                <Table.Td className="note">{historyItem?.note}</Table.Td>
                <Table.Td className={`price-field ${historyItem.value > 0 ? "plus" : "minus"}`}>
                  {historyItem?.type === "TOMATO_POINTS" ?
                    <div className="tomato-point"><span>{historyItem?.value}</span><img alt="tomato points" src={tomato} className="icon" /></div>
                  :
                    formatPrice(historyItem?.value)
                  }
                </Table.Td>
                <Table.Td>
                  <ContextMenu.Menu id={historyItem?.id}>
                    {historyItem.relatedPurchase &&
                      <ContextMenu.Link to={`/orders/${historyItem.relatedPurchase}`} icon="external-link-alt">View purchase</ContextMenu.Link>
                    }
                    <ContextMenu.Button theme="invalid" onClick={() => _void(historyItem?.id)} icon="trash">Void item</ContextMenu.Button>
                  </ContextMenu.Menu>
                </Table.Td>
              </Table.Tr>
            )}
          </Table.Body>
        </Table.Table>
    </div>
  )
}
