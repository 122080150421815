import React from 'react'

import './Loader.css'

export default function Loader({theme = "", size = "",  overlay = false, loading = true}) {

  if (!loading) return null

  return(
    <div className={`${overlay ? "loader-overlay" : ""}`}>
      <div className={`loader ${theme} ${size}`}>
        <span></span>
      </div>
    </div>
  )
}
