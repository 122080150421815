import { gql } from '@apollo/client'

const DELETE_PRODUCT_CATEGORY_MUTATION = gql`
  mutation deleteProductCategory($id: ID!){
    deleteProductCategory(id: $id){
      success
    }
  }
`

export default DELETE_PRODUCT_CATEGORY_MUTATION
