import React, { useContext } from 'react'
import { useMutation } from '@apollo/client'

import { getCommonAsset, toast, validateEmailList } from '../../../helpers'
import { UPLOAD_VENDOR_DOC, EDIT_VENDOR_MUTATION } from '../../../graphql'
import { IntlContext } from '../../../contexts'
import { LabeledInput, Validation, Form, SelectV2, SwitchBox, IntlText, EditBox } from '../../../components'
import { VerifiedDocument } from '../../../views'

export function VendorInvoicingForm({form, vendor, children}) {

  const { getTranslation } = useContext(IntlContext)
  const [uploadVendorDoc] = useMutation(UPLOAD_VENDOR_DOC)
  const [editVendor] = useMutation(EDIT_VENDOR_MUTATION)
  const { form: {name, rfc, street, exterior, interior, neighborhood, city, zip, municipality, state, taxSystem}, updateField, errors } = form
  const states = getCommonAsset('states.json')
  const taxSystems = getCommonAsset('tax-systems.json')

  async function _uploadVendorDoc({target}){
    const alert = toast.loading()
    
    try {
      if (!target.files) return toast.error("There is nothing to upload")

      const file = target.files[0]

      await uploadVendorDoc({variables: {file, vendor: vendor.id, type: "TAX_DOC"}})
      alert.success(getTranslation({id: "upload-success"}))
    } catch (error) {
      alert.apolloError(error)
    }
  }
  
  async function save(data){
    const alert = toast.loading()
    try {
      await editVendor({variables: {id: vendor?.id, data}})
      alert.success(getTranslation({id: "save-success"}))
    } catch (error) {
      error.graphQLErrors?.map(x => alert.error(x.message))
    }
  }

  return(
    <Form.Form>
      <Form.Field>
        <EditBox.Text placeholder={getTranslation({group: "vendor-invoicing-details", id: "input-email"})} save={(value) => save({invoicing: {email: value}})} validation={{validate: validateEmailList, error: getTranslation({group: "validation", id: "email"})}}>{vendor?.invoicing?.email}</EditBox.Text>
      </Form.Field>      
      <Form.Field>
        <SwitchBox id="needsInvoiceOfServicefee" label={<IntlText group="vendor-invoicing-details" id="needsInvoiceOfServicefee-desc" />} checked={vendor.needsInvoiceOfServicefee} onChange={(e) => save({[e.target.id]: e.target.checked})}>
          <IntlText group='vendor-invoicing-details' id='needsInvoiceOfServicefee' />
        </SwitchBox>                 
      </Form.Field>
      {vendor.needsInvoiceOfServicefee &&
        <>
          <Form.Field>
            <VerifiedDocument name={getTranslation({group: "vendor-invoicing-details", id:"tax-doc"})} url={vendor?.taxDoc}  status={vendor?.taxDocStatus} expiry={vendor?.taxDocExpiry} edit={(_val) => save({taxDocExpiry: _val})} accept={() => save({taxDocStatus: "ACCEPTED"})} reject={() => save({taxDocStatus: "REJECTED"})} message={vendor?.taxDocMessage} editMessage={(_val) => save({taxDocMessage: _val})} upload={_uploadVendorDoc} />
          </Form.Field>
          <Form.Field>
            <Validation errors={errors.name}>
              <LabeledInput name="name" placeholder={getTranslation({group: "vendor-invoicing-details", id: "input-name"})} value={name} onChange={({target}) => updateField({key: target.name, value: target.value})}/>
            </Validation>
          </Form.Field>
          <Form.Field>
            <Validation errors={errors.rfc}>
              <LabeledInput name="rfc" placeholder={getTranslation({group: "vendor-invoicing-details", id: "input-rfc"})} value={rfc} onChange={({target}) => updateField({key: target.name, value: target.value})}/>
            </Validation>
          </Form.Field>
            <Validation errors={errors.street}>
              <LabeledInput name="street" placeholder={getTranslation({group: "vendor-invoicing-details", id: "input-street"})} value={street} onChange={({target}) => updateField({key: target.name, value: target.value})}/>
            </Validation>
          <Form.Field>
            <Validation errors={errors.exterior}>
              <LabeledInput name="exterior" placeholder={getTranslation({group: "vendor-invoicing-details", id: "input-exterior"})} value={exterior} onChange={({target}) => updateField({key: target.name, value: target.value})}/>
            </Validation>
          </Form.Field>
          <Form.Field>
            <Validation errors={errors.interior}>
              <LabeledInput name="interior" placeholder={getTranslation({group: "vendor-invoicing-details", id: "input-interior"})} value={interior} onChange={({target}) => updateField({key: target.name, value: target.value})}/>
            </Validation>
          </Form.Field>
          <Form.Field>
            <Validation errors={errors.neighborhood}>
              <LabeledInput name="neighborhood" placeholder={getTranslation({group: "vendor-invoicing-details", id: "input-neighborhood"})} value={neighborhood} onChange={({target}) => updateField({key: target.name, value: target.value})}/>
            </Validation>
          </Form.Field>
          <Form.Field>
            <Validation errors={errors.city}>
              <LabeledInput name="city" placeholder={getTranslation({group: "vendor-invoicing-details", id: "input-city"})} value={city} onChange={({target}) => updateField({key: target.name, value: target.value})}/>
            </Validation>
          </Form.Field>
          <Form.Field>
            <Validation errors={errors.zip}>
              <LabeledInput name="zip" placeholder={getTranslation({group: "vendor-invoicing-details", id: "input-zip"})} value={zip} onChange={({target}) => updateField({key: target.name, value: target.value})}/>
            </Validation>
          </Form.Field>
          <Form.Field>
            <Validation errors={errors.municipality}>
              <LabeledInput name="municipality" placeholder={getTranslation({group: "vendor-invoicing-details", id: "input-municipality"})} value={municipality} onChange={({target}) => updateField({key: target.name, value: target.value})}/>
            </Validation>
          </Form.Field>
          <Form.Field>
            <Validation errors={errors.state}>
              <SelectV2 value={(_value) => _value.name} placeholder={getTranslation({group: "vendor-invoicing-details", id: "input-state"})} selected={states.find(s => s.abbreviation === state)} defaultValue={getTranslation({id: "please-select"})} options={states} labelId="name" onChange={(_data) => updateField({key: "state", value:  _data.abbreviation})} />
            </Validation>
          </Form.Field>
          <Form.Field>
            <Validation errors={errors.taxSystem}>
              <SelectV2 value={(_value) => _value.name} placeholder={getTranslation({group: "vendor-invoicing-details", id: "input-taxSystem"})} selected={taxSystems.find(_ts => _ts.id === taxSystem)} defaultValue={getTranslation({id: "please-select"})} options={taxSystems} labelId="name" onChange={(_data) => updateField({key: "taxSystem", value:  _data.id})} />
            </Validation>
          </Form.Field>  
          {children}
        </>
      }
    </Form.Form>
  )
}