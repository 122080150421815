import { gql } from '@apollo/client'

import { RIDER_FRAGMENT } from './'

export const UPLOAD_RIDER_TAX_CERTIFICATES = gql`
  mutation uploadRiderTaxCertificates($files: [Upload!]!, $id: ID, $password: String!){
    uploadRiderTaxCertificates(files: $files, id: $id, password: $password){
      ...RIDER_FRAGMENT
    }
  }
  ${RIDER_FRAGMENT}
`
