import { useMutation } from '@apollo/client'

import { EDIT_CUSTOMER_MUTATION } from '../../../graphql'
import { useIntl } from '../../../hooks'
import { toast } from '../../../helpers'
import { Form, SwitchBox, IntlText, EditboxV2 } from '../../../components'

export function CustomerSettings({customer}){

  const { getTranslation } = useIntl()
  const [edit] = useMutation(EDIT_CUSTOMER_MUTATION)

  async function _edit(data){
    const alert = toast.loading()
    try {
      await edit({variables: {id: customer?.id, data: data}})
      alert.success(getTranslation({id: 'edit-success'}))
    } catch (e) {
      alert.apolloError(e)
    }
  }

  return(
    <div id="customer-settings">
      <Form.Form>    
        <Form.Field>
          <SwitchBox id="abusive" label={getTranslation({group: "customer-settings", id: "abusive-desc"})} checked={customer.abusive} onChange={({target}) => _edit({[target.id]: target.checked})}>
            <IntlText group='customer-settings' id='abusive' />
          </SwitchBox>               
        </Form.Field>        
        <Form.Field>
          <SwitchBox id="banned" label={getTranslation({group: "customer-settings", id: "banned-desc"})} checked={customer.banned} onChange={({target}) => _edit({[target.id]: target.checked})}>
            <IntlText group='customer-settings' id='banned' />
          </SwitchBox>               
        </Form.Field>        
        <Form.Field>
          <EditboxV2.Text label={getTranslation({group: "customer-settings", id: "notes"})}  desc={getTranslation({group: "customer-settings", id: "notes-desc"})} onChange={(value) => _edit({note: value})}>{customer.note}</EditboxV2.Text>
        </Form.Field>
      </Form.Form>
    </div>
  )

}
