import React from 'react'
import moment from 'moment'
import { Link } from 'react-router-dom'
import { useMutation } from '@apollo/client'

import { REORDER_VENDORS_MUTATION } from '../../../graphql'
import { useModal } from '../../../hooks'
import { toast } from '../../../helpers'
import { ContextMenu, Loader, StatusDot, Table, Badge, IntlText, Modalv3, Title, Sortable } from '../../../components'
import { VendorTags, VendorSettings } from '../../../views'

export function VendorsList({data, refetch, loading}) {

  const [reorderVendors, { loading: orderLoading }] = useMutation(REORDER_VENDORS_MUTATION, {onCompleted: () => {
    refetch()
  }})

  async function onSort(orderedArray){
    const alert = toast.loading()
    try {
      await reorderVendors({variables: {data: orderedArray}})
      alert.success("Successfully reordered!")
    } catch (e) {
      alert.apolloError(e)
    }
  }

  const renderHeader = () => (
    <>
      <Table.Th></Table.Th>
      <Table.Th></Table.Th>
      <Table.Th className="text-left">Name</Table.Th>
      <Table.Th>Rating</Table.Th>
      <Table.Th>Contract</Table.Th>
      <Table.Th>Service fee</Table.Th>
      <Table.Th>Registered</Table.Th>
      <Table.Th>Last processed</Table.Th>
      <Table.Th>Status</Table.Th>
      <Table.Th>Tags</Table.Th>
      <Table.Th />
    </>
  )

  const renderItem = (vendor) => <Item vendor={vendor} />

  return(
    <div id="vendors-list" className="relative">
      {(!!orderLoading || loading) && <div className="full-size-loader"><Loader theme="main"/></div>}
      {data?.length > 0 ?
        <Sortable.Table data={data} onSort={onSort} renderItem={renderItem} renderHeader={renderHeader} />
      :
        <Table.Table>
          <Table.Body>
            <Table.Tr>
              <Table.Td colSpan={8}>There aren't any items to show, try to search</Table.Td>
            </Table.Tr>              
          </Table.Body>      
        </Table.Table>      
      }
    </div>
  )

}

function Status({vendor}){

  function getStatus(){
    if (vendor.suspended) return(
      <Badge icon="exclamation-triangle" theme='invalid'>Suspended</Badge>
    )
    if (vendor.technicalBreak) return(
      <Badge icon="exclamation-triangle" theme='invalid'>Technical break</Badge>
    )
  }
  
  return (
    <div style={{display: 'flex', gap: 5}}>
      {getStatus()}
      {vendor.onVacation &&
        <Badge icon="plane" theme='ui'>On vacation</Badge>
      }
      {vendor.hidden && 
        <Badge icon="eye-slash" theme='ui'>Hidden</Badge>
      }
      {vendor.slow && 
        <Badge theme='warning' icon="clock">Slow preparation</Badge>
      }
      {vendor.vendorBalanceOverDue && 
        <Badge theme='invalid' icon="exclamation-triangle">Balance overdue</Badge>
      }
      {vendor.serviceFeeChargeType !== "PERIODICAL" && (!vendor.suspended && !vendor.hidden) &&
        <Badge theme='warning'>Upgrade needs</Badge>
      }
    </div>
  )

}

function Item({vendor}){

  const modal = useModal()

  return(
    <>
      <Table.Td className="status"><StatusDot active={!(vendor.suspended || vendor.technicalBreak || vendor.hidden || vendor.onVacation)} /></Table.Td>
      <Table.Td className="text-left"><Link to={`/vendors/${vendor.id}`}>{vendor.name}</Link></Table.Td>
      <Table.Td><span style={{color: vendor.percentageOfPositive < 0.5 ? "var(--invalid-color)" : vendor.percentageOfPositive < 0.8 ? "var(--warning-color)" : "var(--valid-color)"}}>{Math.round(vendor.percentageOfPositive * 100)}%</span></Table.Td>
      <Table.Td>{vendor.contract}</Table.Td>
      <Table.Td>{(vendor.serviceFee*100)}%</Table.Td>
      <Table.Td centered>{moment(vendor?.createdAt).format("D. MMM YYYY")}</Table.Td>
      <Table.Td centered>{vendor?.lastPurchaseProcessed &&  `${moment().diff(vendor?.lastPurchaseProcessed, "days")} days ago`}</Table.Td>
      <Table.Td centered><Status vendor={vendor} /></Table.Td>
      <Table.Td centered><VendorTags vendor={vendor} editable/></Table.Td>
      <Table.Td>
        <ContextMenu.Menu id={vendor?.id}>
          <ContextMenu.Link to={`/vendors/${vendor.id}`} icon="external-link-alt">Open</ContextMenu.Link>
          {vendor?.whatsappGroup &&
            <ContextMenu.Button onClick={() => window.open(vendor?.whatsappGroup)} icon={<i className="fab fa-whatsapp" />}>Send what's app</ContextMenu.Button>
          }              
          <ContextMenu.Button onClick={() => modal.show()} icon="cog">Settings</ContextMenu.Button>
        </ContextMenu.Menu>
        <Modalv3 modal={modal} header={<Title tag="h3"><IntlText group="vendor" id="tab-menu-settings" /></Title>}>
          <VendorSettings vendor={vendor} />
        </Modalv3>            
      </Table.Td>
    </>
  )
}