import React from 'react'
import { useQuery, useMutation } from '@apollo/client'
import { useParams, useNavigate } from 'react-router-dom'
import moment from 'moment'

import { GET_RIDER_WORKSHIFT, EDIT_RIDER_WORKSHIFT, RIDER_MODIFICATIONS_QUERY } from '../../graphql'
import { formatPrice } from '../../helpers'
import { Wrapper, Loader, IntlText, Button } from '../../components'
import { RiderWorkshiftTotal, RiderWorkshiftDetails, RiderWorkshiftList } from '../../views'

export function RiderWorkshift() {

  const navigate = useNavigate()
  const { id } = useParams()
  const { data, loading, error } = useQuery(GET_RIDER_WORKSHIFT, {variables: {id}, notifyOnNetworkStatusChange: true})
  const [editRiderWorkShift] = useMutation(EDIT_RIDER_WORKSHIFT)

  async function _resetPurchase(){
    try {
      await editRiderWorkShift({variables: {id: getRiderWorkShift.id, data: {
        status: "ACTIVE",
        end: null
      }}})
      navigate("active")
    } catch (error) {
      console.log("Error", error)
    }
  }

  if (loading) return <Loader theme="main" />
  if (error) return `Error! ${error.message}`

  const { getRiderWorkShift } = data
  
  if (!getRiderWorkShift) return null

  return(
    <Wrapper>
      <div id="workshift" className="container">
        <div className="row padding">
          <RiderWorkshiftTotal data={getRiderWorkShift.account} >
            <Button onClick={_resetPurchase}><IntlText id="reset" /></Button>
          </RiderWorkshiftTotal>  
          <RiderWorkshiftDetails data={getRiderWorkShift.account} />
          <RiderWorkshiftList.List id="modifications-history" title={<IntlText group="day-closing" id="modifications-history" />}>     
            <div className='scroller'>
              <ModificationsHistory rider={data?.getDayClosingSnapshot?.rider.originalId} />
            </div>
          </RiderWorkshiftList.List>        
          <RiderWorkshiftList.List id="tips" title={<IntlText group="day-closing" id="tips" />}>     
            {getRiderWorkShift.account?.tips?.length > 0 ? getRiderWorkShift.account?.tips?.map((modification, key) => 
              <RiderWorkshiftList.ListItem key={key}>
                <span>{modification.title}</span>
                <span className='amount' style={{color: "var(--invalid-color)"}}>{formatPrice(modification.value)}</span>
              </RiderWorkshiftList.ListItem>
            ):
              <div className='no-items'><IntlText id="nothing-found" /></div>
            }
          </RiderWorkshiftList.List>                
          <RiderWorkshiftList.List id="revenue" title={<IntlText group="day-closing" id="revenue" />}>     
            {getRiderWorkShift.account?.revenue?.length > 0 ? getRiderWorkShift.account?.revenue?.map((modification, key) => 
              <RiderWorkshiftList.ListItem key={key}>
                <span>{modification.title}</span>
                <span className='amount' style={{color: "var(--invalid-color)"}}>{formatPrice(modification.riderCollectible)}</span>
              </RiderWorkshiftList.ListItem>
            ):
              <div className='no-items'><IntlText id="nothing-found" /></div>
            }
          </RiderWorkshiftList.List>                
        </div>
      </div>
    </Wrapper>
  )
}

function ModificationsHistory({rider}){

  const {loading, error, data} = useQuery(RIDER_MODIFICATIONS_QUERY, {variables: {rider, reason: ["GAS_SELFSERVE", "NETWORK_AMIGOSINLIMITE"]}})

  if (loading || error) return <Loader theme="main" />

  if (data?.riderModifications.length > 0) return data?.riderModifications.map((modification, key) => 
    <RiderWorkshiftList.ListItem key={key}>
      <span><IntlText group="rider-modifications" id={modification.reason} /></span>
      <span className='date'>{moment(modification.createdAt).format("DD/MM/YYYY")}</span>
      <span className='amount' style={modification.value > 0 ? {color: "var(--valid-color)"} : {color: "var(--invalid-color)"}}>{formatPrice(modification.value)}</span>
    </RiderWorkshiftList.ListItem>
  )
    
  return <div className='no-items'><IntlText id="nothing-found" /></div>
}