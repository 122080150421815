import React, { useContext } from 'react'
import { useMutation } from '@apollo/client'
import { useNavigate } from 'react-router-dom'

import { useValidatedForm } from '../../../hooks'
import { toast } from '../../../helpers'
import { CREATE_RIDER_PAYOUT } from '../../../graphql'
import { IntlContext } from '../../../contexts'
import { Form, IntlText, LabeledInput, Button } from '../../../components'

export function CreateRiderPayout({rider}){

  const navigate = useNavigate()
  const { getTranslation } = useContext(IntlContext)
  const { form: {amount}, updateField } = useValidatedForm({})
  const [createRiderPayout] = useMutation(CREATE_RIDER_PAYOUT)

  async function _createRiderPayout(){
    const alert = toast.loading()
    
    try {
      await createRiderPayout({variables: {rider: rider?.id, amount: parseFloat(amount)}})
      alert.success(getTranslation({id: "create-success"}))
      navigate(-1)
    } catch (e) {
      alert.error(e?.message)
    }    
  }
  
  return(
    <>
      <Form.Form>
        <Form.Field>
          <LabeledInput name="amount" type='number' inputMode="numeric" pattern="[0-9]*" placeholder={getTranslation({id: "amount"})} value={amount} onChange={({target}) => updateField({key: target.name, value: target.value})} />
        </Form.Field>        
        <Form.Field>
          <Button fluid theme="main" onClick={_createRiderPayout}><IntlText id="create" /></Button>
        </Form.Field>        
      </Form.Form>         
    </>
  )
}
