import { gql } from '@apollo/client'

import { VENDOR_FRAGMENT } from './'

export const EDIT_VENDOR_MUTATION = gql`
  mutation editVendor($id: ID, $data: EditedVendorInput!){
    editVendor(id: $id, data: $data){
      ...VENDOR_FRAGMENT
    }
  }
  ${VENDOR_FRAGMENT}
`
