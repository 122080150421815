import { gql } from '@apollo/client'

const ADDRESS_FRAGMENT = gql`
  fragment ADDRESS_FRAGMENT on Address {
    id
    name
    slug
    zone
    type
    building
    unit
    instructions
    location{
      coordinates
    }
  }
`

export default ADDRESS_FRAGMENT
