import { useQuery, useMutation } from '@apollo/client'
import { useLocation } from 'react-router-dom'
import moment from 'moment'

import { PURCHASE_QUOTES, DELETE_PURCHASE_QUOTE } from '../../graphql'
import { useIntl, useConfirm, useQueryBuilder } from '../../hooks'
import { toast } from '../../helpers'
import { Wrapper, Table, Link, ContextMenu, IntlText, Filters, DatePicker, Pagination } from '../../components'

export function PurchaseQuotes() {

  const location = useLocation()
  const search = new URLSearchParams(location.search)
  const { confirm }= useConfirm()
  const { getTranslation } = useIntl()
  const { params, addParam, removeParam, isQuery, buildQuery, runQuery, updateQuery, setPage, page } = useQueryBuilder({
    data: {
      date: {key: "date", label: `${search.getAll("date")[0]} - ${search.getAll("date")[1]}`, value: search.getAll("date")?.length === 2 ? [search.getAll("date")[0], search.getAll("date")[1]] : undefined, skip: !search.get("date")},      
      status: {key: "status", label: `${search.get("status")}`, value: search.get("status")},
    }
  })
  const { loading, data } = useQuery(PURCHASE_QUOTES, {variables: buildQuery(), skip: !isQuery(), })
  const [deletePurchaseQuote] = useMutation(DELETE_PURCHASE_QUOTE)

  async function _deletePurchaseQuote(id){
    const alert = toast.loading(getTranslation({group: "confirm", id: "confirm-to-continue"}))
    try {
      await confirm({title: getTranslation({group: "confirm", id: "delete"}), desc: getTranslation({group: "confirm", id: "delete-info"})})
      alert.update(getTranslation({id: "loading"}))
      await deletePurchaseQuote({variables: {id}})
      alert.success(getTranslation({id: "delete-success"}))
    } catch (error) {
      alert.apolloError(error)
    }
  }

  function setDate({selection: {startDate, endDate}}){
    const _date = [
      moment(startDate).format("YYYY-MM-DD"),
      moment(endDate).add(1, 'd').format("YYYY-MM-DD")
    ]
    addParam({date: {key: "date", value: [_date[0], _date[1]], label: `${_date[0]} - ${_date[1]}`, skip: !(_date[0] && _date[1])}})
  }

  console.log("AS", data?.purchaseQuotes);

  return(
    <Wrapper loading={loading}>
      <div id="purchase-quotes" className="view">
        <div className="row padding">
          <Filters.Bar>
            <Filters.Select value={params?.sort?.value ? getTranslation({group: "history-filter", id: params?.sort?.value}): null} placeholder={getTranslation({group: "history-filter", id: "sort"})} reset={() => removeParam("sort")}>
              <Filters.SelectItem active={params?.sort?.value === "EDITING"} onClick={() => updateQuery({status: {value: "EDITING", id: "status", label: "EDITING"}})}>
                <span>{getTranslation({group: "history-filter", id: "EDITING"})}</span>
              </Filters.SelectItem>            
            </Filters.Select>
            <Filters.Dropdown style={{marginLeft: "auto"}} placeholder={getTranslation({group: "history-filter", id: "date"})} value={params?.date?.label} disableInClickClose right run={runQuery} reset={() => removeParam("date")}>
              <DatePicker.Range startDate={params?.date?.value[0] ? new Date(`${params.date.value[0]}T00:00`) : new Date()} endDate={params?.date?.value[1] ? new Date(`${params.date.value[1]}T00:00`) : new Date()} onChange={setDate} />
            </Filters.Dropdown>
          </Filters.Bar>        
          <Table.Table>
            <Table.Head>
              <Table.Tr>
                <Table.Th>#</Table.Th>
                <Table.Th>Customer</Table.Th>
                <Table.Th>Vendor</Table.Th>
                <Table.Th>Payment</Table.Th>
                <Table.Th>Status</Table.Th>
                <Table.Th>Date</Table.Th>
                <Table.Th></Table.Th>
              </Table.Tr>
            </Table.Head>
            <Table.Body>          
              {data?.purchaseQuotes?.nodes?.map((purchaseQuote) => 
                <Table.Tr key={purchaseQuote.id}>
                  <Table.Td>{purchaseQuote.id}</Table.Td>
                  <Table.Td><Link to={`/customers/${purchaseQuote.customer}`}>{purchaseQuote.customer}</Link></Table.Td>
                  <Table.Td><Link to={`/vendors/${purchaseQuote.vendor}`}>{purchaseQuote.vendor}</Link></Table.Td>
                  <Table.Td>{purchaseQuote.payment}</Table.Td>
                  <Table.Td>{purchaseQuote.status}</Table.Td>
                  <Table.Td>{moment(purchaseQuote.createdAt).format('DD/MM/YYYY HH:mm')}</Table.Td>
                  <Table.Td>
                    <ContextMenu.Menu id={purchaseQuote.id}>
                      <ContextMenu.Button className="invalid" icon="trash" onClick={() => _deletePurchaseQuote(purchaseQuote.id)}><IntlText id="delete" /></ContextMenu.Button>
                    </ContextMenu.Menu>
                  </Table.Td>
                </Table.Tr>
              )}
            </Table.Body>          
        </Table.Table>          
        <Pagination page={page} setPage={setPage} pageInfo={data?.purchaseQuotes?.pageInfo}/>
        </div>
      </div>
    </Wrapper>
  )
}
