import { gql } from '@apollo/client'

import { FAQ_FRAGMENT } from './'

export const EDIT_FAQ = gql`
  mutation editFaqItem($id: ID!, $data: EditedFaqItemInput!){
    editFaqItem(id: $id, data: $data){
      ...FAQ_FRAGMENT
    }
  }
  ${FAQ_FRAGMENT}
`
