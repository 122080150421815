import React from 'react'

import { formatPrice } from '../../../helpers'
import { IntlText } from '../../../components'

import './style.css'

export function RiderWorkshiftTotal({data, children}) {

  return(
    <div className='rider-workshift-total'>
      <div className='total'>
        <span className='amount'>{formatPrice(data.netRiderBalance || 0)}</span>
        <span className='note'><IntlText group="rider-workshift-total" id="amount-to-collect" /></span>
        {children}
      </div>      
    </div>              
  )
  
}