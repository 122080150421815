import React from 'react'

import { NewAdminForm } from '../../views'
import { Wrapper, Grids } from '../../components'

export function NewAdmin() {

  return(
    <Wrapper>
      <div id="new-admin" className="container">
        <div className="row padding">
          <Grids.Grids>
            <Grids.Grid>
              <NewAdminForm />
            </Grids.Grid>
          </Grids.Grids>
        </div>
      </div>
    </Wrapper>
  )
}
