import { gql } from '@apollo/client'

import { CUSTOMER_FRAGMENT } from './'

export const CUSTOMERS_QUERY = gql`
  query customers($query: String, $offset: Int, $limit: Int){
     customers(query: $query, offset: $offset, limit: $limit){
       ...CUSTOMER_FRAGMENT
     }
  }
  ${CUSTOMER_FRAGMENT}
`
