import { gql } from '@apollo/client'

import { CUSTOMER_FRAGMENT } from './'

export const EDIT_CUSTOMER_MUTATION = gql`
  mutation editCustomer($id: ID!, $data:EditedCustomerInput!){
    editCustomer(id: $id, data:$data){
      ...CUSTOMER_FRAGMENT
    }
  }
  ${CUSTOMER_FRAGMENT}
`
