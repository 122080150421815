import React, { useContext } from 'react'
import { toast } from 'react-toastify'
import { useMutation, useQuery } from '@apollo/client'
import { useParams} from 'react-router-dom'


import { showError } from '../../../helpers'
import { VEHICLE, EDIT_RIDER_VEHICLE } from '../../../graphql'
import { IntlContext } from '../../../contexts'
import { EditBox, SelectV2, Form, IntlText, Loader, ContextMenu, CheckboxField } from '../../../components'
import { VerifiedDocument, VerifiedPhoto, StatusNotification } from '../../../views'

export function RiderVehicle(){
  const { vehicleId } = useParams()
  const { loading, data, error } = useQuery(VEHICLE, {variables: {id: vehicleId}})

  if (loading || error) return <Loader theme="main" />

  return(
    <div id="rider-vehicles">
      <RiderVehicleForm vehicle={data?.vehicle} />
    </div>
  )

}

function RiderVehicleForm({vehicle}){

  const { getTranslation } = useContext(IntlContext)
  const [editVehicle] = useMutation(EDIT_RIDER_VEHICLE)
  const types = ["MOTORBIKE", "AUTOMOBILE", "BICYCLE"]

  async function _edit(data){
    try {
      await editVehicle({variables: {id: vehicle?.id, data}})
      toast.success(getTranslation({id: "edit-success"}))
    } catch (error) {
      showError(error)
    }    
  }

  function getStatus(){
    switch (vehicle.status) {
      case "INREVISION":
      case "INREVIEW":
        return "warning"
      case "ACCEPTED":
        return "success"
      case "REJECTED":
      case "EXPIRED_DOC":
        return "error"
      default:
        return "success"
    }
  }

  function getIcon(){
    switch (vehicle.status) {
      case "INREVISION":
      case "INREVIEW":
        return "hourglass"
      case "ACCEPTED":
        return "check"
      case "REJECTED":
        return "times"
      case "EXPIRED_DOC":
        return "hourglass-end"
      default:
        return "check"
    }    
  }
  
  return(
    <>
      <Form.Form>
        <Form.Field>
          <StatusNotification title={<IntlText group="rider-vehicle" id={vehicle?.status} />} icon={getIcon()} type={getStatus()} message={vehicle.statusMessage} editMessage={(_val) =>  _edit({statusMessage: _val})} actions={[
            <ContextMenu.Button key="ACCEPTED" className='valid' onClick={() => _edit({status:  "ACCEPTED"})} icon="check"><IntlText id="ACCEPTED" /></ContextMenu.Button>,
            <ContextMenu.Button key="INREVISION" className='warning' onClick={() => _edit({status:  "INREVISION"})} icon="hourglass"><IntlText id="INREVISION" /></ContextMenu.Button>,
            <ContextMenu.Button key="INREVIEW" className='warning' onClick={() => _edit({status:  "INREVIEW"})} icon="hourglass"><IntlText id="INREVIEW" /></ContextMenu.Button>,
            <ContextMenu.Button key="REJECTED" className='invalid' onClick={() => _edit({status:  "REJECTED"})} icon="times"><IntlText id="REJECTED" /></ContextMenu.Button>,
          ]}>
            <span><IntlText group="rider-vehicle" id={`${vehicle?.status}-info`} /></span>
          </StatusNotification>             
        </Form.Field>
        <Form.Field>
          <SelectV2 value={(_value) => <IntlText group="rider-vehicle" id={_value} />} placeholder={getTranslation({group: "rider-vehicle", id:"type"})} selected={types?.find(_t => _t === vehicle?.type)} defaultValue={getTranslation({id: "please-select"})} options={types} onChange={(_type) => _edit({type:  _type})} />
        </Form.Field>        
        <Form.Field>
          <EditBox.Text placeholder={<div><IntlText group="rider-vehicle" id="alias" /></div>} save={(value) => _edit({alias: value})}>{vehicle.alias}</EditBox.Text>
        </Form.Field>
        <Form.Field>
          <EditBox.Text placeholder={<div><IntlText group="rider-vehicle" id="maker" /></div>} save={(value) => _edit({maker: value})}>{vehicle.maker}</EditBox.Text>
        </Form.Field>
        <Form.Field>
          <EditBox.Text placeholder={<div><IntlText group="rider-vehicle" id="model" /></div>} save={(value) => _edit({model: value})}>{vehicle.model}</EditBox.Text>
        </Form.Field>
        <Form.Field>
          <EditBox.Text placeholder={<div><IntlText group="rider-vehicle" id="maxCoveredDistance" /></div>} save={(value) => _edit({maxCoveredDistance: parseFloat(value)})}>{vehicle.maxCoveredDistance}</EditBox.Text>
        </Form.Field>
        <Form.Field>
          <EditBox.Text placeholder={<div><IntlText group="rider-vehicle" id="maxBasketValue" /></div>} save={(value) => _edit({maxBasketValue: parseFloat(value)})}>{vehicle.maxBasketValue}</EditBox.Text>
        </Form.Field>
        <Form.Field>
          <CheckboxField id="onlySmallPackage" label={getTranslation({group: "rider-vehicle", id:"onlySmallPackage"})} checked={vehicle.onlySmallPackage} onChange={({target}) => _edit({onlySmallPackage: target.checked})} rounded/>
        </Form.Field>
        <Form.Field>
          <VerifiedDocument name={getTranslation({group: "rider-vehicle", id:"insurance-policy"})} url={vehicle?.insurencePolicy} status={vehicle?.insurencePolicyStatus} expiry={vehicle?.insurencePolicyExpiry} edit={(_val) => _edit({insurencePolicyExpiry: _val})} accept={() => _edit({insurencePolicyStatus: "ACCEPTED"})} reject={() => _edit({insurencePolicyStatus: "REJECTED"})} />
        </Form.Field>
        <Form.Field>
          <VerifiedDocument name={getTranslation({group: "rider-vehicle", id:"insurance-policy-payment"})} url={vehicle?.insurencePolicyPayment} status={vehicle?.insurencePolicyPaymentStatus} expiry={vehicle?.insurencePolicyPaymentExpiry} edit={(_val) => _edit({insurencePolicyPaymentExpiry: _val})} accept={() => _edit({insurencePolicyPaymentStatus: "ACCEPTED"})} reject={() => _edit({insurencePolicyPaymentStatus: "REJECTED"})} />
        </Form.Field>
        <Form.Field>
          <VerifiedDocument name={getTranslation({group: "rider-vehicle", id:"registration-card"})} url={vehicle?.registrationCard}  status={vehicle?.registrationCardStatus} expiry={vehicle?.registrationCardExpiry} edit={(_val) => _edit({registrationCardExpiry: _val})} accept={() => _edit({registrationCardStatus: "ACCEPTED"})} reject={() => _edit({registrationCardStatus: "REJECTED"})} />
        </Form.Field>
        <Form.Field>
          <VerifiedPhoto name={getTranslation({group: "rider-vehicle", id:"vehicle-photo"})} url={vehicle?.vehiclePhoto} status={vehicle?.vehiclePhotoStatus} accept={() => _edit({vehiclePhotoStatus: "ACCEPTED"})} reject={() => _edit({vehiclePhotoStatus: "REJECTED"})} />
        </Form.Field>
        <Form.Field>
          <VerifiedPhoto name={getTranslation({group: "rider-vehicle", id:"storage-photo"})} url={vehicle?.storagePhoto} status={vehicle?.storagePhotoStatus} accept={() => _edit({storagePhotoStatus: "ACCEPTED"})} reject={() => _edit({storagePhotoStatus: "REJECTED"})} info={vehicle.storageCategory} />
        </Form.Field> 
      </Form.Form>         
    </>
  )
}
