import React, { useState, useEffect } from 'react'
import FontAwesome from 'react-fontawesome'

import { wsLink } from '../../../client'
import { IntlText } from '../../../components'

import './style.css'

export function ConnectionStatus() {

  const [connected, toggleConnection] = useState(!!wsLink.subscriptionClient.status)

  useEffect(() => {
    const connected= wsLink.subscriptionClient.onConnected(() => toggleConnection(true))
    const disconnect = wsLink.subscriptionClient.onDisconnected(() => toggleConnection(false))
    const error = wsLink.subscriptionClient.onError(() => toggleConnection(false))
    const reconnect = wsLink.subscriptionClient.onReconnected(() => toggleConnection(true))
    return () => {
      connected()
      disconnect()
      error()
      reconnect()
    }
  }, [])

  async function reconnect(){
    // const alert = toast.loading(getTranslation({id: "reconnecting"}))

    try {
      await wsLink.subscriptionClient.connect()
      // alert.success(getTranslation({id: "connect-success"}))
    } catch (error) {
      console.log(error?.message)
    }
  }

  if (connected) return(
    <div className="connection-status connected">
        <FontAwesome name='wifi' />
        <span className='placeholder'><IntlText id='connected' /></span>
    </div>
  )
  
  return (
    <div className="connection-status disconnected" onClick={reconnect}>
        <FontAwesome name='wifi-slash' />
        <span className='placeholder'><IntlText id='disconnected' /></span>
    </div>
  )
}
