import { useQuery, useSubscription } from '@apollo/client'

import { notify } from './helpers'
import { IS_LOGGED_IN, NEW_RIDER_BREAK,  NEW_PURCHASE_SUBSCRIPTION, VENDOR_EDITED_SUBSCRIPTION } from './graphql'

export function Subscriptions() {

  const { data } = useQuery(IS_LOGGED_IN)

  useSubscription(NEW_PURCHASE_SUBSCRIPTION, {
    skip: !data?.isLoggedIn,
    onSubscriptionData: () => {
      notify({title: `New order!`, message: `You've got a new order! Please check the orders page.`})
    }
  })

  useSubscription(NEW_RIDER_BREAK, {
    skip: !data?.isLoggedIn,
    onSubscriptionData: ({subscriptionData}) => {
      if (subscriptionData?.data?.newRiderBreak?.type === "DAILYBREAK" && subscriptionData?.data?.newRiderBreak?.status === "REQUESTED") notify({title: `New break request!`, message: `${subscriptionData?.data?.newRiderBreak?.rider?.name} is asking for his daily break.`})
    }
  })

  useSubscription(VENDOR_EDITED_SUBSCRIPTION, { skip: !data?.isLoggedIn})

  return null
}
