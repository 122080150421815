import React from 'react'

import { IntlText } from '../../../components'

import './style.css'

export function ClosedLabel({vendor, style}){

  if (vendor.technicalBreak || vendor.suspended) return <div style={style} className="closed-label"><IntlText group="closed-label" id="technical-break" /></div>
  if (vendor.onVacation) return <div style={style} className="closed-label"><IntlText group="closed-label" id="vacation" /></div>
  if (!vendor.isOpen) return <div style={style} className="closed-label"><IntlText group="closed-label" id="closed" /></div>

  return null
}
