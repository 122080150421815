import { gql } from '@apollo/client'

import { PURCHASE_PRICE_FRAGMENT, PURCHASE_CUSTOMER_FRAGMENT, PURCHASE_ADDRESS_FRAGMENT, PURCHASE_VENDOR_FRAGMENT, PURCHASE_PRODUCT_FRAGMENT } from './'
import { RIDER_MODIFICATION_FRAGMENT } from '../modification'
import { REFUND_FRAGMENT } from '../fragments'

export const PURCHASE_FRAGMENT = gql`
  fragment PURCHASE_FRAGMENT on Purchase {
    id
    slug
    customerNote
    customer{
      ...PURCHASE_CUSTOMER_FRAGMENT
    }
    feedback{
      id
      deliveryQuantitative
      deliveryQualitative
      productsQuantitative
      productsQualitative
      productsVoided
      createdAt
    }
    address{
      ...PURCHASE_ADDRESS_FRAGMENT
    }
    vendor{
      ...PURCHASE_VENDOR_FRAGMENT
    }
    products{
      ...PURCHASE_PRODUCT_FRAGMENT
    }
    price{
      ...PURCHASE_PRICE_FRAGMENT
    }
    refunds{
      ...REFUND_FRAGMENT
    }
    riderModifications{
      ...RIDER_MODIFICATION_FRAGMENT
    }
    notes{
      id
      note
      ownerName
    }
    rider{
      originalId
      color
      whatsappGroup
      name
      slug
      type
      phone{
        countryCode
        phoneCode
        phoneNumber
      }      
      cel{
        countryCode
        phoneCode
        phoneNumber
      }      
    }
    statusChangeRiderLocations{
      RIDERCONFIRM{
        timestamp
        delay
        location{
          coordinates
        }
      }
      ARRIVEDATVENDOR{
        timestamp
        delay
        location{
          coordinates
        }
      }
      ONTHEWAY{
        timestamp
        delay
        location{
          coordinates
        }
      }
      ATLOCATION{
        timestamp
        delay
        location{
          coordinates
        }
      }
      COMPLETED{
        timestamp
        delay
        location{
          coordinates
        }
      }
    }
    riderConfirmed
    arrivedAtVendor
    status
    payment
    paymentStatus
    paymentProblem
    stripePaymentId
    currency
    sendUtensils
    preparationTime
    completedIn
    voided
    banknote
    problem
    group
    isMobileApp
    timestamps{
      paymentProcessed
      processed
      vendorConfirmed
      assignedToRider
      riderConfirmed
      readyForCollection
      arrivedAtVendor
      collected
      arrivedAtClient
      completed
      deliveryFailed
      cancelled
      rejected
      unprocessed
      paymentFailed
      queued
      flaggedForConfirmation
    }
    promotions{
      slug
      name
      type
      label{
        en
        es
      }
      restrictions{
        key
        value
      }
      configuration{
        key
        value
      }
    }    
    revisions{
      id

      products{
        ...PURCHASE_PRODUCT_FRAGMENT
      }
      price{
        ...PURCHASE_PRICE_FRAGMENT
      }      
      riderConfirmed
      arrivedAtVendor
      status
      payment
      paymentStatus
      paymentProblem
      currency
      sendUtensils
      preparationTime
      completedIn
      voided
      banknote
      problem
      group
      isMobileApp
      customerTransactionEntries{
        id
        description{
          es
          en
        }
        value
        type
        event
        auditMessage
        note
        createdAt
      }
      timestamps{
        paymentProcessed
        processed
        vendorConfirmed
        assignedToRider
        riderConfirmed
        readyForCollection
        arrivedAtVendor
        collected
        arrivedAtClient
        unprocessed
        completed
        deliveryFailed
        cancelled
        rejected
      }      
      createdAt
    }
    type
    createdAt
    updatedAt
  }
  ${PURCHASE_CUSTOMER_FRAGMENT}
  ${PURCHASE_ADDRESS_FRAGMENT}
  ${PURCHASE_VENDOR_FRAGMENT}
  ${PURCHASE_PRODUCT_FRAGMENT}
  ${PURCHASE_PRICE_FRAGMENT}
  ${REFUND_FRAGMENT}
  ${RIDER_MODIFICATION_FRAGMENT}
`
