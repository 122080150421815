import { getEllapsed } from './'

export function getPurchaseActionType({ status, riderConfirmed }){
  status = status.toLowerCase()

  if (status === "inqueue") return "waitingForDispatcher"
  if (status === "processed") return "waitingForVendor"
  if ((status === "inpreparation" || status === "readyforcollection") && !riderConfirmed) return "waitingForRider"
  if (((status === "inpreparation" || status === "readyforcollection") && riderConfirmed) || ( status === "ontheway" || status === "atlocation")) return "processed"
  if (status === "completed") return "completed"
  if (status === "cancelled" || status === "rejected" || status === "unprocessed" || status === "deliveryfailed") return "cancelled"
}

export function getPurchaseWarnings({customer, createdAt, timestamps, preparationTime}, actionType){
  let deliveryTime = getEllapsed(createdAt)
  let unprocessedTime = getEllapsed(createdAt, timestamps.vendorConfirmed || undefined)

  switch (actionType) {
    case "waitingForDispatcher":
      if (unprocessedTime > 5) return "waitingForDispatcherWarning"
      break
    case "waitingForVendor":
      if (unprocessedTime > 5) return "waitingForVendorWarning"
      break
    case "waitingForRider":
      if ((deliveryTime - (unprocessedTime + preparationTime)) > 5) return "waitingForRiderWarning"
      break
    case "processed":
      if ((!timestamps.collected && (deliveryTime - (unprocessedTime + preparationTime) > 15)) || getEllapsed(timestamps.collected) > 15) return "processedWarning"
      break
    default:
      return undefined
  }
}
