import React, { useContext } from 'react'

import { IntlContext } from '../../../contexts'
import { IntlText, LabeledInput } from '../../../components'

import './style.css'

export function ProductPrice({vendor, price, setPrice}){

    const { getTranslation } = useContext(IntlContext)

    function addComission(value){
        const _price = value/(1-vendor.serviceFee)
        setPrice(Math.round(_price))
    }

    function deductComission(value){
        if (!value) return undefined

        const _price = value * (1-vendor.serviceFee)
        return Math.round(_price)
    }

    return(
    <div className='product-price'>
        <div>
            <LabeledInput id="client-price" type="number" onChange={({target}) => setPrice(target.value)} placeholder={getTranslation({group: "purchase-product-custom-price", id:"client-price"})} value={price} />
            <span className='help'><IntlText group="purchase-product-custom-price" id="client-price-info"/></span>
        </div>
        <div>
            <LabeledInput id="vendor-price" type="number" onChange={({target}) => addComission(target.value)} placeholder={getTranslation({group: "purchase-product-custom-price", id:"vendor-price"})} value={deductComission(price)} />
            <span className='help'><IntlText group="purchase-product-custom-price" id="vendor-price-info"/></span>
        </div>
    </div>        
    )
}