import { gql } from '@apollo/client'

import { RIDER_PAYOUT_FRAGMENT } from './'

export const RIDER_PAYOUTS = gql`
  query riderPayouts($rider: ID, $date: [String], $offset: Int, $limit: Int, $showEmpty: Boolean){
    riderPayouts(rider: $rider, date: $date, offset: $offset, limit: $limit, showEmpty: $showEmpty){
      nodes{
        ...RIDER_PAYOUT_FRAGMENT        
      }
      pageInfo{
        limit
        totalNodes
        firstNode
        lastNode
        totalPages
        actualPage
        hasNextPage         
      }  
    }
  }
  ${RIDER_PAYOUT_FRAGMENT}
`
