import { gql } from '@apollo/client'

import { DELIVERY_ZONE_FRAGMENT } from './'

export const EDIT_DELIVERY_ZONE = gql`
  mutation editDeliveryZone($id: ID!, $data: EditedZoneInput!){
     editDeliveryZone(id: $id, data: $data){
       ...DELIVERY_ZONE_FRAGMENT
     }
  }
  ${DELIVERY_ZONE_FRAGMENT}
`
