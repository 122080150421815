import { gql } from '@apollo/client'

import { RIDER_MODIFICATION_FRAGMENT } from './'

export const PROCESS_RIDER_MODIFICATION_MUTATION = gql`
  mutation processRiderModification($id: ID!, $status: RIDER_MODIFICATION_STATUS!, $voided: Boolean){
    processRiderModification(id: $id, status: $status, voided: $voided){
      ...RIDER_MODIFICATION_FRAGMENT
    }
  }
  ${RIDER_MODIFICATION_FRAGMENT}
`
