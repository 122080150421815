import { gql } from '@apollo/client'

import { PURCHASE_FRAGMENT } from './'

export const EDIT_PURCHASE_MUTATION = gql`
  mutation editPurchase($id: ID!, $data: EditedPurchaseInput!){
    editPurchase(id: $id, data: $data){
      ...PURCHASE_FRAGMENT
    }
  }
  ${PURCHASE_FRAGMENT}
`
