import { gql } from '@apollo/client'

import { DELIVERY_ZONE_FRAGMENT } from './'

export const DELIVERY_ZONES = gql`
  query getDeliveryZones{
     getDeliveryZones{
       ...DELIVERY_ZONE_FRAGMENT
     }
  }
  ${DELIVERY_ZONE_FRAGMENT}
`
