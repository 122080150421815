import { gql } from '@apollo/client'

import { RIDER_BREAK_FRAGMENT } from './'

export const NEW_RIDER_BREAK = gql`
  subscription newRiderBreak{
    newRiderBreak{
      ...RIDER_BREAK_FRAGMENT
    }
  }
  ${RIDER_BREAK_FRAGMENT}
`
