import React, { useContext } from 'react'
import { toast } from 'react-toastify'
import { useMutation } from '@apollo/client'
import { useNavigate, useParams } from 'react-router-dom'

import { useValidatedForm } from '../../../hooks'
import { getPromotionTypes } from '../../../helpers'
import { IntlText, Form, SelectV2, Validation, LabeledInput, Button, IntlValue } from '../../../components'
import { PromotionPreview } from '../../../views'
import { CREATE_PROMOTION_MUTATION } from '../../../graphql'
import { IntlContext } from '../../../contexts'

export function NewPromotion(){

  const validations = {
    "name": [{id: "required", validation: (val) => !!val}],
    "type": [{id: "required", validation: (val) => !!val}],
    "minBasket": [{id: "isNumber", validation: (val) => Number.isInteger(Number(val))}],
  }
  const { id } = useParams()
  const navigate = useNavigate()
  const { getTranslation } = useContext(IntlContext)
  const [createPromotion, { loading }] = useMutation(CREATE_PROMOTION_MUTATION)
  const { form: {name, type, minBasket, discount}, updateField, errors, validate, resetForm } = useValidatedForm({
    name: undefined,
    type: undefined,
    discount: 0
  }, validations)
  const _types = getPromotionTypes({discount, minBasket})
  const selectedType = _types.find(_type => _type.id === type)
  
  async function _createPromotion(){

    const { valid } = validate()

    if (!valid) return toast.error(getTranslation({group: "form", id: "error"}))

    let data = {}

    if (type === "FREEDELIVERYOVERBASKETVALUE"){
      data = {
        name,
        label: _types[0].label,
        description: _types[0].description,
        type,
        restrictions: [{key: "MINBASKETVALUE", value: minBasket || "0"}]
      }
    }

    if (type === "PERCENTAGEOVERBASKETVALUE"){
      data = {
        name,
        label: _types[1].label,
        description: _types[1].description,
        type,
        restrictions: [{key: "MINBASKETVALUE", value: minBasket || "0"}],
        configuration: [{key: "DISCOUNT", value: (discount / 100).toString()}]
      }
    }

    try {
      await createPromotion({variables: {vendor: id, data}})
      toast.success(getTranslation({id: "create-success"}))
      resetForm()
      navigate("../promotions", { replace: true })
    } catch (e) {
      console.log("e", e)
      e.graphQLErrors.map(x => toast.error(x.message))
    }
  }

  return(
    <div id="new-promotion">
      <Form.Form>
        {type &&
          <Form.Field>
            <PromotionPreview type={selectedType} />
          </Form.Field>
        }
        <Form.Field>
          <Validation errors={errors["type"]}>
            <SelectV2 value={(_value) => <IntlValue value={_value.name} />} placeholder="Promotion type" selected={selectedType} defaultValue={getTranslation({id: "please-select"})} options={_types} onChange={(_type) => updateField({key: "type", value:  _type.id})} />
          </Validation>
        </Form.Field>
        <Form.Field>
          <Validation errors={errors["name"]}>
            <LabeledInput name="name" placeholder={getTranslation({group: "promotion-form", id: "name"})} value={name} onChange={(e) => updateField({key: "name", value: e.target.value})} />
          </Validation>
        </Form.Field>
        <Form.Field>
          <Validation errors={errors["minBasket"]}>
            <LabeledInput type='number' name="minBasket" placeholder={getTranslation({group: "promotion-form", id: "min-basket"})} value={minBasket} onChange={(e) => updateField({key: "minBasket", value: e.target.value})} />
          </Validation>
        </Form.Field>
        {type === "PERCENTAGEOVERBASKETVALUE" &&
          <Form.Field>
            <Validation errors={errors["discount"]}>
              <LabeledInput type='number' min="0" max="100" name="discount" placeholder={getTranslation({group: "promotion-form", id: "discount"})} value={discount} onChange={(e) => updateField({key: "discount", value: e.target.value})} />
            </Validation>
          </Form.Field>
        }            
        {selectedType?.vendorDescription &&
          <Form.Field>
            <div className='help'>
              <IntlValue value={selectedType?.vendorDescription} />
            </div>
          </Form.Field>
        }
        <Form.Field>
          <Button loading={loading} theme="main" className="full" onClick={() => _createPromotion()}><IntlText id="save" /></Button>
        </Form.Field>
      </Form.Form>
    </div>
  )
}
