import React, { useState, useRef } from 'react'
import { useMutation } from '@apollo/client'

import { formatPrice, toast } from '../../../helpers'
import { useConfirm, useIntl } from '../../../hooks'
import { EDIT_ADDON_OPTION_MUTATION, DELETE_ADDON_OPTION_MUTATION } from '../../../graphql'
import { Loader, Filter, IntlText, IntlValue, LabeledCheckbox, StatusDot, Table, ContextMenu, Modal, Title } from '../../../components'
import { AddonOption } from '../../../views'

export function AddonOptionsList({refetch, data, filter}){

  const modal = useRef(null)
  const { getTranslatedValue, getTranslation } = useIntl()
  const { confirm } = useConfirm()
  const [selectedItem, selectItem] = useState(undefined)
  const [editAddonOption, { loading: editLoading }] = useMutation(EDIT_ADDON_OPTION_MUTATION)
  const [deleteAddonOption] = useMutation(DELETE_ADDON_OPTION_MUTATION)

  function _selectItem(_data){
    selectItem(_data)
    modal.current?.show()
  }  

  async function _delete(id){
    const alert = toast.loading(getTranslation({group: "confirm", id: "confirm-to-continue"}))
    try {
      await confirm({title: getTranslation({group: "confirm", id: "delete"}), desc: getTranslation({group: "confirm", id: "delete-info"})})
      alert.update(getTranslation({id: "loading"}))
      await deleteAddonOption({variables: {id}})
      alert.success(getTranslation({id: "delete-success"}))
      refetch()
    } catch (error) {
      alert.apolloError(error)
    }
  }  

  async function _editAddonOption(data){
    try {
      await editAddonOption({variables: data})
      refetch()
    } catch (e) {
      toast.apolloError(e)
    }
  }

  async function onSuccess(){
    await refetch()
    modal.current?.hide()
  }  

  return(
    <div id="addon-options-list" className="relative">
      {!!editLoading && <div className="full-size-loader"><Loader theme="main"/></div>}
      <Filter id="addons" data={data?.productAddonOptions || []} search={[{key: "search", value: filter}]} filters={[{filter: (data) => data.id}, {filter: (data) => getTranslatedValue(data.name)}, {filter: (data) => data.disabled}]}>
        {(data) =>
          <Table.Table>
            <Table.Head>
              <Table.Tr>
                <Table.Th></Table.Th>
                <Table.Th className="text-left"><IntlText group="table-header" id="name" /></Table.Th>
                <Table.Th><IntlText group="table-header" id="price" /></Table.Th>
                <Table.Th><IntlText group="table-header" id="disabled" /></Table.Th>
                <Table.Th></Table.Th>
              </Table.Tr>
            </Table.Head>
            <Table.Body>
              {data.map((_addonOption) =>
                <Table.Tr key={_addonOption.id} className="addon">
                  <Table.Td><StatusDot active={!_addonOption.disabled} /></Table.Td>
                  <Table.Td className="text-left"><IntlValue value={_addonOption.name} /></Table.Td>
                  <Table.Td>{formatPrice(_addonOption.price)}</Table.Td>
                  <Table.Td centered><LabeledCheckbox id={`disabled-${_addonOption.id}`} checked={_addonOption.disabled} onChange={(e) => _editAddonOption({id: _addonOption.id, data: {disabled: e.target.checked}})} rounded/></Table.Td>
                  <Table.Td>
                    <ContextMenu.Menu id={_addonOption.id} >
                      <ContextMenu.Button icon="edit" onClick={() => _selectItem(_addonOption)}><IntlText id="quick-edit" /></ContextMenu.Button>
                      <ContextMenu.Button icon="trash" theme="invalid" onClick={() => _delete(_addonOption.id)}><IntlText id="delete" /></ContextMenu.Button>
                    </ContextMenu.Menu>
                  </Table.Td>
                </Table.Tr>
              )}
            </Table.Body>
          </Table.Table>
        }
      </Filter>
      <Modal id="add-addon-option" ref={modal} header={<Title tag="h3"><IntlValue value={selectedItem?.name} /></Title>}>
        {() =>
          <AddonOption addonOption={selectedItem} success={onSuccess} cancel={() => modal.current?.hide()} />
        }
      </Modal>             
    </div>
  )
}
