import { gql } from '@apollo/client'

import { REFUND_FRAGMENT } from '../fragments'

const CREATE_REFUND_MUTATION = gql`
  mutation createRefund($data: RefundInput!){
    createRefund(data: $data){
      ...REFUND_FRAGMENT
    }
  }
  ${REFUND_FRAGMENT}
`

export default CREATE_REFUND_MUTATION
