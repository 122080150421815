import { gql } from '@apollo/client'

export const DAY_CLOSING_SNAPSHOT = gql`
  fragment DAY_CLOSING_SNAPSHOT on DayClosing {
    rider{
      originalId
      name
    }
    date
    workshift
    workshiftStatus
    revenue{
      id
      title
      riderCollectible
      completedAt
    }
    modifications{
      id
      purchaseId
      title
      value
      reason
      status
    }
    tips{
      purchaseId
      title
      value
    }
    totalRevenue
    totalModifications
    totalTips
    openingBalance
    completedDeliveries
    netRiderBalance
    collectedCash
    deficit
  }
`
