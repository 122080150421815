import React from 'react'
import { useMutation, useQuery } from '@apollo/client'

import { LabeledCheckbox, TextEditor, Select, LabeledInput, Loader, Form } from '../../../components'
import { SETTINGS_QUERY, EDIT_SETTING_MUTATION } from '../../../graphql'
import { extractSettings } from '../../../helpers'

const settingTypes = [{id: "error", label: "Error"}, {id: "warning", label: "Warning"}, {id: "success", label: "Success"}]

const defaultMessageTypes = [
  {id: "largeinflow", label: "Large inflow"},
  {id: "queueisclosed", label: "Queue is closed"},
  {id: "rainbreak", label: "Rain break"},
  {id: "rainwarning", label: "Rain warning"},
  {id: "signalproblem", label: "Signal problem"},
  {id: "test", label: "Test mode"}
]

const texts = {
  queueisclosed: {
    es: "Lo siento, estamos un poco ocupados en este momento. Ahora no podemos procesar nuevos pedidos pero el servicio van a estar disponible en 10-15 minutos o tan pronto como sea posible. Disculpa la molestia! Constantemente trabajamos para mejorar el servicio para dar la mejor experiencia!",
    en: "Sorry, we've got a bit busy. Unfortunately we can not process new orders at the moment, but we'll be back in 10-15 minutes or as soon as we can. Allow us to apologise for the inconvenience, we are constantly working on improving the service to ensure the best experience!"
  },
  rainbreak: {
    es: "Tuvimos que PAUSAR nuestro servicio por que las calles no son seguras debido a la lluvia fuerte. ¡Los pedidos ya realizados se están entregando y abrimos tan pronto como podemos! ¡Disculpa la molestia!",
    en: "Unfortunately we had to PAUSE our service as the roads are not safe for driving due to the current weather conditions. Already placed orders are currently being delivered and we will return as soon as we can! Sorry for the inconvenience!"
  },
  rainwarning: {
    es: "Para garantizar la seguridad, nuestros repartidores bajan la velocidad en la lluvia. Los tiempos de entrega pueden ser más un poco más largo que lo habitual! ¡Por favor, ayude al trabajo de nuestros conductores con asegurándose de que puedan contactarlo fácilmente cuando lleguen! ¡Gracias por su paciencia y disculpa las molestias!",
    en: "To ensure safety under the current weather conditions our drivers are a bit slower than usual. Delivery times might increase slightly, but we do our best to arrive as soon as we can! Please, help our drivers' work by making sure they can contact you easily when arrive! Thank you for your understanding and patience!"
  },
  largeinflow: {
    es: "Estamos un poco ocupados. El tiempo de entrega puede ser más largo de lo habitual. ¡Gracias por su paciencia y disculpa las molestias!",
    en: "We are a bit busy! Delivery times might be longer than usual. Thank you for your understanding and patience!"
  },
  signalproblem: {
    es: "Estamos experimentando problemas técnicos con nuestro proveedor de servicios telefónicos, ¡pero volveremos tan pronto como podamos! ¡Lo siento por los inconvenientes ocasionados!",
    en: "We are experiecing technical problems with our phone service provider, but will be back as soon as we can! Sorry for the inconvenience!"
  },
  test: {
    es: "<p>&iexcl;Hola! Este es solo un sitio web de prueba; si desea realizar un pedido, visite  <a href='https://www.tomato.mx'>www.tomato.mx</a></p>",
    en: "<p>Hi! This is just a test website, if you want to order please visit <a href='https://www.tomato.mx'>www.tomato.mx</a></p>"
  }
}


export function AlertBarSettings(){

  const [editSetting, { loading: editLoading }] = useMutation(EDIT_SETTING_MUTATION, {refetchQueries: [{query: SETTINGS_QUERY, variables: {keys: ["alertbar", "alertbarHeading", "alertbarText", "alertbartext_es", "alertbartitle_es", "alertbarColor"]}}]})
  const { loading, data, error } = useQuery(SETTINGS_QUERY, {variables: {keys: ["alertbar", "alertbarHeading", "alertbarText", "alertbartext_es", "alertbartitle_es", "alertbarColor"]}})

  async function setDefaultMessage(type) {
    await editSetting({variables: {key: "alertbartext_es", data: {value: texts[type.id].es}}})
    await editSetting({variables: {key: "alertbarText", data: {value: texts[type.id].en}}})
  }

  async function _editSetting(key, value){
    try {
      editSetting({variables: {key, data: {value}}})
    } catch (e) {
      console.log("Error: ", e);
    }
  }

  if (loading) return <Loader theme="main"/>
  if (error) return `Error! ${error.message}`

  let settings = extractSettings(data.settings)

  return(
    <Form.Form>
      <Form.Field>
        <LabeledCheckbox loading={editLoading} id="alertbar" rounded checked={settings["alertbar"]?.value} onChange={(e) => _editSetting(settings["alertbar"]?.key, e.target.checked.toString())}>Enable / disable</LabeledCheckbox>
      </Form.Field>
      <Form.Field>
        <Select placeholder={settings["alertbarcolor"]?.description} selected={settingTypes.find(setting => setting.id === settings["alertbarcolor"]?.value)} defaultValue="Please select" options={settingTypes} onChange={(type) => editSetting({variables: {key: settings["alertbarcolor"]?.key, data: {value: type.id}}})} />
      </Form.Field>
      <Form.Field>
        <Select placeholder="Select between default messages" selected={null} defaultValue="Please select" options={defaultMessageTypes} onChange={(type) => setDefaultMessage(type)} />
      </Form.Field>
      <Form.Legend>English</Form.Legend>
      <Form.Field>
        <LabeledInput id="alertbarheading" placeholder={settings["alertbarheading"]?.description} value={settings["alertbarheading"]?.value} onChange={(e) => _editSetting(settings["alertbarheading"]?.key, e.target.value)}/>
      </Form.Field>
      <Form.Field>
        <TextEditor value={settings["alertbartext"]?.value} placeholder="Type the content here!" save={(data) => _editSetting(settings["alertbartext"]?.key, data)}/>
      </Form.Field>
      <Form.Legend>Spanish</Form.Legend>
      <Form.Field>
        <LabeledInput id="alertbartitle_es" placeholder={settings["alertbartitle_es"]?.description} value={settings["alertbartitle_es"]?.value} onChange={(e) => _editSetting(settings["alertbartitle_es"]?.key, e.target.value)}/>
      </Form.Field>
      <Form.Field>
        <TextEditor value={settings["alertbartext_es"]?.value} placeholder="Type the spanish content here!" save={(data) => _editSetting(settings["alertbartext_es"]?.key, data)}/>
      </Form.Field>
    </Form.Form>
  )

}
