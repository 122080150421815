import { gql } from '@apollo/client'

import { PROMOTION_FRAGMENT } from './'

export const EDIT_PROMOTION_MUTATION = gql`
  mutation editPromotion($id: ID!, $data:EditedPromotionInput!){
    editPromotion(id: $id, data:$data){
      ...PROMOTION_FRAGMENT
    }
  }
  ${PROMOTION_FRAGMENT}
`
