import React, { useState } from 'react'
import FontAwesome from 'react-fontawesome'
import { toast } from 'react-toastify'

import './SetTime.css'

export default function SetTime({accept, cancel, defaultValue = 0, min = 0, max}) {

  const [time, changeTime] = useState(defaultValue)

  const onPressEnter = (e) => {
    if (!e) e = window.event
    var keyCode = e.keyCode || e.which

    if (keyCode === '13' || keyCode === 13) setTime()
  }

  const setTime = () => {

    if (min && min > time) return toast.error(`${min} is the minimum value!`)
    if (max && max < time) return toast.error(`${max} is the maximum value!`)

    accept(time)
    changeTime(defaultValue)
  }

  return(
    <div className="set-time">
      <button onClick={cancel} className="cancel">
        <FontAwesome name="times"/>
      </button>
      <input type="number" value={time} onChange={(e) => changeTime(e.target.value)} step={5} onKeyPress={onPressEnter}/>
      <button onClick={() => setTime()} className="accept">
        <FontAwesome name="check"/>
      </button>
    </div>
  )
}
