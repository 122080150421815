import { gql } from '@apollo/client'

import { RIDER_WORKSHIFT_FRAGMENT } from './'

export const FINISH_RIDER_WORKSHIFT = gql`
  mutation finishRiderWorkShift($rider: ID!, $data: FinishWorkShiftInput!){
    finishRiderWorkShift(rider: $rider, data: $data){
      ...RIDER_WORKSHIFT_FRAGMENT
    }
  }
  ${RIDER_WORKSHIFT_FRAGMENT}
`
