import React from 'react'
import { useMutation } from '@apollo/client'
import { toast } from 'react-toastify'
import FontAwesome from 'react-fontawesome'
import Tippy from '@tippyjs/react'

import { EDIT_CUSTOMER_MUTATION } from '../../../graphql'

const __tags = {
  frequent: <Tippy content="Frequent client"><div style={{color: `var(--valid-color)`}}><FontAwesome name="star" /></div></Tippy>,
  incorrectContact: <Tippy content="Incorrect contact details"><div style={{color: `var(--invalid-color)`}}><FontAwesome name="phone-slash" /></div></Tippy>,
  incorrectAddress: <Tippy content="Incorrect location"><div style={{color: `var(--invalid-color)`}}><FontAwesome name="map-marker-alt-slash" /></div></Tippy>,
  duplicatedOrder: <Tippy content="Duplicated orders"><div style={{color: `var(--invalid-color)`}}><FontAwesome name="clone" /></div></Tippy>,
  noCash: <Tippy content="Ordered without cash"><div style={{color: `var(--invalid-color)`}}><FontAwesome name="usd-square" /></div></Tippy>,
  deliveryFailed: <Tippy content="Has failed delivery"><div style={{color: `var(--warning-color)`}}><FontAwesome name="exclamation-circle" /></div></Tippy>,
  problematicChange: <Tippy content="Problematic modifications, only accept orders through app no modifications though messages"><div style={{color: `var(--warning-color)`}}><FontAwesome name="exclamation-circle" /></div></Tippy>,
  problematicBusy: <Tippy content="Problematic client, can not order or only if accept the possible delay in bad weather conditions or when we are super busy"><div style={{color: `var(--warning-color)`}}><FontAwesome name="exclamation-circle" /></div></Tippy>,
}

export function CustomerTags({customer}) {

  const [editCustomer] = useMutation(EDIT_CUSTOMER_MUTATION)
  const { originalId, note, balance, abusive, tags } = customer
  const _tags = [...new Set(tags)]

  async function _editCustomer(data){
    try {
      await editCustomer({variables: {id: originalId, data: {balance: 0}}})
      return toast.success("Balance cleared successfully")
    } catch (e) {
      e.graphQLErrors.map(x => toast.error(x.message))
    }
  }

  return(
    <div className="tags">
      {_tags && _tags.map((tag, key) =>
         <div key={key}>{__tags[tag] || null}</div>
      )}
      {abusive &&
        <Tippy content={note}>
          <div><FontAwesome name="exclamation-triangle" /></div>
        </Tippy>
      }
      {!!balance &&
        <Tippy content={balance}>
          <div className={`customer-balance ${balance > 0 ? "plus" : "minus"}`} onClick={() => _editCustomer()}>
            <i className="fas fa-sack-dollar"></i>
          </div>
        </Tippy>
      }
    </div>
  )

}
