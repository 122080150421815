import React, { useState, useEffect, useRef } from 'react'
import { Mutation } from '@apollo/client/react/components'
import FontAwesome from 'react-fontawesome'
import moment from 'moment'

import { pad, error } from '../../helpers'
import { SET_PREPARATION_TIME_MUTATION } from '../../graphql'
import { SetTime, Loader, IntlText } from '../../components'

import './CookingTime.css'

export default function CookingTime({purchase: { id, preparationTime, status, timestamps: {vendorConfirmed}}, disableCallback}) {
  const [edit, toggleEdit] = useState(false)
  const [time, setTime] = useState({})
  const isMounted = useRef(true)

  useEffect(() => {
    return () => {
      isMounted.current = false
    }
  }, [])

  useEffect(() => {
    let interval = undefined

    function updateTimer(){
      if (!preparationTime || !isMounted?.current) return false

      let now = moment(vendorConfirmed).add(preparationTime, "m")
      let future = moment()
      let diff = future - now

      if (diff >= 0){
        setTime({mins: 0, secs: 0})
        return clearInterval(interval)
      }

      let mins  = Math.floor( diff / (1000*60) )
      let secs  = Math.floor( diff / 1000 ) - mins  * 60

      mins = secs === 0 ? -mins : -mins - 1
      secs = secs === 0 ? secs : 60 - secs

      setTime({mins, secs})
    }

    updateTimer()
    interval = setInterval(() => updateTimer(), 1000 )

    return () => clearInterval(interval)
  }, [preparationTime, vendorConfirmed])


  function onCompleted(){
    if (isMounted.current) toggleEdit(false)
  }

  let { mins, secs } = time

  if (preparationTime && mins === undefined) return <Loader theme="main"/>

  return(
    <div className="cooking-time">
      {!edit && preparationTime ?
        <div className="current-time">
          {(mins === 0 && secs === 0) || status === "READYFORCOLLECTION" ?
            <div className="ready-for-collect">
              <span className="label">
                <span className="text">
                  {status === "READYFORCOLLECTION" ?
                    <IntlText gourp="cooking-time" id="cooked">Cooked</IntlText>
                    :
                    <IntlText gourp="cooking-time" id="expired">Expired</IntlText>
                  }
                </span>
                <span className="preparation-time">{preparationTime}</span>
              </span>
            <FontAwesome name="edit" onClick={() => toggleEdit(true)}/>
            </div>
          :
            <div>
              <span className="mins">{pad(mins)}</span>
              <span className="separator">:</span>
              <span className="secs">{pad(secs)}</span>
              <FontAwesome name="edit" onClick={() => toggleEdit(true)}/>
            </div>
          }
        </div>
      :
        <Mutation mutation={SET_PREPARATION_TIME_MUTATION} onCompleted={() => onCompleted()}>
          {(setTime) => {
            async function _setTime(_params){
              try {
                await setTime(_params)
              } catch (e) {
                error(e)
              }
            }
            return <SetTime accept={(preparationTime) => _setTime({variables: {id: id, time: parseInt(preparationTime)}})} cancel={() => toggleEdit(false)}/>
          }}
        </Mutation>
      }
    </div>
  )
}
