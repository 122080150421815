import React, { useContext } from 'react'
import { toast } from 'react-toastify'
import FontAwesome from 'react-fontawesome'
import { useMutation } from '@apollo/client'

import { UPLOAD_PRODUCT_MEDIA_MUTATION, DELETE_PRODUCT_MEDIA_MUTATION } from '../../../graphql'
import { FileUploader, IntlText , Loader, Form} from '../../../components'
import { IntlContext } from '../../../contexts'
import { noThumb } from '../../../assets'

export function ProductMedia({product, product: {media, name}}) {

  const { getTranslation, getTranslatedValue } = useContext(IntlContext)
  const [uploadProductImage, { loading: uploadLoading}] = useMutation(UPLOAD_PRODUCT_MEDIA_MUTATION)
  const [deleteProductImage, { loading: deleteLoading}] = useMutation(DELETE_PRODUCT_MEDIA_MUTATION)

  async function uploadMedia({target, type}){
    if (!target.files?.[0]) return toast.error(getTranslation({id: "nothing-to-upload"}))

    try {
      const file = target.files[0]

      await uploadProductImage({variables: {product: product.id, file, type}})
      return toast.success(getTranslation({id: "successfully-upload"}))
    } catch (e) {
      e.graphQLErrors.map(x => toast.error(x.message))
    }
  }

  async function deleteMedia(type){
    try {
      await deleteProductImage({variables: {id: product.id, type}})
      return toast.success(getTranslation({id: "successfully-delete"}))
    } catch (e) {
      e.graphQLErrors.map(x => toast.error(x.message))
    }
  }


  return(
    <div id="product-media" className="relative">
      {(uploadLoading || deleteLoading) && <Loader theme="main" overlay/>}
      <Form.Form>
        <Form.Field>
          <div className="image-uploader">
            <div id="cover" className="preview">
              {media?.cover &&
                <div className="delete"><FontAwesome name="trash" onClick={() => deleteMedia("IMAGE_PRODUCT_COVER")}/></div>
              }
              <img src={media?.cover || noThumb} alt={getTranslatedValue(name)} title={getTranslatedValue(name)} />
            </div>
            <FileUploader id="cover" placeholder={<IntlText id="cover" />} onChange={({target}) => uploadMedia({target, type: "IMAGE_PRODUCT_COVER", width: 1200, height: 460})}/>
          </div>
        </Form.Field>
        <Form.Field>
          <div className="image-uploader">
            <div id="cover" className="preview">
              {media?.thumbnail &&
                <div className="delete"><FontAwesome name="trash" onClick={() => deleteMedia("IMAGE_PRODUCT_THUMBNAIL")}/></div>
              }
              <img src={media?.thumbnail || noThumb} alt={getTranslatedValue(name)} title={getTranslatedValue(name)} />
            </div>
            <FileUploader id="thumbnail" placeholder={<IntlText id="thumbnail" />} onChange={({target}) => uploadMedia({target, type: "IMAGE_PRODUCT_THUMBNAIL", width: 600, height: 600})}/>
          </div>
        </Form.Field>
      </Form.Form>
    </div>
  )
}
