import { gql }from '@apollo/client'

import { ADDON_OPTION_FRAGMENT } from './'

const ADDON_OPTIONS_QUERY = gql`
  query productAddonOptions($owner: String){
     productAddonOptions(owner: $owner) {
       ...ADDON_OPTION_FRAGMENT
     }
  }
  ${ADDON_OPTION_FRAGMENT}
`

export default ADDON_OPTIONS_QUERY
