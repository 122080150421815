import { gql } from '@apollo/client'

import { PURCHASE_FRAGMENT } from './'

export const APPROVE_PURCHASE = gql`
  mutation approvePurchase($id: ID!){
    approvePurchase(id: $id) {
        ...PURCHASE_FRAGMENT
     }
  }
  ${PURCHASE_FRAGMENT}
`
