import React, { useState } from 'react'
import FontAwesome from 'react-fontawesome'

import { useIntl } from '../../hooks'
import { Logo, Authentication, SidebarMenu } from '../../components'

import './style.css'

export function LeftMenu() {

  const { getTranslation } = useIntl()
  const [opened, toggle] = useState(JSON.parse(localStorage.getItem('left-menu')))

  function _toggle(val){
    localStorage.setItem('left-menu', val)
    toggle(val)
  }

  return(
    <Authentication>
      {({userCanSee}) =>
        <div id="left" className={`${opened ? "opened" : ""}`}>
          <div className="sticky">
            <div className="menu-header">
                <Logo small={!opened}/>
            </div>
            <SidebarMenu.Menu>
              <SidebarMenu.Link icon="tasks" to="/tasks">Tasks</SidebarMenu.Link>
              <SidebarMenu.Link icon="shopping-cart" to="/orders">Orders</SidebarMenu.Link>
              <SidebarMenu.Link icon="file-alt" to="/purchase-quotes">Quotes</SidebarMenu.Link>
              <SidebarMenu.SubMenu icon="utensils" label="Vendors" path="/vendors">
                <SidebarMenu.Link icon="users" to="/vendors">Vendors</SidebarMenu.Link>
                {userCanSee() &&
                  <>
                    <SidebarMenu.Link icon="user-plus" to="/vendors/new">New vendor</SidebarMenu.Link>
                    <SidebarMenu.Link icon="file-invoice" to="/vendors/invoices">Invoices</SidebarMenu.Link>
                    <SidebarMenu.Link icon="list" to="/vendors/book-entries">Book entries</SidebarMenu.Link>
                  </>
                }
              </SidebarMenu.SubMenu>
              <SidebarMenu.SubMenu icon="user" label="Customers" path="/customers">
                <SidebarMenu.Link icon="users" to="/customers">All customers</SidebarMenu.Link>
                <SidebarMenu.Link icon="users-slash" to="/customers/deleted">Deleted customers</SidebarMenu.Link>
                <SidebarMenu.Link icon="file-invoice" to="/customers/invoices">Invoices</SidebarMenu.Link>
                <SidebarMenu.Link icon="list" to="/customers/book-entries">Book entries</SidebarMenu.Link>
              </SidebarMenu.SubMenu>
              <SidebarMenu.SubMenu icon="motorcycle" label="Riders" path="/riders">
                <SidebarMenu.Link icon="users" to="/riders">All riders</SidebarMenu.Link>
                <SidebarMenu.Link icon="user-plus" to="/riders/new">New rider</SidebarMenu.Link>
                <SidebarMenu.Link icon="list" to="/riders/breaks">Breaks</SidebarMenu.Link>
                <SidebarMenu.Link icon="list" to="/riders/workshifts">Workshifts</SidebarMenu.Link>
                <SidebarMenu.Link icon="list" to="/riders/modifications">Modifications</SidebarMenu.Link>
                {userCanSee() &&
                  <SidebarMenu.Link icon="money-bill" to="/riders/payouts">Payouts</SidebarMenu.Link>
                }
                {userCanSee() &&
                  <SidebarMenu.Link icon="file" to="/actas">Actas</SidebarMenu.Link>
                }
              </SidebarMenu.SubMenu>
              {userCanSee() &&
                <SidebarMenu.SubMenu icon="user-secret" label="Admins" path="/admins">
                  <SidebarMenu.Link icon="users" to="/admins">Admins</SidebarMenu.Link>
                  <SidebarMenu.Link icon="user-plus" to="/admins/new">New admin</SidebarMenu.Link>
                </SidebarMenu.SubMenu>
              }
              {userCanSee() &&
                <SidebarMenu.SubMenu icon="file" label="Pages" path="/pages">
                  <SidebarMenu.Link icon="list" to="/pages">All pages</SidebarMenu.Link>
                  <SidebarMenu.Link icon="file-plus" to="/pages/new">New page</SidebarMenu.Link>
                </SidebarMenu.SubMenu>
              }
              {userCanSee() &&
                <SidebarMenu.SubMenu icon="file-image" label={getTranslation({group: "main-menu", id: "ads"})} path="/announcements">
                  <SidebarMenu.Link icon="list" to="/announcements">{getTranslation({group: "main-menu", id: "ads-all"})}</SidebarMenu.Link>
                  <SidebarMenu.Link icon="file-plus" to="/announcements/new">{getTranslation({group: "main-menu", id: "ads-new"})}</SidebarMenu.Link>
                </SidebarMenu.SubMenu>
              }
              {userCanSee() &&
                <SidebarMenu.SubMenu icon="question-square" label="FAQ" path="/faqs">
                  <SidebarMenu.Link icon="list" to="/faqs">All FAQ items</SidebarMenu.Link>
                  <SidebarMenu.Link icon="file-plus" to="/faqs/new">New FAQ item</SidebarMenu.Link>
                </SidebarMenu.SubMenu>
              }
              {userCanSee() &&
                <SidebarMenu.SubMenu icon="file" label="Changelogs" path="/changelogs">
                  <SidebarMenu.Link icon="list" to="/changelogs">All changelogs</SidebarMenu.Link>
                  <SidebarMenu.Link icon="file-plus" to="/changelogs/new">New changelog</SidebarMenu.Link>
                </SidebarMenu.SubMenu>
              }
              {userCanSee() &&
                <SidebarMenu.SubMenu icon="cog" label="Configuration" path="/configuration">
                  <SidebarMenu.Link to="/configuration">Settings</SidebarMenu.Link>
                  <SidebarMenu.Link to="/audit">Logs</SidebarMenu.Link>
                  <SidebarMenu.Link to="/configuration/import">Import</SidebarMenu.Link>
                </SidebarMenu.SubMenu>
              }
            </SidebarMenu.Menu>
            <div className="menu-toggle" onClick={() => _toggle(!opened)}>
              <FontAwesome name="chevron-left" />
            </div>
          </div>
        </div>
      }
    </Authentication>
  )
}
