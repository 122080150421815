import React, { useState } from 'react'
import { useQuery, useMutation } from '@apollo/client'

import { LabeledCheckbox, Loader, StatusDot, Filter, Filters, Table, Map } from '../../../components'
import { DELIVERY_ZONES, EDIT_DELIVERY_ZONE } from '../../../graphql'

import './style.css'

const filters = [
  {filter: (data) => data.name}
]

export function DeliveryZones(){

  const [filter, setFilter] = useState()
  const { loading, data, error } = useQuery(DELIVERY_ZONES)
  const [editDeliveryZone, { loading: editLoading }] = useMutation(EDIT_DELIVERY_ZONE)

  async function _editDeliveryZone(obj){
    try {
      await editDeliveryZone({variables:{id: obj.id, data: {disabled: obj.disabled, disabledReason: obj.reason}}})
    } catch (e) {
      console.log("Error: ", e)
    }
  }

  if (loading) return <Loader theme="main"/>
  if (error) return `Error! ${error.message}`

  const { getDeliveryZones: deliveryZones } = data

  function handleApiLoaded(map, maps){
    deliveryZones.forEach((zone) => {
      const geometry = zone.geometry.coordinates[0].map((zone) =>
        ({lat: zone[1], lng: zone[0]})
      )
      const color = zone.disabled ? "red" : "green"
      var polygon = new maps.Polygon({
       paths: geometry,
       strokeColor: color,
       strokeOpacity: 0.8,
       strokeWeight: 1,
       fillColor: color,
       fillOpacity: 0.35
     })
     
     polygon.setMap(map)
    })
  }

  return(
    <div id="delivery-zones">
      <div className="map">
        {editLoading && <Loader theme="main" overlay />}
        <Map.Map key={editLoading} zoom={12} yesIWantToUseGoogleMapApiInternals onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)} />      
      </div>
      <Filters.Bar vertical>
        <Filters.Input id="query" name="query" value={filter} onChange={({target}) => setFilter(target.value)} reset={() => setFilter("")} placeholder="Type here for searching" />
      </Filters.Bar>
      <Filter id="delivery-zones-filter" data={deliveryZones}  search={[{key: "search", value: filter}]} filters={filters} sort={(a, b) => new Date(a.createdAt) - new Date(b.createdAt)} filterKeys={["description", "key", "type"]}>
        {(data) =>
          <Table.Table id="delivery-zones-list" className="table">
            <Table.Head>
              <Table.Tr>
                <Table.Th></Table.Th>
                <Table.Th className="text-left">Name</Table.Th>
                <Table.Th>Temporary disable</Table.Th>
                <Table.Th>WeaTable.Ther conditions</Table.Th>
                <Table.Th>Out of service</Table.Th>
              </Table.Tr>
            </Table.Head>
            <tbody>
              {data && data.map((deliveryZone, key) =>
                <Table.Tr key={key} className={`delivery-zone ${deliveryZones.disabled ? "disabled" : ""}`}>
                  <Table.Td><StatusDot active={!deliveryZones.disabled} /></Table.Td>
                  <Table.Td className="text-left">{deliveryZone.name}</Table.Td>
                  <Table.Td centered className="center"><LabeledCheckbox loading={editLoading} id={`${deliveryZone.slug}-temporary`} checked={deliveryZone.disabled && deliveryZone.disabledReason === "TEMPORARILY_UNAVAILABLE"} onChange={({target}) => _editDeliveryZone({id: deliveryZone.id, disabled: target.checked, reason: "TEMPORARILY_UNAVAILABLE"})} rounded/></Table.Td>
                  <Table.Td centered className="center"><LabeledCheckbox loading={editLoading} id={`${deliveryZone.slug}-weather`} checked={deliveryZone.disabled && deliveryZone.disabledReason === "WEATHER_CONDITIONS"} onChange={({target}) => _editDeliveryZone({id: deliveryZone.id, disabled: target.checked, reason: "WEATHER_CONDITIONS"})} rounded/></Table.Td>
                  <Table.Td centered className="center"><LabeledCheckbox loading={editLoading} id={`${deliveryZone.slug}-no-service`} checked={deliveryZone.disabled && deliveryZone.disabledReason === "OUT_OF_SERVICE_RANGE"} onChange={({target}) => _editDeliveryZone({id: deliveryZone.id, disabled: target.checked, reason: "OUT_OF_SERVICE_RANGE"})} rounded/></Table.Td>
                </Table.Tr>
              )}
            </tbody>
          </Table.Table>
        }
      </Filter>
    </div>
  )

}
