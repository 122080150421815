import { useState, useEffect } from 'react'
import { useMutation, useQuery } from '@apollo/client'
import FontAwesome from 'react-fontawesome'
import moment from 'moment'

import { RIDERS_QUERY, ASSIGN_RIDER_MUTATION, REMOVE_RIDER_MUTATION, RIDER_STATUS_CHANGED_SUBSCRIPTION } from '../../../graphql'
import { useConfirm, useIntl, useModal } from '../../../hooks'
import { toast } from '../../../helpers'
import { Loader, Filterv2, Tooltip, StatusDot, Button, Modalv3, Badge, Input } from '../../../components'

import './style.css'
import { NotFound } from '../../NotFound'

export function AssignRider({purchase}) {

  const { confirm } = useConfirm()
  const { getTranslation } = useIntl()
  const {loading, error, data, subscribeToMore} = useQuery(RIDERS_QUERY, {variables: {active: true}})
  const [assignRider] = useMutation(ASSIGN_RIDER_MUTATION)
  const [removeRider] = useMutation(REMOVE_RIDER_MUTATION)
  const [search, setSearch] = useState(null)
  const modal = useModal()

  useEffect(() => {
    subscribeToMore({
      document: RIDER_STATUS_CHANGED_SUBSCRIPTION,
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data) return prev
        const _riders = [...prev?.riders]
        const index = _riders.findIndex((purchase) => purchase.id === subscriptionData.data.riderStatusChange.id)
        if (index >= 0){
          _riders[index] = subscriptionData.data.riderStatusChange
          return Object.assign({}, prev, {
            riders: _riders
          })
        } else {
          return Object.assign({}, prev, {
            riders: [subscriptionData.data.riderStatusChange, ..._riders]
          })
        }
      }
    })
  // eslint-disable-next-line
  }, [])

  if (loading) return <Loader theme="main"/>
  if (error) return `Error! ${error.message}`

  const { riders } = data

  async function _removeRider(){
    const alert = toast.loading()
    try {
      alert.update(getTranslation({group: "confirm", id: "confirm-to-continue"}))
      await confirm({title: getTranslation({group: "assign-rider", id: "assign-to-remove"}), desc: getTranslation({group: "assign-rider", id: "assign-to-remove-info"})})      
      alert.update(getTranslation({id: "loading"}))
      await removeRider({variables: {id: purchase.id}})
      alert.success(getTranslation({group: "assign-rider", id: "unassign-success"}))
    } catch (e) {
      alert.apolloError(e)
    }
  }

  async function onSelectRider(rider){
    const alert = toast.loading()

    try {
      if (!(rider.active && rider.available)){
        alert.update(getTranslation({group: "confirm", id: "confirm-to-continue"}))
        await confirm({title: getTranslation({group: "assign-rider", id: "assign-to-unavailable"}), desc: getTranslation({group: "assign-rider", id: "assign-to-unavailable-info"})})      
        alert.update(getTranslation({id: "loading"}))
        await assignRider({variables: {id: purchase.id, rider: rider.id}})
      } else {
        await assignRider({variables: {id: purchase.id, rider: rider.id}})
      }
      alert.success(getTranslation({group: "assign-rider", id: "assign-success", variables: {name: rider.name}}))
      modal.hide()
    } catch (error) {
      alert.apolloError(error)
    }
  }

  return(
    <div className="assign-rider-wrapper">
      {purchase.rider ?
        <div className="assigned-rider">
            <Badge icon={
              purchase.arrivedAtVendor
              ? <>
                  <Tooltip text={`Rider confirmed order at ${moment(purchase.timestamps.riderConfirmed).format("HH:mm")}`}><FontAwesome name="check" /></Tooltip>
                  <Tooltip text={`Rider arrived to vendor at ${moment(purchase.timestamps.arrivedAtVendor).format("HH:mm")}`}><FontAwesome name="map-marker" /></Tooltip>
                </>
              : purchase.riderConfirmed
              ? <Tooltip text={`Rider confirmed order at ${moment(purchase.timestamps.riderConfirmed).format("HH:mm")}`}><FontAwesome name="check" /></Tooltip>
              : <Tooltip text={`Rider received the notification at ${moment(purchase.timestamps.assignedToRider).format("HH:mm")}`}><FontAwesome name="times" /></Tooltip>
            }>{purchase.rider.name}</Badge>
            <Button icon="edit" onClick={() => modal.show()}/>
            <Button icon="trash" theme="reject" onClick={_removeRider}/>
        </div>
      :
        <div className="assign-rider">
          <Button icon="plus-circle" onClick={() => modal.show()}>{getTranslation({group: "assign-rider", id: "button"})}</Button>
        </div>
      }
      <Modalv3 id="assign-rider" modal={modal} headerTitle={getTranslation({group: "assign-rider", id: "title"})}>
        <div className='active-riders'>
          <div className='search-bar'><Input icon="search" value={search} placeholder={getTranslation({id: "search"})} onChange={({target}) => setSearch(target.value)} /></div>
          <Filterv2 data={riders} filters={[
            {filter: (data) => (!!data?.name && data.name.toLowerCase().search(search?.toLowerCase()) !== -1) ||( !!data?.username && data.username.toLowerCase().search(search?.toLowerCase()) !== -1), skip: !search},
          ]} >
            {(data) => data.length > 0 ? data.map((item, key) => 
              <div key={key} className='rider' onClick={() => onSelectRider(item)}>
                <div><StatusDot active={!!(item.active && item.available)} />{item.name}</div>
                <div className='tags'>
                  <Badge>{getTranslation({group: "riders", id: item.type})}</Badge>
                  {!item.available &&
                    <Badge theme='invalid'>{getTranslation({group: "riders", id: "not-available"})}</Badge>
                  }
                  {item.canDoCard &&
                    <Badge theme='main' icon="credit-card" />
                  }
                </div>
              </div>
            ) : <NotFound icon='search-minus' theme='ui' text={getTranslation({id: "no-results"})} />}
          </Filterv2>
        </div>
      </Modalv3>
    </div>
  )
}
