import React from 'react'

import { Title, Logo, IntlText } from '../../components'
import { LoginForm } from '../../views'

import './style.css'

export function Login() {
  return(
    <div id="login" className="container">
      <div className="row wrapped">
        <div id="login-form-wrapper">
          <Logo />
          <div className="heading">
            <div className="inner">
              <Title tag="h1"><IntlText group="login" id="title">Login</IntlText></Title>
              <div className="desc"><IntlText group="login" id="desc">Please fill out the form to log in</IntlText></div>
            </div>
          </div>
          <LoginForm />
        </div>
      </div>
    </div>
  )
}
