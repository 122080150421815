import React, { useRef } from 'react'
import { useQuery } from '@apollo/client'
import FontAwesome from 'react-fontawesome'

import { ADDONS_QUERY } from '../../../graphql'
import { Loader, IntlValue, ToggleList, RadioDot, Modal, Title, IntlText } from '../../../components'
import { Addon } from '../../../views'

import './style.css'

export function AddProductAddon({product, addon, onChange}) {

  const modal = useRef(null)
  const {loading, error, data, refetch} = useQuery(ADDONS_QUERY, {variables: {owner: product?.owner}})

  async function onSuccess(){
    await refetch()
    modal?.current?.hide()
  }

  if (loading) return <Loader theme="main"/>
  if (error) return `Error! ${error.message}`

  const { productAddons } = data

  return(
    <div id="add-product-addon">
      <ToggleList items={productAddons} selected={addon}>
        {(item) =>
          <div className="item" onClick={() => onChange(item)}>
            <RadioDot checked={item.id === addon?.id} readOnly/>
            <div className="name">
              <span><IntlValue>{item.name}</IntlValue></span>
              <span className="instruction"><IntlValue>{item.instruction}</IntlValue></span>
            </div>
            <div className="details">
              {item.minSelect ? <div className="detail"><span>min</span><span>{item.minSelect}</span></div> : null}
              {item.maxSelect ? <div className="detail"><span>max</span><span>{item.maxSelect}</span></div> : null}
            </div>
          </div>
        }
      </ToggleList>
      <span id="add-new" onClick={() => modal?.current?.show()}><FontAwesome name="plus-circle" /><IntlText group="add-product-addon-modal" id="add-new-addon" /></span>
      <Modal id="add-addon" ref={modal} header={<Title tag="h3"><IntlText group="add-product-addon" id="add-addon-modal-title" /></Title>}>
        {() =>
          <Addon product={product} success={onSuccess} cancel={() => modal?.current?.hide()}/>
        }
      </Modal>             
    </div>
  )
}
