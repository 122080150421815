import React, { useState, useContext } from 'react'

import { LabeledInput } from '../'
import { IntlContext } from '../../contexts'

import './MultilangInput.css'

export default function MultilangInput({name, placeholder, value = {}, format, onChange}) {

  const { selectedLanguage, languages } = useContext(IntlContext)
  const [selectedLang, changeLanguage] = useState(selectedLanguage)

  const _onChange = (e, language) => {
    if (!value) value = {}
    value[language.shortcode] = format ? format(e.target.value) : e.target.value
    onChange(e, value)
  }

  var filter = Object.entries(languages).map(([key, language])  =>
    <button key={key} className={language.shortcode === selectedLang.shortcode ? "active" : null}  onClick={(e) => changeLanguage(language)} >
      <img src={language.flag} alt={language.name} />
    </button>
  )

  return(
    <div className="multilang-input">
      <div className="languages">
        <div>
          {filter}
        </div>
      </div>
      <LabeledInput name={name} placeholder={placeholder} value={value?.[selectedLang?.shortcode]} onChange={(e) => _onChange(e, selectedLang)}/>
    </div>
  )
}
