import { gql } from '@apollo/client'

export const CHANGELOG_FRAGMENT = gql`
  fragment CHANGELOG_FRAGMENT on Changelog {
    id
    type
    title{
      en
      es
    }
    description{
      en
      es
    }
    content{
      en
      es
    }
    hidden
    createdAt
    updatedAt
  }
`