import React from 'react'
import FontAwesome from 'react-fontawesome'
import Tippy from '@tippyjs/react'

import './style.css'

export function FeedbackField({feedback, type = "VENDOR"}) {

  const qualitative = type === "VENDOR" ? feedback.productsQualitative : feedback.deliveryQualitative
  const quantitative = type === "VENDOR" ? feedback.productsQuantitative : feedback.deliveryQuantitative

  function getFace(number){
    switch (number) {
      case 1:
        return <FontAwesome name="frown" />
      case 2:
        return <FontAwesome name="meh" />
      case 3:
        return <FontAwesome name="smile" />
      default:
        return <FontAwesome name="minus" />
    }
  }

  return(
    <div className="feedback-field">
      {feedback && quantitative &&
        <Tippy content={qualitative || "No message"}><div>{getFace(quantitative)}</div></Tippy>
      }
    </div>
  )
}
