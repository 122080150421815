import React from 'react'

import './style.css'

function Items({className = "", children, ...rest}) {

  return(
    <div className={`items-block ${className}`} {...rest}>
      {children}
    </div>
  )
}

function Item({className = "", fluid, children, ...rest}) {

  return(
    <div className={`items-block-item ${className} ${fluid ? "fluid" : ""}`} {...rest}>
      {children}
    </div>
  )
}

const items = { Items, Item }

export default items
