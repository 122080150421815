import { gql } from '@apollo/client'

import { PURCHASES_FRAGMENT } from './'

export const PURCHASES_QUERY = gql`
  query purchases($date: [String!], $customer: ID, $vendor: ID, $rider: ID, $status: [PURCHASE_STATUS!], $limit: Int, $offset: Int, $type: PURCHASE_TYPE){
     purchases(date: $date, customer: $customer, vendor: $vendor, rider: $rider, status: $status, limit: $limit, offset: $offset, type: $type) {
       ...PURCHASES_FRAGMENT
     }
  }
  ${PURCHASES_FRAGMENT}
`
