import React, { useState, useRef } from 'react'
import { useMutation, useQuery } from '@apollo/client'

import { EDIT_PRODUCT_MUTATION, REORDER_PRODUCTS_MUTATION, DELETE_PRODUCT_MUTATION, PRODUCTS_QUERY } from '../../../graphql'
import { useConfirm, useIntl, useQueryBuilder } from '../../../hooks'
import { formatPrice, toast, returnGraphqlError } from '../../../helpers'
import { LabeledCheckbox, Loader, IntlText, IntlValue, Filter, Filters, Table, StatusDot, ContextMenu, Modal, Title, Sortable } from '../../../components'
import { ProductTags, CategorySelectFilter, ProductDetails } from '../../../views'

import './style.css'

export function ProductsList({vendor}) {

  const { confirm } = useConfirm()
  const modal = useRef(null)
  const [filter, setFilter] = useState()
  const [selectedProduct, selectProduct] = useState(undefined)
  const { getTranslation, getTranslatedValue } = useIntl()
  const { params, removeParam, isQuery, buildQuery, runQuery } = useQueryBuilder()
  const {loading, error, data, refetch} = useQuery(PRODUCTS_QUERY, {variables: buildQuery(), skip: !isQuery()})
  const [editProduct, { loading: editLoading }] = useMutation(EDIT_PRODUCT_MUTATION)
  const [remove] = useMutation(DELETE_PRODUCT_MUTATION)
  const [reorder, { loading: reorderLoading }] = useMutation(REORDER_PRODUCTS_MUTATION)

  if (error) return returnGraphqlError(error)

  function _selectProduct(_product){
    modal.current?.show()
    selectProduct(_product)
  }

  async function _delete(id){
    const alert = toast.loading(getTranslation({group: "confirm", id: "confirm-to-continue"}))
    try {
      await confirm({title: getTranslation({group: "confirm", id: "delete"}), desc: getTranslation({group: "confirm", id: "delete-info"})})
      alert.update(getTranslation({id: "loading"}))
      await remove({variables: {id}})
      alert.success(getTranslation({id: "delete-success"}))
      refetch()
    } catch (error) {
      alert.apolloError(error)
    }
  }     

  async function onSort(orderedArray){
    try {
      await reorder({variables: {data: orderedArray}})
      refetch()
      toast.success(getTranslation({id: "order-success", defaultValue: "Successfully reordered!"}))
    } catch (e) {
      e.graphQLErrors.map(x => toast.error(x.message))
    }
  }

  const renderHeader = () => (
    <>
      <Table.Th></Table.Th>
      <Table.Th></Table.Th>
      <Table.Th className="text-left"><IntlText group="products-list" id="table-header-name" /></Table.Th>
      <Table.Th><IntlText group="products-list" id="table-header-price" /></Table.Th>
      <Table.Th><IntlText group="products-list" id="table-header-tags" /></Table.Th>
      <Table.Th><IntlText group="products-list" id="table-header-disabled" /></Table.Th>
      <Table.Th><IntlText group="products-list" id="table-header-hidden" /></Table.Th>
      <Table.Th></Table.Th>
    </>
  )

  const renderItem = (product) => (
    <>
      <Table.Td><StatusDot active={!product.disabled} /></Table.Td>
      <Table.Td className="text-left"><IntlValue value={product.name} /></Table.Td>
      <Table.Td>{formatPrice(product.price)}</Table.Td>
      <Table.Td><ProductTags product={product}/></Table.Td>
      <Table.Td centered><LabeledCheckbox id={`disabled-${product.id}`} checked={product.disabled} onChange={(e) => editProduct({variables: {id: product.id, data: {disabled: e.target.checked}}})} rounded/></Table.Td>
      <Table.Td centered><LabeledCheckbox id={`hidden-${product.id}`} checked={product.hidden} onChange={(e) => editProduct({variables: {id: product.id, data: {hidden: e.target.checked}}})} rounded/></Table.Td>
      <Table.Td>
        <ContextMenu.Menu id={product?.id}>
          <ContextMenu.Link to={`${product.id}`} icon="external-link"><IntlText id="view" /></ContextMenu.Link>
          <ContextMenu.Button onClick={() => _selectProduct(product)} icon="edit"><IntlText id="edit" /></ContextMenu.Button>
          <ContextMenu.Button className="invalid" onClick={() => _delete(product.id)} icon="trash"><IntlText id="delete" /></ContextMenu.Button>
        </ContextMenu.Menu>
      </Table.Td>
    </>
  )

  return(
    <div id="vendor-products">
      <Filters.Bar>
        <Filters.Input id="query" name="query" value={filter} onChange={({target}) => setFilter(target.value)} reset={() => setFilter("")} placeholder="Type here for searching" />
        <CategorySelectFilter style={{marginLeft: "auto"}} vendor={vendor?.id} category={params.category} onSelect={(_category) => runQuery({instantParams: {category: {key: "category", label: getTranslatedValue(_category.name), value: _category.id}}})} reset={() => removeParam("category")} />
      </Filters.Bar>
      <div id="products-list" className="relative">
        {(loading || reorderLoading || editLoading) && <Loader theme="main" overlay />}
        <Filter search={[{key: "search", value: filter}]} data={data?.products || []} filters={[{filter: (data) => getTranslatedValue(data.name)}]}>
          {(data) =>
            <Sortable.Table data={data} onSort={onSort} renderItem={renderItem} renderHeader={renderHeader} />
          }
        </Filter>
      </div>
      <Modal id="product" ref={modal} header={<Title tag="h3"><IntlValue value={selectedProduct?.name} /></Title>}>
        {() =>
          <ProductDetails product={selectedProduct} onSuccess={() => modal.current?.hide()} />
        }
      </Modal>          
    </div>
  )
}

// <Table.Table>
//   <Table.Tr>
//     <Table.Td colSpan={8}>There aren't any items to show, try to search</Table.Td>
//   </Table.Tr>              
// </Table.Table>