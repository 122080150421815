import { useState } from 'react'
import { useMutation, useQuery } from '@apollo/client'

import { CHANGELOGS, DELETE_CHANGELOG, EDIT_CHANGELOG } from '../../graphql'
import { useIntl, useQueryBuilder, useConfirm } from '../../hooks'
import { toast } from '../../helpers'
import { Loader, Filter, Wrapper, Filters, Table, IntlValue, LabeledCheckbox, StatusDot, ContextMenu, IntlText } from '../../components'

export function Changelogs(){

  const [filter, setFilter] = useState()
  const { confirm } = useConfirm()
  const { getTranslatedValue, getTranslation } = useIntl()
  const { getParams, clearParams, removeParam, buildQuery } = useQueryBuilder()
  const { loading, data, error, refetch } = useQuery(CHANGELOGS, {variables: buildQuery()})
  const [edit] = useMutation(EDIT_CHANGELOG)
  const [deleteOne] = useMutation(DELETE_CHANGELOG)

  if (loading || error) return <Loader theme='main' />

  async function _delete(id){
    const alert = toast.loading(getTranslation({group: "confirm", id: "confirm-to-continue"}))
    try {
      await confirm()
      alert.update(getTranslation({id: "loading"}))
      await deleteOne({variables: {id}})
      alert.success(getTranslation({id: "delete-success"}))
      refetch()
    } catch (error) {
      alert.apolloError(error)
    }      
  }

  async function _edit(_data){
    const alert = toast.loading()
    try {
      await edit({variables: _data})
      alert.success(getTranslation({id: "delete-success"}))
      refetch()
    } catch (error) {
      alert.apolloError(error)
    }      
  }

  return(
    <Wrapper>
      <div id="changelogs" className="view">
        <div className="row padding">
          <Filters.Bar>
            <Filters.Input id="query" name="query" value={filter} onChange={({target}) => setFilter(target.value)} reset={() => setFilter("")} placeholder="Type here for searching" />
          </Filters.Bar>
          <Filters.Bar>
            <Filters.ActiveParams activeParams={getParams()} removeParam={removeParam} clearParams={clearParams}/>
          </Filters.Bar>
          <div id="pages-list" className="relative">
            {(loading) && <Loader theme="main" overlay/>}
            <Filter data={data?.changelogs || []} search={[{key: "search", value: filter}]} filters={[{filter: (data) => getTranslatedValue(data.title)}]} sort={(a, b) => new Date(a.createdAt) - new Date(b.createdAt)} >
              {(_data) =>
                <Table.Table>
                  <Table.Head>
                    <Table.Tr>
                      <Table.Th></Table.Th>
                      <Table.Th className="text-left">Name</Table.Th>
                      <Table.Th className="text-left">Url</Table.Th>
                      <Table.Th>Hidden</Table.Th>
                      <Table.Th></Table.Th>
                    </Table.Tr>
                  </Table.Head>
                  <Table.Body>
                    {_data && _data.length > 0 ? _data.map((page, key) =>
                      <Table.Tr key={key} className="page">
                        <Table.Td><StatusDot active={!page.hidden} /></Table.Td>
                        <Table.Td className="text-left"><IntlValue>{page.title}</IntlValue></Table.Td>
                        <Table.Td className="text-left">{page.slug}</Table.Td>
                        <Table.Td centered><LabeledCheckbox loading={loading} id={`active-${page.id}`} checked={page.hidden} onChange={({target}) => _edit({id: page.id, data: {hidden: target.checked}})} rounded/></Table.Td>
                        <Table.Td>
                          <ContextMenu.Menu id={page?.id}>
                            <ContextMenu.Link to={`/changelogs/${page.id}`} icon="external-link-alt"><IntlText id="edit" /></ContextMenu.Link>
                            <ContextMenu.Button className='invalid' onClick={() => _delete(page.id)} icon="trash"><IntlText id="delete" /></ContextMenu.Button>
                          </ContextMenu.Menu>
                        </Table.Td>
                      </Table.Tr>
                    ) :
                      <Table.Tr>
                        <Table.Td colSpan={5}>There aren't any pages to show.</Table.Td>
                      </Table.Tr>
                    }
                  </Table.Body>
                </Table.Table>
              }
            </Filter>
          </div>
        </div>
      </div>
    </Wrapper>
  )
}