import React from 'react'

import { Title } from '../'

import './style.css'

function Grids({className = "", children, ...rest}) {

  return(
    <div className={`grids ${className}`} {...rest}>
      {children}
    </div>
  )
}

function Grid({size = 1, className = "", noPadding, title, children, ...rest}) {

  return(
    <div className={`grid ${className} ${noPadding ? "no-padding" : null}`} {...rest} style={{flex: size}}>
      {title && <Title tag="h3">{title}</Title>}
      <div className='grid-inner'>
        {children}
      </div>
    </div>
  )
}

const grids = { Grids, Grid }

export default grids
