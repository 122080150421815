import React, { useState, useEffect } from 'react'
import { EditorState, ContentState } from 'draft-js'
import FontAwesome from 'react-fontawesome'
import { Editor } from 'react-draft-wysiwyg'

import { Loader, IntlText } from '../../components'

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'

export function TextEditor({id, name, placeholder, value, save, loading, onChange}) {

  const [editorState, changeState] = useState(EditorState.createWithContent(ContentState.createFromText(value || "")))

  useEffect(() => {
    changeState(EditorState.createWithContent(ContentState.createFromText(value || "")))
  }, [value])

  function onEditorStateChange(state){
    changeState(state)
    if (onChange) onChange({target: {name}, text: state.getCurrentContent().getPlainText()})
  }

  return(
    <Editor
      wrapperId={id}
      editorState={editorState}
      placeholder={placeholder}
      toolbarClassName="toolbarClassName"
      wrapperClassName="wrapperClassName"
      editorClassName="editorClassName"
      toolbarCustomButtons={save ? [
        <CustomOption save={save} loading={loading} />
      ] : null}
      onEditorStateChange={onEditorStateChange}
    />    
  )

}

function CustomOption({save, editorState, loading}){
  
  function onClick() {
    save(editorState.getCurrentContent().getPlainText())
  }

  return (
    <div className="rdw-remove-wrapper" aria-label="rdw-remove-control">
      <div className="rdw-option-wrapper" title={<IntlText id="save" />} style={{display: "flex", alignItems: "center", gap: 3, justifyContent: "center"}} onClick={onClick}>{loading ? <Loader size="small" theme='main' /> : <FontAwesome name="save" />}<IntlText id="save" /></div>
    </div>    
  )
}