import { gql } from '@apollo/client'

import { PURCHASE_QUOTE_FRAGMENT } from './'

export const PURCHASE_QUOTES = gql`
  query purchaseQuotes($status: PURCHASE_QUOTE_STATUS, $date: [String], $offset: Int, $limit: Int){
    purchaseQuotes(status: $status, date: $date, offset: $offset, limit: $limit) {
       nodes{
           ...PURCHASE_QUOTE_FRAGMENT
       }
       pageInfo{
        limit
        totalNodes
        firstNode
        lastNode
        totalPages
        actualPage
        hasNextPage
      }       
     }
  }
  ${PURCHASE_QUOTE_FRAGMENT}
`
