import { gql } from '@apollo/client'

import { CHANGELOG_FRAGMENT } from './'

export const CHANGELOG = gql`
  query changelog($id: String, $slug: String){
    changelog(id: $id, slug: $slug){
      ...CHANGELOG_FRAGMENT
    }
  }
  ${CHANGELOG_FRAGMENT}
`
