import { useState, useRef } from 'react'
import FontAwesome from 'react-fontawesome'

import { useFitInView, useModal } from '../../hooks'
import { pad } from '../../helpers'
import { Button, IntlText, Modalv3 } from '../'

import './style.css'

export function HourPicker({time = "0000", placeholder, onChange, minsStep = 15}) {

  function extractHours(time){
    let data = {
      hours: time.slice(0, time.length-2),
      mins: time.slice(-2)
    }

    return data
  }

  const modal = useModal()
  const menu = useRef(null)
  const defaultValues = extractHours(time)
  const [currentTimes, setValues] = useState(defaultValues)
  const { hours, mins } = currentTimes

  useFitInView({element: menu, offset: 40})

  function updateTime(field){
    setValues({
      ...currentTimes,
      ...field
    })
  }

  function clear(){
    setValues(defaultValues)
    modal.hide()
  }

  function reset(e){
    let { name, value } = e.target

    if (value === "" && name === "hours")
      updateTime({hours: time.slice(0, time.length-2)})

    if (value === "" && name === "mins")
      updateTime({mins: time.slice(-2)})
  }

  function save(){
    modal.hide()
    onChange({hours: pad(hours), mins: pad(mins)})
  }

  function change(e, min, max){
    if ((e.target.value >= min && e.target.value <= max) || e.target.value === "")
      updateTime({[e.target.name]: e.target.value})
  }

  function increaseHour(){
    let newValue = parseInt(hours) + 1

    if (newValue <= 23)
      return updateTime({hours: newValue})

    updateTime({hours: "00"})
  }

  function decreaseHour(){
    let newValue = parseInt(hours) - 1

    if (newValue >= 0)
      return updateTime({hours: newValue})

    updateTime({hours: "23"})
  }

  function increaseMin(){
    let newValue = parseInt(mins) + minsStep
    if (newValue <= 59)
      return updateTime({mins: newValue})

    updateTime({hours: parseInt(hours) + 1, mins: "00"})
  }

  function decreaseMin(){
    let newValue = parseInt(mins) - minsStep
    if (newValue >= 0)
      return updateTime({mins: newValue})

    updateTime({hours: parseInt(hours) - 1, mins: 60 + newValue})
  }

  let extractedTime = extractHours(time)

  return(
    <div className={`hour-picker ${!placeholder ? "no-placeholder" : ""}`}>
      {placeholder && <div className="placeholder">{placeholder}</div>}
      <div className="data" onClick={modal.show}>
        <div className="hours">{pad(extractedTime.hours)}</div>
        <div className="separator">:</div>
        <div className="mins">{pad(extractedTime.mins)}</div>
      </div>
      <Modalv3 id='hour-picker' modal={modal}>
        <div className="times">
          <div className="time-wrap">
            <div className="placeholder">Hour</div>
            <div className="time">
              <FontAwesome name="minus-square" onClick={decreaseHour}/>
              <input name="hours" autoComplete="off" value={hours} onChange={(e) => change(e, 0, 23)} onFocus={() => updateTime({hours: ""})} onBlur={(e) => reset(e)} size="2"/>
              <FontAwesome name="plus-square" onClick={increaseHour}/>
            </div>
          </div>
          <div className="time-wrap">
            <div className="placeholder">Minutes</div>
            <div className="time">
              <FontAwesome name="minus-square" onClick={decreaseMin}/>
              <input name="mins" autoComplete="off" value={mins} onChange={(e) => change(e, 0, 59)} onFocus={() => updateTime({mins: ""})} onBlur={(e) => reset(e)} size="2"/>
              <FontAwesome name="plus-square"  onClick={increaseMin}/>
            </div>
          </div>
        </div>
        <div className="actions">
          <Button onClick={clear}><IntlText id="cancel" /></Button>
          <Button theme="main" onClick={save}><IntlText id="change" /></Button>
        </div>        
      </Modalv3>
    </div>
  )
}
