import { gql } from '@apollo/client'

import { DAILY_STATS_FRAGMENT } from './'

export const DAILY_STATS = gql`
  query dailyStats($startDate: String!, $endDate: String){
    dailyStats(startDate: $startDate, endDate: $endDate){
      ...DAILY_STATS_FRAGMENT
    }
  }
  ${DAILY_STATS_FRAGMENT}
`
