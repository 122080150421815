import React from 'react'

import { LabeledInput, LabeledCheckbox, RangeSlider } from '../../../components'

export default function Setting({value, type, onChange}){
  switch (type.id) {
    case "BOOLEAN":
      return <LabeledCheckbox id="value" rounded checked={value} onChange={(e) => onChange({key: "value", value: e.target.checked})} />
    case "NUMBER":
      return <LabeledInput name="value" type="number" placeholder="Value" value={value} onChange={(e) => onChange({key: "value", value: e.target.value})} />
    case "PERCENTAGE":
      return <RangeSlider value={(value || 0)*100} onChange={(val) => onChange({key: "value", value: val/100})}/>
    // case "JSON":
    //   return
    // case "LIST":
    //   return
    default:
      return <LabeledInput name="value" placeholder="Value" value={value} onChange={(e) => onChange({key: "value", value: e.target.value})} />
  }
}
