import { gql } from '@apollo/client'

import { PROMOTION_FRAGMENT } from './'

export const CREATE_PROMOTION_MUTATION = gql`
  mutation createPromotion($vendor: ID, $data:PromotionInput!){
    createPromotion(vendor: $vendor, data:$data){
      ...PROMOTION_FRAGMENT
    }
  }
  ${PROMOTION_FRAGMENT}
`
