import { useState } from 'react'
import FontAwesome from 'react-fontawesome'

import config from '../../../config'
import { useIntl, useValidatedForm } from '../../../hooks'
import { toast } from '../../../helpers'
import { Map, IntlText, Button, Form, EditboxV2 } from '../../../components'

export function VendorLocation({pickupInformation, location, onChange, loading}) {

  const { getTranslation } = useIntl()
  const [edit, toggleEdit] = useState(false)
  const { form: {coordinates}, updateField } = useValidatedForm({
    coordinates: location?.coordinates || [config.defaultCenter.lng, config.defaultCenter.lat]
  })

  function changeLocation(e){
    if (!edit) return false

    updateField({key: "coordinates", value: [e.lng, e.lat]})
  }

  function save(){
    if (!coordinates) return toast.error(<IntlText group="vendor-location" id="no-location-selected" />)

    onChange({location: {type: "Point", coordinates: coordinates}})
    toggleEdit(false)
  }

  return(
    <div id="vendor-location">
      <Form.Form>
        <Form.Field>
          <div className="map" style={{height: 400}}>
            {edit ?
              <Map.Actions>
                <Button onClick={() => toggleEdit(false)}><IntlText id="cancel" /></Button>
                <Button theme="main" loading={loading} onClick={save}><IntlText id="save" /></Button>
              </Map.Actions>
            : !edit ?
              <Map.Actions>
                <Button onClick={() => toggleEdit(true)}><IntlText id="edit" /></Button>
              </Map.Actions>
            : null}
            <Map.Map center={{lng: coordinates[0], lat: coordinates[1]}} onClick={(e) => changeLocation(e)}>
              <Map.Pin lng={coordinates[0]} lat={coordinates[1]}><FontAwesome name="map-marker" /></Map.Pin>
            </Map.Map>
          </div>
        </Form.Field>
        <Form.Field>
          <EditboxV2.Text icon="motorcycle" label={getTranslation({group: "vendor-location", id: "delivery-pickup-information"})} onChange={(value) => onChange({pickupInformation: value})}>{pickupInformation}</EditboxV2.Text>
        </Form.Field>
      </Form.Form>
    </div>
  )
}
