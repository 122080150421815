import React from 'react'

import './style.css'

function View({className = "", children, ...rest}) {

  return(
    <div className={`sidebar-view ${className}`} {...rest}>
      {children}
    </div>
  )
}

function Menu({size = 1, style, className = "", children, ...rest}) {

  return(
    <div className={`sidebar-view-menu ${className}`} {...rest} style={{flex: size, ...style}}>
      {children}
    </div>
  )
}

function Content({size = 1, style, className = "", children, ...rest}) {

  return(
    <div className={`sidebar-view-content ${className}`} {...rest} style={{flex: size, ...style}}>
      {children}
    </div>
  )
}

const sidebar = { View, Menu, Content }

export default sidebar
