import React from 'react'
import { Query } from '@apollo/client/react/components'

import { ME_QUERY, IS_LOGGED_IN } from '../../graphql'
import { Loader, Dropdown, LanguageSwitcher, MediaQuery, ColorSchemeSelector } from '../../components'
import { Breadcrumbs, ConnectionStatus } from '../../views'
import { AuthKit } from '../../helpers'

import './style.css'

export function Header() {

  function logout(){
    AuthKit.logout()
  }

  return(
    <div id="header">
      <div className="block left">
        <MediaQuery min={800}>
          <Breadcrumbs />
        </MediaQuery>
      </div>
      <div className="user block right">
        <ConnectionStatus />
        <ColorSchemeSelector />
        <LanguageSwitcher />
        <Query query={IS_LOGGED_IN}>
          {({ data: { isLoggedIn } }) =>
            <Query query={ME_QUERY} skip={!isLoggedIn}>
              {({ loading, error, data }) => {
                if (loading) return <Loader theme="main"/>
                if (error) return `Error! ${error.message}`
                if (!data) return null

                const { me } = data

                return(
                  
                  <Dropdown.Dropdown button={<span>{me.name}</span>}>
                    <Dropdown.Item icon={<i className="fas fa-sign-out-alt" />} onClick={logout}>Logout</Dropdown.Item>
                  </Dropdown.Dropdown>
                )
              }}
            </Query>
          }
        </Query>
      </div>
    </div>
  )
}
