import { gql } from '@apollo/client'

export const SERVICE_FEE_PROFORMA_INVOICE_FRAGMENT = gql`
  fragment SERVICE_FEE_PROFORMA_INVOICE_FRAGMENT on ProformaInvoiceServiceFee {
    id
    reference
    periodEnd
    periodicity
    vendor{
      originalId
      name
    }
    grossInvoiceAmount
    netInvoiceAmount
    tax
    status
    isGlobal
    paymentTermDays
    dueAt
    approvedAt
    invoiceCreatedAt
    lastEmailSent
    overdue
    proformaLink
    invoiceFailed
    invoiceLink
    facturapiId
    createdAt
    updatedAt
  }
`
