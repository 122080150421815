import React, { useContext, useState } from 'react'
import { useQuery } from '@apollo/client'
import { toast } from 'react-toastify'

import { useModal } from '../../../hooks'
import { formatPrice, scrollTo } from '../../../helpers'
import { PRODUCT_QUERY } from '../../../graphql'
import { IntlContext } from '../../../contexts'
import { Button, Loader, QuantitySelector, LabeledTextarea, IntlText, Modalv3, Title } from '../../../components'
import { PurchaseProductAddon, PurchaseProductCustomPrice } from '../../../views'

import './style.css'

export function PurchaseProduct({product, ...rest}){  
  const _id = product.id || product.originalId
  const { data, loading, error } = useQuery(PRODUCT_QUERY, {variables: { id: _id}, skip: !_id})
  
  if (loading || error) return <Loader theme="main" />
  if (!data?.product) return null
  
  return(
    <Inner product={data?.product} forcedPrice={product.price} {...rest} />
  )
}

function Inner({product, forcedPrice, addItem, vendor, index, ...rest}){
  const modal = useModal()
  const { getTranslation } = useContext(IntlContext)  
  const initialOptions = {quantity: 1, addons: [], ...rest}
  const [options, setOptions] = useState(initialOptions)
  const { addons, instructions, quantity } = options    
  const [_forcedPrice, setPrice] = useState((forcedPrice !== product.price ? forcedPrice : product.price)+ calculateAddonsPrice())

  function updateOption(option){
    setOptions({
      ...options,
      ...option
    })
  }

  function addOption(addon, option, qty = 1){
    if (forcedPrice === product.price){
      setPrice(0)
    }    
    let selectedAddon = {...(addons.find(a => a.addon.id === addon.id || a.addon.originalId === addon.id) || {addon, selectedOptions: []})}
    const length = selectedAddon.selectedOptions.reduce((prev, current) => {
      return prev + (current.quantity || 1)
    }, 0)

    const { maxSelect } = addon

    if (maxSelect && length >= maxSelect){
      if (maxSelect === 1){
        selectedAddon["selectedOptions"] = [{...option, quantity: qty}]
      } else{
        return toast.error(getTranslation({group: "purchase-product", id: "addon-max-reached"}))
      }
    } else {
      selectedAddon["selectedOptions"] = selectedAddon["selectedOptions"].filter(_option => _option.id !== option.id && _option.originalId !== option.id).concat({...option, quantity: qty})
    }

    const _addons = addons.filter(obj => obj.addon.id !== addon.id && obj.addon.originalId !== addon.id).concat(selectedAddon)
    const target = document.getElementById(addon.id)
    target.classList.remove("active")

    updateOption({addons: _addons})   
  }

  function removeOption(addon, option, qty = 0){
    if (forcedPrice === product.price){
      setPrice(0)
    }

    let selectedAddon = {...(addons.find(a => a.addon.id === addon.id || a.addon.originalId === addon.id) || {addon, selectedOptions: []})}
    
    if (qty >= 0){
      if (qty === 0) { 
        selectedAddon["selectedOptions"] = selectedAddon["selectedOptions"].filter(_option => _option.id !== option.id && _option.originalId !== option.id)
      } else {  
        selectedAddon["selectedOptions"] = selectedAddon["selectedOptions"].filter(_option => _option.id !== option.id && _option.originalId !== option.id).concat({...option, quantity: qty})
      }
    }
    
    const _addons = addons.filter(obj => obj.addon.id !== addon.id && obj.addon.originalId !== addon.id).concat(selectedAddon)

    updateOption({addons: _addons})
  }  

  function increaseQty(product){
    let _quantity = product.minPurchaseQuantity && product.minPurchaseQuantity > quantity ? product.minPurchaseQuantity : quantity
    let max = product.maxPurchaseQuantity || undefined
    _quantity++

    if (max && max < _quantity) return toast.error(getTranslation({group: "purchase-product", id: "max-qty"}))

    updateOption({quantity: _quantity})
  }

  function decreaseQty(product){
    let _quantity = product.minPurchaseQuantity && product.minPurchaseQuantity > quantity ? product.minPurchaseQuantity : quantity
    let min = product.minPurchaseQuantity || 1
    _quantity--

    if (_quantity < min) return toast.error(getTranslation({group: "purchase-product", id: "min-qty"}))

    updateOption({quantity: _quantity})
  }  

  function calculateItemPrice() {
    let pricePerItem = product.price
    
    if (_forcedPrice && _forcedPrice !== product.price) return _forcedPrice
    if (addons) pricePerItem += calculateAddonsPrice()
  
    return pricePerItem
  }  

  function calculateItemTotalPrice(){
    const pricePerItem = calculateItemPrice()

    return (pricePerItem * quantity)
  }

  function calculateAddonsPrice() {
    let addonsPricePerItem = 0
    addons.forEach(({selectedOptions}) => {
      selectedOptions.forEach((option) => {
        return addonsPricePerItem += (option.quantity || 1) * option.price
      })
    })
  
    return addonsPricePerItem
  }  

  function _addItem(){
    try {
      if (validateAddons(product)){
        const totalPricePerProduct = calculateItemPrice()
        const _totalPricePerProduct = totalPricePerProduct - calculateAddonsPrice()
        const totalPrice = _totalPricePerProduct * quantity
        addItem({...rest, index, product, addons, instructions, quantity, totalPricePerProduct, totalPrice, forcedPrice: _totalPricePerProduct})
      }
    } catch (error) {
      console.log("Add to cart error: ", error);
    }
  }

  function validateAddons(product){
    let valid = true

    product.addons.some((addonObj) => {
      let { addon } = addonObj

      let { maxSelect, minSelect, required } = addon

      let selectedAddon = addons.find(_addon => _addon.addon.id === addon.id || _addon.addon.originalId === addon.id) || {options: {}, selectedOptions: []}
      const length = selectedAddon.selectedOptions.reduce((prev, current) => {
        return prev + (current.qty || 1)
      }, 0)

      if (required && !length > 0){
        valid = false
        return addonError(addon, getTranslation({group: "purchase-product", id: "addon-required"}))
      }

      if (minSelect && length < minSelect){
        valid = false
        return addonError(addon, getTranslation({group: "purchase-product", id: "addon-choose-more"}))
      }

      if (maxSelect && length > maxSelect){
        valid = false
        return addonError(addon, getTranslation({group: "purchase-product", id: "addon-choose-less"}))
      }

      return false
    })

    return valid

  }  

  function addonError(addon, message){
    var wrapper = document.getElementById('purchase-product')
    var target = document.getElementById(addon.id)

    target.classList.add("active")
    toast.error(message)

    return scrollTo(wrapper, target, 10, 600)
  }

  function _setPrice(value){
    setPrice(value)
  }

  function resetPrice(){
    setPrice(forcedPrice || forcedPrice)
    modal?.hide()
  }

  function save(){
    modal?.hide()
  }

  const _quantity = product.minPurchaseQuantity && product.minPurchaseQuantity > quantity ? product.minPurchaseQuantity : quantity
  const _addons = product.addons?.length > 0 ? product.addons.map((addon, key) => 
    <PurchaseProductAddon key={key} addon={addon} selected={addons.find(_addon => _addon.addon.id === addon.addon.id || _addon.addon.originalId === addon.addon.id) || []} addOption={addOption} removeOption={removeOption}/>
  ) : []

  return(
    <div id='purchase-product'>
      <div style={{maxHeight: "60vh", overflowY: "auto"}}>
        {_addons}
        <div style={{marginTop: 15}}>
          <LabeledTextarea name="instructions" placeholder={getTranslation({group: "purchase-product", id: "instructions"})} value={instructions} onChange={(e) => updateOption({[e.target.name]: e.target.value})} />
        </div>
      </div>
      <div className='product-actions'>
        <QuantitySelector qty={_quantity} increase={() => increaseQty(product)} decrease={() => decreaseQty(product)}/>      
        <div className='buttons'>
          <Button icon="edit" onClick={() => modal?.show()}><IntlText group="purchase-product" id="add-custom-price" /></Button>
          <Button id="add-to-cart-button" theme="main" onClick={_addItem}>{index >= 0 ? <IntlText group="purchase-product" id="edit-product" /> : <IntlText group="purchase-product" id="add-to-cart" />} - {formatPrice(calculateItemTotalPrice())}</Button>
        </div>
      </div>    
      <Modalv3 id="custom-price" modal={modal} header={<Title tag="h3"><IntlText group="purchase-product" id="add-custom-price" /></Title>} onHide={resetPrice} >
        <PurchaseProductCustomPrice vendor={vendor} product={product} price={_forcedPrice} setPrice={_setPrice} save={save} reset={resetPrice} />
      </Modalv3>    
    </div>
  )
}