import React, { useContext } from 'react'
import { toast } from 'react-toastify'
import { useMutation } from '@apollo/client'
import { useNavigate } from 'react-router-dom'

import { useValidatedForm } from '../../../hooks'
import { IntlContext } from '../../../contexts'
import { LabeledInput, Button, Form, IntlText, Validation, SelectV2 } from '../../../components'
import { CREATE_RIDER, RIDERS_QUERY } from '../../../graphql'

export function NewRiderForm(){

  const navigate = useNavigate()
  const { getTranslation } = useContext(IntlContext)
  const [createRider, { loading }] = useMutation(CREATE_RIDER, {refetchQueries: [{query: RIDERS_QUERY}]})
  const validations = {
    name: [{id: "required", validation: (val) => !!val}],
    password: [{id: "required", validation: (val) => !!val}],
    rank: [{id: "required", validation: (val) => !!val}],
  }    
  const riderTypes = ["CONTRACTOR", "FULL_TIME_WORKER"]
  const ranks = ["TEST", "BEGINNER", "ADVANCED", "PROFESSIONAL", "ELITE"]
  const { form, updateField, validate, errors } = useValidatedForm({}, validations)
  
  async function _createRider(){
    try {
      let { valid } = validate()

      if (!valid) return toast.error(getTranslation({id: "form-error"}))

      await createRider({variables: {data: {...form, username: removeNonAlphaNumeric(form.name), rank: form?.rank?.id}}})
      toast.success(getTranslation({id: "create-success"}))
      navigate("/riders")
    } catch (e) {
      e.graphQLErrors.map(x => toast.error(x.message))
    }
  }

  function removeNonAlphaNumeric(str){
    if (!str) return ""

    return str.replace(/[\W_]/g,"").toLowerCase()
  }

  return(
    <div id="new-rider" >
      <Form.Form>
        <Form.Field>
          <Validation errors={errors.name}>
            <LabeledInput name="name" placeholder={getTranslation({group: "rider-form", id:"name"})} value={form?.name} onChange={({target}) => updateField({key: target.name, value: target.value})} />
          </Validation>
        </Form.Field>
        <Form.Field>
          <LabeledInput name="username" placeholder={getTranslation({group: "rider-form", id:"username"})} value={removeNonAlphaNumeric(form?.name)} disabled />
        </Form.Field>
        <Form.Field>
          <Validation errors={errors.password}>
            <LabeledInput name="password" placeholder={getTranslation({group: "rider-form", id:"password"})} value={form?.password}  onChange={({target}) => updateField({key: target.name, value: target.value})} />
          </Validation>
        </Form.Field>
        <Form.Field>
          <Validation errors={errors.email}>
            <LabeledInput name="email" placeholder={getTranslation({group: "rider-form", id:"email"})} value={form?.email}  onChange={({target}) => updateField({key: target.name, value: target.value})} />
          </Validation>
        </Form.Field>
        <Form.Field>
          <Validation errors={errors.type}>
            <SelectV2 value={(_value) => <IntlText group="rider-form" id={_value} />} placeholder="Rider type" selected={riderTypes?.find(_t => _t === form?.type)} defaultValue={getTranslation({id: "please-select"})} options={riderTypes} onChange={(_type) => updateField({key: "type", value:  _type})} />
          </Validation>
        </Form.Field>
        <Form.Field>
          <Validation errors={errors.rank}>
            <SelectV2 value={(_value) => <IntlText group="rider-form" id={_value} />} placeholder="Rider rank" selected={ranks?.find(_t => _t === form?.rank)} defaultValue={getTranslation({id: "please-select"})} options={ranks} onChange={(_type) => updateField({key: "rank", value:  _type})} />
          </Validation>
        </Form.Field>
        <Form.Field fluid>
          <Button fluid loading={loading} disabled={loading} theme="main" onClick={_createRider}><IntlText id="create" /></Button>
        </Form.Field>
      </Form.Form>
    </div>
  )
}
