import { gql } from '@apollo/client'

import { RIDER_FRAGMENT } from './'

export const EDIT_RIDER_MUTATION = gql`
  mutation editRider($id: ID!, $data:EditedRiderInput!){
    editRider(id: $id, data:$data){
      ...RIDER_FRAGMENT
    }
  }
  ${RIDER_FRAGMENT}
`
