import React from 'react'

import { CustomerReferralStats, ReferredCustomers, ReferralTransactions, ReferralCode } from '../../../views'

import './style.css'

export function CustomerReferral({customer}) {

  return(
    <div id="customer-referrals">
      <ReferralCode customer={customer} />
      {customer?.referrerCode && customer?.referralTermsAccepted &&
        <>
          <CustomerReferralStats customer={customer} />
          <ReferredCustomers customer={customer} />
          <ReferralTransactions customer={customer} />
        </>
      }
    </div>
  )

}
