import { gql } from '@apollo/client'

import { VENDOR_FRAGMENT } from './$vendor'

export const CREATE_VENDOR = gql`
  mutation createVendor($data: VendorInput!){
    createVendor(data: $data){
      ...VENDOR_FRAGMENT
    }
  }
  ${VENDOR_FRAGMENT}
`
