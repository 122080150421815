import React from 'react'
import { useMutation } from '@apollo/client'

import { DELETE_CUSTOMER_PERMANENTLY, RESTORE_CUSTOMER } from '../../../graphql'
import { useConfirm, useIntl } from '../../../hooks'
import { toast } from '../../../helpers'
import { StatusDot, Loader, Table, ContextMenu, IntlText } from '../../../components'

export function DeletedCustomersList({loading, data, refetch}) {

  const { confirm } = useConfirm()
  const { getTranslation } = useIntl()
  const [restore] = useMutation(RESTORE_CUSTOMER, {onCompleted: () => {
    refetch()
  }})
  const [remove] = useMutation(DELETE_CUSTOMER_PERMANENTLY, {onCompleted: () => {
    refetch()
  }})

  async function _restore(id){
    const alert = toast.loading(getTranslation({group: "confirm", id: "confirm-to-continue"}))
    try {
      await confirm({title: getTranslation({group: "deleted-customers", id: "restore"}), desc: getTranslation({group: "deleted-customers", id: "restore-confirm"})})
      alert.update(getTranslation({id: "loading"}))
      await restore({variables: {id}})
      alert.success(getTranslation({id: "restore-success"}))
      refetch()
    } catch (e) {
      alert.apolloError(e)
    }
  }

  async function _delete(id){
    const alert = toast.loading(getTranslation({group: "confirm", id: "confirm-to-continue"}))
    try {
      await confirm({title: getTranslation({group: "deleted-customers", id: "delete-permanently"}), desc: getTranslation({group: "deleted-customers", id: "delete-permanently-confirm"})})
      alert.update(getTranslation({id: "loading"}))
      await remove({variables: {id}})
      alert.success(getTranslation({id: "delete-success"}))
      refetch()
    } catch (error) {
      alert.apolloError(error)
    }
  }    

  return(
    <div id="customers-list" className="relative">
      {(loading) && <div className="full-size-loader"><Loader theme="main"/></div>}
      {data?.length > 0 &&
        <Table.Table>
          <Table.Head>
            <Table.Tr>
              <Table.Th></Table.Th>
              <Table.Th className="text-left"><IntlText group="form" id="name" /></Table.Th>
              <Table.Th><IntlText group="form" id="phone" /></Table.Th>
              <Table.Th><IntlText group="form" id="email" /></Table.Th>
              <Table.Th></Table.Th>
            </Table.Tr>
          </Table.Head>
          <tbody>
            {data && data.map((customer, key) =>
              <Table.Tr key={key} className={["customer", customer.abusive ? "disabled" : null].join(" ")}>
                <Table.Td className="status"><StatusDot active={!(customer.abusive || customer.banned)} /></Table.Td>
                <Table.Td className="text-left">{customer.name}</Table.Td>
                <Table.Td className="nowrap">{customer?.phone?.phoneCode} {customer?.phone?.phoneNumber}</Table.Td>
                <Table.Td className="nowrap">{customer?.email}</Table.Td>
                <Table.Td>
                  <ContextMenu.Menu id={customer?.id}>
                    <ContextMenu.Button className="valid" onClick={() => _restore(customer.id)} icon="undo"><IntlText group="deleted-customers" id="restore" /></ContextMenu.Button>
                    <ContextMenu.Button className="invalid" onClick={() => _delete(customer.id)} icon="trash"><IntlText group="deleted-customers" id="delete-permanently" /></ContextMenu.Button>
                  </ContextMenu.Menu>
                </Table.Td>
              </Table.Tr>
            )}
          </tbody>
        </Table.Table>
      }
    </div>
  )
}
