import React from 'react'
import { useMutation } from '@apollo/client'

import { EDIT_RIDER_MUTATION } from '../../../graphql'
import { useIntl } from '../../../hooks'
import { toast } from '../../../helpers'
import { Form, SwitchBox } from '../../../components'

export function RiderSettings({rider}){

  const { getTranslation } = useIntl()
  const [editRider] = useMutation(EDIT_RIDER_MUTATION)
  
  async function changeSwitch(data){
    const alert = toast.loading()

    try {
      await editRider({variables: {id: rider?.id, data: data}})
      alert.success(getTranslation({id: "change-success"}))
    } catch (e) {
      alert.error(e.message)
    }
  }

  return(
    <div id="rider-settings">
      <Form.Form>
        <Form.Field>
          <SwitchBox id="active" checked={rider.active} onChange={({target}) => changeSwitch({[target.id]: target.checked})}>
            Active
          </SwitchBox>                  
        </Form.Field>
        <Form.Field>
          <SwitchBox id="available" checked={rider.available} onChange={({target}) => changeSwitch({[target.id]: target.checked})}>
            Available
          </SwitchBox>                  
        </Form.Field>
        <Form.Field>
          <SwitchBox id="banned" checked={rider.banned} onChange={({target}) => changeSwitch({[target.id]: target.checked})}>
            Banned
          </SwitchBox>                  
        </Form.Field>
        <Form.Field>
          <SwitchBox id="canDoCard" checked={rider.canDoCard} onChange={({target}) => changeSwitch({[target.id]: target.checked})}>
            Can do card
          </SwitchBox>                  
        </Form.Field>
        <Form.Field>
          <SwitchBox id="canDoCash" checked={rider.canDoCash} onChange={({target}) => changeSwitch({[target.id]: target.checked})}>
            Can do cash
          </SwitchBox>                  
        </Form.Field>
      </Form.Form>
    </div>
  )

}
