import React from 'react'
import { toast } from 'react-toastify'
import { useMutation } from '@apollo/client'
import { useNavigate } from 'react-router-dom'

import { useForm } from '../../../hooks'
import { LabeledInput, Button, LabeledCheckbox, Form } from '../../../components'
import { CREATE_ADMIN, ADMINS } from '../../../graphql'

export function NewAdminForm(){

  const navigate = useNavigate()
  const { form, updateField } = useForm({isMachine: false})
  const [createAdmin, { loading }] = useMutation(CREATE_ADMIN, {refetchQueries: [{query: ADMINS}]})

  async function _createAdmin(){
    try {
      createAdmin({variables: {data: form}})
      toast.success("Successfully created")
      navigate("/admins")
    } catch (e) {
      e.graphQLErrors.map(x => toast.error(x.message))
    }
  }

  return(
    <div id="new-admin" >
      <Form.Form>
        <Form.Field>
          <LabeledInput name="name" placeholder="Name" value={form.name} onChange={({target}) => updateField({[target.name]: target.value})} />
        </Form.Field>
        <Form.Field>
          <LabeledInput name="username" placeholder="Username" value={form.username} onChange={({target}) => updateField({[target.name]: target.value})} />
        </Form.Field>
        <Form.Field>
          <LabeledInput name="password" placeholder="Password" value={form.password}  onChange={({target}) => updateField({[target.name]: target.value})} />
        </Form.Field>
        <Form.Field>
          <LabeledCheckbox name="isMachine" checked={form.isMachine}  onChange={({target}) => updateField({[target.name]: target.checked})}>Is machine?</LabeledCheckbox>
        </Form.Field>
        <Form.Field fluid>
          <Button fluid loading={loading} disabled={loading} theme="main" onClick={() => _createAdmin()}>Create</Button>
        </Form.Field>
      </Form.Form>
    </div>
  )
}
