import { useEffect, useRef } from 'react'

export const useAwakeDetection = ({callback, minutes = 5}) => {
  const lastRefreshTimeRef = useRef(Date.now())

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (!document.hidden) {
        const currentTime = Date.now()
        const timeSinceLastRefresh = currentTime - lastRefreshTimeRef.current

        if (timeSinceLastRefresh >= minutes * 60 * 1000) {
          callback();
          lastRefreshTimeRef.current = currentTime
        }
      }
    };

    const handleWindowFocus = () => {
      const currentTime = Date.now()
      const timeSinceLastRefresh = currentTime - lastRefreshTimeRef.current;

      if (timeSinceLastRefresh >= 5 * 60 * 1000) {
        callback();
        lastRefreshTimeRef.current = currentTime
      }
    }

    document.addEventListener('visibilitychange', handleVisibilityChange);
    window.addEventListener('focus', handleWindowFocus);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
      window.removeEventListener('focus', handleWindowFocus);
    };
  }, [callback, minutes])
}