import React from 'react'

import './style.css'

export function Actions({children, full, className, ...rest}){

  return(
    <div className={`actions ${full ? "full" : null} ${className}`} {...rest}>
      {children}
    </div>
  )

}
