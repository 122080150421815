import { gql } from '@apollo/client'

import { AUDITLOG_FRAGMENT } from './'

export const AUDITLOGS = gql`
  query auditlogs($type: [AUDIT_LOG_TYPE]!, $maxAge: Int){
     auditlogs(type: $type, maxAge: $maxAge) {
       ...AUDITLOG_FRAGMENT
     }
  }
  ${AUDITLOG_FRAGMENT}
`
