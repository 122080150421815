import { gql } from '@apollo/client'

export const REFERRAL_TRANSACTIONS = gql`
  query referralTransactions($customer: ID, $offset: Int!, $limit: Int!){
     referralTransactions(customer: $customer, offset: $offset, limit: $limit) {
      nodes{
         id
         book
         customer
         relatedPurchase
         description{
            en
            es
         }
         value
         type
         event
         auditMessage
         note
         voided
         createdAt
         updatedAt
      }
      pageInfo{
         limit
         totalNodes
         firstNode
         lastNode
         totalPages
         actualPage
         hasNextPage         
      }
     }
  }
`
