import React, { useState, useEffect, useRef } from 'react'
import FontAwesome from 'react-fontawesome'

import { LabeledInput } from '../'

import './style.css'

import _countries from './countries.json'

export function PhoneNumberInput({onChange, value, defaultCountry, featuredCountries, name, placeholder}) {

  const [showList, toggleList] = useState(false)
  const [search, setSearch] = useState("")
  const [countries, setCountries] = useState(_countries)
  const element = useRef(null)

  useEffect(() => {
    const handleClick = (e) => {
      if (element.current && element.current.contains(e.target)) return false
      toggleList(false)
    }

    document.addEventListener("click", handleClick)

    return () => document.removeEventListener("click", handleClick)
  }, [])

  function onSearch(string){
    let filtered = _countries.filter((country) => country.name.toLowerCase().search(string.toLowerCase()) !== -1 || country.phoneCode.toLowerCase().search(string.toLowerCase()) !== -1)

    setSearch(string)
    setCountries(filtered)
  }

  const _onChange = (data) => {
    onChange(data)
    toggleList(false)
  }

  let selectedCountry =  value || _countries.filter((data) => data.countryCode.toLowerCase() === defaultCountry)[0] || countries[0]

  let countriesList = countries.reduce((current, next) => {
    let listItem =
      <li key={`${next.countryCode}`} onClick={() => _onChange({...next, phoneNumber: value?.phoneNumber || ""})}>
        <span className="list-flag"><span className={"flag-icon flag-icon-" + next.countryCode.toLowerCase()}></span></span>
        <span className="list-name">{next.name}</span>
        <span className="list-code">({next.phoneCode})</span>
      </li>

    if (featuredCountries && (featuredCountries.includes(next.countryCode.toLowerCase()) || featuredCountries.includes(next.countryCode))){
      current.featured.push(listItem)
    } else {
      current.normal.push(listItem)
    }

    return current

  }, {featured: [], normal: []})

  return(
    <div className="phone-picker" ref={element}>
      <div className="country" onClick={() => toggleList(!showList)}>
        <span className={"flag-icon flag-icon-" + selectedCountry.countryCode.toLowerCase()}></span>
        <span className="country-code">{selectedCountry.phoneCode}</span>
        <FontAwesome name="caret-down" />
      </div>
      <LabeledInput type="phone" name={name} placeholder={placeholder} value={value?.phoneNumber || ""} onChange={(e) => onChange({...selectedCountry, phoneNumber: e.target.value})} />
      <div className={`country-dropdown ${showList ? "opened" : ""}`}>
        <ul className="country-list">
          <input name="search" autoComplete="off" type="text" value={search} placeholder="Search" onChange={(e) => onSearch(e.target.value)}/>
          {countriesList.featured}
          {countriesList.featured.length ? <li key="divider" className="divider"></li> : ""}
          {countriesList.normal}
        </ul>
      </div>
    </div>
  )
}
