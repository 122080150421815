import { gql } from '@apollo/client'

import { SERVICE_FEE_PROFORMA_INVOICE_FRAGMENT } from './'

export const SERVICE_FEE_PROFORMA_INVOICE = gql`
  query serviceFeeProformaInvoice($id: ID!){
     serviceFeeProformaInvoice(id: $id) {
      ...SERVICE_FEE_PROFORMA_INVOICE_FRAGMENT         
     }
  }
  ${SERVICE_FEE_PROFORMA_INVOICE_FRAGMENT}
`
