import { Filters } from '../../../components'

import { ActiveOrders } from './ActiveOrders'
import { ActiveRiders } from './ActiveRiders'
import { Breaks } from './Breaks'
import { OperationInfo } from './OperationInfo'
import { DispatcherActions } from './DispatcherActions'
import './style.css'

export function DispatcherBar({runQuery, params, addParam, removeParam}) {

  function onSearch(e){
    if (e.key !== 'Enter') return false
    runQuery({instantParams: {query: {value: params?.query?.value, id: "search", label: `Search for ${params?.query?.value}`}}})    
  }

  return(
    <div id="dispatcher-bar">
      <Filters.Input id="query" name="query" value={params?.query?.value} onChange={({target}) => addParam({query: {value: target?.value, id: "search", label: `Search for ${target.value}`, skip: !target?.value}})} reset={() => removeParam("query")} placeholder="Type here for searching" onKeyDown={onSearch}/>
      <div className='bar-actions'>
        <ActiveOrders runQuery={runQuery} />
        <ActiveRiders runQuery={runQuery} />
        <Breaks runQuery={runQuery} />
        <DispatcherActions />
        <OperationInfo />
      </div>
    </div>
  )
}