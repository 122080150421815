import FontAwesome from 'react-fontawesome'

import { Loader } from '../'

import './style.css'

export function Input({value, icon, reset, loading, ...rest}){

    function showIcon(){
      switch (typeof icon) {
        case "string":
          return <div className="icon"><FontAwesome name={icon} /></div>
        default:
          return <div className="icon">{icon}</div>
      }
    }

    return(
      <div className={`input ${!!value ? "selected" : ""}`}>
        {icon && showIcon()}
        <input autoComplete="off" value={value || ""} {...rest}/>
        {loading && <Loader loading={loading} />}
        {!!value && !loading && reset &&  <FontAwesome onClick={reset} name="trash" />}
      </div>
    )
  
  }
  