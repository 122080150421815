import React from 'react'

import { IntlText, Badge } from '../../../components'

export function DeliveryFee({vendor}) {

  if (vendor.contract === "FREEDELIVERY" || (vendor.tags && vendor.tags.includes("freeDelivery"))) return(
    <Badge theme='main'>
      <IntlText group="delivery-fee" id="free-delivery" />
    </Badge>
  )

  if (vendor.tags && vendor.tags.includes("freeDeliveryOptions")) return(
    <Badge theme='main'>
      <IntlText group="delivery-fee" id="free-delivery-options" />
    </Badge>    
  )

  return null

}
