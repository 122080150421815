import { gql } from '@apollo/client'

import { CUSTOMER_FRAGMENT } from './'

export const PAGINATED_CUSTOMERS_QUERY = gql`
  query paginatedCustomers($query: String, $isReferrer: Boolean, $offset: Int!, $limit: Int!){
    paginatedCustomers(query: $query, isReferrer: $isReferrer, offset: $offset, limit: $limit){
      nodes{
        ...CUSTOMER_FRAGMENT
      }
      pageInfo{
        limit
        totalNodes
        firstNode
        lastNode
        totalPages
        actualPage
        hasNextPage                 
      }
     }
  }
  ${CUSTOMER_FRAGMENT}
`
