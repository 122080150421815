import React, { useEffect } from 'react'
import { useQuery, useMutation } from '@apollo/client'
import moment from 'moment'
import FontAwesome from 'react-fontawesome'

import { useModal } from '../../../hooks'
import { toast } from '../../../helpers'
import { RIDER_BREAKS, RIDER_BREAK_UPDATED, NEW_RIDER_BREAK, CHANGE_BREAK_STATUS } from '../../../graphql'
import { Loader, Modalv3, Title, IntlText, Button } from '../../../components'

export function Breaks(){
  
    const modal = useModal()
    const [changeBreakStatus] = useMutation(CHANGE_BREAK_STATUS)
    const { data, loading, error, subscribeToMore } = useQuery(RIDER_BREAKS, {variables: {date: moment().format("YYYY-MM-DD")}})
   
    useEffect(() => {
      const riderBreakUpdated = subscribeToMore({
          document: RIDER_BREAK_UPDATED,
          updateQuery: (prev, { subscriptionData }) => {
              if (!subscriptionData.data) return prev
              const _riderBreaks = [...prev?.riderBreaks]
              const index = _riderBreaks.findIndex((purchase) => purchase.id === subscriptionData.data.riderBreakUpdated.id)
              if (index >= 0){
                  _riderBreaks[index] = subscriptionData.data.riderBreakUpdated
                  return Object.assign({}, prev, {
                      riderBreaks: _riderBreaks
                  })
              } else {
                  return Object.assign({}, prev, {
                      riderBreaks: [subscriptionData.data.riderBreakUpdated, ..._riderBreaks]
                  })
              }
          }
      })
  
      const newRiderBreak = subscribeToMore({
          document: NEW_RIDER_BREAK,
          updateQuery: (prev, { subscriptionData }) => {
              if (!subscriptionData.data) return prev
              const _riderBreaks = [...prev?.riderBreaks]
              const index = _riderBreaks.findIndex((purchase) => purchase.id === subscriptionData.data.newRiderBreak.id)
              if (index >= 0){
                  _riderBreaks[index] = subscriptionData.data.newRiderBreak
                  return Object.assign({}, prev, {
                      riderBreaks: _riderBreaks
                  })
              } else {
                  return Object.assign({}, prev, {
                      riderBreaks: [subscriptionData.data.newRiderBreak, ..._riderBreaks]
                  })
              }
          }
      })

      return () => {
        riderBreakUpdated()
        newRiderBreak()
      }

    // eslint-disable-next-line
  }, [])  

  async function _changeBreakStatus(_data){
    try {
      await changeBreakStatus({variables: _data})
    } catch (e) {
      toast.error(e?.message)
    }
  }  
  
    if (loading || error) return <Loader />
  
    const  { riderBreaks } = data
    const all = riderBreaks?.filter(_riderBreak => _riderBreak.type === "DAILYBREAK" && _riderBreak.status === "REQUESTED")

    if (!all?.length) return null

    return(
        <>
            <div className="btn" onClick={() => modal.show()}>
                <FontAwesome name="utensils" />
                <span className="label">Waiting for break</span>
                <span className="value">{all.length}</span>          
            </div>
            <Modalv3 modal={modal} id="active-breaks" header={<Title tag="h3"><IntlText group="active-breaks" id="title" /></Title>} >
                <div className='rider-buttons'>
                    {all.sort((y,x)=> new Date(x.createdAt) < new Date(y.createdAt) ? 1 : -1).map((_break, key) => 
                        <div className='rider-button' key={key}>
                            <span>{_break.rider.name}</span>
                            <span>{moment(_break.createdAt).format("HH:mm")}</span>
                            {riderBreaks.find((value => value.status === "ONBREAK" && value.rider.originalId === _break.rider.originalId)) ? 
                                <Button onClick={() => toast.error("Rider is currently on a technical break")}>Not available</Button>
                            :
                                <Button onClick={() => _changeBreakStatus({id: _break.id, status: "INTRANSFER_TO_BREAK"})}>Send to eat</Button>
                            }
                        </div>
                    )}
                </div>
            </Modalv3>
        </>
    )
  }
