import { gql } from '@apollo/client'

import { CATEGORY_FRAGMENT } from './'

const EDIT_CATEGORY_MUTATION = gql`
  mutation editProductCategory($id: ID!, $data:EditedProductCategoryInput!){
    editProductCategory(id: $id, data:$data){
      ...CATEGORY_FRAGMENT
    }
  }
  ${CATEGORY_FRAGMENT}
`

export default EDIT_CATEGORY_MUTATION
