import React, { useContext } from 'react'
import moment from 'moment'
import { toast } from 'react-toastify'
import { useMutation, useQuery } from '@apollo/client'
import { useLocation } from 'react-router-dom'

import { VENDOR_BOOK_ENTRIES, VOID_VENDOR_BOOK_ENTRY } from '../../graphql'
import { useQueryBuilder } from '../../hooks'
import { formatPrice } from '../../helpers'
import { IntlContext } from '../../contexts'
import { Table, Wrapper, Pagination, ContextMenu, IntlValue, StatusDot, Filters, DatePicker } from '../../components'

export function VendorsBookEntries() {

  const { getTranslation } = useContext(IntlContext)
  const location = useLocation()
  const locationSearch = new URLSearchParams(location.search)
  const { params, addParam, removeParam, isQuery, buildQuery, runQuery, setPage, page } = useQueryBuilder({
    data: {
      date: {key: "date", label: `${locationSearch.getAll("date")[0]} - ${locationSearch.getAll("date")[1]}`, value: locationSearch.getAll("date")?.length === 2 ? [locationSearch.getAll("date")[0], locationSearch.getAll("date")[1]] : undefined, skip: !locationSearch.get("date")},
    },
    page: locationSearch.get("page"),
    limit: 50
  }, [{id: "date", validation: (obj) => !!obj?.value, error: "You have to select date"}])  
  const { data, loading, error, refetch } = useQuery(VENDOR_BOOK_ENTRIES, {variables: buildQuery(), skip: !isQuery()})
  const [voidVendorBookEntry] = useMutation(VOID_VENDOR_BOOK_ENTRY)

  function setDate({selection: {startDate, endDate}}){
    const _date = [
      moment(startDate).format("YYYY-MM-DD"),
      moment(endDate).add(1, 'd').format("YYYY-MM-DD")
    ]
    addParam({date: {key: "date", value: [_date[0], _date[1]], label: `${_date[0]} - ${_date[1]}`, skip: !(_date[0] && _date[1])}})
  }  

  function onSearch(e){
    if (e.key !== 'Enter') return false
    runQuery()
  }  

  async function _voidVendorBookEntry(id){
    try {
      await voidVendorBookEntry({variables: {id}})
      refetch()
      toast.success("Record voided successful")
    } catch (e) {
      console.log("Error: ", e.message)
    }
  }  

  console.log("data", data);

  return(
    <Wrapper loading={loading || error}>
      <div id="vendor" className="container">
        <div className="row padding">
          <Filters.Bar>
            <Filters.Input id="query" name="query" value={params?.query?.value} onChange={({target}) => addParam({query: {key: "query", value: target.value, skip: !target.value}})} reset={() => removeParam("query")} placeholder="Type here for searching" onKeyDown={onSearch} />
            <Filters.Dropdown style={{marginLeft: "auto"}} placeholder={getTranslation({group: "history-filter", id: "date"})} value={params?.date?.label} disableInClickClose right run={runQuery} reset={() => removeParam("date")}>
              <DatePicker.Range startDate={params?.date?.value[0] ? new Date(`${params.date.value[0]}T00:00`) : new Date()} endDate={params?.date?.value[1] ? new Date(`${params.date.value[1]}T00:00`) : new Date()} onChange={setDate} />
            </Filters.Dropdown>
          </Filters.Bar>  
          <Table.Table>
            <Table.Head>
              <Table.Tr>
                <Table.Th></Table.Th>
                <Table.Th className="text-left">Date</Table.Th>
                <Table.Th className="text-left">Type</Table.Th>
                <Table.Th className="text-left">Note</Table.Th>
                <Table.Th>Amount</Table.Th>
                <Table.Th></Table.Th>
              </Table.Tr>
            </Table.Head>
            <Table.Body>
              {data?.vendorBookEntries?.nodes?.map((historyItem, key) =>
                <Table.Tr className="item" key={key}>
                  <Table.Td><StatusDot active={!historyItem.voided} /></Table.Td>
                  <Table.Td className="date text-left">{moment(historyItem?.createdAt).format("YYYY MMMM DD. HH:mm:ss")}</Table.Td>
                  <Table.Td className="description-field text-left">
                    <div>
                      <span><IntlValue>{historyItem?.description}</IntlValue></span>
                      <span className="message">{historyItem.auditMessage}</span>
                    </div>
                  </Table.Td>
                  <Table.Td className="note text-left">{historyItem?.note}</Table.Td>
                  <Table.Td className={`price-field ${historyItem.value > 0 ? "plus" : "minus"}`}>
                    {formatPrice(historyItem?.value)}
                  </Table.Td>
                  <Table.Td>
                    <ContextMenu.Menu id={historyItem?.id}>
                      {historyItem.relatedPurchase &&
                        <ContextMenu.Link to={`/orders/${historyItem.relatedPurchase}`} icon="external-link-alt">View purchase</ContextMenu.Link>
                      }
                      <ContextMenu.Button className="invalid" onClick={() => _voidVendorBookEntry(historyItem?.id)} icon="times">Void</ContextMenu.Button>
                    </ContextMenu.Menu>
                  </Table.Td>
                </Table.Tr>
              )}
            </Table.Body>
          </Table.Table>
          <Pagination page={page} setPage={setPage} pageInfo={data?.vendorBookEntries?.pageInfo}/>          
        </div>
      </div>
    </Wrapper>
  )

}
