import FontAwesome from 'react-fontawesome'

import { IntlText } from '../../components'

import './style.css'

export function NotFound({text, fill, icon = "exclamation-triangle", theme = "invalid"}) {
  return(
    <div id="not-found" className="container">
      <div className="row padding">
        <div id="not-found-wrapper" className={`${theme} ${fill ? "fill" : ""}`}>
          <FontAwesome name={icon} />
          <p className="error-text">{text ? text : <IntlText group="not-found" id="message" />}</p>
        </div>
      </div>
    </div>
  )
}
