import React, { useContext } from 'react'
import { useMutation } from '@apollo/client'

import { useValidatedForm } from '../../../hooks'
import { validateRfc, validateName, toast } from '../../../helpers'
import { EDIT_VENDOR_MUTATION } from '../../../graphql'
import { IntlContext } from '../../../contexts'
import { Button, IntlText, Form } from '../../../components'
import { VendorInvoicingForm } from '../../../views'

const validations = {
  "name": [{id: "required", validation: (val) => !!val}, {id: "validName", validation: (val) => validateName(val)}],
  "rfc": [{id: "required", validation: (val) => !!val}, {id: "rfc", validation: (val) => validateRfc(val)}],
  "city": [{id: "required", validation: (val) => !!val}],
  "zip": [{id: "required", validation: (val) => !!val}],
  "state": [{id: "required", validation: (val) => !!val}],
  "taxSystem": [{id: "required", validation: (val) => !!val}],
}

export function VendorInvoicingDetails({vendor}) {

  const { getTranslation } = useContext(IntlContext)
  const [editVendor] = useMutation(EDIT_VENDOR_MUTATION)
  const form = useValidatedForm({...vendor?.invoicing}, validations)
  const { form: {name, rfc, street, exterior, interior, neighborhood, city, zip, municipality, state, taxSystem}, validate } = form

  async function _submit(){
    const { valid } = validate()

    if (!valid) return toast.error(getTranslation({group: "form", id: "error"}))

    let data = {
      invoicing:{
        name,
        rfc,
        street,
        exterior,
        interior,
        neighborhood,
        city,
        zip,
        municipality,
        state,
        taxSystem
      }
    }

    try {
      await editVendor({variables: {id: vendor?.id, data}})
      return toast.success(getTranslation({id: "save-success"}))
    } catch (e) {
      if (e.graphQLErrors) e.graphQLErrors.map(x => toast.error(x.message))
      console.log("Error: ", e)
    }
  }

  return(
    <div id="invoicing-details">
      <VendorInvoicingForm vendor={vendor} form={form}>
        <Form.Field>
          <Button theme="main" className="full" onClick={_submit}><IntlText id="save" /></Button>
        </Form.Field>             
      </VendorInvoicingForm>
    </div>
  )
}