import React, { useEffect } from 'react'
import ReactDOM from 'react-dom'

import { Title } from '../'

import './style.css'

export function Confirm({title, desc, resolve, reject}) {

  useEffect(() => {
    document.body.classList.add("modal-open")

    return () => {
      document.body.classList.remove("modal-open")
    }
  }, [])

  function _resolve(){
    ReactDOM.unmountComponentAtNode(document.getElementById('notifications-portal'))
    resolve() 
  }

  function _reject(){
    ReactDOM.unmountComponentAtNode(document.getElementById('notifications-portal'))
    reject(new Error("Action cancelled by user"))
  }

  return(
    <div id="confirm-portal">
      <div className="confirm-modal">
        <div className="confirm-modal-header">
          <Title tag="h3">{title}</Title>
        </div>
        <div className="confirm-modal-content">
          <div className="desc">{desc}</div>
          <div className="choices">
            <div className="reject" onClick={_reject}>no</div>
            <div className="accept" onClick={_resolve}>yes</div>
          </div>
        </div>
      </div>
    </div>
  )
}
