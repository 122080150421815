import React, { useContext } from 'react'
import Modal from 'react-modal'

import { Title, CloseButton, IntlText } from '../../../components'
import { Category } from '../../../views'
import { ModalContext } from '../../../contexts'

import style from '../../../style/modal'

export function CategoryModal() {

  const { isOpened, closeModal, attributes } = useContext(ModalContext)
  const { category } = attributes

  if (isOpened("category")) return(
    <Modal isOpen={isOpened("category")} style={style} onRequestClose={() => closeModal()} appElement={document.getElementById('root')}>
      <div id="category-modal" className="modal">
        <CloseButton onClick={() => closeModal()}/>
        <div className="modal-info">
          <div className="modal-header">
            <Title tag="h2"><IntlText group="category-modal" id="title">Category</IntlText></Title>
          </div>
          <div className="modal-content">
            <Category category={category} success={closeModal}/>
          </div>
        </div>
      </div>
    </Modal>
  )

  return null
}
