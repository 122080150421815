import { gql } from '@apollo/client'

const DELETE_VENDOR_MUTATION = gql`
  mutation deleteVendor($id: ID!){
    deleteVendor(id: $id){
      success
    }
  }
`

export default DELETE_VENDOR_MUTATION
