import { useQuery } from '@apollo/client'

import { Modalv3, Loader } from '../../components'
import { PurchaseStatus } from '../../views'
import { PURCHASE_QUERY } from '../../graphql'
import { useIntl } from '../../hooks'

export default function PurchaseStatusModal({modal}) {

  const { getTranslation } = useIntl()

  return(
    <Modalv3 id="purchase-status" modal={modal} headerTitle={getTranslation({})}>
      <Inner modal={modal} />
    </Modalv3>         
  )  
  
}

function Inner({modal}){

  const { loading, data, error } = useQuery(PURCHASE_QUERY, {variables: {id: modal?.state?.id}})

  if (loading || error) return <Loader theme='main' />  

  return(
    <PurchaseStatus purchase={data?.purchase} />
  )
}