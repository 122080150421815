import { gql } from '@apollo/client'

import { SERVICE_FEE_PROFORMA_INVOICE_FRAGMENT } from './'

export const SET_SERVICE_FEE_INVOICE_COMPLETED = gql`
  mutation setServiceFeeProformaInvoiceToCompleted($id: ID!, $createEntry: Boolean){
    setServiceFeeProformaInvoiceToCompleted(id: $id, createEntry: $createEntry){
      ...SERVICE_FEE_PROFORMA_INVOICE_FRAGMENT    
    }
  }
  ${SERVICE_FEE_PROFORMA_INVOICE_FRAGMENT}
`
