import Tippy from '@tippyjs/react'

import './style.css'

export function Tooltip({text, children}) {

  if (!text || text === "") return children

  return(
    <Tippy content={text}>
      <div className='tooltip'>{children}</div>
    </Tippy>    
  )
}
