import { gql } from '@apollo/client'

import { RIDER_BREAK_FRAGMENT } from './'

export const RIDER_BREAK_UPDATED = gql`
  subscription riderBreakUpdated{
    riderBreakUpdated{
      ...RIDER_BREAK_FRAGMENT
    }
  }
  ${RIDER_BREAK_FRAGMENT}
`
