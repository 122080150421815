import { useState } from 'react'
import { useMutation, useQuery } from '@apollo/client'
import moment from 'moment'

import { FINISH_CONTRACTOR_RIDER_WORKSHIFT, PURCHASES_QUERY } from '../../../graphql'
import { useIntl, useModal } from '../../../hooks'
import { toast } from '../../../helpers'
import { StatusDot, Table, ContextMenu, Loader, IntlText, Filterv2, Badge, Tooltip, Modalv3, SelectV2, Form, Button, Notification } from '../../../components'
import { AssignRider, StartRiderWorkshift } from '../../../views'

import './style.css'

export function RidersList({data, loading, refetch, filter}) {

  const startWorkshiftModal = useModal()
  const finishContractorWorkshiftModal = useModal()
  const { getTranslation } = useIntl()
  
  function sendMessage(cel){
    const phone = (cel?.phoneCode + cel?.phoneNumber)?.replace(/[\s+()]/g, "")
    window.open(`whatsapp://send/?phone=${phone}`)
  }

  return(
    <div id="riders-list">
      {(loading) && <Loader theme="main" overlay/>}
      {data.length > 0 &&  
        <Filterv2 data={data || []} filters={[
          {filter: (data) => (!!data?.name && data.name.toLowerCase().search(filter?.toLowerCase()) !== -1) ||( !!data?.username && data.username.toLowerCase().search(filter?.toLowerCase()) !== -1), skip: !filter},
        ]} >
          {(data) =>
            <Table.Table>
              <Table.Head>
                <Table.Tr>
                  <Table.Th></Table.Th>
                  <Table.Th className="text-left"><IntlText group="riders" id="name" /></Table.Th>
                  <Table.Th className="text-left"><IntlText group="riders" id="username" /></Table.Th>
                  <Table.Th className="text-left"><IntlText group="riders" id="email" /></Table.Th>
                  <Table.Th className="text-left"><IntlText group="riders" id="phone" /></Table.Th>
                  <Table.Th className="text-left"><IntlText group="riders" id="type" /></Table.Th>
                  <Table.Th><IntlText group="riders" id="rating" /></Table.Th>
                  <Table.Th><IntlText group="riders" id="tags" /></Table.Th>
                  <Table.Th className="text-left"><IntlText group="riders" id="createdAt" /></Table.Th>
                  <Table.Th className="text-left"><IntlText group="riders" id="lastPurchaseDelivered" /></Table.Th>
                </Table.Tr>
              </Table.Head>
              <Table.Body>
                {data.map((rider, key) =>
                  <Table.Tr key={key} className={["rider", rider.active && rider.available ? null : "disabled"].join(" ")}>
                    <Table.Td style={{width: 20}}><StatusDot active={rider.active} /></Table.Td>
                    <Table.Td className="text-left">
                      <div style={{display: "flex", alignItems: "center", gap: 10}}>
                        {rider.name}
                        <ContextMenu.Menu id={rider?.id}>
                          <ContextMenu.Link to={`/riders/${rider.id}`} icon="external-link-alt">Open</ContextMenu.Link>
                          {!rider.active && !rider.available && rider.type !== "CONTRACTOR" &&
                            <ContextMenu.Button onClick={() => startWorkshiftModal.show({rider})} icon="play">Start workshift</ContextMenu.Button>
                          }
                          {rider.active && rider.type === "CONTRACTOR" &&
                            <ContextMenu.Button onClick={() => finishContractorWorkshiftModal?.show({rider})} icon="stop">End workshift</ContextMenu.Button>
                          }
                        </ContextMenu.Menu>                      
                      </div>
                    </Table.Td>
                    <Table.Td className="text-left">{rider.username}</Table.Td>
                    <Table.Td className="text-left">{rider.email}</Table.Td>
                    <Table.Td className="text-left">{rider.cel && <div  onClick={() => sendMessage(rider.cel)}><i className="fab fa-whatsapp-square" />{`+${rider.cel?.phoneCode} ${rider.cel?.phoneNumber}`}</div>}</Table.Td>
                    <Table.Td className="text-left"><IntlText group="riders" id={rider.type} /></Table.Td>
                    <Table.Td><span style={{color: rider.percentageOfPositive < 0.5 ? "var(--invalid-color)" : rider.percentageOfPositive < 0.8 ? "var(--warning-color)" : "var(--valid-color)"}}>{Math.round(rider.percentageOfPositive * 100)}%</span></Table.Td>
                    <Table.Td className="text-left">
                      {rider.banned &&
                        <Tooltip text={rider.bannedNote}>
                          <Badge theme='invalid'>Banned</Badge>                      
                        </Tooltip>
                      }
                      {rider.suspended &&
                        <Tooltip text={rider.suspendMessage}>
                          <Badge theme='invalid'>Suspended</Badge>                      
                        </Tooltip>
                      }
                      {rider.profileStatus === "APPROVED" &&
                        <Badge theme='valid'>Approved</Badge>                      
                      }
                    </Table.Td>
                    <Table.Td className="text-left">{moment(rider.createdAt).format("DD/MM/YYYY ")}</Table.Td>
                    <Table.Td className="text-left">
                      {rider.lastPurchaseDelivered &&
                        <Tooltip text={`${moment().diff(rider.lastPurchaseDelivered, "days")} days ago`}>
                          <div style={{display: "flex", flexDirection: "column", textAlign: "center"}}>
                            <span style={{color: "var(--ui-placeholder)", fontSize: "var(--font-xs)"}}>{`${moment().diff(rider.lastPurchaseDelivered, "days")} days ago`}</span>
                            <span>{moment(rider.lastPurchaseDelivered).format("DD/MM/YYYY HH:mm")}</span>
                          </div>
                        </Tooltip>
                      }
                    </Table.Td>
                  </Table.Tr>
                )}
              </Table.Body>
            </Table.Table>
          }
        </Filterv2>
      }
      <Modalv3 id="start-contractor-workshift" modal={startWorkshiftModal} headerTitle={getTranslation({group: "start-rider-workshift", id: "modal-title"})}>
        <StartRiderWorkshift loading={loading} data={startWorkshiftModal?.state} cancel={() => startWorkshiftModal?.hide()} refetch={refetch}/>
      </Modalv3>         
      <Modalv3 id="finish-contractor-workshift" modal={finishContractorWorkshiftModal} headerTitle={getTranslation({group: "finish-contractor-workshift", id: "title"})}>
        <FinishContractorWorkshift modal={finishContractorWorkshiftModal} rider={finishContractorWorkshiftModal?.state?.rider} />
      </Modalv3>
    </div>
  )
}

function FinishContractorWorkshift({rider, modal}){
  const reasons = ["TECHNICAL_PROBLEM", "PROBLEM_WITH_VEHICLE", "ENOUGH_WORK", "NO_RESPONSE_FOR_PURCHASE", "NO_RESPONSE_DURING_PURCHASE", "OTHER"]
  const { getTranslation } = useIntl()
  const [finishContractorRiderWorkShift, {loading}] = useMutation(FINISH_CONTRACTOR_RIDER_WORKSHIFT)
  const [reason, setReason] = useState(null)
  const [hasActive, toggleHasActive] = useState(false)

  async function _finishContractorRiderWorkShift(){
    const alert = toast.loading()
    try {
      await finishContractorRiderWorkShift({variables: {rider: rider.id, reason}})
      alert.success(getTranslation({group: "finish-contractor-workshift", id: "success", variables: {name: rider.name}}))
      modal.hide()
    } catch (error) {
      toggleHasActive(true)
      alert.apolloError(error)
    }
  }  

  return(
    <div style={{minHeight: "20vh", alignItems: "stretch", display: "flex", flexDirection: "column"}}>
      <Form.Form>
        <Form.Field>
          <SelectV2 value={(_value) => getTranslation({group: "finish-contractor-workshift", id: _value})} placeholder={getTranslation({group: "finish-contractor-workshift", id: "reason"})} selected={reason} options={reasons} onChange={(_value) => setReason(_value)} />
        </Form.Field>
        {hasActive &&
          <Form.Field>
            <Notification icon="exclamation-triangle" type='error'>
              <IntlText group="finish-contractor-workshift" id="has-active" />
            </Notification>      
            <RiderActivePurchases rider={rider} />
          </Form.Field>
        }
        <Form.Field>
          <Button fluid theme="main" loading={loading} onClick={_finishContractorRiderWorkShift}>{getTranslation({group: "finish-contractor-workshift", id: "button"})}</Button>
        </Form.Field>
      </Form.Form>
    </div>
  )
}

function RiderActivePurchases({rider}){
  const { loading, data, error } = useQuery(PURCHASES_QUERY, {variables: {status: ["PROCESSED", "INPREPARATION", "READYFORCOLLECTION", "ONTHEWAY", "ATLOCATION"], rider: rider.id}})

  if (loading || error) return <Loader theme='main' />

  const { purchases } = data

  return(
    <div className='active-purchases'>
      {purchases?.map((item, key) =>
        <div key={key} className='purchase'>
          <div>{item.vendor.name}</div>
          <div>{item.customer.name}</div>
          <div>{item.address?.zone}</div>
          <div><AssignRider purchase={item} /></div>
        </div>
      )}
    </div>
  )
}