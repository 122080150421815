import React from 'react'
import FontAwesome from 'react-fontawesome'
import { Mutation } from '@apollo/client/react/components'

import { CHANGE_STATUS_MUTATION } from '../../graphql'
import { Button } from '../../components'

import './ReadyForCollectionButton.css'

export default function ReadyForCollectionButton({purchase: {id, status, preparationTime}}) {

  if (status === "READYFORCOLLECTION" || !preparationTime) return null

  return(
    <Mutation mutation={CHANGE_STATUS_MUTATION}>
      {(changeStatus) =>
        <Button className="ready-for-collection" onClick={() => changeStatus({variables: {id: id, status: "READYFORCOLLECTION"}})}>
          <FontAwesome name="check"/>
        </Button>
      }
    </Mutation>
  )
}
