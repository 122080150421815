import { gql } from '@apollo/client'

export const AUDITLOG_FRAGMENT = gql`
  fragment AUDITLOG_FRAGMENT on AuditLog {
    id
    type
    event
    tags
    message{
      es
      en
    }
    metadata
    createdAt
  }
`
