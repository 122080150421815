import { gql } from '@apollo/client'

import { ADMIN_FRAGMENT } from '../admin'

export const ADMINS = gql`
  query admins{
     admins{
       ...ADMIN_FRAGMENT
     }
  }
  ${ADMIN_FRAGMENT}
`
