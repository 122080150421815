import React, { useContext } from 'react'
import { useMutation } from '@apollo/client'
import { toast } from 'react-toastify'

import { useValidatedForm } from '../../../hooks'
import { getCommonAsset, validateEmail, validateRfc } from '../../../helpers'
import { IntlContext } from '../../../contexts'
import {  EDIT_RIDER_MUTATION } from '../../../graphql'
import { Form, IntlText, Validation, LabeledInput, SelectV2, Button, ContextMenu } from '../../../components'
import { StatusNotification } from '../../../views'

const validations = {
  "name": [{id: "required", validation: (val) => !!val}],
  "rfc": [{id: "required", validation: (val) => !!val}, {id: "rfc", validation: (val) => validateRfc(val)}],
  "email": [{id: "required", validation: (val) => !!val}, {id: "email", validation: (val) => validateEmail(val)}],
  "city": [{id: "required", validation: (val) => !!val}],
  "zip": [{id: "required", validation: (val) => !!val}],
  "state": [{id: "required", validation: (val) => !!val}],
  "taxSystem": [{id: "required", validation: (val) => !!val}],
}

export function RiderInvoicing({rider}){

  const { getTranslation } = useContext(IntlContext)
  const [editRider, {loading}] = useMutation(EDIT_RIDER_MUTATION)
  const { form: {name, rfc, email, street, exterior, interior, neighborhood, city, zip, municipality, state, taxSystem}, updateField, validate, errors } = useValidatedForm({...rider?.invoicing}, validations)  
  const states = getCommonAsset('states.json')
  const taxSystems = getCommonAsset('tax-systems.json')

  async function save(_data){
    try {
      const { valid } = validate()

      if (!valid) return toast.error(getTranslation({group: "form", id: "error"}))
  
      const data = {
        name,
        rfc,
        email,
        street,
        exterior,
        interior,
        neighborhood,
        city,
        zip,
        municipality,
        state,
        taxSystem
      }

      await editRider({variables: {id: rider.id, data: {invoicing: data}}})
      toast.success(getTranslation({id: "edit-success"}))
    } catch (e) {
      e.graphQLErrors.map(x => toast.error(x.message))
    }
  }

  async function changeStatusMutation(_data){
    try {
      await editRider({variables: {id: rider.id, data: _data}})
      toast.success(getTranslation({id: "edit-success"}))
    } catch (e) {
      e.graphQLErrors.map(x => toast.error(x.message))
    }      
  }

  function getStatus(){
    switch (rider.invoicingStatus) {
      case "INREVIEW":
        return "warning"
      case "ACCEPTED":
        return "success"
      case "REJECTED":
      case "EXPIRED":
        return "error"
      default:
        return "warning"
    }
  }

  function getIcon(){
    switch (rider.invoicingStatus) {
      case "INREVIEW":
        return "clock"
      case "ACCEPTED":
        return "check"
      case "REJECTED":
      case "EXPIRED":
        return "times"
      default:
        return "clock"
    }    
  }    
  
  return(
    <div id="rider-details">
      <StatusNotification title={<IntlText id={rider?.invoicingStatus} />} icon={getIcon()} type={getStatus()} message={rider.invoicingMessage} editMessage={(_val) => changeStatusMutation({invoicingMessage: _val})} actions={[
          <ContextMenu.Button key="accepted" className='valid' onClick={() => changeStatusMutation({invoicingStatus: "ACCEPTED"})} icon="check"><IntlText id="ACCEPTED" /></ContextMenu.Button>,
          <ContextMenu.Button key="inreview" className='warning' onClick={() => changeStatusMutation({invoicingStatus: "INREVIEW"})} icon="clock"><IntlText id="INREVIEW" /></ContextMenu.Button>,
          <ContextMenu.Button key="rejected" className='invalid' onClick={() => changeStatusMutation({invoicingStatus: "REJECTED"})} icon="ban"><IntlText id="REJECTED" /></ContextMenu.Button>
      ]}>
        <span><IntlText group="rider-form" id={`${rider?.profileStatus}-info`} /></span>     
      </StatusNotification>       
      <Form.Form>
        <Form.Field>
          <Validation errors={errors.name}>
            <LabeledInput name="name" placeholder={getTranslation({group: "vendor-invoicing-details", id: "input-name"})} value={name} onChange={({target}) => updateField({key: target.name, value: target.value})}/>
          </Validation>
        </Form.Field>
        <Form.Field>
          <Validation errors={errors.rfc}>
            <LabeledInput name="rfc" placeholder={getTranslation({group: "vendor-invoicing-details", id: "input-rfc"})} value={rfc} onChange={({target}) => updateField({key: target.name, value: target.value})}/>
          </Validation>
        </Form.Field>
        <Form.Field>
          <Validation errors={errors.email}>
            <LabeledInput name="email" placeholder={getTranslation({group: "vendor-invoicing-details", id: "input-email"})} value={email} onChange={({target}) => updateField({key: target.name, value: target.value})}/>
          </Validation>
        </Form.Field>
          <Validation errors={errors.street}>
            <LabeledInput name="street" placeholder={getTranslation({group: "vendor-invoicing-details", id: "input-street"})} value={street} onChange={({target}) => updateField({key: target.name, value: target.value})}/>
          </Validation>
        <Form.Field>
          <Validation errors={errors.exterior}>
            <LabeledInput name="exterior" placeholder={getTranslation({group: "vendor-invoicing-details", id: "input-exterior"})} value={exterior} onChange={({target}) => updateField({key: target.name, value: target.value})}/>
          </Validation>
        </Form.Field>
        <Form.Field>
          <Validation errors={errors.interior}>
            <LabeledInput name="interior" placeholder={getTranslation({group: "vendor-invoicing-details", id: "input-interior"})} value={interior} onChange={({target}) => updateField({key: target.name, value: target.value})}/>
          </Validation>
        </Form.Field>
        <Form.Field>
          <Validation errors={errors.neighborhood}>
            <LabeledInput name="neighborhood" placeholder={getTranslation({group: "vendor-invoicing-details", id: "input-neighborhood"})} value={neighborhood} onChange={({target}) => updateField({key: target.name, value: target.value})}/>
          </Validation>
        </Form.Field>
        <Form.Field>
          <Validation errors={errors.city}>
            <LabeledInput name="city" placeholder={getTranslation({group: "vendor-invoicing-details", id: "input-city"})} value={city} onChange={({target}) => updateField({key: target.name, value: target.value})}/>
          </Validation>
        </Form.Field>
        <Form.Field>
          <Validation errors={errors.zip}>
            <LabeledInput name="zip" placeholder={getTranslation({group: "vendor-invoicing-details", id: "input-zip"})} value={zip} onChange={({target}) => updateField({key: target.name, value: target.value})}/>
          </Validation>
        </Form.Field>
        <Form.Field>
          <Validation errors={errors.municipality}>
            <LabeledInput name="municipality" placeholder={getTranslation({group: "vendor-invoicing-details", id: "input-municipality"})} value={municipality} onChange={({target}) => updateField({key: target.name, value: target.value})}/>
          </Validation>
        </Form.Field>
        <Form.Field>
          <Validation errors={errors.state}>
            <SelectV2 value={(_value) => _value.name} placeholder={getTranslation({group: "vendor-invoicing-details", id: "input-state"})} selected={states.find(s => s.abbreviation === state)} defaultValue={getTranslation({id: "please-select"})} options={states} labelId="name" onChange={(_data) => updateField({key: "state", value:  _data.abbreviation})} />
          </Validation>
        </Form.Field>
        <Form.Field>
          <Validation errors={errors.taxSystem}>
            <SelectV2 value={(_value) => _value.name} placeholder={getTranslation({group: "vendor-invoicing-details", id: "input-taxSystem"})} selected={taxSystems.find(_ts => _ts.id === taxSystem)} defaultValue={getTranslation({id: "please-select"})} options={taxSystems} labelId="name" onChange={(_data) => updateField({key: "taxSystem", value:  _data.id})} />
          </Validation>
        </Form.Field>
        <Form.Field>
          <Button theme="main" className="full" loading={loading} onClick={save}><IntlText id="save" /></Button>
        </Form.Field>        
      </Form.Form>
    </div>
  )

}