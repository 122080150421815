import React from 'react'
import moment from 'moment'
import { useQuery } from '@apollo/client'
import { useParams } from 'react-router-dom'
import { useLocation } from 'react-router-dom'

import { useQueryBuilder } from '../../../hooks'
import { PURCHASES_PAGINATED_QUERY } from '../../../graphql'
import { Loader, Pagination, QueryResults } from '../../../components'
import { OrdersList, HistoryFilter } from '../../../views'

export function VendorPurchases() {

  const location = useLocation()
  const search = new URLSearchParams(location.search)
  const { id } = useParams()
  const { params, getParams, addParam, removeParam, clearParams, isQuery, buildQuery, runQuery, updateQuery, setPage, page, sort, setSort } = useQueryBuilder({
    data: {
      date: {key: "date", label: `${moment().startOf('month').format('YYYY-MM-DD')}-${moment().endOf('month').format('YYYY-MM-DD')}`, value: [moment().startOf('month').format('YYYY-MM-DD'), moment().endOf('month').format('YYYY-MM-DD')]},
      sort: {key: "payment", label: search.get("sort"), value: search.get("sort") || "CREATEDAT_DESCENDING"},
    },
  })

  const { data, loading, error } = useQuery(PURCHASES_PAGINATED_QUERY, {variables: {vendor: id, ...buildQuery()}, skip: !isQuery() || !id})

  if (loading || error) return <Loader theme="main" />

  return(
    <div id="vendor-orders">
      <HistoryFilter loading={loading} params={params} getParams={getParams} addParam={addParam} removeParam={removeParam} clearParams={clearParams} sort={sort} setSort={setSort} runQuery={runQuery} updateQuery={updateQuery} />
      <div id="pending-orders">
        <QueryResults pageInfo={data?.purchasesPaginated?.pageInfo} reset={clearParams} />
        <OrdersList data={data?.purchasesPaginated?.nodes || []} visibleFields={{vendor: false}} />
        <Pagination page={page} setPage={setPage} pageInfo={data?.purchasesPaginated?.pageInfo}/>
      </div>
    </div>
  )
}
