import { gql } from '@apollo/client'

import { SETTING_FRAGMENT } from './'

export const CREATE_SETTING_MUTATION = gql`
  mutation createSetting($data: SettingInput!){
    createSetting(data: $data){
      ...SETTING_FRAGMENT
    }
  }
  ${SETTING_FRAGMENT}
`
