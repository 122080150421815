import { gql } from '@apollo/client'

import { PURCHASES_FRAGMENT } from './'

export const PURCHASES_PAGINATED_QUERY = gql`
  query purchasesPaginated($query: String, $onlyModified: Boolean, $isReferral: Boolean, $date: [String!], $customer: ID, $vendor: ID, $rider: ID, $hasRider: Boolean, $payment: PAYMENT_METHOD, $status: [PURCHASE_STATUS!], $limit: Int, $offset: Int, $type: PURCHASE_TYPE, $sort: [PURCH_SORT_CRITERIA]){
     purchasesPaginated(query: $query, onlyModified: $onlyModified, isReferral: $isReferral, date: $date, customer: $customer, vendor: $vendor, rider: $rider, hasRider: $hasRider, payment: $payment, status: $status, limit: $limit, offset: $offset, type: $type, sort: $sort) {
       nodes{
         ...PURCHASES_FRAGMENT
       }
       pageInfo{
         limit
         totalNodes
         firstNode
         lastNode
         totalPages
         actualPage
         hasNextPage
       }
     }
  }
  ${PURCHASES_FRAGMENT}
`
