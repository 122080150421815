import { gql }from '@apollo/client'

import { VENDOR_USER_FRAGMENT } from './'

export const EDIT_VENDOR_USER = gql`
  mutation editVendorUser($id: ID!, $data: EditedVendorUserInput!){
    editVendorUser(id: $id, data: $data){
      ...VENDOR_USER_FRAGMENT
    }
  }
  ${VENDOR_USER_FRAGMENT}
`
