import { gql } from '@apollo/client'

import { RIDER_FRAGMENT } from './'

export const CREATE_RIDER = gql`
  mutation createRider($data: RiderInput!){
    createRider(data: $data){
      ...RIDER_FRAGMENT
    }
  }
  ${RIDER_FRAGMENT}
`
