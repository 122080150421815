import React, { useContext } from 'react'
import moment from 'moment'
import { useQuery } from '@apollo/client'

import { useQueryBuilder } from '../../../hooks'
import { IntlContext } from '../../../contexts'
import { PURCHASE_FEEDBACKS_PAGINATED } from '../../../graphql'
import { Filters, DatePicker, Loader, Pagination } from '../../../components'
import { FeedbacksList } from '../../../views'

export function VendorFeedbacks({vendor}) {

  const { getTranslation } = useContext(IntlContext)
  const { params, removeParam, isQuery, buildQuery, runQuery, page, setPage } = useQueryBuilder({})
  const { data, loading } = useQuery(PURCHASE_FEEDBACKS_PAGINATED, {variables: {vendor: vendor.id, ...buildQuery()}, skip: !isQuery(), fetchPolicy: "network-only"})

  function setDate({selection: {startDate, endDate}}){
    const _start = moment(startDate)
    const _end = moment(endDate)

    const _date = [
      _start.format("YYYY-MM-DD"),
      _end.add(1, 'd').format("YYYY-MM-DD")
    ]
    runQuery({instantParams: {date: {key: "date", label: `${_date[0]} - ${_date[1]}`, value: [_date[0], _date[1]], skip: !(_date[0] && _date[1])}}})
  }  

  return(
    <div id="vendor-feedbacks">
      {loading && <Loader theme="main" overlay/>}
      <Filters.Bar>
        <Filters.Dropdown style={{marginLeft: "auto"}} placeholder={getTranslation({group: "history-filter", id: "date"})} value={params?.date?.label} disableInClickClose right reset={() => removeParam("date")}>
          <DatePicker.Range startDate={params?.date?.value[0] ? new Date(`${params.date.value[0]}T00:00`) : new Date()} endDate={params?.date?.value[1] ? new Date(`${params.date.value[1]}T00:00`) : new Date()} onChange={setDate} />
        </Filters.Dropdown>
      </Filters.Bar>
      <FeedbacksList data={data?.purchaseFeedbacksPaginated?.nodes || []} />
      <Pagination page={page} setPage={setPage} pageInfo={data?.purchaseFeedbacksPaginated?.pageInfo}/>
    </div>   
  )

}
