import { useContext } from 'react'
import { toast } from 'react-toastify'
import { useMutation, useQuery } from '@apollo/client'
import moment from 'moment'

import { useQueryBuilder } from '../../../hooks'
import { formatPrice } from '../../../helpers'
import { IntlContext } from '../../../contexts'
import { Badge, Table, Loader, Pagination, ContextMenu, IntlText, Filters, Placeholder, DatePicker, QueryResults } from '../../../components'
import { SERVICE_FEE_PROFORMA_INVOICES, CREATE_PDF_OF_INVOICE, SEND_SERVICE_FEE_INVOICE_EMAIL } from '../../../graphql'

export function VendorInvoices({vendor}) {

  const { getTranslation } = useContext(IntlContext)
  const { isQuery, buildQuery, params, addParam, updateQuery, removeParam, clearParams, runQuery, setPage, page } = useQueryBuilder({
    limit: 50,
    data: {
      date: {key: "date", value: [moment().subtract(90, 'days').startOf('day').format("YYYY-MM-DD"), moment().add(1, "day").format("YYYY-MM-DD")]},
    },    
  })
  const { loading, data, error } = useQuery(SERVICE_FEE_PROFORMA_INVOICES, {variables: {vendor: vendor?.id, ...buildQuery()}})
  const [createPDF] = useMutation(CREATE_PDF_OF_INVOICE)
  const [sendInvoiceToEmail] = useMutation(SEND_SERVICE_FEE_INVOICE_EMAIL)
  const statusArray = [
    {id: "WAITING_FOR_PAYMENT"},
    {id: "PAID"},
    {id: "CLOSED_WITHOUT_PAYMENT"},
  ]    

  async function _createPDF(id){
    try {
      await createPDF({variables: {id}})
    } catch (error) {
      console.log("Error", error)
    }
  }
  
  async function _sendInvoiceToEmail(id){
    try {
      await sendInvoiceToEmail({variables: {id}})
      toast.success(getTranslation({id: "create-success"}))
    } catch (error) {
      console.log("Error", error)
    }
  }  

  function setDate({selection: {startDate, endDate}}){
    const _date = [
      moment(startDate).format("YYYY-MM-DD"),
      moment(endDate).add(1, 'd').format("YYYY-MM-DD")
    ]
    addParam({date: {key: "date", value: [_date[0], _date[1]], label: `${_date[0]} - ${_date[1]}`, skip: !(_date[0] && _date[1])}})
  }  

  if (loading || error) return <Loader theme='main' />

  const _invoices = data?.serviceFeeProformaInvoices?.nodes

  return(
    <div id="vendor-service-fee-invoices">
      <Filters.Bar>
        <Filters.Select style={{marginLeft: "auto"}} value={statusArray.find(s => s.id === params?.status?.id)} placeholder={getTranslation({group: "vendor-service-fee-invoices", id: "status"})} reset={() => removeParam("status")}>
          {statusArray.map((_status, key) =>
            <Filters.SelectItem key={key} active={params?.status === _status.id} onClick={() => updateQuery({status: {key: "status", value: _status?.id, skip: !_status?.id}})}>
              <span>{getTranslation({group: "vendor-service-fee-invoices", id: _status.id})}</span>
            </Filters.SelectItem>            
          )}
        </Filters.Select>            
        <Filters.Dropdown placeholder={getTranslation({group: "history-filter", id: "date"})} value={params?.date?.label} disableInClickClose right run={runQuery} reset={() => removeParam("date")}>
          <DatePicker.Range startDate={params?.date?.value[0] ? new Date(`${params.date.value[0]}T00:00`) : new Date()} endDate={params?.date?.value[1] ? new Date(`${params.date.value[1]}T00:00`) : new Date()} onChange={setDate} />
        </Filters.Dropdown>
      </Filters.Bar>          
      <QueryResults pageInfo={data?.riderPayouts?.pageInfo} reset={clearParams} />          
      {isQuery() ?      
        <div>
          <Table.Table>
            <Table.Head>
              <Table.Tr>
                <Table.Th className="text-left"><IntlText group="vendor-service-fee-invoices" id="netInvoiceAmount" /></Table.Th>
                <Table.Th className="text-left"><IntlText group="vendor-service-fee-invoices" id="periodicity" /></Table.Th>
                <Table.Th className="text-left"><IntlText group="vendor-service-fee-invoices" id="created-at" /></Table.Th>
                <Table.Th><IntlText group="vendor-service-fee-invoices" id="status" /></Table.Th>
                <Table.Th><IntlText group="vendor-service-fee-invoices" id="proforma" /></Table.Th>
                <Table.Th></Table.Th>
              </Table.Tr>
            </Table.Head>
            <Table.Body>
              {_invoices.length > 0 ? _invoices.map((invoice, key) =>
                <Table.Tr key={key} className="invoice">
                  <Table.Td className="text-left">{formatPrice(invoice.grossInvoiceAmount)}</Table.Td>
                  <Table.Td className="text-left">{invoice.periodicity}</Table.Td>
                  <Table.Td className="text-left">{moment(invoice.createdAt).format("DD/MM/YYYY HH:mm")}</Table.Td>
                  <Table.Td >
                    <Badge theme={invoice.status === "PAID" ? "valid" : invoice.status === "WAITING_FOR_PAYMENT" ? "warning" : null}><IntlText group="vendor-service-fee-invoices" id={invoice.status} /></Badge>
                    {invoice.overdue &&
                      <Badge style={{marginLeft: 10}} icon="exclamation-triangle" theme='invalid'><IntlText group="vendor-service-fee-invoices" id="overdue" /></Badge>
                    }                        
                  </Table.Td>
                  <Table.Td>{invoice?.proformaLink ? <Badge theme='valid'><IntlText group="vendor-service-fee-invoices" id="generated" /></Badge> : <Badge theme='warning'><IntlText group="vendor-service-fee-invoices" id="pending" /></Badge>}</Table.Td>
                  <Table.Td>
                  <ContextMenu.Menu id={invoice?.id}>
                    <ContextMenu.Link to={`${invoice.id}`} icon="external-link-alt">View invoice</ContextMenu.Link>
                    <ContextMenu.Button onClick={() => _createPDF(invoice.id)} icon="file-pdf"><IntlText group="vendor-service-fee-invoices" id="create-pdf" /></ContextMenu.Button>
                    <ContextMenu.Button onClick={() => _sendInvoiceToEmail(invoice.id)} icon="envelope"><IntlText group="vendor-service-fee-invoices" id="send-email" /></ContextMenu.Button>
                  </ContextMenu.Menu>
                  </Table.Td>
                </Table.Tr>
              ) :
                <Table.Tr>
                  <Table.Td colSpan={6}>There aren't any items to show, try to search</Table.Td>
                </Table.Tr>
              }
            </Table.Body>
          </Table.Table>
          <Pagination page={page} setPage={setPage} pageInfo={data?.serviceFeeProformaInvoices?.pageInfo}/>
        </div>
      :
        <Placeholder.Search />
      }             
    </div>
  )

}
