import { toast } from 'react-toastify'

export function sentenceCase(str) {
  return str.replace(/[a-z]/i, function (letter) {
    return letter.toUpperCase()
  })
}

export function toTitle(string) {
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase()
}

export function stringToColor(str) {
  var hash = 0
  for (var i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash)
  }
  var color = '#'
  for (var j = 0; j < 3; j++) {
    var value = (hash >> (j * 8)) & 0xFF
    color += ('00' + value.toString(16)).substr(-2)
  }
  return color
}

export function copyToClipboard(string, callback){
  if (!string) return false
  string = string.toString()
  const el = document.createElement('textarea')
  el.value = string
  document.body.appendChild(el)
  el.select()
  document.execCommand('copy')
  document.body.removeChild(el)
  callback && callback()
  toast.success("Successfully copied!")
}
