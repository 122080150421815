import React, { useContext } from 'react'
import FontAwesome from 'react-fontawesome'
import { toast } from 'react-toastify'
import { useMutation } from '@apollo/client'
import { useNavigate } from 'react-router-dom'

import { useValidatedForm } from '../../../hooks'
import { RIDER_BOOK_ENTRIES, CREATE_RIDER_BOOK_ENTRY, RIDER_BOOK } from '../../../graphql'
import { IntlContext } from '../../../contexts'
import { Validation, Select, LabeledInput, LabeledTextarea, IntlText, Button, Form } from '../../../components'

import './style.css'

export function RiderBalanceNewEntry({rider}) {

  const navigate = useNavigate()
  const { getTranslation } = useContext(IntlContext)
  const types = [
    {id: "CASH_CREDIT", label: <div className="valid"><FontAwesome name="plus" />{getTranslation({group: "vendor-balance", id: "select-cash-credit"})}</div>},
    {id: "CASH_DEBIT", label: <div className="invalid"><FontAwesome name="minus" />{getTranslation({group: "vendor-balance", id: "select-cash-debit"})}</div>},
    {id: "CASH_PURCHASE_REFUND_TO_CASH_CREDIT_BALANCE", label: <div className="valid"><FontAwesome name="plus" />{getTranslation({group: "vendor-balance", id: "CASH_PURCHASE_REFUND_TO_CASH_CREDIT_BALANCE"})}</div>},
    {id: "CASH_CREDIT_CREDIT", label: <div className="valid"><FontAwesome name="plus" />{getTranslation({group: "vendor-balance", id: "CASH_CREDIT_CREDIT"})}</div>},
    {id: "CASH_CREDIT_DEBIT", label: <div className="invalid"><FontAwesome name="minus" />{getTranslation({group: "vendor-balance", id: "CASH_CREDIT_DEBIT"})}</div>},
  ]
  const validations = {
    value: [{id: "required", validation: (val) => !!val}],
    event: [{id: "required", validation: (val) => !!val?.id}],
  }

  const [createRiderBookEntry, { loading: createLoading }] = useMutation(CREATE_RIDER_BOOK_ENTRY)
  const { form: { event, value, note }, errors, updateField, validate } = useValidatedForm({}, validations)

  async function _createRiderBookEntry(){
    const { valid } = validate()

    if (!valid) return toast.error(getTranslation({id: "form-error"}))

    try {
      await createRiderBookEntry({variables: { data: {rider, event: event?.id, note, value: parseFloat(value)}}, refetchQueries: [{query: RIDER_BOOK_ENTRIES, variables: {rider}}, {query: RIDER_BOOK, variables: {rider}}] })
      toast.success(getTranslation({id: "create-success"}))
      navigate('../')
    } catch (e) {
      toast.error(e?.message)
    }
  }

  return(
    <Form.Form>
      <Form.Field>
        <Validation errors={errors.event}>
          <Select placeholder="Type" selected={event} defaultValue="Please select" options={types} onChange={(type) => updateField({key: "event", value: type})} />
        </Validation>
      </Form.Field>
      <Form.Field>
        <Validation errors={errors.value}>
          <LabeledInput name="value" placeholder="Amount" value={value} onChange={({target}) => updateField({key: "value", value: target.value})} />
        </Validation>
      </Form.Field>
      <Form.Field>
        <LabeledTextarea name="note" placeholder="Note" value={note} onChange={({target}) => updateField({key: "note", value: target.value})} />
      </Form.Field>
      <Form.Fieldset>
        <Form.Field fluid>
          <Button className="fluid" loading={createLoading} onClick={() => navigate("../balance")}><IntlText id="back" /></Button>
        </Form.Field>
        <Form.Field fluid>
          <Button className="fluid" loading={createLoading} theme="main" onClick={_createRiderBookEntry}><IntlText id="create" /></Button>
        </Form.Field>
      </Form.Fieldset>
    </Form.Form>
  )
}
