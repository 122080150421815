import { gql }from '@apollo/client'

export const PURCHASE_FEEDBACK_FRAGMENT = gql`
  fragment PURCHASE_FEEDBACK_FRAGMENT on PurchaseFeedback {
    id
    purchase
    customer
    vendor
    deliveryQuantitative
    deliveryQualitative
    productsQuantitative
    productsQualitative
    productsVoided
    createdAt
  }
`
