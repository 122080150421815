import { gql } from '@apollo/client'

import { RIDER_WORKSHIFT_FRAGMENT } from './'

export const START_RIDER_WORKSHIFT = gql`
  mutation startRiderWorkShift($rider: ID, $cash: Int!, $note: String, $time: Date, $setToAvailable: Boolean){
    startRiderWorkShift(rider: $rider, cash: $cash, note: $note, time: $time, setToAvailable: $setToAvailable){
      ...RIDER_WORKSHIFT_FRAGMENT
    }
  }
  ${RIDER_WORKSHIFT_FRAGMENT}
`
