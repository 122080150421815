import React from 'react'

import { Actions, Button } from '../'

import './style.css'

function Steps({className = "", children, ...rest}){
  return(
    <div className={`steps ${className}`} {...rest}>
      {children}
    </div>
  )
}

function StepActions({children, ...rest}){
  return(
    <Actions className="step-actions" full {...rest}>
      {children}
    </Actions>
  )
}

function Next({change, selected, className = "", children, ...rest}){
  return (
    <Button onClick={() => change(selected+1)} className={`next ${className}`} >
      {children ? children : <span>Next</span>}
    </Button>
  )
}

function Prev({change, selected, className = "", children, ...rest}){
  return (
    <Button onClick={() => change(selected-1)} className={`prev ${className}`} >
      {children ? children : <span>Previous</span>}
    </Button>
  )
}

function Submit({className = "", children, onClick, loading, theme = "main"}){
  return (
    <Button className={`submit ${className}`} theme={theme} onClick={onClick} loading={loading}>
      {children ? children : <span>Submit</span>}
    </Button>
  )
}

function Content({change, selected, step, className = "", children, ...rest}){
  if (selected !== step) return null

  return(
    <div className={`step-content ${className}`}>
      {children}
    </div>
  )
}

export function Nav({change, selected, steps}){
  return(
    <div className="steps-nav">
      {steps.map((item, key) =>
        <div key={key} className={`step ${(key+1) === selected && "active"} ${(key+1) < selected && "done"}`} onClick={() => change(key)}>
          <div>
            <span className="number">{++key}</span>
            <span className="label">{item}</span>
          </div>
          <span className="separator"></span>
        </div>
      )}
    </div>
  )

}

const steps =  { Steps, Content, Nav, Actions: StepActions, Next, Prev, Submit }

export default steps
