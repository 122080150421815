import { gql } from '@apollo/client'

export const PURCHASE_QUOTE_FRAGMENT = gql`
  fragment PURCHASE_QUOTE_FRAGMENT on PurchaseQuote {
    id
    deliveryDistance
    deliveryAvailable
    deliveryUnavailableReason
    isTomatoOpen
    isVendorOpen
    vendorClosesInSeconds
    customerBalance
    promotions{
      originalId
      owner
      name
      description{
        en
        es
      }
      label{
        en
        es
      }
    }
    currencyPaymentAvailable
    cashPaymentAvailable
    conektaPaymentAvailable
    mercadopagoPaymentAvailable
    fullBalancePaymentAvailable
    networkTransactionsDisabledForVendor
    stripePaymentAvailable
    stripeClientSecret
    stripePaymentId
    unavailableProducts
    unavailableAddonOptions    
    freeDeliveryIsApplicable
    customerHasCel
    isVerifiedCustomer
    forcedCelVerification
    forcedCelVerificationReason
    price{
      subtotal
      serviceFeeCustomer
      discount
      deliveryFeeBase
      deliveryFeeDistance
      deliveryFeeTotal
      transactionFee
      tax
      total
      commissionFreeTotal
      currency
      applicableExchangeRate
      totalInCurrency
      grossTip
      customerCashBalanceAdjustment
      chargeInCash
      chargeOnCard
      cashChargeInCurrency
    }
    status
    payment
    currency
    flow
    customer
    vendor
  }
`
