import React, { useState, useContext } from 'react'
import { useMutation, useQuery } from '@apollo/client'
import { toast } from 'react-toastify'

import { EDIT_PRODUCT_MUTATION, CREATE_PRODUCT_MUTATION, ME_QUERY, PRODUCTS_QUERY } from '../../../graphql'
import { IntlContext } from '../../../contexts'
import { useValidatedForm } from '../../../hooks'
import { sentenceCase, returnGraphqlError } from '../../../helpers'
import { LabeledInput, Button, LabeledCheckbox, IntlText, Validation, Loader, Form } from '../../../components'
import { CategorySelect } from '../../../views'

import './style.css'

const validations = {
  "category": [{id: "required", validation: (val) => !!val}],
  "nameEn": [{id: "required", validation: (val) => !!val}, {id: "max_length", validation: (val) => !(val?.length>70)}],
  "nameEs": [{id: "required", validation: (val) => !!val}, {id: "max_length", validation: (val) => !(val?.length>70)}],
  "descriptionEn": [{id: "max_length", validation: (val) => !(val?.length>200)}],
  "descriptionEs": [{id: "max_length", validation: (val) => !(val?.length>200)}],
  "price": [{id: "min0", validation: (val) => val >= 0}],
  "discountedPrice": [{id: "lessThanPrice", validation: (val, {price, discount}) => {
    return (!discount || (parseInt(val) > 0 && parseInt(price) < parseInt(val)))
  }}]
}

export function ProductDetails({actions, product, onSuccess, refetch, buttonText}) {

  const { getTranslation } = useContext(IntlContext)
  const { data, error, loading } = useQuery(ME_QUERY)
  const [editProduct, { loading: editLoading }] = useMutation(EDIT_PRODUCT_MUTATION)
  const [createProduct, { loading: createLoading }] = useMutation(CREATE_PRODUCT_MUTATION, {refetchQueries: [{query: PRODUCTS_QUERY}]})
  const { form: {nameEn, nameEs, descriptionEn, descriptionEs, price, discount, discountedPrice, maxPurchaseQuantity, minPurchaseQuantity, category}, updateField, errors, validate, updateFields } = useValidatedForm({
    nameEn: product?.name?.en,
    nameEs: product?.name?.es,
    descriptionEn: product?.description?.en,
    descriptionEs: product?.description?.es,
    price: product?.price,
    freeDeliveryAvailable: product?.freeDeliveryAvailable,
    freeDeliveryFixRate: product?.freeDeliveryFixRate,
    maxPurchaseQuantity: product?.maxPurchaseQuantity,
    minPurchaseQuantity: product?.minPurchaseQuantity,
    discount: product?.discount,
    discountedPrice: product?.discountedPrice,
    category: product?.category
  }, validations)
  const [quantityLimits, toggleQuantityLimits] = useState(!!maxPurchaseQuantity || !!minPurchaseQuantity || false)

  async function save(){
    let { valid } = validate()

    if (!valid) return toast.error(getTranslation({group: "form", id: "error"}))

    let data = {
      discount,
      name: {
        es: nameEs,
        en: nameEn
      },
      description: {
        es: descriptionEs,
        en: descriptionEn
      },
      price: parseInt(price),
      category,
      maxPurchaseQuantity: parseInt(maxPurchaseQuantity),
      minPurchaseQuantity: parseInt(minPurchaseQuantity),
      discountedPrice: parseInt(discountedPrice)
    }

    if (product?.id){
      await edit(data)
    } else {
      await create(data)
    }
  }

  async function create(data){
    try {
      let _product = await createProduct({variables: {data}})
      toast.success(getTranslation({id: "save-success"}))
      if (refetch) refetch()
      if (onSuccess) onSuccess(_product?.data?.createProduct)
    } catch (e) {
      returnGraphqlError(e)
    }
  }

  async function edit(data){
    try {
      let _product = await editProduct({variables: {id: product.id, data}})
      toast.success(getTranslation({id: "edit-success"}))
      if (onSuccess) onSuccess(_product?.data?.editProduct)
    } catch (e) {
      returnGraphqlError(e)
    }
  }

  function enableQuantityLimits(){
    updateFields({fields: [{key: "minPurchaseQuantity", value: 0}, {key: "maxPurchaseQuantity", value: 0}]})
    toggleQuantityLimits(!quantityLimits)
  }

  if (loading) return <Loader theme="main" />
  if (error) return returnGraphqlError(error)

  const { me } = data

  return(
    <div id="product-details">
      <Form.Form>
        <div className="warning"><IntlText group="form" id="responsibility-warning" /></div>
        <Form.Field>
          <Validation errors={errors["category"]}>
            <CategorySelect owner={product?.owner} category={category} onChange={(_category) => updateField({key: "category", value: _category.id})}/>
          </Validation>
        </Form.Field>
        <Form.Field>
          <Validation errors={errors["nameEn"]} variables={{length: 70}}>
            <LabeledInput name="nameEn" placeholder={getTranslation({group: "form", id: "name-en"})} value={nameEn} onChange={(e) => updateField({key: "nameEn", value: sentenceCase(e.target.value)})} />
          </Validation>
        </Form.Field>
        <Form.Field>
          <Validation errors={errors["descriptionEn"]} variables={{length: 200}}>
            <LabeledInput name="descriptionEn" placeholder={getTranslation({group: "form", id: "description-en"})} value={descriptionEn} onChange={(e) => updateField({key: "descriptionEn", value: sentenceCase(e.target.value)})} />
          </Validation>
        </Form.Field>
        <Form.Field>
          <Validation errors={errors["nameEs"]} variables={{length: 70}}>
            <LabeledInput name="nameEs" placeholder={getTranslation({group: "form", id: "name-es"})} value={nameEs} onChange={(e) => updateField({key: "nameEs", value: sentenceCase(e.target.value)})} />
          </Validation>
        </Form.Field>
        <Form.Field>
          <Validation errors={errors["descriptionEs"]} variables={{length: 200}}>
            <LabeledInput name="descriptionEs" placeholder={getTranslation({group: "form", id: "description-es"})} value={descriptionEs} onChange={(e) => updateField({key: "descriptionEs", value: sentenceCase(e.target.value)})} />
          </Validation>
        </Form.Field>
        <Form.Field className="price-field">
          <Validation errors={errors["price"]}>
            <LabeledInput name="price" placeholder={getTranslation({group: "form", id: "price"})} value={price} onChange={(e) => updateField({key: e.target.name, value: e.target.value})} disabled={discount}/>
          </Validation>
          <div className="warning"><IntlText group="form" id="vendor-price-info" variables={{price: ((price*(1-me?.serviceFee)) || 0).toFixed(2)}} /></div>
        </Form.Field>
        <Form.Field>
          <LabeledCheckbox rounded id="discount" name="discount" checked={!!discount} onChange={(e) => updateField({key: e.target.name, value: e.target.checked})} placeholder={getTranslation({group: "form", id: "discount"})}/>
        </Form.Field>
        {!!discount &&
          <Form.Fieldset top>
            <Form.Field fluid className="price-field">
              <Validation errors={errors["price"]}>
                <LabeledInput name="price" placeholder={getTranslation({group: "form", id: "price"})} value={price} onChange={(e) => updateField({key: e.target.name, value: e.target.value})} />
              </Validation>
              <div className="warning"><IntlText group="form" id="vendor-price-info" variables={{price: (price*(1-me?.serviceFee) || 0).toFixed(2)}} /></div>
            </Form.Field>
            <Form.Field fluid>
              <Validation errors={errors["discountedPrice"]}>
                <LabeledInput name="discountedPrice" placeholder={getTranslation({group: "form", id: "originalPrice"})} value={discountedPrice} onChange={(e) => updateField({key: e.target.name, value: e.target.value})} />
              </Validation>
            </Form.Field>
          </Form.Fieldset>
        }
        <Form.Field>
          <LabeledCheckbox rounded id="quantity-limits" checked={quantityLimits} onChange={enableQuantityLimits} placeholder={getTranslation({group: "form", id: "purchase-qty"})}/>
        </Form.Field>
        {quantityLimits &&
          <Form.Fieldset>
            <Form.Field fluid>
              <LabeledInput name="minPurchaseQuantity" placeholder={getTranslation({group: "form", id: "purchase-qty-min"})} value={minPurchaseQuantity} onChange={(e) => updateField({key: e.target.name, value: e.target.value})} />
            </Form.Field>
            <Form.Field fluid>
              <LabeledInput name="maxPurchaseQuantity" placeholder={getTranslation({group: "form", id: "purchase-qty-max"})} value={maxPurchaseQuantity} onChange={(e) => updateField({key: e.target.name, value: e.target.value})} />
            </Form.Field>
          </Form.Fieldset>
        }
        <Form.Fieldset>
          {actions}
          <Form.Field fluid>
            <Button theme="main" fluid loading={editLoading || createLoading} onClick={save}>{buttonText ? buttonText : <IntlText id="save" />}</Button>
          </Form.Field>
        </Form.Fieldset>
      </Form.Form>
    </div>
  )
}
