import { gql } from '@apollo/client'

export const CUSTOMER_BOOK_FRAGMENT = gql`
  fragment CUSTOMER_BOOK_FRAGMENT on CustomerBook {
    id
    owner
    cashBalance
    tomatoPoints
    createdAt
    updatedAt
  }
`
