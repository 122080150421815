import { Title, IntlValue } from '../../../components'

import './style.css'

export function PurchasePromotions({purchase: {promotions}}) {
  
  return(
    <div id="purchase-promotions">
      {promotions?.map((promotion, key) => 
        <div key={key} className="promotions-title">
          <i className="fas fa-badge-percent"></i>
          <div className="info">
            <Title tag='h4'><IntlValue>{promotion.label}</IntlValue></Title>
          </div>
        </div>
      )}
    </div>      
  )
}