import { gql } from '@apollo/client'

import { VENDOR_FRAGMENT } from './'

export const VENDOR_QUERY = gql`
  query vendor($slug:String, $id:ID){
    vendor(slug: $slug, id:$id){
      ...VENDOR_FRAGMENT
    }
  }
  ${VENDOR_FRAGMENT}
`
