import React from 'react'

import { IntlText, IntlValue } from '../../../components'
import { formatPrice } from '../../../helpers'

import './style.css'

export function ReviewProductAddon({addon, options}) {

  return(
    <div id="review-product-addon">
      {addon &&
        <div className="selected-addon">
          <div className="name">
            <span><IntlValue>{addon.name}</IntlValue></span>
            <div className="details">
              <div className="detail"><span className="instruction"><IntlValue>{addon.instruction}</IntlValue></span></div>
              {addon.minSelect ? <div className="detail"><span><IntlText id="min-select" /></span><span className="value">{addon.minSelect}</span></div> : null}
              {addon.maxSelect ? <div className="detail"><span><IntlText id="max-select" /></span><span className="value">{addon.maxSelect}</span></div> : null}
            </div>
          </div>
          {(addon?.required || addon?.minSelect >= 1) &&
            <div className="required">
              <IntlText id="required" />
            </div>
          }
        </div>
      }
      {options?.length > 0 &&
        <div className="selected-options">
          <span><IntlText group="add-product-addon-modal" id="selected-options" /></span>
          <ul>
            {options.map((option, key) =>
              <li key={key} className="selected-option">
                <IntlValue>{option?.name}</IntlValue>
                {option?.price > 0 && <span>{formatPrice(option.price) || ""}</span>}
              </li>
            )}
          </ul>
        </div>
      }
    </div>
  )
}
