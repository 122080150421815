import { gql } from '@apollo/client'

import { RIDER_FRAGMENT } from '../rider'

const RIDER_STATUS_CHANGED_SUBSCRIPTION = gql`
  subscription riderStatusChange{
    riderStatusChange{
      ...RIDER_FRAGMENT
    }
  }
  ${RIDER_FRAGMENT}
`

export default RIDER_STATUS_CHANGED_SUBSCRIPTION
