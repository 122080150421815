import { gql } from '@apollo/client'

export const RIDER_WORKSHIFT_FRAGMENT = gql`
  fragment RIDER_WORKSHIFT_FRAGMENT on RiderWorkShift {
    id
    rider{
      originalId
      name
    }
    date
    start
    end
    status
    account{
      revenue{
        id
        title
        riderCollectible
        completedAt
      }
      modifications{
        id
        purchaseId
        title
        value
        reason
        status
      }
      tips{
        purchaseId
        title
        value
      }
      totalRevenue
      totalModifications
      totalTips
      openingBalance
      completedDeliveries
      netRiderBalance
      collectedCash
      deficit
    }
    note
    createdAt
  }
`
