import React from 'react'

import { Link } from '../'

import './style.css'

export function SlidingMenu({menu = [], base = "/"}){

    return(
        <div className='sliding-menu'>
            {menu.map((item, key) => 
                <Link key={key} to={`${base}${item.to}`}>
                    {item.label}
                </Link>
            )}
        </div>
    )
}