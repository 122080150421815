import React from 'react'
import { useQuery } from '@apollo/client'
import { useLocation } from 'react-router-dom'

import { useQueryBuilder } from '../../hooks'

import { DeletedCustomersList } from '../../views'
import { Wrapper, Filters } from '../../components'
import { DELETED_CUSTOMERS_QUERY } from '../../graphql'

export function DeletedCustomers() {

  const location = useLocation()
  const search = new URLSearchParams(location.search)
  const { params, addParam, removeParam, buildQuery, runQuery } = useQueryBuilder({
    data: {
      query: {key: "query", label: `Search for ${search.get("query")}`, value: search.get("query")}
    }
  })

  const { data, loading, error, refetch } = useQuery(DELETED_CUSTOMERS_QUERY, {variables: buildQuery(), notifyOnNetworkStatusChange: true})

  function onSearch(e){
    if (e.key !== 'Enter') return false
    runQuery()
  }

  if (error) return `Error! ${error.message}`

  return(
    <Wrapper>
      <div id="deleted-customers" className="container">
        <div className="row padding">
          <Filters.Bar>
            <Filters.Input id="query" name="query" value={params?.query?.value} onChange={({target}) => addParam({query: {value: target?.value, id: "search", label: `Search for ${target.value}`, skip: !target?.value}})} reset={() => removeParam("query")} placeholder="Type here for searching" onKeyDown={onSearch}/>
          </Filters.Bar>
          <DeletedCustomersList loading={loading} data={data?.deletedCustomers || []} refetch={refetch}/> 
        </div>
      </div>
    </Wrapper>
  )
}
