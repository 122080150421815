import { gql } from '@apollo/client'

const IMPORT_MUTATION = gql`
  mutation import($file: Upload!, $type: IMPORT_TYPE!, $vendor: ID, $delimiter: String){
    import(file: $file, type: $type, vendor: $vendor, delimiter: $delimiter){
      success
    }
  }
`

export default IMPORT_MUTATION
