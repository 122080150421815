import { gql } from '@apollo/client'

import { CHANGELOG_FRAGMENT } from './'

export const CHANGELOGS = gql`
  query changelogs($type: CHANGELOG_TYPE, $after: String){
    changelogs(type: $type, after: $after){
      ...CHANGELOG_FRAGMENT
    }
  }
  ${CHANGELOG_FRAGMENT}
`
