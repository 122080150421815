import React, { useContext } from 'react'
import FontAwesome from 'react-fontawesome'
import { useMutation } from '@apollo/client'

import { EDIT_ADDON_OPTION_MUTATION, DELETE_PRODUCT_ADDON_MUTATION, PRODUCT_QUERY } from '../../../graphql'
import { useConfirm, useIntl } from '../../../hooks'
import { formatPrice, toast } from '../../../helpers'
import { ModalContext } from '../../../contexts'
import { Title, Checkbox, IntlValue, Loader, Button, IntlText } from '../../../components'

import './style.css'

export function ProductAddons({product: {id, addons}}) {

  const { confirm } = useConfirm()
  const { getTranslatedValue, getTranslation } = useIntl()
  const { openModal } = useContext(ModalContext)
  const [editAddonOption, { loading: editLoading }] = useMutation(EDIT_ADDON_OPTION_MUTATION)
  const [remove] = useMutation(DELETE_PRODUCT_ADDON_MUTATION)

  async function toggleAddonOption(option){
    try {
      await editAddonOption({variables: {id: option.id, data: {disabled: !option.disabled}}})
      return toast.success("Successfully changed")
    } catch (e) {
      e.graphQLErrors.map(x => toast.error(x.message))
    }
  }

  async function _delete(productAddonId){
    const alert = toast.loading(getTranslation({group: "confirm", id: "confirm-to-continue"}))
    try {
      await confirm({title: getTranslation({group: "confirm", id: "delete"}), desc: getTranslation({group: "confirm", id: "delete-info"})})
      alert.update(getTranslation({id: "loading"}))
      await remove({variables: {id: productAddonId}, refetchQueries: [{query: PRODUCT_QUERY, variables: {id}}]})
      alert.success(getTranslation({id: "delete-success"}))
    } catch (error) {
      alert.apolloError(error)
    }
  }    

  return(
    <div id="product-addons">
      {editLoading && <Loader theme="main" overlay />}
      <div className="actions">
        <Button theme="main" onClick={() => openModal("add-product-addon", {id})}><FontAwesome name="plus-circle" /><IntlText group="product-addons" id="add-new-addon">Add new addon</IntlText></Button>
      </div>
      {addons.map(({addon, options, id: productAddonId}, key) =>
        <div key={key} className="product-addon">
          <div className="product-addon-label">
            <Title tag="h3"><IntlValue>{addon.label}</IntlValue></Title>
            <div className="quantity-limits">{`min: ${addon.minSelect} max: ${addon.maxSelect}`}</div>
            <div className="actions">
              <Button theme="alt" onClick={() => openModal("add-product-addon-option", {addon, options, productAddonId: productAddonId, id})}><FontAwesome name="edit" /><IntlText id="edit" /></Button>
              <Button theme="reject" onClick={() => _delete(productAddonId)}><FontAwesome className="delete" name="trash" /><IntlText id="delete" /></Button>
            </div>
          </div>
          <div className="product-addon-options">
            {options.map((option, key) =>
              <div key={key} className="product-addon-option">
                <Checkbox id={addon.id + "-" + option.id} label={getTranslatedValue(option.name)} checked={!option.disabled} onChange={() => toggleAddonOption(option)}/>
                {option.price > 0 && <span className="price">{formatPrice(option.price)}</span>}
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  )
}
