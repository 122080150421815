import React from 'react'
import FontAwesome from 'react-fontawesome'

import { Button } from '../../components'

import './style.css'

export function Pagination({setPage, pageInfo}){

  if (!pageInfo || !(pageInfo?.totalPages > 1)) return null

  return(
    <div className="pagination">
      <div className="left">
        <Button onClick={() => setPage(parseInt(pageInfo?.actualPage)-1)} disabled={!(pageInfo?.actualPage > 1)}><FontAwesome name="angle-double-left"/></Button>
      </div>
      <div className="label">
        <div>Page {parseInt(pageInfo?.actualPage)}/{pageInfo?.totalPages}</div>
        <div className="total-results">
          ({pageInfo?.totalNodes} total results)
        </div>
      </div>
      <div className="right">
        <Button onClick={() => setPage(parseInt(pageInfo?.actualPage)+1)} className="left" disabled={!pageInfo?.hasNextPage}><FontAwesome name="angle-double-right"/></Button>
      </div>
    </div>
  )

}
