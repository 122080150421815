import { gql } from '@apollo/client'

export const PAGE_FRAGMENT = gql`
  fragment PAGE_FRAGMENT on Page {
    id
    slug
    hidden
    template
    title{
      en
      es
    }
    description{
      en
      es
    }
    content{
      en
      es
    }
    createdAt
    tags
  }
`
