import { useMutation, useQuery } from '@apollo/client'

import { VENDOR_USERS_QUERY, EDIT_VENDOR_USER, DELETE_VENDOR_USER } from '../../../graphql'
import { useIntl } from '../../../hooks'
import { toast } from '../../../helpers'
import { IntlText, Loader, Switch, Table, ContextMenu } from '../../../components'

export function VendorUsers({vendor}) {

  const { getTranslation } = useIntl()
  const { loading, data, error, refetch } = useQuery(VENDOR_USERS_QUERY, {variables: {vendor: vendor?.id}})
  const [edit] = useMutation(EDIT_VENDOR_USER)
  const [deleteUser] = useMutation(DELETE_VENDOR_USER)

  if (loading || error) return <Loader theme='main' />

  async function onChange(variables){
    const alert = toast.loading()
    try {
      await edit({variables})
      alert.success(getTranslation({id: "edit-success"}))
    } catch (error) {
      alert.apolloError(error)
    }
  }

  async function _delete(id){
    const alert = toast.loading()
    try {
      await deleteUser({variables: {id}})
      alert.success(getTranslation({id: "delete-success"}))
      refetch()
    } catch (error) {
      alert.apolloError(error)
    }
  }

  return(
    <div id="vendor-users">
        <Table.Table>
          <Table.Head>
            <Table.Tr>
              <Table.Th><IntlText group='vendor-users' id='name' /></Table.Th>
              <Table.Th><IntlText group='vendor-users' id='canReadPurchases' /></Table.Th>
              <Table.Th><IntlText group='vendor-users' id='canEditPurchases' /></Table.Th>
              <Table.Th><IntlText group='vendor-users' id='canReadFeedback' /></Table.Th>
              <Table.Th><IntlText group='vendor-users' id='canSetProductAvailability' /></Table.Th>
              <Table.Th></Table.Th>
              <Table.Th></Table.Th>
            </Table.Tr>
          </Table.Head>
          <Table.Body>
            {data?.vendorUsers?.map((vendorUser) => 
              <Table.Tr key={`${vendorUser.id}-`}>
                <Table.Td>
                  {vendorUser.name}
                </Table.Td>
                <Table.Td>
                  <Switch rounded id={`${vendorUser.id}-canReadPurchases`} checked={vendorUser.canReadPurchases} onChange={() => onChange({id: vendorUser.id, data: {canReadPurchases: !vendorUser?.canReadPurchases}})} />
                </Table.Td>
                <Table.Td>
                  <Switch rounded id={`${vendorUser.id}-canEditPurchases`} checked={vendorUser.canEditPurchases} onChange={() => onChange({id: vendorUser.id, data: {canEditPurchases: !vendorUser?.canEditPurchases}})} />
                </Table.Td>
                <Table.Td>
                  <Switch rounded id={`${vendorUser.id}-canReadFeedback`} checked={vendorUser.canReadFeedback} onChange={() => onChange({id: vendorUser.id, data: {canReadFeedback: !vendorUser?.canReadFeedback}})} />
                </Table.Td>
                <Table.Td>
                  <Switch rounded id={`${vendorUser.id}-canSetProductAvailability`} checked={vendorUser.canSetProductAvailability} onChange={() => onChange({id: vendorUser.id, data: {canSetProductAvailability: !vendorUser?.canSetProductAvailability}})} />
                </Table.Td>
                <Table.Td>
                  <ContextMenu.Menu>
                    <ContextMenu.Link to={`./${vendorUser.id}`} icon="external-link-alt"><IntlText id="edit" /></ContextMenu.Link>
                    <ContextMenu.Button icon="trash" className='invalid' onClick={() => _delete(vendorUser.id) }><IntlText id="delete" /></ContextMenu.Button>
                  </ContextMenu.Menu>                  
                </Table.Td>
              </Table.Tr>
            )}
          </Table.Body>
        </Table.Table>
    </div>
  )
}
