import React from 'react'
import moment from 'moment'

import { formatPrice } from '../../../helpers'

export default function InvoiceDetails({invoice}) {

  return(
    <div id="invoice-details">    
      <table className="table full">
        <tbody>
          <tr>
            <th>Name</th>
            <td>{invoice.customer.name}</td>
          </tr>
          <tr>
            <th>RFC</th>
            <td>{invoice.rfc}</td>
          </tr>
          <tr>
            <th>Email</th>
            <td>{invoice.email}</td>
          </tr>
          <tr>
            <th>Address</th>
            <td>{invoice.address}</td>
          </tr>
          <tr>
            <th>Requested at</th>
            <td>{moment(invoice.createdAt).format("YYYY MMMM DD. HH:mm:ss")}</td>
          </tr>
          <tr>
            <th>Status</th>
            <td>{invoice.status}</td>
          </tr>
          <tr>
            <th>Invoice amount</th>
            <td>{formatPrice(invoice.amount)}</td>
          </tr>
          <tr>
            <th>Invoice</th>
            <td>{invoice.downloadlink ? <a href={invoice.downloadlink}>Download here</a> :  <span>Pending</span>}</td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}
