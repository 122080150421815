import React, { useContext, useEffect } from 'react'
import { useMutation } from '@apollo/client'

import { Modalv2 as Modal, Button, Actions, IntlText } from '../../../components'
import { AddProductAddonOption } from '../../../views'
import { EDIT_PRODUCT_ADDON_MUTATION, PRODUCT_QUERY } from '../../../graphql'
import { returnGraphqlError } from '../../../helpers'
import { useValidatedForm } from '../../../hooks'
import { ModalContext } from '../../../contexts'

export function AddProductAddonOptionModal({product}) {

  const [editProductAddon, { loading: editLoading }] = useMutation(EDIT_PRODUCT_ADDON_MUTATION)
  const { attributes, closeModal } = useContext(ModalContext)
  const { form: {options}, updateField } = useValidatedForm({options: attributes?.options})

  useEffect(() => {
    updateField({key: "options", value: attributes?.options})
    // eslint-disable-next-line
  }, [attributes])


  async function save(){
    try {
      let data = {
        options: options ? options.map((option) => option.id) : []
      }
      await editProductAddon({variables: {id: attributes?.productAddonId, data}, refetchQueries: [{query: PRODUCT_QUERY, variables: {id: product.id}}]})
      closeModal()
    } catch (e) {
      returnGraphqlError(e)
    }
  }

  return(
    <Modal.Modal id="add-product-addon-option">
      {({attributes}) =>
        <Modal.Info>
          <Modal.Header><IntlText group="add-product-addon-option-modal" id="title" /></Modal.Header>
          <Modal.Content>
            <AddProductAddonOption product={product} options={options} onChange={(data) => updateField({key: "options", value: data.selected})}/>
          </Modal.Content>
          <Modal.Footer>
            <Actions>
              <Button onClick={closeModal}><IntlText id="back" /></Button>
              <Button loading={editLoading} theme="main" onClick={save}><IntlText id="save" /></Button>
            </Actions>
          </Modal.Footer>
        </Modal.Info>
      }
    </Modal.Modal>
  )
}
