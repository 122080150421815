import React, { useContext } from 'react'
import ReactModal from 'react-modal'

import { Title, CloseButton } from '../../components'
import { ModalContext } from '../../contexts'

import style from '../../style/modal'
import './style.css'

function Modal({id, title, children}) {

  const { isOpened, closeModal, attributes } = useContext(ModalContext)

  if (isOpened(id)) return(
    <ReactModal isOpen={isOpened(id)} style={style} onRequestClose={() => closeModal()} appElement={document.getElementById('root')}>
      <div id={`${id}-modal`} className="modal">
        {title ?
          <div className="modal-info">
            <ModalHeader>{title}</ModalHeader>
            <ModalContent>
              {children}
            </ModalContent>
          </div>
        :
          children({closeModal, attributes})
        }
      </div>
    </ReactModal>
  )

  return null
}

function ModalHeader({title, children}){

  const { closeModal } = useContext(ModalContext)

  return(
    <div className="modal-header">
      <Title tag="h2">{title || children}</Title>
      <CloseButton onClick={() => closeModal()}/>
    </div>
  )

}

function ModalContent({children}){

  return(
    <div className="modal-content">
      {children}
    </div>
  )

}

function ModalInfo({children}){

  return(
    <div className="modal-info">
      {children}
    </div>
  )

}

function ModalFooter({children}){

  return(
    <div className="modal-footer">
      {children}
    </div>
  )

}

const exportedObject = { Modal, Header: ModalHeader, Content: ModalContent, Info: ModalInfo, Footer: ModalFooter}

export default exportedObject
