import React, { useContext } from 'react'
import { useMutation } from '@apollo/client'

import { IntlContext } from '../../../contexts'
import { EDIT_RIDER_MUTATION } from '../../../graphql'
import { toast } from '../../../helpers'
import { Form, IntlText, EditBox } from '../../../components'
import { VerifiedDocument } from '../../../views'

export function RiderPaymentSettings({rider}){

  const { getTranslation } = useContext(IntlContext)
  const [editRider] = useMutation(EDIT_RIDER_MUTATION)

  async function save(data){
    const alert = toast.loading()

    try { 
      await editRider({variables: {id: rider.id, data}})
      alert.success(getTranslation({id: "edit-success"}))
    } catch (e) {
      alert.error(e.message)
    }
  }
  
  return(
    <div id="rider-details">
      <Form.Form>
        <Form.Field>
          <EditBox.Text placeholder={<IntlText group="rider-payment-settings" id="clabe" />} save={(value) => save({clabe: value})}>{rider?.clabe}</EditBox.Text>
        </Form.Field>        
        <Form.Field>
          <EditBox.Text placeholder={<IntlText group="rider-payment-settings" id="bank-name" />} save={(value) => save({bankName: value})}>{rider?.bankName}</EditBox.Text>
        </Form.Field>        
        <Form.Field>
          <EditBox.Text placeholder={<IntlText group="rider-payment-settings" id="account-holder" />} save={(value) => save({accountHolder: value})}>{rider?.accountHolder}</EditBox.Text>
        </Form.Field>        
        <Form.Field>
          <VerifiedDocument name={getTranslation({group: "rider-payment-settings", id:"bank-statement"})} url={rider?.bankStatement} status={rider?.bankStatementStatus} accept={() => save({bankStatementStatus: "ACCEPTED"})} reject={() => save({bankStatementStatus: "REJECTED"})} message={rider?.bankStatementMessage} editMessage={(_val) => save({bankStatementMessage: _val})} />
        </Form.Field>       
      </Form.Form>
    </div>
  )

}
