import React, { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useQuery } from '@apollo/client'

import { AddonsList  } from '../../../views'
import { Filters, Loader } from '../../../components'
import { ADDONS_QUERY } from '../../../graphql'
import { useQueryBuilder } from '../../../hooks'

export function VendorAddons({vendor}){

  const navigate = useNavigate()
  const location = useLocation()
  const search = new URLSearchParams(location?.search)
  const [filter, setFilter] = useState()
  const { getParams, removeParam, buildQuery, runQuery, clearParams } = useQueryBuilder({})
  const { data, loading, refetch } = useQuery(ADDONS_QUERY, {variables: {...buildQuery(), owner: vendor?.id}, fetchPolicy: "network-only"})

  function setSearch(str){
    if (str === "") return clearSearch()
    search.set("search", str)
    navigate({search: search.toString()})
    setFilter(str)
  }

  function clearSearch(){
    search.delete("search")
    navigate({search: search.toString()})
    setFilter("")
  }

  function onSearch(e){
    if (e.key !== 'Enter') return false
    runQuery()
  }

  return(
    <div id="addons">
      {loading && <Loader theme="main" overlay/>}
      <Filters.Bar vertical>
        <div key="query" className="left">
          <Filters.Input id="query" name="query" value={filter} onChange={({target}) => setSearch(target.value)} reset={() => clearSearch()} placeholder="Type here for searching" onKeyDown={onSearch}/>
        </div>
        <Filters.ActiveParams activeParams={getParams()} removeParam={removeParam} clearParams={clearParams} />
      </Filters.Bar>
      <AddonsList data={data} refetch={refetch} filter={filter}/>
    </div>
  )
}
