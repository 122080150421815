import React, { useContext } from 'react'
import { toast } from 'react-toastify'
import { useMutation } from '@apollo/client'
import { useNavigate, useParams } from 'react-router-dom'

import { useValidatedForm } from '../../hooks'
import { CREATE_FAQ } from '../../graphql'
import { IntlContext } from '../../contexts'
import { LabeledInput, HtmlEditor, IntlText, Validation, Wrapper, Select, LabeledCheckbox, Form, Grids } from '../../components'
import { FaqOptions, PageHeader } from '../../views'

export function NewFaq(){

  const topics = [
    {id: "CUSTOMER_GET_STARTED", label: "Customer get started"},
    {id: "CUSTOMER_PURCHASE", label: "Customer purchases"},
    {id: "CUSTOMER_REFUNDS", label: "Customer refunds"},
    {id: "VENDOR_ONBOARDING", label: "Vendor on boarding"},
    {id: "VENDOR_PURCHASE", label: "Vendor purchases"},
    {id: "VENDOR_REFUNDS", label: "Vendor refunds"},
    {id: "RIDER_REGISTRATION", label: "Rider registration"},
    {id: "RIDER_PURCHASES", label: "Rider purchases"},
    {id: "RIDER_PAYMENTS", label: "Rider payments"},
  ]


  const navigate = useNavigate()
  const { language } = useParams()
  const _language = language || "en"
  const { getTranslation } = useContext(IntlContext)
  const { form: { title, slug, content, topic, whatsappButton, hidden }, updateField, updateMultilangField, errors, getEditedData, resetForm } = useValidatedForm({hidden: true})
  const [createFaq, { loading }] = useMutation(CREATE_FAQ)

  async function _createFaq(){
    const editedData = getEditedData()
    const data = {
      title,
      content,
      topic: editedData?.topic?.id,
      hidden,
      whatsappButton
    }

    try {
      createFaq({variables: {data}})
      toast.success(getTranslation({id: "create-success"}))
      resetForm()
      navigate("/faqs")
    } catch (e) {
      e.graphQLErrors.map(x => toast.error(x.message))
    }
  }

  return(
    <Wrapper>
      <div id="new-faq" className="view">
        <div className="row padding">
          <PageHeader slug={slug} title={title} titlePlaceholder="New Faq" onChange={({target}) => updateField({key: target.name, value: target.value})} url="help/" />
          <Grids.Grids>
            <Grids.Grid>
              <Form.Form>
                <Form.Field>
                  <Validation errors={errors?.topic} style={{}}>
                    <Select placeholder="Topic" selected={topic} defaultValue="Please select" options={topics} onChange={(type) => updateField({key: "topic", value: type})} />
                  </Validation>
                </Form.Field>
                <Form.Field>
                  <Validation errors={errors?.title}>
                    <LabeledInput name="title" placeholder="Faq title" value={title?.[_language]} onChange={({target}) => updateMultilangField({key: target.name, value: target.value, language: _language})} />
                  </Validation>
                </Form.Field>
                <Form.Field>
                  <Validation errors={errors?.content}>
                    <HtmlEditor id={`editor-${_language}`} name="content" placeholder="FAQ content" value={content?.[_language]} onChange={({text, target}) => updateMultilangField({key: target.name, value: text, language: _language})}/>
                  </Validation>
                </Form.Field>
                <Form.Field>
                  <LabeledCheckbox id="hidden" name="hidden" placeholder="Hidden" checked={hidden} onChange={({target}) => updateField({key: target.name, value: target.checked})} rounded/>
                </Form.Field>
                <Form.Field>
                  <LabeledCheckbox id="whatsappButton" name="whatsappButton" placeholder="Show What's App button?" checked={whatsappButton} onChange={({target}) => updateField({key: target.name, value: target.checked})} rounded/>
                </Form.Field>
              </Form.Form>
            </Grids.Grid>
            <Grids.Grid className='sidebar'>
              <FaqOptions placeholder={<IntlText id="create" />} language={_language} save={_createFaq} loading={loading} url={`/faqs/new`} />
            </Grids.Grid>
          </Grids.Grids>
        </div>
      </div>
    </Wrapper>
  )
}
