import React from 'react'
import { useQuery } from '@apollo/client'
import { useParams } from 'react-router-dom'

import { Loader, Title, Tabs, Wrapper } from '../../components'
import { useTabs } from '../../hooks'
import { InvoiceStatus, InvoiceForm, InvoiceDetails, InvoiceUpload } from '../../views'
import { INVOICE_REQUEST_QUERY } from '../../graphql'

export function Invoice() {

  const { id } = useParams()
  const { loading, data, error } = useQuery(INVOICE_REQUEST_QUERY, {variables: {id}})
  const tabs = useTabs({defaultTab: "invoices"})

  if (loading) return <Loader theme="main"/>
  if (error) return `Error! ${error.message}`

  const { invoiceRequest } = data

  return(
    <Wrapper>
      <div id="invoice" className="container">
        <div className="row padding">
          <div className="heading">
            <div className="inner">
            <Title tag="h1">{invoiceRequest.name}</Title>
            <div className="desc">{invoiceRequest.id}</div>
            </div>
          </div>
          <div className="content">
            <div className="block">
              <InvoiceStatus invoice={invoiceRequest} />
            </div>
            <div className="block">
              <Tabs.Tabs>
                <Tabs.Menu>
                  <Tabs.Button id="details" tabs={tabs}>Details</Tabs.Button>   
                  <Tabs.Button id="problems" tabs={tabs}>Problems</Tabs.Button>   
                  <Tabs.Button id="upload" tabs={tabs}>Upload</Tabs.Button>   
                </Tabs.Menu>
                <Tabs.Content>
                  <Tabs.Tab id="details" tabs={tabs}>
                    <InvoiceDetails invoice={invoiceRequest} />
                  </Tabs.Tab>   
                  <Tabs.Tab id="problems" tabs={tabs}>
                    <InvoiceForm invoice={invoiceRequest}/>
                  </Tabs.Tab>   
                  <Tabs.Tab id="upload" tabs={tabs}>
                    <InvoiceUpload invoice={invoiceRequest}/>
                  </Tabs.Tab>   
                </Tabs.Content>   
              </Tabs.Tabs>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  )
}
