import React from 'react'

import './style.css'

export function SegmentedControl({className = "", value, onClick, options = []}){
    
    return(
        <div className={`segmented-control ${className}`}>
            {options.map((_option, key) => 
                <div key={key} className={`${_option.id === value ? "selected" : ""}`} onClick={() => onClick(_option)}>
                    <label>{_option.label}</label>
                </div>
            )}
        </div>
    )
    
}