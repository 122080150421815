import React, { useContext } from 'react'
import FontAwesome from 'react-fontawesome'

import { IntlContext } from '../../../contexts'
import { copyToClipboard } from '../../../helpers'
import { Title, Button } from '../../../components'

import './style.css'

export function PageHeader({title, titlePlaceholder, slug = "", url = "", onChange}){

  const { getTranslatedValue } = useContext(IntlContext)

  return(
    <div className="page-header">
      <div>
        <Title tag="h1">{getTranslatedValue(title) || titlePlaceholder}</Title>
        <div className="desc">
          <div className='url'>
            <span>{`${process.env.REACT_APP_CLIENT_HOME}/${url}`}</span>
            <input name="slug" placeholder="Slug" value={slug} onChange={onChange}  />
          </div>
          <div className='actions'>
            <Button size="small" onClick={() => copyToClipboard(`${process.env.REACT_APP_CLIENT_HOME}/${url}${slug}`)}><FontAwesome name="copy" /></Button>
          </div>
        </div>
      </div>
    </div>
  )
}
