import React from 'react'
import FontAwesome from 'react-fontawesome'

import { formatPrice } from '../../../helpers'
import { Title, IntlText } from '../../../components'

import './style.css'

export function CustomerReferralStats({customer}){

  return(
    <div>
      <Title tag="h3" styled><IntlText group="referred-customers" id="referral-stats"/></Title>
      <div className='referral-stats'>
        <div className='referral-stat'>
          <FontAwesome name='star' />
          <h5><IntlText group="referred-customers" id="referrerLevel"/></h5>
          <span className='value'>{customer?.referrerLevel}</span>
        </div>
        <div className='referral-stat'>
          <FontAwesome name='percent' />
          <h5><IntlText group="referred-customers" id="referrerPercentage"/></h5>
          <span className='value'>{customer?.referrerPercentage*100}%</span>
        </div>
        <div className='referral-stat'>
          <FontAwesome name='shopping-cart' />
          <h5><IntlText group="referred-customers" id="referrerPurchasesCount"/></h5>
          <span className='value'>{customer?.referrerPurchasesCount}</span>
        </div>
        <div className='referral-stat'>
          <FontAwesome name='sack-dollar' />
          <h5><IntlText group="referred-customers" id="totalReferrerIncome"/></h5>
          <span className='value'>{formatPrice(customer?.totalReferrerIncome)}</span>
        </div>
      </div>
    </div>
  )
}