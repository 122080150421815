import React from 'react'
import ReactDOM from 'react-dom'

import { useIntl } from './useIntl'
import { Confirm } from '../components'

export function useConfirm(){
    
    const { getTranslation } = useIntl()

    function confirm(props) {
        return new Promise((resolve, reject) => {
            ReactDOM.render(<Confirm resolve={resolve} reject={reject} title={props?.title || getTranslation({group:"confirm", id: "title"})} desc={props?.desc || getTranslation({group: "confirm", id: "desc"})} />, document.getElementById('notifications-portal'))
        })
    }

    return { confirm }

}