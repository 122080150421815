import { gql }from '@apollo/client'

import { VENDOR_STAT_FRAGMENT } from './'

export const VENDOR_STATS = gql`
  query vendorStats($vendor: ID, $start: Date!, $end: Date!, $periodicity: VENDOR_STAT_PERIOD!){
    vendorStats(vendor: $vendor, start: $start, end: $end, periodicity: $periodicity){
      ...VENDOR_STAT_FRAGMENT
    }
  }
  ${VENDOR_STAT_FRAGMENT}
`
