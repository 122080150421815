import { gql } from '@apollo/client'

import { RIDER_BREAK_FRAGMENT } from './'

export const RIDER_BREAKS = gql`
  query riderBreaks($date: String!){
    riderBreaks(date: $date){
      ...RIDER_BREAK_FRAGMENT
    }
  }
  ${RIDER_BREAK_FRAGMENT}
`
