import { gql } from '@apollo/client'

export const PROMOTION_FRAGMENT = gql`
  fragment PROMOTION_FRAGMENT on Promotion {
    id
    slug
    name
    description{
      en
      es
    }
    vendorDescription
    label{
      en
      es
    }
    type
    restrictions{
      key
      value
    }
    configuration{
      key
      value
    }
    level
    active
  }
`
