import React from 'react'
import FontAwesome from 'react-fontawesome'

import './StatusDot.css'

export default function StatusDot({active, pending}){

  return(
    <div className={`status-dot ${pending ? "pending" : active ? "active" : "inactive"}`}>
      <FontAwesome name="circle" />
    </div>
  )

}
