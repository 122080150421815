import { gql } from '@apollo/client'

export const REFERRED_CUSTOMERS = gql`
  query referredCustomers($customer: ID, $offset: Int!, $limit: Int!){
     referredCustomers(customer: $customer, offset: $offset, limit: $limit) {
      nodes{
         id
         name
         email
         referralSource
         validReferral
         referralProblem
         referralPurchasesCount
      }
      pageInfo{
         limit
         totalNodes
         firstNode
         lastNode
         totalPages
         actualPage
         hasNextPage         
      }
     }
  }
`
