import React, { useContext } from 'react'
import moment from 'moment'
import { es } from 'date-fns/locale'
import { DateRangePicker, Calendar } from 'react-date-range'

import { IntlContext } from '../../contexts'

import './styles.css' // main style file
import './default.css' // theme css file

function Range({startDate = new Date(), endDate = new Date(), ...rest}){

  const selectionRange = {
    startDate,
    endDate,
    key: 'selection',
 }
 const { selectedLanguage, getTranslation } = useContext(IntlContext)
 const definedRanges = [
   {
     label: getTranslation({group: "datepicker", id: "today"}),
     range: () => ({
       startDate: moment().startOf('day'),
       endDate: moment().endOf('day'),
     }),
   },
   {
     label: getTranslation({group: "datepicker", id: "yesterday"}),
     range: () => ({
       startDate: moment().subtract(1, 'days').startOf('day'),
       endDate: moment().subtract(1, 'days').endOf('day'),
     }),
   },
   {
     label: getTranslation({group: "datepicker", id: "this-week"}),
     range: () => ({
       startDate: moment().startOf('isoWeek'),
       endDate: moment().endOf('isoWeek'),
     }),
   },    
   {
     label: getTranslation({group: "datepicker", id: "last-week"}),
     range: () => ({
       startDate: moment().subtract(1, 'weeks').startOf('isoWeek'),
       endDate: moment().subtract(1, 'weeks').endOf('isoWeek'),
     }),
   },
   {
     label: getTranslation({group: "datepicker", id: "this-month"}),
     range: () => ({
       startDate: moment().startOf('month'),
       endDate: moment().endOf('month'),
     }),      
   },
   {
     label: getTranslation({group: "datepicker", id: "last-month"}),
     range: () => ({
       startDate: moment().subtract(1, 'month').startOf('month'),
       endDate: moment().subtract(1, 'month').endOf('month'),
     }),      
   },
   {
     label: getTranslation({group: "datepicker", id: "last-90"}),
     range: () => ({
       startDate: moment().subtract(90, 'days').startOf('day'),
       endDate: moment().add(1, "day"),
     }),      
   },
   {
     label: getTranslation({group: "datepicker", id: "this-year"}),
     range: () => ({
       startDate: moment().startOf('year'),
       endDate: moment(),
     }),      
   },
   {
     label: getTranslation({group: "datepicker", id: "last-year"}),
     range: () => ({
       startDate: moment().subtract(1, "year").startOf('year'),
       endDate: moment().subtract(1, "year").endOf('year'),
     }),      
   }
 ]

 const staticRangeHandler = {
   range: {},
   isSelected(range) {
     const definedRange = this.range();

     return moment(range.startDate).isSame(definedRange.startDate, 'day') && moment(range.endDate).isSame(definedRange.endDate, 'day')
   },
 }
 
 function createStaticRanges(ranges) {
   return ranges.map(range => ({ ...staticRangeHandler, ...range }));
 }
 

  return(
    <DateRangePicker
      locale={selectedLanguage.shortcode === "es" ? es : undefined}
      ranges={[selectionRange]}
      staticRanges={createStaticRanges(definedRanges)}
      inputRanges={[]}
      weekStartsOn={1}
      showDateDisplay={false}
      {...rest}
    />
  )

}

function Date({date, fluid, ...rest}){
  return(
    <div className={`date-picker ${fluid ? "fluid" : ""}`}>
      <Calendar
        showDateDisplay={false}
        weekStartsOn={1}
        date={date}
        {...rest}
        />
    </div>
  )

}

const DatePicker = { Range, Date }

export default DatePicker
