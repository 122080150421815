import React from 'react'
import FontAwesome from 'react-fontawesome'
import { useQuery } from '@apollo/client'

import { CUSTOMER_BOOK } from '../../../graphql'
import { Loader } from '../../../components'
import { tomato } from '../../../assets'

import './style.css'

export function CustomerBalance({customer}) {

  const { data, loading, error } = useQuery(CUSTOMER_BOOK, {variables: {customer}})

  if (loading) return <Loader theme="main" />
  if (error) return <Loader theme="main" />

  const { customerBook } = data

  return(
    <div className="customer-balance">
      <div className="balance">
        <FontAwesome className="icon" name="dollar-sign" />
        <span className="value">{customerBook?.cashBalance || 0}</span>
      </div>
      <div className="balance">
        <img alt="tomato points" src={tomato} className="icon" />
        <span className="value">{customerBook?.tomatoPoints || 0}</span>
      </div>
    </div>
  )
}
