import { gql } from '@apollo/client'

import { PURCHASE_FRAGMENT } from './'

export const PROCESS_QUEUED_PURCHASE = gql`
  mutation processQueuedPurchase($id: ID!){
    processQueuedPurchase(id: $id) {
        ...PURCHASE_FRAGMENT
     }
  }
  ${PURCHASE_FRAGMENT}
`
