import { useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import FontAwesome from 'react-fontawesome'

import { useIntl, useModal } from '../../hooks'
import { LanguageTabs } from '../../views'
import { Modalv3, Button as UIButton, IntlText, Title } from '../'

import "./style.css"

function Button({children, label, desc, ...rest}){

  return(
    <div className="boxed boxed-button">
      <div className='label'>
        <span>{label}</span>
        <span>{desc}</span>
      </div>
      <div className='value'>
        <UIButton {...rest}>{children}</UIButton>
      </div>
    </div>
  )
}

function Text({children, icon, label, desc, value, onChange}){

  const modal = useModal()
  const [_value, setValue] = useState(children || value)

  function _onChange(){
    onChange(_value)
    modal.hide()
  }

  function showIcon(){
    switch (typeof icon) {
      case "string":
        return <div className="icon"><FontAwesome name={icon} /></div>
      default:
        return <div className="icon">{icon}</div>
    }
  }    

  return(
    <div className="boxed boxed-button">
      {icon ? 
        <span className='icon'>{showIcon()}</span>
      :null}
      <div className='label'>
        <span>{label}</span>
        {desc ? 
          <span className='desc'>{desc}</span>
        :null}
      </div>
      <div className='value'>{children || value}</div>
      {onChange ?
        <UIButton icon="edit" theme="alt" onClick={modal.show}/>
      : null}
      <Modalv3 id="boxed-text" modal={modal} header={<Title tag='h3'>{label}</Title>}>
        <input value={_value} onChange={({target}) => setValue(target.value)} />
        <div className='actions' style={{marginTop: 15}}>
          <UIButton onClick={modal.hide}><IntlText id="cancel" /></UIButton>
          <UIButton theme="main" onClick={_onChange}><IntlText id="save" /></UIButton>
        </div>
      </Modalv3>
    </div>
  )
}


function IntlTextComp({children, icon, label, desc, value, onChange}){

  const modal = useModal()
  const [searchParams] = useSearchParams()
  const { selectedLanguage, getTranslatedValue } = useIntl()
  const [_value, setValue] = useState({en: children?.en || value?.en, es: children?.es || value?.es})
  const language = searchParams.get('lang') || selectedLanguage?.shortcode || 'en'

  function onSave(){
    onChange(_value)
    modal.hide()
  }

  function _onChange({target}){
    setValue({
      en: _value?.en,
      es: _value?.es,
      [language]: target.value
    })
  }

  function showIcon(){
    switch (typeof icon) {
      case "string":
        return <div className="icon"><FontAwesome name={icon} /></div>
      default:
        return <div className="icon">{icon}</div>
    }
  }    

  return(
    <div className="boxed boxed-button">
      {icon ? 
        <span className='icon'>{showIcon()}</span>
      :null}      
      <div className='label'>
        <span>{label}</span>
        {desc ? 
          <span className='desc'>{desc}</span>
        :null}
      </div>
      <div className='value'>{getTranslatedValue(_value)}</div>
      <UIButton icon="edit" theme="alt" onClick={modal.show}/>
      <Modalv3 id="boxed-text" modal={modal} header={<Title tag='h3'>{label}</Title>}>
        <LanguageTabs />
        <div className="grids">
          <div className="grid">
            <textarea value={_value?.[language] || ""} onChange={_onChange} />
          </div>
        </div>
        <div className='actions' style={{marginTop: 15}}>
          <UIButton onClick={modal.hide}><IntlText id="cancel" /></UIButton>
          <UIButton theme="main" onClick={onSave}><IntlText id="save" /></UIButton>
        </div>
      </Modalv3>
    </div>
  )
}


const editbox = { Button, Text, IntlText: IntlTextComp }

export default editbox