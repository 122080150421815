import React from 'react'
import { toast } from 'react-toastify'
import { useMutation } from '@apollo/client'

import { useValidatedForm } from '../../../hooks'
import { EDIT_INVOICE_MUTATION } from '../../../graphql'
import { Button, IntlText, Loader, Validation, LabeledTextarea, Select } from '../../../components'

import './InvoiceForm.css'

const reasons = [
  {id: "INCORRECT_DATA", label: "Incorrect data", status: "INREVISION"},
  {id: "PURCHASE_NOT_PAID", label: "Purchase not paid", status: "REJECTED"},
  {id: "OTHER", label: "Other", status: "DISPUTE"}
]

const validations = {
  note: [{id: "required", validation: (val) => !!val}],
  problem: [{id: "required", validation: (val) => !!(val && val.id && val.status)}],
}

export default function InvoiceForm({invoice}) {

  const { form: {problem, note}, updateField, errors, validate, resetForm } = useValidatedForm({}, validations)
  const [editInvoice, { loading }] = useMutation(EDIT_INVOICE_MUTATION)

  async function _editInvoice(){
    let { valid } = validate()

    if (!valid) return toast.error("The are errors in the form, please check it.")

    try {
      await editInvoice({variables: {id: invoice.id, data: {note, statusNote: problem.id, status: problem.status}}})
      toast.success(`Note successfully added!`)
      resetForm()
    } catch (e) {
      e.graphQLErrors.map(x => toast.error(x.message))
    }
  }

  return(
    <div id="invoice-form">
      {loading && <Loader theme="main" overlay/>}
      <div className="form">
        <Validation errors={errors.problem}>
          <Select placeholder="Problem" selected={problem} defaultValue="Please select" options={reasons} onChange={(type) => updateField({key: "problem", value: type})} />
        </Validation>
        <Validation errors={errors.note}>
          <LabeledTextarea name="note" placeholder="Note" value={note} onChange={(e) => updateField({key: "note", value: e.target.value})} />
        </Validation>
        <Button theme="main" loading={loading} onClick={_editInvoice}><IntlText group="invoice-form" id="submit">Add note</IntlText></Button>
      </div>
    </div>
  )
}
