import { gql }from '@apollo/client'

export const VENDOR_STAT_FRAGMENT = gql`
  fragment VENDOR_STAT_FRAGMENT on VendorStat {
    id
    vendor
    period
    start
    end
    completedPurchases
    completedPurchasesChange
    completedPurchasesChangePercentage
    completedCashPurchases
    completedCashPurchasesChange
    completedCashPurchasesChangePercentage
    completedCardPurchases
    completedCardPurchasesChange
    completedCardPurchasesChangePercentage
    cancelledPurchases
    cancelledPurchasesChange
    cancelledPurchasesChangePercentage
    rejectedPurchases
    rejectedPurchasesChange
    rejectedPurchasesChangePercentage
    unprocessedPurchases
    unprocessedPurchasesChange
    unprocessedPurchasesChangePercentage
    unprocessedPurchasesRatio
    grossIncome
    grossIncomeChange
    grossIncomeChangePercentage
    grossIncomeCash
    grossIncomeCashChange
    grossIncomeCashChangePercentage
    grossIncomeCard
    grossIncomeCardChange
    grossIncomeCardChangePercentage
    netIncome
    netIncomeChange
    netIncomeChangePercentage
    netIncomeCash
    netIncomeCashChange
    netIncomeCashChangePercentage
    netIncomeCard
    netIncomeCardChange
    netIncomeCardChangePercentage
    servicefee
    servicefeeChange
    servicefeeChangePercentage
    servicefeeCash
    servicefeeCashChange
    servicefeeCashChangePercentage
    servicefeeCard
    servicefeeCardChange
    servicefeeCardChangePercentage
    vendorPriceAdjustmentByCustomerCashBalance
    aov
    aovChange
    aovChangePercentage
    feedbacks
    positiveFeedbacks
    negativeFeedbacks
    neutralFeedbacks
    mostOrderedProducts{
      name{
        en
        es
      }
      categoryName{
        en
        es
      }
      rank
      count
    }
  }
`
