import React from 'react'
import { toast } from 'react-toastify'
import { useMutation } from '@apollo/client'

import { UPLOAD_INVOICE_MUTATION } from '../../../graphql'
import { useValidatedForm } from '../../../hooks'
import { FileUploader, Validation, Button } from '../../../components'

import './InvoiceUpload.css'

export default function InvoiceUpload({invoice}) {

  const validations = {
    file: [{id: "required", validation: (val) => !!val}],
  }

  const { form: { file }, updateField, errors, validate, resetForm } = useValidatedForm({}, validations)
  const [uploadInvoice, { loading }] = useMutation(UPLOAD_INVOICE_MUTATION)

  async function _uploadInvoice(){
    let { valid } = validate()

    if (!valid) return toast.error("The are errors in the form, please check it.")

    try {
      await uploadInvoice({variables: {invoiceRequest: invoice.id, file}})
      toast.success(`Successfully upload!`)
      resetForm()
    } catch (e) {
      e.graphQLErrors.map(x => toast.error(x.message))
    }
  }


  return(
    <div id="invoice-upload">
      <Validation errors={errors.file}>
        <FileUploader id="import-file" value={file} onChange={(e) => updateField({key: "file", value: e.target.files[0]})}/>
      </Validation>
      <Button theme="main" loading={loading} className="full" onClick={_uploadInvoice}>Upload</Button>
    </div>
  )
}
