import { gql } from '@apollo/client'

import { PRODUCT_FRAGMENT } from './'

export const PRODUCTS_QUERY = gql`
  query products($owner: String, $category: String){
     products(owner: $owner, category: $category) {
       ...PRODUCT_FRAGMENT
     }
  }
  ${PRODUCT_FRAGMENT}
`
