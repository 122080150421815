import { gql } from '@apollo/client'

export const PURCHASES_VENDOR_FRAGMENT = gql`
  fragment PURCHASES_VENDOR_FRAGMENT on PurchaseVendor {
    originalId
    name
    slug
    contract
    type
    serviceFee
    whatsappGroup
  }
`
