import { gql } from '@apollo/client'

import { VEHICLE_FRAGMENT } from './'

export const VEHICLES = gql`
  query vehicles($owner: String!){
    vehicles(owner: $owner){
      ...VEHICLE_FRAGMENT
    }
  }
  ${VEHICLE_FRAGMENT}
`
