import React, { useContext, useState } from 'react'
import moment from 'moment'
import FontAwesome from 'react-fontawesome'

import { useModal, useValidatedForm } from '../../../hooks'
import { toast } from '../../../helpers'
import { IntlContext } from '../../../contexts'
import { Button, IntlText, Badge, ContextMenu, Modalv3, Title, DatePicker, LabeledInput, Form, Validation } from '../../../components'

import './style.css'

export function RiderTaxCertificatesDocument({name, url, status, expiry, accept, reject, edit, message, editMessage, upload}){

  const modal = useModal()
  const messageModal = useModal()
  const uploadModal = useModal()

  function getIcon(){
    switch (status) {
      case "ACCEPTED":
        return <FontAwesome name="check" style={{color: 'var(--valid-color)'}}/>
      case "REJECTED":
      case "EXPIRED":
        return <FontAwesome name="times" style={{color: 'var(--invalid-color)'}}/>
      default:
        return <FontAwesome name="clock" style={{color: 'var(--ui-color)'}}/>
    }
  }

  return(
    <div className='verified-upload'>
      <div className='icon'>
        {getIcon()}
      </div>
      <div className='name'>{name}</div>
      {edit && url &&
        <div className='expiry'>
          <span className='placeholder'><IntlText group="verified-document" id="expiry" /></span>
          <span style={{display: "flex", alignItems: "center"}}>{expiry ? moment.unix(expiry).format("DD/MM/YYYY") : <div><IntlText id="not-set" /></div>}</span>
        </div>
      }
      <Badge  style={{marginLeft: "auto"}} theme={status === "ACCEPTED" ? "valid" : status === "REJECTED" ? "invalid" : "ui"}>
        {status || "WAITING_FOR_UPLOAD"}
      </Badge>           
      <div className='actions'>
        <ContextMenu.Menu>
          <ContextMenu.Button icon="link"><a href={url} target="blank"><IntlText group="verified-document" id="view-document" /></a></ContextMenu.Button>
          <ContextMenu.Button onClick={() => modal?.show()} icon="calendar"><IntlText group="verified-document" id="edit-expiry" /></ContextMenu.Button>
          {editMessage && <ContextMenu.Button onClick={() => messageModal?.show()} icon="comment"><IntlText group="verified-document" id="edit-message" /></ContextMenu.Button>}
          {upload && <ContextMenu.Button onClick={() => uploadModal?.show()} icon="upload"><IntlText group="verified-document" id="upload-document" /></ContextMenu.Button>}
          <ContextMenu.Button className='valid' onClick={accept} icon="check"><IntlText id="accept" /></ContextMenu.Button>
          <ContextMenu.Button className='invalid' onClick={reject} icon="times"><IntlText id="reject" /></ContextMenu.Button>
        </ContextMenu.Menu>
      </div>
      {edit && <ExpiryModal modal={modal} expiry={expiry} edit={edit} />}
      {editMessage && <MessageModal modal={messageModal} message={message} edit={editMessage} />}
      {upload && <UploadModal modal={uploadModal} upload={upload} />}
    </div>    
  )
}

function ExpiryModal({modal, expiry, edit}){

  const [_expiry, setExpiry] = useState(expiry)

  function save(){
    edit(_expiry)
    modal.hide()
  }    

  function hideModal(){
    setExpiry(expiry)
    modal.hide()
  }    

  return(
    <Modalv3 modal={modal} header={<Title tag="h3"><IntlText group="verified-document" id="edit-expiry" /></Title>} onHide={hideModal}>
      <DatePicker.Date date={_expiry ? new Date(_expiry * 1000) : new Date()} onChange={(_date) => setExpiry(moment(_date).unix())} />
      <div className='actions' style={{marginTop: 15}}>
        <Button onClick={hideModal}><IntlText id="cancel" /></Button>
        <Button theme="main" onClick={save}><IntlText id="save" /></Button>
      </div>
    </Modalv3>      
  )
}

function MessageModal({modal, message, edit}){

  const { getTranslation } = useContext(IntlContext)
  const [_message, setMessage] = useState(message)

  function save(){
    edit(_message)
    modal.hide()
  }        

  function hideModal(){
    setMessage(message)
    modal.hide()
  }        

  return(
    <Modalv3 modal={modal} header={<Title tag="h3"><IntlText group="verified-document" id="edit-message" /></Title>} onHide={() => modal.hide()}>
      <LabeledInput placeholder={getTranslation({group: "verified-document", id: "message"})} value={_message} onChange={(e) => setMessage(e.target.value)}/>
      <div className='actions' style={{marginTop: 15}}>
        <Button onClick={hideModal}><IntlText id="cancel" /></Button>
        <Button theme="main" onClick={save}><IntlText id="save" /></Button>
      </div>
    </Modalv3>             
  )    
}

function UploadModal({modal, upload}){

  const validations = {
    password: [{id: "required", validation: (val) => !!val}]
  }  
  const { getTranslation } = useContext(IntlContext)
  const { form: {files, password }, updateField, validate, errors } = useValidatedForm({}, validations)

  function _upload(){
    const { valid } = validate()
    
    if (!valid) return toast.error(getTranslation({id: "form-error"}))    

    upload({files, password})
    modal.hide()
  }        

  return(
    <Modalv3 id="upload-document" modal={modal} header={<Title tag="h3"><IntlText group="verified-document" id="edit-message" /></Title>} onHide={() => modal.hide()}>
      <Form.Form>
        <Form.Field>
          <Validation errors={errors.password}>
            <LabeledInput name="password" placeholder={getTranslation({group: "form", id: "password"})} value={password} onChange={({target}) => updateField({key: "password", value: target.value})} />
          </Validation>
        </Form.Field>
        <Form.Field>
          <div className='drop-box'>
            {!files?.length > 0 ? 
              <span><IntlText group="file-uploader" id="drop-file" /></span>
            : 
              <div>
                <span><IntlText group="file-uploader" id="selected-file" /></span>
                {[...files].map((_file, key) => <span key={key}>{`${_file.name} (${(_file.size / 1000).toFixed(2)} kb)`}</span>)}
              </div>
            }
            <label htmlFor="upload-modal-input" className=""></label>
            <input id="upload-modal-input" type="file" onChange={({target}) => updateField({key: "files", value: target.files})} multiple />
          </div>
        </Form.Field>
        <Form.Field>
          <Button fluid theme="main" onClick={_upload}><IntlText id="upload" /></Button>
        </Form.Field>
      </Form.Form>
    </Modalv3>             
  )    
}