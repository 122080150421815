import { gql } from '@apollo/client'

import { RIDER_MODIFICATION_FRAGMENT } from './'

export const RIDER_MODIFICATION_QUERY = gql`
  query riderModification($id: ID!){
    riderModification(id: $id){
    ...RIDER_MODIFICATION_FRAGMENT
    }
  }
  ${RIDER_MODIFICATION_FRAGMENT}
`
