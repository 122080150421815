import React from 'react'
import { useMutation } from '@apollo/client'

import { useIntl } from '../../../hooks'
import { toast } from '../../../helpers'
import { Form, EditBox } from '../../../components'
import { EDIT_VENDOR_MUTATION } from '../../../graphql'

export function VendorContactDetails({vendor}) {

  const { getTranslation } = useIntl()
  const [editVendor] = useMutation(EDIT_VENDOR_MUTATION)

  async function save(data){
    const alert = toast.loading()
    try {
      await editVendor({variables: {id: vendor.id, data: data}})
      return alert.success(getTranslation({id: "save-success"}))
    } catch (e) {
      alert.apolloError(e)
    }
  }

  return(
    <Form.Form id="vendor-contact" >
      <Form.Field>
        <EditBox.Text placeholder={getTranslation({group: "vendor-contact", id: "email"})} save={(value) => save({email: value})}>{vendor?.email}</EditBox.Text>
      </Form.Field>
      <Form.Field>
        <EditBox.Text placeholder={getTranslation({group: "vendor-contact", id: "whatsapp"})} save={(value) => save({whatsappGroup: value})}>{vendor?.whatsappGroup}</EditBox.Text>
      </Form.Field>
    </Form.Form>
  )

}
