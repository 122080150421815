import React from 'react'
import { useMutation } from '@apollo/client'
import { toast } from 'react-toastify'

import { EDIT_CUSTOMER_MUTATION } from '../../../graphql'
import { MultiTag } from '../../../components'

const tags = [
  {id: "frequent", value: {en: "Frequent client", es: "Frequent client"}},
  {id: "incorrectContact", value: {en: "Incorrect contact details", es: "Datos de contacto incorrectos"}},
  {id: "incorrectAddress", value: {en: "Incorrect address details", es: "Incorrect address details"}},
  {id: "duplicatedOrder", value: {en: "Duplicated order", es: "Duplicated order"}},
  {id: "noCash", value: {en: "Order without cash", es: "Order without cash"}},
  {id: "deliveryFailed", value: {en: "Failed delivery", es: "Failed delivery"}},
  {id: "problematicChange", value: {en: "Problematic modifications", es: "Modificaciones problemáticas"}},
  {id: "problematicBusy", value: {en: "Problematic client", es: "Problematic client"}},
]

export function CustomerTagEditor({customer}) {

  const [editCustomer] = useMutation(EDIT_CUSTOMER_MUTATION)

  async function changeTags(id, {options, values}){
    let data = values ? values.map((value, key) => value.id) : undefined

    if (!data) return toast.error("Nothing to add")

    data = [...new Set(data)]

    try {
      await editCustomer({variables: {id: customer.id, data: {[id]: data}}})
      return toast.success("Successfully changed")
    } catch (e) {
      e.graphQLErrors.map(x => toast.error(x.message))
    }
  }

  return(
    <div id="basic-details">
      <MultiTag values={customer.tags} options={tags} onChange={(data) => changeTags("tags", data)}/>
    </div>
  )

}
