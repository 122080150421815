import React from 'react'

import { getCommonAsset } from '../../../helpers'
import { Button, Form, IntlText, Link } from '../../../components'

export function AnnouncementOptions({placeholder, loading, save, language, url, children}){

  return(
    <Form.Form>
      <Form.Field>
        <Button fluid loading={loading} disabled={loading} theme="main" onClick={save}>{placeholder ? placeholder : <IntlText id="save" />}</Button>
      </Form.Field>                     
      <Form.Legend style={{textAlign: "center", fontWeight: "bold"}}>Available languages</Form.Legend>
      <Form.Fieldset>                     
        <Form.Field>
          <Link to={`${url}/en`}><Button fluid active={language === "en"} innerStyle={{display: "flex", alignItems: "center"}}><img style={{width: 30, height: "auto", marginRight: 5}} src={getCommonAsset("en.png")} alt="English" />English</Button></Link>
        </Form.Field>            
        <Form.Field>
          <Link to={`${url}/es`}><Button fluid active={language === "es"} innerStyle={{display: "flex", alignItems: "center"}}><img style={{width: 30, height: "auto", marginRight: 5}} src={getCommonAsset("es.png")} alt="Spanish" />Spanish</Button></Link>
        </Form.Field>                
      </Form.Fieldset>                     
      {children}
    </Form.Form>
  )
}
