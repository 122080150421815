import { gql } from '@apollo/client'

import { RIDER_FRAGMENT } from './'

export const UPLOAD_RIDER_TAX_DOC = gql`
  mutation uploadRiderTaxDoc($file: Upload!, $id: ID){
    uploadRiderTaxDoc(file: $file, id: $id){
      ...RIDER_FRAGMENT
    }
  }
  ${RIDER_FRAGMENT}
`
