import React from 'react'
import { useMutation } from '@apollo/client'

import { DELETE_ANNOUNCEMENT, EDIT_ANNOUNCEMENT, ANNOUNCEMENTS_QUERY } from '../../../graphql'
import { useConfirm, useIntl } from '../../../hooks'
import { toast } from '../../../helpers'
import { ContextMenu, LabeledCheckbox, IntlValue, StatusDot, Table, IntlText } from '../../../components'

export function AnnouncementList({data}) {

  const { confirm } = useConfirm()
  const { getTranslation } = useIntl()
  const [edit, { loading }] = useMutation(EDIT_ANNOUNCEMENT)
  const [remove] = useMutation(DELETE_ANNOUNCEMENT, {refetchQueries: [{query: ANNOUNCEMENTS_QUERY}]})

  async function _edit(data){
    const alert = toast.loading()
    try {
      await edit(data)
      alert.success(getTranslation({id: "edit-success"}))
    } catch (error) {
      alert.apolloError(error)
    }
  }
  
  async function _remove(id){
    const alert = toast.loading(getTranslation({group: "confirm", id: "confirm-to-continue"}))
    try {
      await confirm()
      alert.update(getTranslation({id: "loading"}))
      await remove({variables: {id}})
      alert.success(getTranslation({id: "delete-success"}))
    } catch (error) {
      alert.apolloError(error)
    }
  }  

  return(
    <Table.Table>
      <Table.Head>
        <Table.Tr>
          <Table.Th></Table.Th>
          <Table.Th className="text-left">Name</Table.Th>
          <Table.Th className="text-left">Url</Table.Th>
          <Table.Th>Active</Table.Th>
          <Table.Th></Table.Th>
        </Table.Tr>
      </Table.Head>
      <Table.Body>
        {data && data.length > 0 ? data.map((page, key) =>
          <Table.Tr key={key} className="page">
            <Table.Td><StatusDot active={!page.hidden} /></Table.Td>
            <Table.Td className="text-left"><IntlValue>{page.title}</IntlValue></Table.Td>
            <Table.Td className="text-left">{page.slug}</Table.Td>
            <Table.Td centered><LabeledCheckbox loading={loading} id={`active-${page.id}`} checked={page.active} onChange={({target}) => _edit({variables: {id: page.id, data: {active: target.checked}}})} rounded/></Table.Td>
            <Table.Td>
              <ContextMenu.Menu id={page?.id}>
                <ContextMenu.Link to={`/announcements/${page.id}`} icon="external-link-alt"><IntlText id="edit" /></ContextMenu.Link>
                <ContextMenu.Button className='invalid' onClick={() => _remove(page.id)} icon="trash"><IntlText id="delete" /></ContextMenu.Button>
              </ContextMenu.Menu>
            </Table.Td>
          </Table.Tr>
        ) :
          <Table.Tr>
            <Table.Td colSpan={5}>There aren't any pages to show.</Table.Td>
          </Table.Tr>
        }
      </Table.Body>
    </Table.Table>
  )
}
