import React from 'react'

import './style.css'

export function ProgressSteps({items, selected, change, navigationEnabled = true}){

  function changeStep(num){
    if (change) change(num)
  }

  return(
    <div className="progress-steps">
      {items.map((item, key) =>
        <div key={key} className={`step ${(key+1) === selected && "active"} ${(key+1) < selected && "done"}`} onClick={() => navigationEnabled ? changeStep(key) : null}>
          <div>
            <span className="number">{++key}</span>
            <span className="label">{item}</span>
          </div>
          <span className="separator"></span>
        </div>
      )}
    </div>
  )

}
