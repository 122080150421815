import { gql } from '@apollo/client'

export const DELIVERY_ZONE_FRAGMENT = gql`
  fragment DELIVERY_ZONE_FRAGMENT on DeliveryZone {
    id
    name
    slug
    disabled
    disabledReason
    geometry{
      type
      coordinates
    }
  }
`
