import { useEffect } from 'react'
import { useQuery } from '@apollo/client'
import moment from 'moment'
import FontAwesome from 'react-fontawesome'

import { PURCHASES_QUERY, MY_PURCHASES_UPDATED_SUBSCRIPTION, NEW_PURCHASE_SUBSCRIPTION } from '../../../graphql'
import { useAwakeDetection } from '../../../hooks'
import { Loader } from '../../../components'

export function ActiveOrders({runQuery}){

    const { data, error, loading, networkStatus, subscribeToMore, refetch } = useQuery(PURCHASES_QUERY, {variables: {date: moment().format("YYYY-MM-DD"), status: ["REQUIRES_CONFIRMATION", "INQUEUE", "PROCESSED", "INPREPARATION", "READYFORCOLLECTION", "ONTHEWAY", "ATLOCATION"]}, notifyOnNetworkStatusChange: false})

    useAwakeDetection({callback: refetch, minutes: 1})
    useEffect(() => {
      const myPurchasesUpdated = subscribeToMore({
        document: MY_PURCHASES_UPDATED_SUBSCRIPTION,
        updateQuery: (prev, { subscriptionData }) => {
          if (!subscriptionData.data) return prev
          const _data = Array.from(prev?.purchases || [])
          const index = _data.findIndex((purchase) => purchase.id === subscriptionData.data.myPurchasesUpdated.id)
          if (index >= 0){
            if (["CANCELLED", "DELIVERYFAILED", "PAYMENTFAILED", "REJECTED", "UNPROCESSED", "COMPLETED"].includes(_data[index].status)){
              _data.splice(index, 1)
            } else {
              _data[index] = subscriptionData.data.myPurchasesUpdated
            }
            return Object.assign({}, prev, {
              purchases: _data
            })
          } else {
            return Object.assign({}, prev, {
              purchases: [..._data, subscriptionData.data.myPurchasesUpdated]
            })              
          }
        }
      })
      
      const newPurchase = subscribeToMore({
        document: NEW_PURCHASE_SUBSCRIPTION,
        updateQuery: (prev, { subscriptionData }) => {
          if (!subscriptionData.data) return prev
          const _data = Array.from(prev?.purchases || [])
          return Object.assign({}, prev, {
            purchases: [subscriptionData.data.newPurchase, ..._data]
          })
        }
      })

      return () => {
        myPurchasesUpdated()
        newPurchase()
      }
    // eslint-disable-next-line
    }, [])    
  
    function _onClick(_data){
      runQuery({instantParams: _data})
    }  
  
    if ((loading || error) && networkStatus === 1) return <Loader theme="main"/>  
  
    const activeOrders = data?.purchases?.filter(_purchase => _purchase.status !== "INQUEUE")?.length || 0
    const inqueueOrders = data?.purchases?.filter(_purchase => _purchase.status === "INQUEUE")?.length || 0
    const waitFortider = data?.purchases?.filter(_purchase => !_purchase.rider && _purchase.status !== "INQUEUE")?.length || 0
  
    return(
      <>
        <div className="btn" onClick={() => _onClick({status: {value: ["REQUIRES_CONFIRMATION", "PROCESSED", "INPREPARATION", "READYFORCOLLECTION", "ONTHEWAY", "ATLOCATION"], label: "Active orders"}})}>
          <FontAwesome name="hamburger" />
          <span className="label">Active orders</span>
          <span className="value">{activeOrders}</span>
        </div>
        <div className="btn" onClick={() => _onClick({hasRider: {value: false, label: "Without rider"}, status: {value: ["REQUIRES_CONFIRMATION", "PROCESSED", "INPREPARATION", "READYFORCOLLECTION"], label: "Wait for collection"}})}>
          <FontAwesome name="clock" />
          <span className="label">Wait for rider</span>
          <span className="value">{waitFortider}</span>
        </div>    
        {inqueueOrders > 0 &&
          <div className="btn" onClick={() => _onClick({status: {value: ["INQUEUE"], label: "Orders inqueue"}})}>
            <FontAwesome name="clock" />
            <span className="label">Orders inqueue</span>
            <span className="value">{inqueueOrders}</span>
          </div>            
        }
      </>
    )
  }