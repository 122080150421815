import { gql } from '@apollo/client'

import { SERVICE_FEE_PROFORMA_INVOICE_FRAGMENT } from './'

export const EDIT_SERVICE_FEE_INVOICE = gql`
  mutation editServiceFeeProformaInvoice($id: ID!, $data: EditedProformaInvoiceServiceFeeInput!){
    editServiceFeeProformaInvoice(id: $id, data: $data){
      ...SERVICE_FEE_PROFORMA_INVOICE_FRAGMENT    
    }
  }
  ${SERVICE_FEE_PROFORMA_INVOICE_FRAGMENT}
`
