import { gql } from '@apollo/client'

import { RIDER_MODIFICATION_FRAGMENT } from './'

export const RIDER_MODIFICATIONS_QUERY = gql`
  query riderModifications($date: String, $rider: ID, $reason: [RIDER_MODIFICATION_REASON]){
    riderModifications(date: $date, rider: $rider, reason: $reason){
    ...RIDER_MODIFICATION_FRAGMENT
    }
  }
  ${RIDER_MODIFICATION_FRAGMENT}
`
