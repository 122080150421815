import { gql } from '@apollo/client'

import { PURCHASE_FRAGMENT } from './'

export const MY_PURCHASE_UPDATED_SUBSCRIPTION = gql`
  subscription myPurchasesUpdated{
    myPurchasesUpdated{
      ...PURCHASE_FRAGMENT
    }
  }
  ${PURCHASE_FRAGMENT}
`
