export function Filterv2({data, filters = [], children}){

    const _data = data.filter((elem, key) => {
      if (!filters?.length > 0) return elem
      if (filters.every((filter) => {
        if (filter.skip) return true
        return filter.filter(elem)
      })) return elem
  
      return undefined
    })
  
    return children(_data)
  }