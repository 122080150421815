import { gql } from '@apollo/client'

import { CHANGELOG_FRAGMENT } from './'

export const EDIT_CHANGELOG = gql`
  mutation editChangelog($id: ID!, $data: EditedChangelogInput!){
    editChangelog(id: $id, data: $data){
      ...CHANGELOG_FRAGMENT
    }
  }
  ${CHANGELOG_FRAGMENT}
`
