import React from 'react'
import FontAwesome from 'react-fontawesome'

import "./style.css"

export function CheckboxField({id, checked, onChange, label, disabled, children, ...rest}){

  return(
    <div className="checkbox-field" {...rest}>
      {(label || children) && <div className="label">{label || children}</div>}
      <input id={id} type="checkbox" disabled={disabled} checked={checked} onChange={onChange}/>
      <label htmlFor={id}>
        {disabled ? <FontAwesome name="times" /> : <span></span>}
      </label>
    </div>
  )
}
