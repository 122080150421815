import { gql } from '@apollo/client'

export const ADMIN_FRAGMENT = gql`
  fragment ADMIN_FRAGMENT on Admin {
    id
    name
    username
    role
    superAdmin
  }
`
