import { useState, useContext } from 'react'
import { useQuery, useMutation } from '@apollo/client'
import { useLocation } from 'react-router-dom'
import moment from 'moment'

import { useConfirm, useQueryBuilder } from '../../hooks'
import { formatPrice, toast } from '../../helpers'
import { GET_RIDER_WORKSHIFTS, DELETE_RIDER_WORKSHIFT } from '../../graphql'
import { IntlContext } from '../../contexts'
import { Wrapper, Filter, Filters, Table, IntlText, ContextMenu, DatePicker, StatusDot } from '../../components'
import { StatBar } from '../../views'

export function Workshifts() {

  const location = useLocation()
  const { confirm } = useConfirm()
  const [deleteWorkShift] = useMutation(DELETE_RIDER_WORKSHIFT)
  const { getTranslation } = useContext(IntlContext)
  const search = new URLSearchParams(location.search)
  const [filter, setFilter] = useState()
  const { updateQuery, runQuery, buildQuery, isQuery, params, removeParam } = useQueryBuilder({
    data: {
      date: {key: "date", label: search.get("date") || moment().format("YYYY-MM-DD"), value: search.get("date") || moment().format("YYYY-MM-DD")},
    }
  })
  const { data, error, refetch } = useQuery(GET_RIDER_WORKSHIFTS, {variables: buildQuery(), skip: !isQuery(), notifyOnNetworkStatusChange: true})

  function onSearch(e){
    if (e.key !== 'Enter') return false
    runQuery()
  }

  function setDate(_date){
    updateQuery({date: {key: "date", label: moment(_date).format("YYYY-MM-DD"), value: moment(_date).format("YYYY-MM-DD"), skip: !_date}})
  }

  async function _deleteWorkShift(_id){
    const alert = toast.loading(getTranslation({group: "confirm", id: "confirm-to-continue"}))
    try {
      await confirm({title: getTranslation({group: "confirm", id: "delete"}), desc: getTranslation({group: "confirm", id: "delete-info"})})
      alert.update(getTranslation({id: "loading"}))
      await deleteWorkShift({variables: {id: _id}})
      alert.success(getTranslation({id: "delete-success"}))
      refetch()        
    } catch (error) {
      alert.apolloError(error)
    }
  }

  if (error) return `Error! ${error.message}`

  return(
    <Wrapper>
      <div id="rider-workshifts" className="container">
        <div className="row padding">
          <Filters.Bar >
            <Filters.Input id="query" name="query" value={filter} onChange={({target}) => setFilter(target.value)} reset={() => setFilter(null)} placeholder={getTranslation({id: "search-type"})} onKeyDown={onSearch}/>
            <Filters.Dropdown style={{marginLeft: "auto"}} placeholder={getTranslation({id: "date"})} value={params?.date?.label} right disableInClickClose reset={() => removeParam("date")}>
              <DatePicker.Date date={params?.date?.value && new Date(`${params.date.value}T00:00`)} onChange={setDate} />
            </Filters.Dropdown>            
          </Filters.Bar>
          <StatBar buildQuery={buildQuery} isQuery={isQuery} />
          <Filter id="breaks" data={data?.getRiderWorkShifts || []} search={[{key: "search", value: filter}]} filters={[{filter: (data) => data.rider.name}]} sortables={[{id: "name", type: "text", data: (elem) => elem.name}]} >
            {(data) =>
              <Table.Table>
                <Table.Head>
                  <Table.Tr>
                    <Table.Th />
                    <Table.Th><IntlText group="form" id="name" /></Table.Th>
                    <Table.Th><IntlText group="form" id="start-date" /></Table.Th>
                    <Table.Th><IntlText group="form" id="end-date" /></Table.Th>
                    <Table.Th><IntlText group="form" id="duration" /></Table.Th>
                    <Table.Th><IntlText group="form" id="completed-deliveries" /></Table.Th>
                    <Table.Th><IntlText group="form" id="revenue" /></Table.Th>
                    <Table.Th><IntlText group="form" id="modifications" /></Table.Th>
                    <Table.Th><IntlText group="form" id="tips" /></Table.Th>
                  </Table.Tr>
                </Table.Head>
                <Table.Body> 
                  {data.map((riderWorkshift, key) => 
                    <Table.Tr key={key} className="rider-workshift">
                      <Table.Td style={{width: 20}}><StatusDot active={riderWorkshift.status === "ACTIVE"} /></Table.Td>
                      <Table.Td>
                        <div style={{display: "flex", alignItems: "center", gap: 10}}>
                          {riderWorkshift.rider.name}
                          <ContextMenu.Menu id={riderWorkshift?.id}>
                            {riderWorkshift.status === "ACTIVE" ?
                              <ContextMenu.Link to={`${riderWorkshift?.id}/active`} icon="clock"><IntlText group="rider-workshifts" id="finish-workshift" /></ContextMenu.Link>
                              :
                              <ContextMenu.Link to={riderWorkshift?.id} icon="external-link-alt"><IntlText id="open" /></ContextMenu.Link>
                            }
                            <ContextMenu.Link to={`/riders/${riderWorkshift.rider.originalId}`} icon="motorcycle"><IntlText id="view" /> rider</ContextMenu.Link>
                            {riderWorkshift.status === "FINISHED" &&
                              <ContextMenu.Button className='invalid' onClick={() => _deleteWorkShift(riderWorkshift?.id)} icon="trash"><IntlText id="delete" /></ContextMenu.Button>
                            }
                          </ContextMenu.Menu>
                        </div>
                      </Table.Td>                    
                      <Table.Td>{riderWorkshift.start ? moment(riderWorkshift.start).format("HH:mm") : "-"}</Table.Td>
                      <Table.Td>{riderWorkshift.end ? moment(riderWorkshift.end).format("HH:mm") : "-"}</Table.Td>            
                      <Table.Td><span style={{whiteSpace: "nowrap"}}>{riderWorkshift?.start && riderWorkshift?.end ? `${moment.duration(moment(riderWorkshift.end).diff(riderWorkshift.start)).asMinutes().toFixed(0)} minutes` : ""}</span></Table.Td>
                      <Table.Td className="center">{riderWorkshift.account.completedDeliveries}</Table.Td>
                      <Table.Td className="center">{formatPrice(riderWorkshift.account.totalRevenue)}</Table.Td>
                      <Table.Td className="center">{formatPrice(riderWorkshift.account.totalModifications)}</Table.Td>
                      <Table.Td className="center">{formatPrice(riderWorkshift.account.totalTips)}</Table.Td>
                    </Table.Tr>
                  )}         
                </Table.Body>          
              </Table.Table>       
            }   
          </Filter>
        </div>
      </div>
    </Wrapper>
  )
}