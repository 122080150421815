import { gql } from '@apollo/client'

export const RIDER_PAYOUT_FRAGMENT = gql`
  fragment RIDER_PAYOUT_FRAGMENT on RiderPayout {
    id
    reference
    rider{
      originalId
      name
      username
      fullName
    }
    grossInvoiceAmount
    netInvoiceAmount
    tax
    invoice
    invoiceId
    status
    statusMessage
    invoiceDueAt
    invoiceUploadedAt
    periodicity
    createdAt
    updatedAt
  }
`
